import { ApiService } from "@services/api.service";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Const } from "@const/Const";
import { UIHelper } from "@services/UIHelper";
import { RequestUpdateTaskAnswer } from "@app/interfaces";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { WorkTask } from "@wearewarp/types/data-model";
import { WorkTaskUtil } from "@app/utils/worktask";
import { RequestUpdateTaskPriority } from "@wearewarp/types-server-workqueue/common";
import { WorkTaskPriorityMask } from "@wearewarp/types/data-model/types/WorkTask";


@Injectable()
export class TaskHandlerService {
  
  constructor(private api: ApiService) {}
  
  updateTaskAnswer(taskId: string, data: RequestUpdateTaskAnswer): Observable<any> {
    if (!taskId) {
      UIHelper.showErr('Invalid taskId');
      return;
    }
    const url = `${environment.backendWorkQueueUrl}/${Const.API_UPDATE_TASK_ANSWER(taskId)}`;
    return this.api.POST(url, data).pipe(
      map((resp: any) => resp?.data)
    );
  }

  completeTask(taskId: string) {
    const url = `${environment.backendWorkQueueUrl}/${Const.API_COMPLETE_TASK(taskId)}`;
    return this.api.POST(url, {}).pipe(
      map((resp: any) => resp?.data)
    );
  }

  updateTaskPriority(task: WorkTask, props: {priorityMask: WorkTaskPriorityMask, note: string}) {
    const isTrackTraceTask = WorkTaskUtil.isTrackTraceTask(task);
    let url = '';
    if (isTrackTraceTask) {
      const jobId = task.object.entityId;
      url = `${environment.backendWorkQueueUrl}/${Const.API_UPDATE_JOB_TT_TASKS_PRIORITY(jobId)}`;
    } else {
      url = `${environment.backendWorkQueueUrl}/${Const.API_UPDATE_TASK_PRIORITY(task.id)}`;
    }
    const params: RequestUpdateTaskPriority = {
      priorityMask: props.priorityMask,
      note: props.note
    }
    return this.api.POST(url, params).pipe(
      map((resp: any) => resp?.data)
    );
  }


}