<div class="view-full-screen">
    <img *ngIf="!isPDF" class="match-parent" [src]="itemUrl" alt="" style="width: 100%; height: 100%; object-fit: contain; display: block; margin: 0 auto;"/>
    <pdf-viewer *ngIf="isPDF" [src]="itemUrl" style="height:100%"></pdf-viewer>
    <div class="buttons">
        <div class="btn-navigation back" [ngClass]="{'disabled': !canBack}" (click)="onBtnBack()">
          <i nz-icon nzType="left" nzTheme="outline"></i>
        </div>
        <div class="space"></div>
        <div class="btn-navigation next" [ngClass]="{'disabled': !canNext}" (click)="onBtnNext()">
          <i nz-icon nzType="right" nzTheme="outline"></i>
        </div>
      </div>
</div>
  