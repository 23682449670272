import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AppointmentInfo } from "@wearewarp/types/data-model";
import { DateUtil } from '@services/date-util';
import { CommonModule } from "@angular/common";
import { NzTagModule } from "ng-zorro-antd/tag";
@Component({
  selector: 'display-appointment',
  standalone: true,
  imports: [CommonModule, NzTagModule],
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAppointment {
  public displayInfo: any = {};

  @Input() appointment: AppointmentInfo;
  @Input() label: string;
  @Input() timezone: string;
  @Input() scheduled?: boolean = false;

  ngOnChanges() {
    this.displayInfo = {
      time: this.getDisplayTime(),
      appointmentType: this.getAppointmentType(),
      scheduled: this.scheduled,
      timezone: this.getDisplayTimezone()
    }
  }

  private getDisplayTime() {
    return DateUtil.displayTimeWindow(this.appointment, {
      timezone: this.timezone,
      formatDateOnly: 'MM/DD/YY',
      format: "MM/DD/YY h:mm A",
    })
  }
  private getAppointmentType() {
    switch (this.appointment?.type) {
      case 'fcfs': return 'FCFS'
      case 'callAhead': return 'Call Ahead'
      case 'strictAppointment': return 'Strict Appointment'
    }
  }

  private getDisplayTimezone(){
    return DateUtil.timezoneStandardToUsShort(this.timezone)
  }

}