import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { environment } from "@env/environment";
import { ApiService } from "@services/api.service";
import { Observable } from "rxjs";

export interface EntityInfo {
  entityId: string;
  entity: string;
  name: string;
}
@Injectable({
  providedIn: 'root'
})
export class WhenByService {
  constructor(private api: ApiService) { }

  getListWhenBy(entityIds: {entityId: string, entity: string}[]): Observable<EntityInfo[]> {
    const data = entityIds.map(it => {
      return {
        entityId: it.entityId,
        entity: it.entity || 'users'
      }
    }).filter(it => it.entityId!!);
    return this.api.POST(`${environment.backendWorkQueueUrl}/v1/users/list-when-by-info`, {
      data
    }).pipe(
      map(response => response?.data?.list_data as unknown as EntityInfo[])
    );
  }
}
