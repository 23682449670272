import { Component, OnInit } from '@angular/core';
import { TrackTraceService } from '../../services/track-trace.service';
import { Job, WorkTask } from '@wearewarp/types/data-model';
import { CommonModule } from '@angular/common';
import { DialogService } from '@app/dialogs/dialog.service';
import { NotifySlackComponent } from './notify-slack/notify-slack.component';
import { RemindTaskDialog } from '@app/components/worktask-dialogs/remind-task';
import { GetNextTaskDialog } from '@app/components/worktask-dialogs/next-task';
import { TaskHandlerService } from '@app/track-trace/services/task-handler.service';
import { UIHelper } from '@services/UIHelper';
import { ModalHelper } from '@wearewarp/ng-antd-2';
import { UpdateTaskPriorityMaskDialog } from './update-priority';
import { Const } from '@const/Const';
import { StatisticsService } from '@app/track-trace/services/statistics.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
@Component({
  selector: 'app-load-actions',
  templateUrl: './load-actions.component.html',
  styleUrls: ['./load-actions.component.scss'],
  standalone: true,
  imports: [CommonModule, NzButtonModule],
  providers: [TaskHandlerService, ModalHelper]
})
export class LoadActionsComponent implements OnInit {
  taskInfo: WorkTask;
  loadInfo: Job;

  isProcessing = false;

  constructor(
    private trackTraceService: TrackTraceService,
    private taskHandlerService: TaskHandlerService,
    private statisticsService: StatisticsService,
    private modalHelper: ModalHelper
  ) {}

  ngOnInit() {
    this.trackTraceService.getWorkTask().subscribe(info => {
      this.taskInfo = info;
    });
    this.trackTraceService.getLoadInfo().subscribe(info => {
      this.loadInfo = info;
    });
  }

  openNotifySlack() {
    DialogService.openFormDialog(NotifySlackComponent, {
      nzTitle: null,
      nzFooter: null,
      nzWidth: 600,
      nzData: {
        loadInfo: this.loadInfo
      }
    });
  }

  onBtnRemindTask() {
    DialogService.openFormDialog(RemindTaskDialog, {
      nzTitle: null,
      nzData: {
        task: this.taskInfo,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.fetchNewTask();
        }
      },
      nzMaskClosable: false,
      nzFooter: null,
      nzWidth: 510,
    });
  }

  onBtnNextTask() {
    if (this.isProcessing) return;
    this.isProcessing = true;
    const isSpecificTask = this.trackTraceService.isSpecificTask.value;
    if (isSpecificTask) {
      this.openNextTaskDialog();
      this.isProcessing = false;
    }
    else {
      this.completeTask();
    }
  }

  completeTask() {
    const taskId = this.taskInfo?.id;
    this.taskHandlerService.completeTask(taskId).subscribe({
      next: (resp) => {
        this.openNextTaskDialog();
        this.isProcessing = false;
      },
      error: (err) => {
        UIHelper.showErr(err);
        this.openNextTaskDialog();
        this.isProcessing = false;
      }
    });
  }

  openNextTaskDialog() {
    DialogService.openFormDialog(GetNextTaskDialog, {
      nzTitle: null,
      nzData: {
        task: this.taskInfo,
        stopWorking: () => {
          this.stopWorking();
          this.isProcessing = false;
        },
        getNextTask: () => {
          this.fetchNewTask();
          this.isProcessing = false;
        },
        resetInputs: () => {
          this.resetNotes();
        }
      },
      nzMaskClosable: false
    });
  }

  resetNotes() {
    this.trackTraceService.resetNotes();
  }

  fetchNewTask() {
    this.trackTraceService.fetchNewTask();
  }

  stopWorking() {
    this.trackTraceService.stopWorking();
  }

  onBtnUpdateMarkUrgent(type: 'markUrgent' | 'unmarkUrgent') {
    let title = '';
    let priorityMask = Const.PriorityMask.medium;
    if (type === 'markUrgent') {
      title = 'Mark this route as urgent';
      priorityMask = Const.PriorityMask.urgent;
    }
    else {
      title = 'Unmark this route as urgent';
      priorityMask = Const.PriorityMask.medium;
    }
    this.modalHelper.open(UpdateTaskPriorityMaskDialog, {
      nzTitle: title,
      nzData: {
        task: this.taskInfo,
        priorityMask,
        onSuccess: () => {
          this.trackTraceService.refreshThisTask();
        }
      },
      nzFooter: null,
      nzWidth: 510,
    });
  }

  isUrgent() {
    return this.taskInfo?.priorityMask === Const.PriorityMask.urgent;
  }
} 