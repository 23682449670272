import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { map, Observable } from "rxjs";
import { DeliveryUpdate } from "@app/interfaces/map-v2/delivery-update";
import { ApiService } from "@services/api.service";

@Injectable({
  providedIn: 'root'
})
export class DeliveryUpdateService {
  baseUrl: string | null = null

  constructor(
    private apiService: ApiService
  ) {
    this.baseUrl = environment.eventUrl + '/delivery'
  }

  loadJobDriverLocations(jobId: string, limit?: number): Observable<DeliveryUpdate[]> {
    return this.loadDeliveryUpdates('JOB', jobId, 'LOCATION', limit).pipe(map((l) => l.map(this.convertMph)))
  }

  loadDriverLocations(id: string, limit?: number): Observable<DeliveryUpdate[]> {
    const comps = id.split('_')
    return this.loadDeliveryUpdates(comps[0], comps[1], 'LOCATION', limit).pipe(map((l) => l.map(this.convertMph)))
  }

  convertMph(update: DeliveryUpdate): DeliveryUpdate {
    if (update.source == 'driver-app' && update.location.speed !== undefined) {
      update.location.speed = Math.max(0, update.location.speed * 3600 / 1609.34)
    }
    return update
  }

  loadDeliveryUpdates(entity: string, id: string, type: string, limit?: number): Observable<DeliveryUpdate[]> {
    if (environment.mock) {
      console.log('mock request')
      let fileName = type == 'LOCATION' ? 'locations' : ''
      const p = require(`../../../../../assets/mock/${fileName}.json`);
      return new Observable((observer) => observer.next(p))
    }
    return this._loadDeliveryUpdates(entity, id, type, limit)
  }

  private _loadDeliveryUpdates(entity: string, id: string, type: string, limit?: number) {
    const url = `${this.baseUrl}/${entity}_${id}/${type}` + (limit ? `?limit=${limit}` : '')
    return this.apiService.GET(url)
  }
}