import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Carrier, Driver } from "@wearewarp/types/data-model";
import { map, Observable } from "rxjs";
import { ApiService } from "@services/api.service";

@Injectable({
  providedIn: 'root'
})
export class CarrierService {
  constructor(private api: ApiService) { }

  getCarriers(carrierIds: string[]): Observable<Carrier[]> {
    return this.api.POST(`${environment.backendWorkQueueUrl}/v1/carriers`, {
      carrierIds
    }).pipe(
      map(response => response?.data?.list_data as unknown as Carrier[])
    );
  }
}
