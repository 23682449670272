export const PIN_ICON_MAPPING = {
    red: { x: 0, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    green: { x: 40, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    blue: { x: 80, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    orange: { x: 120, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    redGreen: { x: 0, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    greenBlue: { x: 40, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    blueOrange: { x: 80, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    orangeRed: { x: 120, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
};

export const warehousePinMapping = () => {
    const pins: any = {}
    for (let index = 0; index < 15; index++) {
        const pin = {
            x: index * 29,
            y: 0,
            width: 29,
            height: 38,
            anchorY: 35,
            mask: false,
        }
        pins[index.toString()] = pin
    }
    
    // Map color names to indices
    pins['default'] = pins['0']      // yellow/white center
    pins['yellow'] = pins['0']
    pins['success'] = pins['1']      // green
    pins['green'] = pins['1']
    pins['failed'] = pins['2']       // red
    pins['red'] = pins['2']
    pins['inprogress'] = pins['3']   // blue
    pins['blue'] = pins['3']
    pins['arrived'] = pins['4']      // orange
    pins['orange'] = pins['4']
    pins['canceled'] = pins['5']     // gray
    pins['gray'] = pins['5']
    pins['cyan'] = pins['6']         // cyan
    pins['purple'] = pins['7']       // purple
    pins['maroon'] = pins['8']       // maroon
    pins['navy'] = pins['9']         // navy
    pins['teal'] = pins['10']        // teal
    pins['lime'] = pins['11']        // lime
    pins['black'] = pins['12']       // black
    pins['fuchsia'] = pins['13']     // fuchsia
    pins['olive'] = pins['14']       // olive
    // Aliases
    pins['aqua'] = pins['cyan']      // aqua is same as cyan
    return pins
}

export const DRIVER_PIN_ICON_MAPPING = {
    "0": { x: 0, y: 0, width: 36, height: 36, mask: false, anchorY: 18 },
    "1": { x: 40, y: 0, width: 36, height: 36, mask: false, anchorY: 18 },
    "2": { x: 80, y: 0, width: 36, height: 36, mask: false, anchorY: 18 },
    "3": { x: 120, y: 0, width: 36, height: 36, mask: false, anchorY: 18 },
    "4": { x: 160, y: 0, width: 36, height: 36, mask: false, anchorY: 18 },
    "5": { x: 200, y: 0, width: 36, height: 36, mask: false, anchorY: 18 },

    "01": { x: 0, y: 36, width: 36, height: 36, mask: false, anchorY: 18 },
    "11": { x: 40, y: 36, width: 36, height: 36, mask: false, anchorY: 18 },
    "21": { x: 80, y: 36, width: 36, height: 36, mask: false, anchorY: 18 },
    "31": { x: 120, y: 36, width: 36, height: 36, mask: false, anchorY: 18 },
    "41": { x: 160, y: 36, width: 36, height: 36, mask: false, anchorY: 18 },
    "51": { x: 200, y: 36, width: 36, height: 36, mask: false, anchorY: 18 },

};

export const MAP_PIN_ICON_MAPPING = {
    green: { x: 0, y: 0, width: 40, height: 64, mask: false, anchorY: 64 },
    red: { x: 40, y: 0, width: 40, height: 64, mask: false, anchorY: 64 },
    blue: { x: 80, y: 0, width: 40, height: 64, mask: false, anchorY: 64 },
    orange: { x: 120, y: 0, width: 40, height: 64, mask: false, anchorY: 64 },
    cyan: { x: 160, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    purple: { x: 200, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    maroon: { x: 240, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    navy: { x: 280, y: 0, width: 36, height: 64, mask: false, anchorY: 64 },
    silver: { x: 0, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    teal: { x: 40, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    aqua: { x: 80, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    olive: { x: 120, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    fuchsia: { x: 160, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    black: { x: 200, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    yellow: { x: 240, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
    lime: { x: 280, y: 64, width: 36, height: 64, mask: false, anchorY: 64 },
};
