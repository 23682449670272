{{ type }}
@if (_task.metadata?.shipment) {
    <job-task-code [task]="_task"></job-task-code>
} @else if (_task.metadata?.shipmentCode) {
    <warp-code [theme]="status" [code]="_task.metadata?.shipmentCode" style="font-size: 13px; line-height: 13px;; margin-right: 3px; margin-left: 2px; vertical-align: text-top;"></warp-code>
}
<warp-status [status]="status"></warp-status>
@if (updatedWhen) {
<span style="color: #888;" class="left-3 right-3">&#64;</span>
<span>{{ updatedWhen | date : 'MM/dd hh:mma'}}</span>
}