import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseFormDialog } from '@app/dialogs/base-form-dialog';
import { Const } from '@const/Const';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { Utils } from '@services/utils';
import { UIHelper } from '@services/UIHelper';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { RouterModule } from '@angular/router';
import { TrackTraceService } from '@app/track-trace/services/track-trace.service';
import { AddressUS } from '@wearewarp/types/data-model';
import { MasterData } from '@services/master.data';
import { RouteService } from '@app/work-dispatcher/task-queue/services/route.service';
import { FormFooter } from '@app/form-footer/form-footer';

const CustomInputs = [CommonModule, RouterModule, FormsModule, NzFormModule, NzInputModule, NzButtonModule, NzIconModule, NzSpinModule, NzCheckboxModule, NzSelectModule, ReactiveFormsModule, NzAutocompleteModule, FormFooter]

@Component({
  selector: 'app-email-bol',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrl: './index.scss'
})
export class EmailBolComponent extends BaseFormDialog implements OnInit {
  protected formGroupDeclaration: FormGroupDeclaration = {
    // sendToCustomer: { label: "Customer" },
    customerEmails: { label: "Email" },
    sendToCarrier: { label: "Carrier" },
    carrierEmail: { label: "Email" },
    carrierName: { label: "Email" },
    remark: { label: "Remark" },
    sendToPickupStop: { label: "Pickup Stop" },
  };
  
  @Input() jobId;
  @Input() bolFileId;
  @Input() carrier;
  // @Input() clients;
  @Input() stops;
  @Input() loadInfo;
  public isError = false;
  public isLoading = false;
  public isUpdate: boolean = false;
  public onProgress: boolean = false;
  public confirmData: any = {};
  public inConfirm: boolean = false;
  public indeterminate = false;
  public contacts: any[] = [];
  public tasks: any[] = [];
  public routeAdminOrderList = Const.routeAdminOrderList;

  allContacts = new Map();
  sendContacts = new Set();

  constructor(@Inject(NZ_MODAL_DATA) data: any, private trackTraceService: TrackTraceService, private routeService: RouteService) {
    super(data);
    this.jobId = data.jobId;
    this.loadInfo = data.loadInfo;
    this.bolFileId = data.bolFileId;
    this.carrier = data.carrier;
    this.stops = data.stops;
    // this.clients = data.clients;
  }

  ngOnInit(): void {
    this.setRouteId(this.jobId);
    this.routeService.fetchShipments();
    this.getData();
  }

  private async setRouteId(jobId: string) {
    await this.routeService.setRouteId(jobId);
  }

  hasSendTo(type) {
    return this.getItemValue(type);
  }

  get canClickOK() {
    let data: any = this.getFormData_JSON(true);
    //nếu chọn customer nhưng khôngnhập email customer
    // if (data.sendToCustomer && data.customerEmails?.length == 0) return false;
    //nếu chọn carrier nhưng không nhập email carrier
    if (data.sendToCarrier && !data.carrierEmail) return false;
    // nếu select stop nhưng không chọn task
    if (data.sendToPickupStop && this.sendContacts.size === 0) return false;
    //nếu không chọn cả carrier & customer
    if (!data.sendToCarrier && !data.sendToPickupStop) return false;

    return true;
  }

  async getData() {
    this.createFormInput();

    //lấy luôn dữ liệu từ job để đẩy vào form, không cần fetch lại API
    let basicInfo = this.carrier?.basicInfo || {};
    let carrierContacts = basicInfo?.contacts || [];
    let carrierContact = basicInfo.owner?.email ? basicInfo.owner : (carrierContacts[0] || {});
    this.contacts = Utils.uniqElementsArray([carrierContact?.email, ...carrierContacts.map(it => it.email)].filter(it => it));
    // let clientEmails = [];
    // clients.map((client) => {
    //   let contact = client.primaryContact || {};
    //   let email = contact.email;
    //   console.log('email', email);
    //   if (!email) return;
    //   clientEmails.push(email);
    // });

    if (carrierContact.email) {
      this.setItemValue("sendToCarrier", true);
      this.setItemValue("carrierName", basicInfo.name || "Carrier");
      this.setItemValue("carrierEmail", carrierContact.email);
      this.getItemByKey("carrierEmail").disable();
    }

    this.stops.forEach((stop) => {
      if (stop.type !== Const.TaskType.PICKUP) return;

      this.trackTraceService.getSpecificTask(stop.taskIds[0]).subscribe(task => {
          const email = this.getPrimaryContact(task)?.email;
          const name = Utils.getFullName(this.getPrimaryContact(task));
          if (email && !this.allContacts.has(email)) {
              this.allContacts.set(email, { name, email, type: "Warehouse", task: task });
          }
          if(!stop.tasks) {
            stop.tasks = [];
          }
          stop.tasks.push(task);
      }, error => {
          console.error("Error fetching task:", error);
      });
      // tasks.forEach((task) => {
      //   const email = task.getPrimaryContact()?.email;
      //   const name = this.getFullName(task.getPrimaryContact());
      //   console.log("EMAIL & NAME", email, name)
      //   if (email && !this.allContacts.has(email)) {
      //     this.allContacts.set(email, { name: name, email: email, type: "Warehouse" });
      //   }
      // });
    });
    // //luôn set giá trị cho array customerEmails để tránh null
    // this.setItemValue("customerEmails", clientEmails);
    // if (clientEmails.length > 0) {
    //   this.getItemByKey("customerEmails").disable();
    //   this.setItemValue("sendToCustomer", false);
    // }
  }

  getFullName(user) {
    return Utils.getFullName(user);
  }

  getPrimaryContact(task) {
    const primaryContact = task.info?.contacts?.find(contact => contact.type === 'primary');
    if (primaryContact) return primaryContact;
    return task.info?.contacts?.[0];
  }

  getLocationName(stop) {
    return stop.info.locationName || "No Name"
  }

  getAddressText(stop): string {
    let addr: AddressUS = stop?.info?.addr;
    if (!addr) return '';
    let countryName;
    if (addr.countryId && addr.countryId != Const.CountryId_USA) {
      countryName = `(${MasterData.getCountryNameById(addr.countryId)})`;
    }
    let addrArray = [addr.street, addr.street2, addr.city, addr.state, addr.zipcode, countryName];
    return addrArray.filter(it => it).join(', ')
  }

  getShipment(task) {
    if(!task?.shipmentId) return null;
    return this.routeService.getShipmentById(task.shipmentId);
  }

  getOrderId(task) {
    return this.getShipment(task).getOrderId();
  }

  getCodeText(task) {
    return this.getShipment(task).getCodeText();
  }

  onEditEmail(formControlName) {
    //khi click btn edit trên form (cạnh input) thì enable để user có thể edit input.
    if (this.getItemByKey(formControlName).enabled)
      this.getItemByKey(formControlName).disable();
    else this.getItemByKey(formControlName).enable();
  }

  onBtnSave() {
    let json: any = this.getFormData_JSON(true);
    if (!this.inConfirm) {
      this.confirmData = json;
      this.inConfirm = true;
      return;
    }

    this.onProgress = true;
    this.isLoading = true;

    let contacts = [];
    if (json.sendToCarrier) {
      contacts.push({
        name: json.carrierName,
        email: json.carrierEmail,
        type: "carrier",
      });
    }

    if (json.sendToPickupStop && this.sendContacts.size > 0) {
      const _sendContacts = Array.from(this.sendContacts.values()).map(it => this.allContacts.get(it));
      contacts = [...contacts, ..._sendContacts];
    }

    const data = {
      contacts: contacts,
      remark: json.remark,
      jobId: this.jobId,
      // fileId: this.bolFileId,
      type: "bol",
    };

    this.api.POST(`${Const.APIURI_JOBS}/send_file_email`, data).subscribe(
      (resp) => {
        this.stopProgress();
        this.isLoading = false;
        this.onProgress = false;
        this.closeDialog();
        UIHelper.showSuccess("BOL mail has been sent successfully");
      },
      (err) => {
        UIHelper.showErr(err)
        this.stopProgress();
        this.isLoading = false;
        this.onProgress = false;
        this.closeDialog();
      }
    );
  }

  onBtnCancel() {
    //back to form
    if (this.inConfirm) {
      this.inConfirm = false;
      return;
    }
    //close dialog
    this.closeDialog();
  }

  onCheckPUStop(checked) {
    this.indeterminate = false;
    if (checked) {
      this.sendContacts = new Set(this.allContacts.keys());
    }
  }

  onSelectStop(event, value) {
    console.log("onSelectStop", event, value);
    if (!event) return this.sendContacts.delete(value);
    this.sendContacts.add(value);
  }
}
