import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Const } from '@const/Const';
import { ConfirmEtaSuggestion } from './confirm-eta-list';
import { EnrouteTrackingSuggestion } from './enroute-tracking-list';
import { ConfirmDepartureSuggestion } from './confirm-departure-list';
import { ConfirmArrivalSuggestion } from './confirm-arrival-list';
import { TrackTraceService } from '@app/track-trace/services/track-trace.service';
import { Job, WorkTask } from '@wearewarp/types/data-model';
import { NzIconModule } from 'ng-zorro-antd/icon';

const CustomInputs = [
  CommonModule,
  NzIconModule,
  ConfirmEtaSuggestion,
  EnrouteTrackingSuggestion,
  ConfirmDepartureSuggestion,
  ConfirmArrivalSuggestion
]

@Component({
  selector: 'app-load-suggestions',
  templateUrl: './load-suggestions.component.html',
  styleUrls: ['./load-suggestions.component.scss'],
  standalone: true,
  imports: CustomInputs
})
export class LoadSuggestionsComponent {
  taskInfo: WorkTask;
  loadInfo: Job;
  stopIndexByTask: number;    // stop ứng với task hiện tại (có thể không phải stop focus của route)
  stopIndexCurrent: number;    // stop focus của route
  isDriverActive: boolean = false;
  isExpanded: boolean = false;

  constructor(
    private trackTraceService: TrackTraceService,
  ) { }

  ngOnInit() {
    this.trackTraceService.getWorkTask().subscribe(task => {
      this.taskInfo = task;
    })
    this.trackTraceService.getLoadInfo().subscribe(job => {
      this.onLoadInfoChange(job);
    })
  }

  getStopIndexByWorkTask() {
    // stopSequence đã được +1 từ trước
    const stopSequence = this.taskInfo?.object?.metadata?.stopSequence;
    if (stopSequence) {
      return stopSequence - 1;
    }
    return null;
  }

  onLoadInfoChange(job: Job) {
    this.loadInfo = job;
    this.isDriverActive = job?.assignedDriver?.isActive || false;
    const currentStop = this.trackTraceService.getCurrentStop();
    this.stopIndexCurrent = currentStop ? this.trackTraceService.findStopIndex(currentStop?.id) : null;
    this.stopIndexByTask = this.getStopIndexByWorkTask();
  }

  get isTaskConfirmETA() {
    return this.taskInfo?.type === Const.WorkRouteTask.etaConfirmation;
  }
  get isTaskConfirmArrival() {
    return this.taskInfo?.type === Const.WorkRouteTask.arrivalConfirmation;
  }
  get isTaskConfirmDeparture() {
    return this.taskInfo?.type === Const.WorkRouteTask.departureConfirmation;
  }
  get isTaskConfirmEnroute() {
    return this.taskInfo?.type === Const.WorkRouteTask.enrouteTracking;
  }

  get taskName() {
    return this.taskInfo?.type ? Const.WorkRouteTaskLabel[this.taskInfo?.type] : 'N/A';
  }

  get displayStopIndex() {
    if (this.stopIndex >= 0) {
      return `at stop ${this.stopIndex + 1}`;
    }
    return '';
  }

  get stopIndex() {
    return this.stopIndexByTask ?? this.stopIndexCurrent ?? 0;
  }

  get stopInfo() {
    return this.loadInfo?.stops?.[this.stopIndex];
  }

  get stopType() {
    return this.stopInfo?.type || 'PICKUP';
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
} 