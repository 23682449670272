<div class="map" [class]="theme">
    <div style="width: 100%; height: 100%;" #mapboxContainer></div>

@if (hoveredLocation) {
    <div class="popup">
        <div class="location-name">
            @if (hoveredLocation.stop?.index) {
                <span class="right5">Stop {{ hoveredLocation.stop?.index }}:</span>
            }
            @if (hoveredLocation.name) {
                <i nz-icon nzType="home" class="right5"></i>
                {{ hoveredLocation.name }}
            }
        </div>
        @if (hoveredLocation.address) {
        <div>
            <i class="fa fa-map right5" style="color: #888888;"></i>
            <us-address mode="full" [address]="hoveredLocation.address"></us-address>
        </div>
        }
        @if (hoveredLocation.stop) {
            @if (hoveredLocation.stop.trafficCost.distance) {
                <div>
                    From previous stop:
                    <i nz-icon nzType="node-index"></i> {{ hoveredLocation.stop.trafficCost.distance / 1609.34 | number : "1.0-0" }}mi
                    <i nz-icon nzType="clock-circle" class="left5"></i> <duration [seconds]="hoveredLocation.stop.trafficCost.time"></duration>
                </div>
            }
    
            @for (task of hoveredLocation.stop.tasks; track $index) {
                <div>
                    <warp-status [status]="task.status"></warp-status> {{ task.type}}
                    @if (task.metadata?.shipment) {
                        <job-task-code [task]="task"></job-task-code>
                    }
                    <code style="font-size: 12px;">({{ task.metadata?.shipment?.warpId ?? task.metadata.shipmentWarpId }})</code>
                </div>
            }
        }
    </div>
}

@if (hoveredLeg) {
    <div class="popup">
        <div>Route <warp-code [code]="hoveredLeg.job.job.code"></warp-code></div>
        @if (hoveredLeg.toStop.trafficCost.distance) {
            <div>
                From stop [{{ hoveredLeg.toStop.index - 1 }}]
                to stop [{{ hoveredLeg.toStop.index }}]:
                <i nz-icon nzType="node-index"></i> {{ hoveredLeg.toStop.trafficCost.distance / 1609.34 | number : "1.0-0" }}mi
                <i nz-icon nzType="clock-circle" class="left5"></i> <duration [seconds]="hoveredLeg.toStop.trafficCost.time"></duration>
            </div>
        }
    </div>
}
</div>

@if (hoveredGps) {
    <div class="popup">
        <div class="time">
            <i nz-icon nzType="clock-circle" class="right5"></i>
            {{ hoveredGps.gps.ts | date : 'medium' }}
        </div>
        <div>
            <i nz-icon nzType="environment" class="right5"></i>
            <span class="label">GPS:</span> <code>{{ hoveredGps.gps.location.latitude.toFixed(4) }}, {{ hoveredGps.gps.location.longitude.toFixed(4) }}</code>
        </div>
        <div>
            <i nz-icon nzType="car" class="right5"></i>
            <span class="label">Speed:</span>
            <code>{{ hoveredGps.gps.location.speed.toFixed(0) }} mph</code>
        </div>
        @if (hoveredGps.gps.source) {
            <div>
                <i nz-icon nzType="mobile" class="right5"></i>
                <span class="label">Source:</span> {{ hoveredGps.gps.source }}</div>
        }
    </div>
}