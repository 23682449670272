@if (_address) {
    @if (!mode || mode == 'short') {
        <span>{{ _address.city }}, {{ _address.state }} {{ _address.zipcode }}</span>
    } @else if (mode == 'compact') {
        <span>{{ _address.state }} {{ _address.zipcode }}</span>
    } @else if (mode == 'full') {
        <span>{{ _address.street }} {{ _address.city }}, {{ _address.state }} {{ _address.zipcode }}</span>
    } @else if (mode == 'full2') {
        <span>{{ _address.street2 ?? '' }} {{ _address.street }} {{ _address.city }}, {{ _address.state }} {{ _address.zipcode }}</span>
    }
}