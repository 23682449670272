import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'confirm-eta-suggestion',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './index.html',
  styleUrl: './index.scss'
})
export class ConfirmEtaSuggestion {
  @Input() isDriverActive: boolean = false;
}
