import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { ApiService } from "@services/api.service";
import { ClientMeta } from "@wearewarp/types/data-model";
import { BehaviorSubject, Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(private api: ApiService) { }
  
  private allClients = new BehaviorSubject<ClientMeta[] | null>(null);
  private isLoading = new BehaviorSubject<boolean>(false);

  getAllClients(): Observable<ClientMeta[]> {
    if (this.isLoading.value) {
      return this.allClients.asObservable();
    }
    if (this.allClients.value) {
      return this.allClients.asObservable();
    }
    this.isLoading.next(true);
    const url = `${environment.backendWorkQueueUrl}/v1/clients`;
    this.api.GET(url).subscribe((res: any) => {
      const clients = res.data?.list_data as unknown as ClientMeta[];
      this.allClients.next(clients);
      this.isLoading.next(false);
    });
    return this.allClients.asObservable();
  }
  
}