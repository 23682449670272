<div class="empty-map" *ngIf="!_jobId">Empty Map</div>
<div class="map" *ngIf="_jobId">
  <job-detail-map [detail]="_detail" 
    (onMapChange)="onMapChange($event)"
    [historicalLocations]="historicalLocations"
    [updatedLocations]="updatedLocations"
    [liveLocations]="liveLocations"
  ></job-detail-map>
  <div class="driver-location-chart" #driverLocationChart>
    @if (allLocations.length) {
      <driver-location-chart id="full-chart" [width]="_chartWidth" [height]="_chartHeight" (onClick)="onClickDriverLocation($event)" (onHover)="onHoverDriverLocation($event)" [locations]="allLocations" [timelines]="_detail?.stops"></driver-location-chart>
    }
  </div>
  @if (_hoveredLocation) {
    <div class="location-popup">
      @if (_hoveredLocation.location) {
      <div><i nz-icon nzType="clock-circle" class="right5"></i> {{ _hoveredLocation.location.ts | date : 'MM/dd hh:mm a' }} </div>
      <div><i nz-icon nzType="environment" class="right5"></i> GPS: {{ _hoveredLocation.location.location?.latitude | number : '1.0-3' }}, {{ _hoveredLocation.location.location?.longitude | number : '1.0-3' }}</div>
      <div><i nz-icon nzType="car" class="right5"></i> Speed: {{ _hoveredLocation.location.location?.speed | number : '1.0-0' }}</div>
      <div><i nz-icon nzType="mobile" class="right5"></i> Source: {{ _hoveredLocation.location.source }}</div>
      }
      @if (_hoveredLocation.timeline) {
        <div class="separator h top10 bottom10"></div>
        <div>
          <span>Arrived: {{ _hoveredLocation.timeline.arrivalTime | date : 'MM/dd hh:mm a' }}</span>
        </div>
        <div>
          <span>Departed: {{ _hoveredLocation.timeline.departureTime | date : 'MM/dd hh:mm a' }}</span>
        </div>
        <div>
          <span>Dwell Time: </span><duration [seconds]="_hoveredLocation.timeline.dwellTime"></duration>
        </div>
        <delivery-progress-stop-popup [stop]="_hoveredLocation.timeline"></delivery-progress-stop-popup>
      }
    </div>
  }
</div>