<div class="scroll-check" scrollTracker (scrollingFinished)="getNotifications()">
  <div class="f18 notify-dispatch-header">Notifications
    <ng-container>
      <span class="mark-all-as-read" (click)="markAllAsRead()">Mark all as read</span>
    </ng-container>
  </div>
  <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  <div *ngIf="!isLoading">
    <div class="notify-body">
      <div *ngFor="let group of groupNotifications">
        <div class="notigy-group-name">{{group.groupName}}</div>
        <div *ngFor="let item of group.listData">
          <div class="notify-item" (click)="gotoRouteDetail(item)">
            <a [routerLink]="[item.hyperLinkUrl]" class="notify-hyperlink">
              <span *ngIf="!item.read" class="notify-point-unread"></span>
              <span class="notify-content" [ngClass]="{'read': item.read, 'unread': !item.read}">{{item.contentNotification}}</span>
              <span ngClass="notify-status status-{{item.status}}">{{item.status}}</span>
              <div class="notify-time">{{item.timeAgo}}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!loadedAllData" style="text-align: center; margin-top: 10px;  margin-bottom: 30px;">
      <!-- <button nz-button nzType="primary" [nzLoading]="isLoadingNext" (click)="getNotifications()">Load more</button> -->
      <i nz-icon nzType="loading" nzTheme="outline" *ngIf="isLoadingNext"></i>
    </div>

    <ng-container *ngIf="loadedAllData">
      <div style="display: flex;justify-content: center; margin-top: 10px;">
        <img class="img-notify-dispatch" src="assets/img/notifi-dispatch.png" alt="">
      </div>

      <div *ngIf="isEmptyNotifications" style="text-align: center; margin-top: 10px;font-size: 13px;font-weight: 300;">
        You have no notifications from the last 30 days.
      </div>

      <div *ngIf="!isEmptyNotifications" style="text-align: center; margin-top: 10px;font-size: 13px;font-weight: 300;">
        That's all your notifications from <br> the last 30 days.
      </div>
    </ng-container>
  </div>
</div>

