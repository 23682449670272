<div class="custom-tracking-modal">
  <div class="modal-header">
    <h2>Carrier external tracking URL</h2>
  </div>
  
  <div class="modal-content">
    <div class="url-input">
      <input 
        nz-input 
        [(ngModel)]="link" />
    </div>
  </div>
  
  <div class="modal-footer">
    <button 
      nz-button 
      nzType="default" 
      (click)="onBtnCancel()"
      [disabled]="onProgress">
      Cancel
    </button>
    <button
      nz-button
      nzType="primary"
      (click)="onBtnSave()"
      [nzLoading]="onProgress">
      Add
    </button>
  </div>
</div>
