<div class="carrier-driver-tab" *ngIf="isAssignedCarrier">
  <div class="section carrier-section">
    <div class="carrier-info-container">    
      <app-carrier-info></app-carrier-info>
      <nz-divider></nz-divider>
      <app-confirm-booking-info></app-confirm-booking-info>
    </div>
    <nz-divider nzType="vertical" class="vertical-divider"></nz-divider>
    <div class="driver-info-container">
      <app-driver-info></app-driver-info>
      <nz-divider></nz-divider>
      <app-tracking-info></app-tracking-info>
    </div>
  </div>
  <nz-divider></nz-divider>
  <div class="section history-section">
    <app-assign-history></app-assign-history>
  </div>
</div>
<div class="carrier-driver-tab" *ngIf="!isAssignedCarrier">
  <div class="section">
    <h3>No Carrier Assigned</h3>
    <button nz-button nzType="primary" class="unassign-btn" (click)="goToCarrierBid()" [nzLoading]="isLoading">Go to Carrier Bid</button>
  </div>
  <div class="section history-section">
    <app-assign-history></app-assign-history>
  </div>
</div>