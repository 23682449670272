import { Component, Input } from '@angular/core';
import { AddressUS } from '@wearewarp/types/data-model';

@Component({
    selector: 'us-address',
    templateUrl: 'index.html',
    styleUrl: './index.scss',
    imports: [],
    standalone: true
})
export class AddressComponent {
    _address: AddressUS | null = null
    @Input() set address(v: AddressUS) {
        this._address = v
    }

    @Input() mode: string | null = null
}