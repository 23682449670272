const cache: any = {}

export class StringUtils {
    static cache = {}
    static normalize(s: string): string {
        const v = cache[s]
        if (v) {
            return v
        }
        const n = this.normalizeString(s)
        cache[s] = n
        return n
    }

    static normalizeString(s: string) {
        const words: string[] = []
        let lastWord: string | null = null
        let lastCode: number = 0
        const a = 'a'.charCodeAt(0)
        for (let i = 0; i < s.length; i++) {
            const c = s.charCodeAt(i)
            if (!lastWord || (lastCode >= a && c < a)) {
                if (lastWord)
                    words.push(lastWord)
                lastWord = s.charAt(i)
            } else {
                lastWord = lastWord + s.charAt(i)
            }
            lastCode = c
        }
        if (lastWord)
            words.push(lastWord)
        return words.join(' ')
    }

}
