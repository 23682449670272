<div class="route-filter-container">
  <div class="header">
    <form nz-form [formGroup]="formInput">
      <div class="flex flex-space-between">
        <div class="search-box" *ngIf="'searchTxt' as key">
          <nz-input-group [nzPrefix]="suffixIconSearch">
            <input nz-input  [formControlName]="key" [placeholder]="getPlaceHolder(key)" />
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <span nz-icon nzType="search"></span>
          </ng-template>
        </div>
        <div class="filter-box">
          <!-- <div *ngIf="'clientIds' as key" style="width: 250px;">
            <nz-select
              [nzMode]="'multiple'"
              [nzAllowClear]="true"
              [formControlName]="key"
              [nzPlaceHolder]="getPlaceHolder(key)"
              style="width: 100%;">
              <nz-option *ngFor="let client of clients" [nzValue]="client.id" [nzLabel]="client.name"></nz-option>
            </nz-select>
          </div> -->

          <div class="btn-search">
            <button nz-button nzType="primary" [disabled]="isSearching || !isInputChanged()" (click)="doSearch()" [nzLoading]="isSearching">
              Search
            </button>
          </div>
        </div>
      </div>
    </form>  
  </div>

  <div class="list-body top20">
    <div class="text-secondary">Result ({{listData.length}})</div>

    <div class="list-data">
      <div class="list-item" *ngFor="let item of displayData; let i = index">
        <div class="separator" *ngIf="shouldShowSeparator(i)"></div>
        <ng-container *ngTemplateOutlet="jobItemTemplate; context: { item }"></ng-container>
      </div>
    </div>
  </div>

  <ng-template #jobItemTemplate let-item="item">
    <div class="item-info">
      <div class="item-title">
        <div class="text-title">{{ item?.title }}</div>
        <div class="job-status-box">
          <span class="job-status" [ngClass]="item.statusClass">{{ item?.statusTxt }}</span>
        </div>
      </div>
      <ng-container *ngIf="item?.lastTrackTxt">
        <div class="text-last-track">{{ item?.lastTrackTxt }}</div>
      </ng-container>
      <div class="item-desc" *ngIf="item?.desc">{{ item?.desc }}</div>
      <div class="group-btn top10">
        <div class="btn-item" (click)="goToRoute(item)">Go to this route</div>
        <div class="btn-item" (click)="openCreateTaskModal(item)">Create & Assign task</div>
      </div>
    </div>
  </ng-template>
</div>
