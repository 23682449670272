import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { BaseFormDialog } from '@app/dialogs/base-form-dialog';
import { Const } from '@const/Const';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { UIHelper } from '@services/UIHelper';
import { FormFooter } from '@app/form-footer/form-footer';

const CustomInputs = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NzFormModule,
  NzInputModule,
  NzCheckboxModule,
  NzIconModule,
  NzGridModule,
  NzButtonModule,
  FormFooter
];

@Component({
  selector: 'app-email-bol-client',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  standalone: true,
  imports: CustomInputs,
  encapsulation: ViewEncapsulation.None,
})
export class EmailBolClientComponent extends BaseFormDialog implements OnInit {
  protected formGroupDeclaration: FormGroupDeclaration = {
    customers: {
      label: '',
      type: 'formArray', childItem: {
        id: { label: '', type: 'string' },
        name: { label: 'Name', type: 'string' },
        email: { label: 'Email', type: 'string' },
        isSend: { label: '', type: 'boolean', initialValue: false }
      }
    },
    remark: { label: "Remark" },
  };

  @Input() jobId;
  @Input() clients;
  public isError = false;
  public isLoading = false;
  public isUpdate: boolean = false;
  public onProgress: boolean = false;
  public confirmData: any = {};
  public inConfirm: boolean = false;

  constructor(@Inject(NZ_MODAL_DATA) data: any) { 
    super(data);
    this.jobId = data.jobId;
    this.clients = data.clients;
  }

  ngOnInit(): void {
    this.getData();
  }

  getFormItemValue(key: string) {
    return this.getItemValue(key)
  }

  getFormArrayKey(index, key) {
    return `customers[${index}].${key}`
  }

  getData() {
    this.createFormInput();

    //lấy luôn dữ liệu từ job để đẩy vào form, không cần fetch lại API
    let clients = this.clients || [];
    let clientEmails = [];
    clients.map((client) => {
      let contact = client.primaryContact || {};
      clientEmails.push({ id: client.id, email: contact.email, name: client.name});
    });

    //luôn set giá trị cho array customerEmails để tránh null
    this.setItemValue("customers", clientEmails);
    if (clientEmails.length > 0) {
      for(let index in clientEmails) {
        this.getItemByKey(`customers[${index}].email`).disable();
      }
    }
  }

  onEditEmail(index, formControlName) {
    let key = `customers[${index}].${formControlName}`
    //khi click btn edit trên form (cạnh input) thì enable để user có thể edit input.
    if (this.getItemByKey(key).enabled)
      this.getItemByKey(key).disable();
    else this.getItemByKey(key).enable();
  }

  get canClickOK() {
    let data: any = this.getFormData_JSON(true);
    let customers = data.customers || [];
    for(let item of customers) {
      if(item.isSend && !item.email) return false;
    }
    let count = customers.filter(it => it.isSend).length;
    return count > 0;
  }

  onBtnSave() {
    let json: any = this.getFormData_JSON(true);
    if (!this.inConfirm) {
      this.confirmData = json;
      this.confirmData.customers = json.customers.filter(it => it.isSend)
      this.inConfirm = true;
      return;
    }

    let contacts = json.customers.filter(it => it.isSend);
    if(!contacts.length) {
      return;
    }

    const data = {
      contacts: contacts,
      remark: json.remark,
      jobId: this.jobId,
      type: "bol"
    };

    this.onProgress = true;
    this.isLoading = true;
    this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/send-bol-to-customers`, data).subscribe(
      (resp) => {
        this.stopProgress();
        this.isLoading = false;
        this.onProgress = false;
        this.closeDialog();
        UIHelper.showSuccess("BOL mail has been sent successfully");
      },
      (err) => {
        UIHelper.showErr(err);
        this.stopProgress();
        this.isLoading = false;
        this.onProgress = false;
        this.closeDialog();
      }
    );
  }

  onBtnCancel() {
    //back to form
    if (this.inConfirm) {
      this.inConfirm = false;
      return;
    }
    //close dialog
    this.closeDialog();
  }
}
