import { Subject, Subscription } from "rxjs";
import { ulid } from "ulid";
import Sockette from "sockette";
import { environment } from "@env/environment";

// @Injectable({
//     providedIn: 'root'
// })
export abstract class BaseWsUpdateService<T> {
    private behaviors: Map<string, string[]> = new Map()
    private subscriptions: Map<string, Subscription> = new Map()
    private behaviorBySubscription: Map<string, Subject<T>> = new Map()
    private ws: Sockette;
    private wsUrl: string;
    private wsConnected: boolean = false;
    public onMessage: Subject<any> = new Subject();

    // private pullingInterval = 60000; // 60s
    // private timer: any = null

    /**
     * Parse data from raw string
     * @param raw 
     */
    abstract parseWsData(raw: string): T | null

    /**
     * 
     * @param data 
     */
    abstract getObjectId(data: T): string | null

    constructor(
        private type: string
    ) {
        this.wsUrl = environment.wsUrl + '/' + type
        this.subscribe = this.subscribe.bind(this)
        this.initSocket()

        // this.ping = this.ping.bind(this)
        // this.timer = setInterval(this.ping, this.pullingInterval)

    }

    private initSocket() {
        this.ws = new Sockette(this.wsUrl + '/NONE', {
            timeout: 5e3,
            maxAttempts: 10,
            onopen: e => {
                console.log('connected', e)
                this.wsConnected = true
                // this.ws.send("RESET")
                const ids = [...this.behaviors.keys()]
                if (ids.length) {
                    this.ws.send(`SUBSCRIBE@${ids.join(',')}`)
                }
            },
            onmessage: (e) => {
                this.wsConnected = true
                const data = this.parseWsData(e.data)
                const objectId = data ? this.getObjectId(data) : null
                // console.log('Websocket onmessage ', data);
                if (data && objectId) {
                    const subscriptions = this.behaviors.get(objectId)
                    if (subscriptions)
                    for (let subs of subscriptions)
                        this.behaviorBySubscription.get(subs)?.next(data)
                }
            },
            onclose: (e) => {
                this.wsConnected = false;
                console.log('Websocket Closed', e);
            },
            onreconnect: e => console.log('Reconnecting...', e),
            onerror: e => console.error('Websocket Error:', e)
        });
    }

    subscribe(id: string, handler: (object: T | null) => void): string {
        const behavior = new Subject<T>()
        const subscription = behavior.subscribe(handler)
        const subId = ulid()
        this.subscriptions.set(subId, subscription)
        this.behaviorBySubscription.set(subId, behavior)
        const l = this.behaviors.get(id) ?? []
        l.push(subId)
        this.behaviors.set(id, l)
        // console.log('subscribing', this.type, id)

        if (this.wsConnected)
            this.ws.send(`SUBSCRIBE@${id}`)

        return subId
    }

    subscribeMultiple(ids: string[], handler: (object: any | null) => void): string {
        const behavior = new Subject<any>()
        const subscription = behavior.subscribe(handler)
        const subId = ulid()
        this.subscriptions.set(subId, subscription)
        this.behaviorBySubscription.set(subId, behavior)
        for (let id of ids) {
            const l = this.behaviors.get(id) ?? []
            l.push(subId)
            this.behaviors.set(id, l)    
        }
        if (this.wsConnected)
            this.ws.send(`SUBSCRIBE@${ids.join(',')}`)
        return subId
    }

    unsubscribe(subscriptionId: string) {
        this.subscriptions[subscriptionId]?.unsubscribe()
        const behavior: Subject<any> | null = this.behaviorBySubscription.get(subscriptionId)
        behavior?.complete()
        this.behaviorBySubscription.delete(subscriptionId)
        this.subscriptions.delete(subscriptionId)

        this.checkSubscription()
    }

    checkSubscription() {
        const ids = this.behaviors.keys()
        for (let id of ids) {
            const subscriptions = this.behaviors.get(id)
            const filtered = subscriptions.filter(it => this.subscriptions.has(it))
            if (!filtered?.length) {
                // no need to keep listenning
                this.behaviors.delete(id)
                if (this.wsConnected) {
                    this.ws.send(`UNSUBSCRIBE@${id}`)
                }
            } else {
                if (filtered.length != subscriptions.length) {
                    this.behaviors.set(id, filtered)
                }
            }
        }
    }

    // TODO: Implement manual ping
    // private ping() {
    //     // ping by id
    //     const ids = this.behaviors.keys()
    //     for (let id of ids) {
    //         const subscriptions = this.behaviors.get(id)
    //         const filtered = subscriptions.filter(it => this.subscriptions.has(it))
    //         if (!filtered?.length) {
    //             this.behaviors.delete(id)
    //         } else {
    //             if (filtered.length != subscriptions.length) {
    //                 this.behaviors.set(id, filtered)
    //             }
    //             // pull data
    //             this.deliveryUpdateService.loadDriverLocations(id, 1).subscribe({
    //                 next: (v) => {
    //                     for (let subscriptionId of filtered) {
    //                         this.behaviorBySubscription.get(subscriptionId)?.next(v)
    //                     }
    //                 }
    //             })
    //         }
    //     }
    // }

}