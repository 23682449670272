import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { StopDetailsComponent } from '../stop-details/stop-details.component';
import { TrackTraceService } from '../../services/track-trace.service';
import { Job, Stop } from '@wearewarp/types/data-model';
import { Const } from '@const/Const';

@Component({
  selector: 'app-load-stops',
  templateUrl: './load-stops.component.html',
  styleUrls: ['./load-stops.component.scss'],
  standalone: true,
  imports: [CommonModule, NzTabsModule, StopDetailsComponent, NzToolTipModule]
})
export class LoadStopsComponent implements OnInit {
  loadInfo: Job;
  selectedStopIndex: number = 0;
  selectedStop: Stop;
  isSelectedStopScheduled: boolean = false;
  initSelectedStopIndex: number | null = null;

  constructor(private trackTraceService: TrackTraceService) { }

  ngOnInit() {
    // Nếu không có loadInfo từ input, fetch từ service
    this.initLoadInfo();
  }

  initLoadInfo() {
    this.trackTraceService.getLoadInfo().subscribe(info => {
      if(!info) return;
      this.loadInfo = info;
      if (info.stops?.length > 0) {
        const currentStopIndex = this.findCurrentStopIndex();
        this.initSelectedStopIndex = currentStopIndex;
        this.selectStop(currentStopIndex);
      }
    });
  }

  findCurrentStopIndex() {
    //nếu có stop status là created, enroute, arrived thì return index của stop đó
    const currentStopIndex = this.loadInfo.stops.findIndex(stop => [null, Const.StopStatus.created, Const.StopStatus.enroute, Const.StopStatus.arrived].includes(stop.status));
    if (currentStopIndex !== -1) {
      return currentStopIndex;
    }
    //nếu không thì return stop completed cuối cùng
    const completedStops = this.loadInfo.stops.filter(stop => stop.status === Const.StopStatus.succeeded);
    if (completedStops.length > 0) {
      return this.loadInfo.stops.findIndex(stop => stop.id === completedStops[completedStops.length - 1].id);
    }
    //nếu không có thì return 0
    return 0;
  }

  selectStop(index: number) {
    this.selectedStopIndex = index;
    this.selectedStop = this.loadInfo.stops[index];
    this.isSelectedStopScheduled = this.isScheduled(this.selectedStop);
  }

  isScheduled(stop: Stop): boolean {
    return stop.info?.appointmentInfo?.from ? true : false;
  }

  getStatusClass(status: string): string {
    return status ? Const.StopStatus[status.toLowerCase()] : '';
  }

  getStatusText(status: string): string {
    return status ? Const.getStopStatusText(status) : '';
  }

  isInitialSelectedStop() {
    return this.selectedStopIndex === this.initSelectedStopIndex;
  }
} 