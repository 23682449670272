<div class="shipment-items">
  <nz-table 
    #itemsTable 
    [nzData]="items"
    nzSize="small"
    [nzShowPagination]="false">
    <thead>
      <tr>
        <th>Item</th>
        <th nzWidth="200px">Barcode</th>
        <th nzWidth="200px">Status</th>
        <th nzWidth="120px">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of itemsTable.data; let i = index;">
        <td>
          <div>{{ getItemName(item, i) }},</div>
          <div>
            <span>{{ getItemQty(item) }}, {{ getItemTotalWeight(item) }} ({{ getItemWeightPerUnit(item) }}), {{ getItemSize(item) }}</span>
          </div>
          </td>
        <td> 
          <nz-tag *ngFor="let barcode of item.barcodes">{{ barcode }}</nz-tag>
        </td>
        <td>{{ item.status }}</td>
        <td>
          <button *ngIf="item.status !== 'pickupFailed'" nz-button nzDanger nzType="text" (click)="onBtnReportMissing(item, i)">
            Report missing
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div> 