import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { copyTextToClipboard } from '@app/utils/map-v2.util';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { PopupComponent, PopupContentDirective } from '../popup';
import { NgTemplateOutlet } from '@angular/common';
import { Router } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
    selector: 'warp-code',
    templateUrl: './index.html',
    styleUrl: './index.scss',
    imports: [
        NzIconModule,
        MatSnackBarModule,
        PopupContentDirective,
        PopupComponent,
        NgTemplateOutlet,
        MatMenuModule
    ],
    standalone: true
})
export class WarpCode {
    _code: string = ''
    suffix: string = ''
    main: string = ''
    prefix: string = ''
    @Input() link: string | null = null
    @Input() theme: string = 'dark'
    @Output() onOpen: EventEmitter<any> = new EventEmitter()
    @Input() silent: boolean = false
    @Input() class: any

    @Input() set code(v: string) {
        const comps = v.split("-")
        if (comps.length > 2) {
            this.prefix = comps[0]
            this.main = comps[1]
            this.suffix = comps[2]
        } else if (comps.length == 1) {
            this.main = comps[0]
        } else if (comps.length == 2) {
            this.main = comps[0]
            this.suffix = comps[1]
        }
        this._code = v
    }

    constructor(
        private snackBar: MatSnackBar,
        private router: Router
    ) {}

    @ContentChild(TemplateRef) content: TemplateRef<any> | null = null;
    @ContentChild('popupMenu') menu: TemplateRef<any> | null = null;

    onClick() {
        if (this.silent) return
        copyTextToClipboard(this._code).then(() => {
            this.snackBar.open(`${this._code} has been copied to clipboard`, 'OK', {duration: 1000})
        })
    }

    openLink() {
        if (!this.link) return
        if (this.link.startsWith('http')) {
            window.open(this.link, '_blank')
            return
        }
        this.router.navigateByUrl(this.link)
    }

    onLinkBtn($event) {
        this.onOpen.emit($event)
    }
}