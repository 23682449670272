import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { CommonModule } from '@angular/common';
import { TrackTraceService } from 'src/app/track-trace/services/track-trace.service';
import { Job } from '@wearewarp/types/data-model';
import { InputHelper } from '@services/input-helper';
import { DialogService } from '@app/dialogs/dialog.service';
import { EditDriverDialog } from '@app/components/edit-driver-dialog';
import { Utils } from '@services/utils';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { UIHelper } from '@services/UIHelper';
import { ModalHelper } from '@app/utils/modalHelper';
import { DriverAppStatus } from '@app/track-trace/components/dispatch-live-tracking/driver-app-status';
import { Subscription } from 'rxjs';
import { DispatchLiveTracking } from '@app/track-trace/components/dispatch-live-tracking';
import { Log } from '@services/log';
@Component({
  selector: 'app-driver-info',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    NzTagModule,
    NzToolTipModule,
    NzIconModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './driver-info.component.html',
  styleUrl: './driver-info.component.scss',
  providers: [ModalHelper]
})
export class DriverInfoComponent {
  loadInfo: Job;
  isDriverOnline = false;
  driverTrackingSubscription: Subscription = new Subscription();

  constructor(
    private trackTraceService: TrackTraceService,
    private dispatchLiveTracking: DispatchLiveTracking,
    private modalHelper: ModalHelper
  ) {
    this.trackTraceService.getLoadInfo().subscribe(loadInfo => {
      this.loadInfo = loadInfo;
      this.resetDriverTrackingSubscription();
    });
  }

  ngOnDestroy() {
    if (this.driverTrackingSubscription) {
      this.driverTrackingSubscription.unsubscribe();
    }
  }

  resetDriverTrackingSubscription() {
    this.isDriverOnline = false;
    if (this.driverTrackingSubscription) {
      this.driverTrackingSubscription.unsubscribe();
      this.driverTrackingSubscription = undefined;  
    }
    const driverId = this.loadInfo?.assignedDriver?.driverId;
    if (!driverId) return;
    this.driverTrackingSubscription = new Subscription();
    this.driverTrackingSubscription.add(this.dispatchLiveTracking.trackDriverOnline(driverId, (value: boolean) => {
      Log.d(`driver ${driverId} ${value ? "online" : "offline"}`);
      this.isDriverOnline = value;
    }));
  }
  
  isAssignedDriver() {
    return this.loadInfo.assignedDriver?.driverId;
  }

  isDriverInfo() {  
    return this.loadInfo?.driver;
  }

  get isShowSecondaryDriver (): boolean {
    return !!(this.loadInfo?.requiredVehicle?.options ?? []).includes('TEAM');
  };

  isShowSecondDriverInfo() {
    return this.loadInfo?.assignedSecondaryDrivers?.length > 0;
  }

  getDriverName() {
      return this.loadInfo.driver?.fullName ?? `${this.loadInfo.driver?.firstName} ${this.loadInfo.driver?.lastName}`;
    }

  getSecondDriverName() {
    return `${this.loadInfo?.assignedSecondaryDrivers?.[0]?.firstName} ${this.loadInfo?.assignedSecondaryDrivers?.[0]?.lastName}`;
  }

  getDriverPhone() {
    return InputHelper.formatPhone(this.loadInfo.driver?.phone);
  }

  getSecondDriverPhone() {
    return InputHelper.formatPhone(this.loadInfo?.assignedSecondaryDrivers?.[0]?.phone);
  }

  editDriver() {
    const jobId = this.loadInfo.id;
    DialogService.openFormDialog(EditDriverDialog, {
      nzData: {
        jobId,
        carrierId: this.loadInfo?.assignedCarrier?.carrierId,
        action: 'assign-driver',
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.onUpdateDriverSuccess(resp);
        }
      },
      nzMaskClosable: false,
      nzClassName: 'edit-driver-dialog',
    })
  }

  onUpdateDriverSuccess(resp) {
    this.trackTraceService.refreshLoadInfo();
  }

  copyToClipboard(text: string) {
    Utils.copyTextToClipboard(text, (err) => {
      if (err) {
        UIHelper.showErr(
          'Copy failed, please try again'
        );
      } else {
        UIHelper.showSuccess(
          'Successfully',
          'Copied to clipboard'
        );
      }
    });
  }

  viewDriverAppStatus() {
    const driverId = this.loadInfo.assignedDriver?.driverId;
    if (!driverId || !this.isDriverOnline) return;
    const jobCode = this.loadInfo?.code || "";
    this.modalHelper.open(DriverAppStatus, {
      nzTitle: `Driver for the route <b>${jobCode}</b>`,
      nzFooter: null,
      nzData: {
        driverId,
        jobCode
      }
    })
  }
}
