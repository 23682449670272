<div *ngIf="!isLoading">
  <div *ngIf="itemPreviewList.length == 0">
    <div style="font-style: italic">No data document</div>
  </div>
  <div  *ngIf="itemPreviewList.length > 0">
    <div class="pod-item flex" *ngFor="let docItem of itemPreviewList;let i = index">
      <div class="image">
        <a (click)="viewPodItem(docItem, i)">
          <img *ngIf="!docItem.isPdf" class="attached-pod" nz-image width="100px" [nzSrc]="docItem.imgUrl" alt=""
            [nzDisablePreview]="true" [nzFallback]="fallbackImg" />
          <div *ngIf="docItem.isPdf" class="attached-pod pdf overlay-container">
            <canvas id="customer-document-{{docItem._id}}" width="100px"></canvas>
          </div>
        </a>
      </div>
      <div class="info-text flex1">
        <div class="name">WARP ID: {{getShipmentId(docItem)}}</div>
        <div class="name">Customer: {{docItem?.client?.name}}</div>
        <div class="name">Uploaded by {{docItem.createdBy}}</div>
        <div class="date">{{formatDate(docItem.createdAt)}}</div>
      </div>
      <div class="flex group-document-action">
        <div class="document-action flex clickable" (click)="onBtnDownload(docItem)">
          <div><img src="/assets/img/download.svg" /></div>
          <div class="label">Download</div>
        </div>
        <div class="document-action flex clickable" (click)="onBtnDelete(docItem.shipmentId, docItem._id)">
          <div><img src="/assets/img/delete.svg" /></div>
          <div class="label remove">Remove</div>
        </div>
      </div>
    </div>
  </div>
</div>
