import { Component, Inject, Input, OnInit } from '@angular/core';
import { BaseFormDialog } from '@app/dialogs/base-form-dialog';
import { CommonModule } from '@angular/common';
import { Log } from '@services/log';
import { Const } from '@const/Const';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FormsModule } from '@angular/forms';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { UIHelper } from '@services/UIHelper';

@Component({
  selector: 'app-add-custom-tracking',
  standalone: true,
  imports: [CommonModule, FormsModule, NzInputModule, NzButtonModule, NzFormModule, NzIconModule],
  templateUrl: './add-custom-tracking.component.html',
  styleUrl: './add-custom-tracking.component.scss'
})
export class AddCustomTrackingComponent extends BaseFormDialog implements OnInit {
  @Input() jobId: string;
  @Input() links: string[];
  link: string = '';

  constructor( @Inject(NZ_MODAL_DATA) data: any) {
    super(data);
    this.jobId = data.jobId;
    this.links = data.links;
  }

  ngOnInit(): void {
    this.link = this.links && this.links.length ? this.links[this.links.length - 1] : '';
  }
  
  onBtnCancel(): void {
    if (this.onProgress) {
      return;
    }
    this.closeDialog();
  }

  onBtnSave(): void {
    if (!this.link) {
      UIHelper.showWarning('URL required', 'Please enter a tracking URL');
      return;
    }
    
    if (!this.isValidUrl(this.link)) {
      UIHelper.showWarning('Invalid URL', 'Please enter a valid URL (e.g. example.com or https://example.com)');
      return;
    }
    this.link = this.link.toLowerCase().startsWith('http') ? 
      this.link : 
      `https://${this.link}`;
    this.setEnableFormGroup(false);
    this.startProgress();
    let data = [];
    if(this.link?.length > 0) data.push(this.link);
    
    this.api
      .PUT(`${Const.APIURI_JOBS}/${this.jobId}/custom_tracking`, {
        links: data,
      })
      .subscribe(
        (resp) => {
          Log.d("addCustomTracking done ", resp);
          this.onUpdateSuccess(resp);
          this.stopProgress();
          UIHelper.showSuccess('Successfully', 'External tracking URL has been added');
          this.closeDialog();
        },
        (err) => {
          UIHelper.showErr(err);
          this.stopProgress();
          this.closeDialog();
        }
      );
  }
  
  private isValidUrl(url: string): boolean {
      const urlToTest = url.toLowerCase().startsWith('http') ? 
        url : 
        `https://${url}`;
      try {
      new URL(urlToTest);
      return true
      } catch (e) {
        return false;
      }
  }
}
