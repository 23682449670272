<form nz-form [formGroup]="driverForm" (ngSubmit)="onSubmit()">
  <!-- First Name -->
  <nz-form-item>
    <nz-form-label [nzRequired]="true">First name</nz-form-label>
    <nz-form-control [nzErrorTip]="'Please input first name'">
      <input nz-input formControlName="firstName" placeholder="Enter first name">
    </nz-form-control>
  </nz-form-item>
  <!-- Last Name -->
  <nz-form-item>
    <nz-form-label [nzRequired]="true">Last name</nz-form-label>
    <nz-form-control [nzErrorTip]="'Please input last name'">
      <input nz-input formControlName="lastName" placeholder="Enter last name" >
    </nz-form-control>
  </nz-form-item>
  <!-- Phone -->
  <nz-form-item>
    <nz-form-label [nzRequired]="true" style="margin-right: 24px;">Phone</nz-form-label>
    <nz-form-control [nzErrorTip]="'Please input phone number'">
      <input nz-input formControlName="phone" placeholder="Enter phone number" class="">
    </nz-form-control>
  </nz-form-item>
  <!-- SMS Opt-in -->
  <nz-form-item style="display:flex; gap: 12px;">
      <label nz-checkbox formControlName="optInSMS">
      </label>
      <span>Driver has opted in to receive text messages</span>
  </nz-form-item>
    <!-- Footer Buttons -->
  <div class="footer-buttons">
    <button nz-button (click)="closeModal()">
      Cancel
    </button>
    <button nz-button nzType="primary" [disabled]="!driverForm.valid || isLoading" [nzLoading]="isLoading">
      Save
    </button>
  </div>
</form>

