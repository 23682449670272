import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { CarrierBid } from "@wearewarp/types/data-model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class CarrierBidService {
  constructor(private http: HttpClient) { }

  getCarrierBid(jobId: string): Observable<CarrierBid> {
    return this.http.get(`${environment.backendWorkQueueUrl}/v1/jobs/${jobId}/carrier_bid`).pipe(
      map((res: any) => res.data)
    );
  }
}
