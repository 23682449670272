import { VehicleType } from "@wearewarp/types/data-model";

export function getVehicleText(vehicles: VehicleType[]) {
  let vehicleText = vehicles?.map(it => {
    let name = it.name;
    if (it.options?.length) {
      name += ' w/ '
      let optionText = it.options.map(op => normalizedString(op)).join(', ');
      name += optionText;
    }
    return name;
  }).join(', ');
  return vehicleText; 
}

function normalizedString(v: string) {
  if (!v) return v;
  return v.replace('_', ' ').replace('-', ' ').split(' ').map(it => it.substring(0, 1).toUpperCase() + it.substring(1).toLowerCase()).join(" ");
}