<div class="load-carrier-driver">
  <nz-tabset>
    <nz-tab nzTitle="Carrier & Driver">
      <app-carrier-driver-tab></app-carrier-driver-tab>
    </nz-tab>
    <nz-tab nzTitle="Document">
      <app-document-tab></app-document-tab>
    </nz-tab>
    <!-- <nz-tab nzTitle="Revenues" *ngIf="showCostTab">
      <app-cost-tab></app-cost-tab>
    </nz-tab> -->
  </nz-tabset>
</div>
