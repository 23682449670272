import { Component } from '@angular/core';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { CarrierDriverTabComponent } from './carrier-driver-tab/carrier-driver-tab.component';
import { DocumentTabComponent } from './document-tab/document';
import { CostTabComponent } from './cost-tab/cost-tab.component';
import { TrackTraceService } from '../../services/track-trace.service';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '@abstract/BaseComponent';
import { isAccounting, isAccountingReadOnly} from '@services/auth.check-role';
@Component({
  selector: 'app-load-carrier-driver',
  standalone: true,
  imports: [
    CommonModule,
    NzTabsModule,
    CarrierDriverTabComponent,
    DocumentTabComponent,
    CostTabComponent
  ],
  templateUrl: './load-carrier-driver.component.html',
  styleUrl: './load-carrier-driver.component.scss'
})
export class LoadCarrierDriverComponent extends BaseComponent {
  isAssignedCarrier = false;
  
  constructor(private trackTraceService: TrackTraceService) {
    super();
    this.trackTraceService.getLoadInfo().subscribe(loadInfo => {
      this.isAssignedCarrier = !!loadInfo?.assignedCarrier?.carrierId;
    });
  }

  ngOnInit() {
  }

  get showCostTab() {
    return isAccountingReadOnly(this.authUser) || isAccounting(this.authUser);
  }
}
