import { LayersList, PickingInfo } from '@deck.gl/core'
import { MapboxOverlay as DeckOverlay } from "@deck.gl/mapbox";
import mapboxgl, { MapboxOptions } from 'mapbox-gl'
import { ElementRef } from "@angular/core"
import { MapService } from './map.service';
import { environment } from '../../../environments/environment';
import LocationDotLayer from "./layers/location-dot";
import { Location } from "@app/interfaces/map-v2/location";
import JobTripLayer from './layers/job-trip';
import { LatLngLike } from "@app/interfaces/map-v2/latlng";
import { getLatitude, getLngLat, getLongitude } from '@app/utils/map-v2.util';
import HighlightedLocationsLayer from './layers/highlighted_locations_layer';
import { JobDetail } from "@app/interfaces/map-v2/job-detail";
import { DeliveryUpdate } from '@app/interfaces/map-v2/delivery-update';
import GpsLocationLayer from './layers/gps-layer';
import LiveTrackingLayer from './layers/live-tracking-layer';
import JobPathLayer from './layers/job-path';
import { JobSearchResultItem } from '@app/interfaces/map-v2/job-search-result';
import HighlightedLiveTrackingLayer from './layers/highlighted-live-tracking-layer';
import { Shipment, Warehouse } from '@wearewarp/types/data-model';
import MapPinLayer from './layers/map-pin-layer';
import ShipmentArcLayer from './layers/shipment-layer';
import { Network } from '@app/interfaces/map-v2/net-work';
import NetworkLayer from './layers/network-layer';
import { DrawPolygonMode, EditableGeoJsonLayer, FeatureCollection } from '@deck.gl-community/editable-layers';
import { Feature, Polygon } from 'geojson';
import { GeoJsonLayer } from '@deck.gl/layers';
import { ulid } from 'ulid';
import { fromEvent, Subject } from 'rxjs';
import { MasterData } from '@services/master.data';

export default class DeliveryMapV2 {
    deckgl: DeckOverlay | null = null
    map: mapboxgl.Map | null = null
    timer: any
    currentTime: number = 0
    id?: string
    initialViewState: any = null
    mapboxContainer: ElementRef;
    displayOptions: any = {}

    public static defaultInitialViewState: MapboxOptions = {
        container: '',
        center: [-97.33953883423565, 39.07976952659801],
        zoom: 8,
        pitch: 30,
        minPitch: 0,
        style: 'mapbox://styles/mapbox/streets-v11'
    }
    
    constructor(
        id: string,
        mapboxContainer: ElementRef,
        private service: MapService | null = null,
        initialViewState = DeliveryMapV2.defaultInitialViewState,
        config = {},
    ) {
        this.id = id
        this.mapboxContainer = mapboxContainer
        this.initialViewState = initialViewState || DeliveryMapV2.defaultInitialViewState
        this.onClick = this.onClick.bind(this)
        this.onHover = this.onHover.bind(this)
        this.getToolTip = this.getToolTip.bind(this)
        // this.onClickLocation = this.onClickLocation.bind(this)
        // this.onClickShipment = this.onClickShipment.bind(this)
        // this.config = config

        // this.setPitch = this.setPitch.bind(this)
        // this.setHighlightLocations = this.setHighlightLocations.bind(this)
        // this.processOnHoverRoute = this.processOnHoverRoute.bind(this)

        this.initialize(initialViewState)

        this.displayOptions = {}

        // bind keyboard event
        fromEvent(window, 'keydown').subscribe({
            next: (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    this._selectionFeatures = [];
                    this._selectionLayer = null;
                    this.onSelectingChange.next([])
                    this.disableSelecting()
                    this.onClick(null, {})
                }
                if (this._selectionFeatures.length) {
                    if (event.key === 'Backspace' || event.key === 'Delete') {
                        this._selectionFeatures = this._selectionFeatures.filter(it => it.properties['selected'] !== 'true')
                        this.onSelectingChange.next(this._selectionFeatures)
                        this._selectionLayer = this.createSelectionLayer();
                        this.refresh()
                    }
                }
                if (event.key === 'a') {
                    this.enableSelecting()
                }
            }
        })
    }

    destroy() {
        console.log('Releasing resources')
        this.map?.remove()
        this.deckgl?.finalize()
        this.timer && clearInterval(this.timer)
        this.map = null
    }

    initialize(initialViewState: MapboxOptions) {
        this.map = this.createMapBox(initialViewState)
        if (!this.map) return
        this.deckgl = this.createDeckGl()
        // this.deckgl.setProps({
        //     onLayerClick: () => {}
        // })
        this.map.addControl(this.deckgl)
    }

    createMapBox(initialViewState: MapboxOptions) {
        if (!this.mapboxContainer?.nativeElement) return null
        const map = new mapboxgl.Map({
            ...initialViewState, 
            container: this.mapboxContainer.nativeElement,
            // accessToken: environment.mapboxToken,
            accessToken: MasterData.mapboxToken,
            interactive: true,
            touchPitch: true,
        });
        map.on('click', 'points', (e: { originalEvent: { stopPropagation: () => void; }; }) => {
            e.originalEvent.stopPropagation();
        })
        map.addControl(new mapboxgl.ScaleControl({unit: 'imperial'}), 'bottom-right');
        // map.addControl(new mapboxgl.NavigationControl({visualizePitch: true}), 'bottom-right');
        return map
    }

    createDeckGl() {
        return new DeckOverlay(
            {
                layers: this.layers,
                pickingRadius: 3,
                getTooltip: this.getToolTip,
                onClick: this.onClick,
                onBeforeRender: () => {
                    if (this.deckgl) { }
                },
                onHover: this.onHover,
            },
        )
    }

    resize() {
        setTimeout(() => this.map?.resize(), 10)
    }

    refresh() {
        this.deckgl?.setProps({
            layers: this.layers
        })
    }

    onClick(info: PickingInfo | undefined | null, event: any) {
        if (this._selectingLayer) {
            this._selectingLayer?._forwardEventToCurrentLayer({type: "anyclick", srcEvent: event.srcEvent.originalEvent})
            return
        }
        this.service?.click(this.id ?? '', info)
        
        if (info?.object?.type == 'Feature') {
            this.selectFeature(info.object.properties['id'])
        }
    }

    onHover(info: PickingInfo, event: any) {
        this.service?.hover(this.id ?? '', info)
    }

    getToolTip(info: PickingInfo) {
        if (!info.object) return null
        if (info.object.info) return info.object.info
        if (info.object.getInfo) {
            return info.object.getInfo(info)
        }
    }

    setPitch(pitch: number) {
        this.map?.setPitch(pitch)
    }

    min(arr: number[]) {
        if (!arr.length) return 0
        if (arr.length == 1) return arr[0]
        return arr.reduce((a,b) => Math.min(a,b))
    }
    max(arr: number[]) {
        if (!arr.length) return 0
        if (arr.length == 1) return arr[0]
        return arr.reduce((a,b) => Math.max(a,b))
    }

    _bound: LatLngLike[] = []
    fitBounds(bounds: LatLngLike[], force: boolean = false) {
        if (!this.map) return
        if (!bounds || !bounds.length) return
        const minLng = this.min(bounds.map(it => getLongitude(it)))
        const maxLng = this.max(bounds.map(it => getLongitude(it)))
        const minLat = this.min(bounds.map(it => getLatitude(it)).filter(it => it).map(it => it!!))
        const maxLat = this.max(bounds.map(it => getLatitude(it)).filter(it => it).map(it => it!!))
        const expLat = 0.5 * (Math.max(maxLat - minLat, 0.05) - (maxLat - minLat))
        const expLng = 0.5 * (Math.max(maxLng - minLng, 0.05) - (maxLng - minLng))
        const newBound = [[minLng - expLng, minLat - expLat], [maxLng + expLng, maxLat + expLat]]
        if (!force) {
        let isUpdated = false
            if (newBound.length != this._bound.length) {
                isUpdated = true
            } else {
                for (let i = 0; i < newBound.length; i++) {
                    if (newBound[i][0] != this._bound[i][0]) {
                        isUpdated = true
                    }
                    else if (newBound[i][1] != this._bound[i][1]) {
                        isUpdated = true
                    }
                }
            }
            if (!isUpdated) return
        }
        this._bound = newBound
        this.map.fitBounds(newBound as any, {padding: 50, duration: 400, pitch: 30})
    }

    /**
     * Prepare layers
     */
    _hidden: any = {}
    toggleLayer(key: string) {
        this._hidden[key] = !this._hidden[key]
        this.refresh()
    }
    setLayerVisible(key: string, v: boolean) {
        this._hidden[key] = !v
        this.refresh()
    }
    set hidden(v: any) {
        this._hidden = v
        this.refresh()
    }
    get layers(): LayersList {
        return [
            this._selectionLayer,
            this.highlightedJobPathLayer,
            this.highlightedLiveGpsLayer,
            this.jobPathLayer,
            ...(this._hidden.jobTrip ? [] : this.jobTripLayers),
            this.historicalGpsLayer,
            this.updatedGpsLayer,
            this.liveGpsLayer,
            this.locationLayer,
            this.highlightedLocationLayer,
            this.shipmentArcLayer,
            this.warehouseLayer,
            this.networkLayer,
            this._selectingLayer,
        ].filter(l => l)
    }

    _network: Network | null
    _networkLayer: NetworkLayer | null
    get network() {
        return this._network
    }
    set network(v: Network) {
        this._network = v;
        this._networkLayer = v ? new NetworkLayer(v) : null;
    }
    get networkLayer() {
        if (this._hidden.network) {
            this._networkLayer = null
            return null
        }
        if (!this._networkLayer)
            this._networkLayer = this._network ? new NetworkLayer(this._network) : null;
        return this._networkLayer
    }


    private _locations: Location[] = []
    private _locationLayer: LocationDotLayer | null = null
    get locationLayer() {
        if (!this._locations) return null
        if (this._hidden.location) {
            this._locationLayer = null
            return null
        }
        if (!this._locationLayer) {
            this._locationLayer = new LocationDotLayer(`${this.id}-location`, this._locations)
        }
        return this._locationLayer
    }

    set locations(v: Location[]) {
        this._locations = v;
        this._locationLayer = new LocationDotLayer(`${this.id}-location`, this._locations)
    }

    private _warehouses: Warehouse[] = []
    private warehouseLayer: MapPinLayer | null = null
    set warehouses(v: Warehouse[]) {
        this._warehouses = v;
        this.warehouseLayer = new MapPinLayer(`${this.id}-warehouses`, this._warehouses, {icon: this.displayOptions.warehouseIcon})
    }

    private _highlightedLocations: LatLngLike[] = []
    private highlightedLocationLayer: HighlightedLocationsLayer | null = null
    set highlightedLocations(v: LatLngLike[]) {
        this._highlightedLocations = v;
        this.highlightedLocationLayer = new HighlightedLocationsLayer(`${this.id}-higlight-location`, this._highlightedLocations)
    }

    private _jobs: JobDetail[] = []
    private _jobTripLayers: JobTripLayer[] = []
    _preferJobTrip: boolean = true

    get jobTripLayers() {
        if (this._hidden.jobPath) {
            this._jobTripLayers = []
            return []
        }
        if (!this._jobTripLayers) {
            this._jobTripLayers = this.createJobTripLayers()
        }
        return this._jobTripLayers
    }
    createJobTripLayers() {
        if (this._hidden.jobPath) {
            return []
        }
        const quickJobs = this._preferJobTrip ? (this._quickJobs ?? []).filter(it => it.jobInfo?.path?.length) : []
        const jobs = [...this._jobs, ...quickJobs]
        return jobs.map(it => new JobTripLayer(it))
    }
    set jobs(v: JobDetail[]) {
        this._jobs = v;
        this._jobTripLayers = this.createJobTripLayers()
    }

    private _quickJobs: JobSearchResultItem[] = []
    private _jobPathLayer: JobPathLayer | null = null
    get jobPathLayer() {
        if (this._hidden.jobPath) {
            this._jobPathLayer = null
            return null
        }
        if (!this._jobPathLayer) {
            this._jobPathLayer = this.createJobPathLayers()
        }
        return this._jobPathLayer
    }
    createJobPathLayers() {
        if (this._hidden.jobPath) {
            return null
        }
        const quickJobs = (this._quickJobs ?? []).filter(it => !this._preferJobTrip || !it.jobInfo?.path?.length)
        return new JobPathLayer(`${this.id}`, quickJobs, {minWidth: 2})
    }
    set quickJobs(v: JobSearchResultItem[]) {
        this._quickJobs = v;
        this._jobPathLayer = this.createJobPathLayers()
        this._jobTripLayers = this.createJobTripLayers()
    }

    private _highlightedJobItems: JobSearchResultItem[] = []
    private highlightedJobPathLayer: JobPathLayer | null = null
    set highlightedJobItems(v: JobSearchResultItem[]) {
        this._highlightedJobItems = v;
        this.highlightedJobPathLayer = new JobPathLayer(`${this.id}-highlighted`, this._highlightedJobItems, {minWidth: 8, color: [42, 200, 43, 200], pickable: false})
    }


    private _updatedGpsLocation: DeliveryUpdate[] = []
    private updatedGpsLayer: GpsLocationLayer | null = null
    set updatedGpsLocation(v: DeliveryUpdate[]) {
        this._updatedGpsLocation = v
        this.updatedGpsLayer = new GpsLocationLayer(`${this.id}-updated-gps`, this._updatedGpsLocation)
    }

    private _historicalGpsLocation: DeliveryUpdate[] = []
    private historicalGpsLayer: GpsLocationLayer | null = null
    set historicalGpsLocation(v: DeliveryUpdate[]) {
        this._historicalGpsLocation = v
        this.historicalGpsLayer = new GpsLocationLayer(`${this.id}-historical-gps`, this._historicalGpsLocation)
    }

    private _liveGpsLocation: DeliveryUpdate[] = []
    private _liveGpsLayer: LiveTrackingLayer | null = null
    get liveGpsLayer() {
        if (!this._liveGpsLocation) return null
        if (this._hidden.liveGps) {
            this._liveGpsLayer = null
            return null
        }
        if (this._liveGpsLayer == null) {
            this._liveGpsLayer = new LiveTrackingLayer(`${this.id}-live-gps`, this._liveGpsLocation, {noAnimation: this._liveGpsLocation.length > 1})
        }
        return this._liveGpsLayer
    }
    set liveGpsLocation(v: DeliveryUpdate[]) {
        this._liveGpsLocation = v
        this._liveGpsLayer = new LiveTrackingLayer(`${this.id}-live-gps`, this._liveGpsLocation, {noAnimation: v.length > 1})
    }

    private _highlightedLiveGpsLocation: DeliveryUpdate[] = []
    private highlightedLiveGpsLayer: HighlightedLiveTrackingLayer | null = null
    set highlightedLiveGpsLocation(v: DeliveryUpdate[]) {
        this._highlightedLiveGpsLocation = v
        this.highlightedLiveGpsLayer = new HighlightedLiveTrackingLayer(this.id, this._highlightedLiveGpsLocation)
    }

    private _shipments: Shipment[] = []
    private shipmentArcLayer: ShipmentArcLayer | null = null
    set shipments(v: Shipment[]) {
        this._shipments = v
        this.shipmentArcLayer = new ShipmentArcLayer(this.id, {shipments: this._shipments}, {animation: -1, sharedTilt: this.displayOptions.arcSharedTilt})
    }

    private _selectionLayer: GeoJsonLayer | null = null;
    private _selectionFeatures: Feature[] = [];
    get selectionFeatures(): Feature[] {
        return this._selectionFeatures;
    }
    set selectionFeatures(features: Feature[]) {
        this._selectionFeatures = features;
        this._selectionLayer = this.createSelectionLayer();
        this.refresh();
    }
    selectFeature(id: string) {
        const feature = this._selectionFeatures.find(it => it.properties?.['id'] === id)
        if (feature) {
            if (feature.properties['selected'] === 'true') {
                feature.properties['selected'] = 'false'
            } else {
                feature.properties['selected'] = 'true'
            }
        }
        for (let feature of this._selectionFeatures) {
            if (feature.properties['id'] !== id) {
                feature.properties['selected'] = 'false'
            }
        }
        this._selectionLayer = this.createSelectionLayer();
        this.refresh();
}

    private createSelectionLayer() {
        return new GeoJsonLayer({
            id: `${this.id}-selection-${new Date().getTime()}`,
            data: this._selectionFeatures,
            pickable: true,
            filled: true,
            getFillColor: (feature: Feature) => feature.properties['selected'] === 'true' ? [255, 0, 0, 128] : [0, 255, 0, 128],
            getLineColor: (feature: Feature) => feature.properties['selected'] === 'true' ? [255, 0, 0, 255] : [0, 255, 0, 255],
            lineWidthMinPixels: 2,
            parameters: {
                depthTest: false
            }
        });
    }

    private _selectingLayer: EditableGeoJsonLayer | null = null;
    private _selectingFeatures: FeatureCollection = {
        type: 'FeatureCollection',
        features: []
    };
    // onSelectingChange?: (features: Feature[]) => void;
    onSelectingChange: Subject<Feature[]> = new Subject()

    enableSelecting() {
        this._selectingLayer = this.createSelectingLayer();
        this.deckgl.setProps({
            getCursor: this._selectingLayer.getCursor.bind(this._selectingLayer)
        })
        this.refresh();
    }

    disableSelecting() {
        this._selectingLayer = null;
        this._selectingFeatures.features = [];
        this.deckgl.setProps({
            getCursor: () => 'default'
        })
        this.refresh();
    }

    onDoneSelecting() {
        this.selectionFeatures = [
            ...this._selectionFeatures,
            ...this._selectingFeatures.features.map(it => Object.assign({}, it, {properties: {id: ulid()}}))
        ];
        this.disableSelecting();
        this.refresh();
        this.onSelectingChange.next(this._selectionFeatures);
        // this.onSelectingChange?.(this._selectingFeatures.features);
    }

    clearSelecting() {
        this._selectingFeatures.features = [];
        console.log('Cleared selecting')
        this.refresh();
    }

    private handleSelectingUpdate() {
        if (!this._selectingFeatures.features.length) return;

        const lastPolygon = this._selectingFeatures.features[this._selectingFeatures.features.length - 1];
        // if (lastPolygon.geometry.type !== 'Polygon') return;

        // console.log('lastPolygon', lastPolygon)

        // Find shipments within the polygon
        const selectedShipments = this._shipments.filter(shipment => {
            const pickup = shipment.deliveryInfos.find(x => x.type === 'PICKUP')?.addr;
            const dropoff = shipment.deliveryInfos.find(x => x.type === 'DROPOFF')?.addr;
            
            if (!pickup?.metadata?.latitude || !dropoff?.metadata?.latitude) return false;

            const pickupPoint = [pickup.metadata.longitude, pickup.metadata.latitude];
            const dropoffPoint = [dropoff.metadata.longitude, dropoff.metadata.latitude];

            // Check if either pickup or dropoff is within the polygon
            // return this.isPointInPolygon(pickupPoint, lastPolygon) ||
            //        this.isPointInPolygon(dropoffPoint, lastPolygon);
            return false;
        });

        // Notify about selecting change
        // this.onSelectingChange?.(selectedShipments);
    }

    private isInSelection(latlng: LatLngLike): boolean {
        const point = getLngLat(latlng);
        for (const feature of this._selectionFeatures) {
            if (this.isPointInPolygon(point, feature)) {
                return true;
            }
        }
        return false;
    }

    private isPointInPolygon(point: number[], feature: Feature): boolean {
        const polygon = feature.geometry as Polygon;
        const coordinates = polygon.coordinates[0];
        let inside = false;
        
        for (let i = 0, j = coordinates.length - 1; i < coordinates.length; j = i++) {
            const xi = coordinates[i][0], yi = coordinates[i][1];
            const xj = coordinates[j][0], yj = coordinates[j][1];
            
            const intersect = ((yi > point[1]) !== (yj > point[1])) &&
                (point[0] < (xj - xi) * (point[1] - yi) / (yj - yi) + xi);
            
            if (intersect) inside = !inside;
        }
        
        return inside;
    }

    get selectedFeatures() {
        return this._selectingFeatures.features;
    }

    get selectedNetworkNodes() {
        if (!this._network?.nodes) return []
        if (!this._selectionFeatures) return []
        return this._network.nodes.filter(it => this.isInSelection(it.location) )
    }

    private createSelectingLayer() {
        return new EditableGeoJsonLayer({
            id: `${this.id}-selecting`,
            data: this._selectingFeatures,
            mode: DrawPolygonMode,
            selectedFeatureIndexes: [],
            
            // Styling
            getFillColor: (feature, isSelected, mode) => {
                return isSelected ? [255, 0, 0, 30] : [0, 0, 255, 30]
            },
            getLineColor: (feature, isSelected, mode) => {
                return isSelected ? [255, 0, 0, 120] : [0, 0, 255, 120]
            },
            getLineWidth: 2,
            stroked: true,
            filled: true,
            pickable: true,
            lineWidthMinPixels: 2,
            pointRadiusMinPixels: 2,
            pointRadiusScale: 2000,
      
            // Editing callbacks
            onEdit: ({ updatedData, editType }) => {
                // console.log('updatedData', updatedData, editType)
                
                if (editType === 'addFeature') {
                    this._selectingFeatures = updatedData;
                    this.onDoneSelecting();
                    // // console.log('this._selectingFeatures.features', this._selectingFeatures.features) 
                    // this.handleSelectingUpdate();
                    // this._selectingLayer = this.createSelectingLayer();
                    // this.refresh()
                }
            },

            // Edit handles styling
            // editHandleType: 'point',
            // getEditHandlePointColor: [0, 0, 0, 255],
            // getEditHandlePointRadius: 5,
        });
    }
}
