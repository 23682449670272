<div class="form-header no-border no-padding-bottom">
  <div class="form-title f18b">Update Time</div>
  <div class="top10 f14">Update the arrived and departed time.</div>
  <i class="ic-close clickable" (click)="onBtnCancel()" nz-icon nzType="close" nzTheme="outline"></i>
</div>

<form class="form-detail" [formGroup]="formInput" nz-form style="padding: 26px;">
  <div nz-row>
    <div nz-col [nzSpan]="24">
      <div class="form-label-v2 single-line fix-height">
        {{getLabel('arrived')}}
        <span *ngIf="isRequired('arrived') && getLabel('arrived').length > 0" class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group [nzAddOnAfter]="shortTimezone">
            <nz-date-picker nz-input
              [formControlName]="'arrived'"
              nzFormat="yyyy-MM-dd HH:mm"
              style="width: 100%;"
              [nzShowTime]="{nzFormat: 'HH:mm', nzMinuteStep: 15}"
              (ngModelChange)="onChange($event, 'arrived')"
            ></nz-date-picker>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24">
      <div class="form-label-v2 single-line fix-height">
        {{getLabel('departed')}}
        <span *ngIf="isRequired('departed') && getLabel('departed').length > 0" class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group [nzAddOnAfter]="shortTimezone">
            <nz-date-picker nz-input
              [formControlName]="'departed'"
              nzFormat="yyyy-MM-dd HH:mm"
              style="width: 100%;"
              [nzShowTime]="{nzFormat: 'HH:mm', nzMinuteStep: 15}"
              (ngModelChange)="onChange($event, 'departed')"
            ></nz-date-picker>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
    <ng-container *ngIf="isShowDelayCode">
      <div nz-col [nzSpan]="24" *ngFor="let key of ['delayCodeId']">
        <div class="form-label-v2 single-line fix-height">
          {{getLabel(key)}}
          <span class="label-mark-required"></span>
        </div>
        <nz-form-item>
          <nz-form-control>
            <nz-input-group>
              <nz-select nzBackdrop="true" nzAllowClear nzShowSearch style="width: 100%;"
                [formControlName]="key"
                [nzPlaceHolder]="getPlaceHolder(key)"
                nzDropdownClassName="select-delay-code">
                <nz-option nzCustomContent *ngFor="let item of listDelayCodes" [nzValue]="item._id" [nzLabel]="item.name">
                  <div><span class="delay-code-type">[{{item.type}}]</span> {{item.name}}</div>
                  <div *ngIf="item.reason" class="sub-info">{{item.reason}}</div>
                </nz-option>
              </nz-select>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
      <ng-container *ngIf="isShowDelayCode">
        <div nz-col [nzSpan]="24" *ngFor="let key of ['note']">
          <div class="form-label-v2 single-line fix-height">
            {{getLabel(key)}}
            <span *ngIf="isRequired('key') && getLabel('key').length > 0" class="label-mark-required"></span>
          </div>
          <nz-form-item>
            <nz-form-control>
              <nz-input-group>
                <textarea
                  nz-input 
                  [formControlName]="key"
                  [nzAutosize]="{minRows: 2, maxRows: 4}">
                </textarea>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>
    </ng-container>
    
    <ng-container *ngIf="isConfirm">
      <div nz-col [nzSpan]="24" *ngFor="let key of ['confirmed']">
        <nz-form-item>
          <nz-form-control>
            <nz-input-group>
              <div nz-checkbox [formControlName]="key">
                Confirmed Actual Time
              </div>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>
  </div>
</form>

<div form-footer class="no-border"
  [canClickOK]="needUpdate" [canClickCancel]="true"
  nzIconOK="save" labelOK="Save" (onOK)="onBtnSave()"
  (onCancel)="onBtnCancel()"></div>
