import { Injectable } from "@angular/core";
import { DeliveryUpdate } from "@app/interfaces/map-v2/delivery-update";
import { BaseWsUpdateService } from "./base-ws-update.service";

@Injectable({
    providedIn: 'root'
})
export class LiveDeliveryUpdateService extends BaseWsUpdateService<any> {
    override parseWsData(raw: string): any {
        const comps = raw.split(',')
        const data: any = {
            action: comps[0]
        }
        for (let c of comps) {
            const cc = c.split("_")
            if (cc.length > 1) {
                const key = cc[0].toLowerCase()
                cc.splice(0, 1)
                const value = cc.join("_")
                data[key] = value
            }
        }
        return data
    }
    override getObjectId(data: any): string | null {
        if (!data) return null
        if (data['job']) {
            return 'JOB_' + data['job']
        }
        if (data['shipment']) {
            return 'SHIPMENT_' + data['shipment']
        }
        if (data['task']) {
            return 'TASK_' + data['shipment']
        }
        return null
    }

    constructor(
    ) {
        super('delivery-update')
    }
}