import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { TaskHandlerService } from "@app/track-trace/services/task-handler.service";
import { TrackTraceService } from "@app/track-trace/services/track-trace.service";
import { WhenByService } from "@app/track-trace/services/when-by.service";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-util";
import { UIHelper } from "@services/UIHelper";
import { WorkTask } from "@wearewarp/types/data-model";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzTagModule } from "ng-zorro-antd/tag";
import { lastValueFrom, Subscription } from "rxjs";

interface IDisplayTaskInfo {
  name: string,
  description: string,
  createdBy: string,
  createdAt: string,
}

const CustomInputs = [
  CommonModule,
  NzButtonModule,
  NzTagModule,
]

@Component({
  selector: 'work-requested-task',
  standalone: true,
  imports: CustomInputs,
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  providers: [TaskHandlerService],
})
export class WorkRequestedTaskComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  taskInfo: WorkTask;
  displayInfo: IDisplayTaskInfo;


  constructor(
    private trackTraceService: TrackTraceService,
    private taskHandlerService: TaskHandlerService,
    private whenByService: WhenByService,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.trackTraceService.getWorkTask().subscribe(task => {
        this.taskInfo = task;
        this.buildDisplayInfo();
      })
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get isTaskCompleted() {
    return this.taskInfo?.status === Const.WorkTaskStatus.completed;
  }

  async buildDisplayInfo() {
    this.displayInfo = {
      name: this.getTaskName(),
      description: this.getTaskDescription(),
      createdBy: await this.getReporter(),
      createdAt: this.getTimeCreated(),
    }
  }

  getTaskName() {
    return this.taskInfo?.name || this.taskInfo?.type;
  }

  getTaskDescription() {
    return this.taskInfo?.description || '';
  }

  getTimeCreated() {
    const createdAt = (this.taskInfo as any)?.insert?.when;
    return createdAt ? DateUtil.displayLocalTime(createdAt, DateUtil.getLocalTimeZone(), 'MM/DD hh:mm a') : '';
  }

  async getReporter() {
    const insert = (this.taskInfo as any)?.insert;
    if (!insert) return '';
    const reporter = {
      entityId: insert.byId,
      entity: insert.collection || 'users',
    }
    return lastValueFrom(this.whenByService.getListWhenBy([reporter])).then(listData => listData?.[0]?.name || '');
  }

  onBtnMarkAsResolved() {
    UIHelper.confirmYesNo(
      `Are you sure you want to mark this task '${this.displayInfo?.name}' as resolved?`,
      () => {
        this.taskHandlerService.completeTask(this.taskInfo.id).subscribe({
          next: () => {
            UIHelper.showSuccess('Task marked as resolved');
            this.trackTraceService.refreshThisTask();
          },
          error: (err) => {
            UIHelper.showErr(err);
          }
        });
      }
    );
  }
}