import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, OnDestroy } from '@angular/core';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { CommonModule } from '@angular/common';
import { Job } from '@wearewarp/types/data-model';
import { CarrierService } from '@app/track-trace/services/carrier.service';
import { DriverService } from '@app/track-trace/services/driver.service';
import { UserService } from '@app/track-trace/services/user.service';
import { TrackTraceService } from '@app/track-trace/services/track-trace.service';
import { combineLatest, map, Observable, BehaviorSubject, shareReplay, takeUntil, Subject, switchMap } from 'rxjs';
import { WhenByService, EntityInfo } from '@app/track-trace/services/when-by.service';

interface HistorySubject {
  id: string;
  collections: string;
  name: string;
}

interface HistoryEntry {
  text: string;
  by: HistorySubject;
  subject: HistorySubject;
  time: string;
}

@Component({
  selector: 'app-assign-history',
  standalone: true,
  imports: [
    CommonModule,
    NzTimelineModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './assign-history.component.html',
  styleUrl: './assign-history.component.scss'
})
export class AssignHistoryComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private loadInfo$ = new BehaviorSubject<Job>(null);
  histories: HistoryEntry[] = [];

  constructor(
    private trackTraceService: TrackTraceService,
    private whenByService: WhenByService
  ) {
    this.initializeDataStream();
  }

  ngOnInit() {
    this.trackTraceService.getLoadInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe(loadInfo => {
        this.loadInfo$.next(loadInfo);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeDataStream() {
    this.loadInfo$.pipe(
      takeUntil(this.destroy$),
      map(loadInfo => {
        if (!loadInfo) return [];

        const changeCarrierHistories = loadInfo.historyChangeCarrier ?? [];
        const changeDriverHistories = loadInfo.historyChangeDriver ?? [];
        const assignedCarrierHistory = this.getAssignedCarrierHistory(loadInfo);
        
        return [
          ...(changeCarrierHistories.length ? changeCarrierHistories.map(this.mapCarrierHistory) : 
              (assignedCarrierHistory ? [assignedCarrierHistory] : [])),
          ...changeDriverHistories.map(this.mapDriverHistory)
        ].sort((a, b) => {
          const timeA = new Date(a.time).getTime();
          const timeB = new Date(b.time).getTime();
          return timeB - timeA; // Sắp xếp giảm dần (mới nhất lên đầu)
        });
      }),
      map(changeHistories => {
        console.log('changeHistories', changeHistories);
        const entities = this.collectEntityIds(changeHistories);
        console.log('entities', entities);
        return { changeHistories, entities };
      }),
      switchMap(({ changeHistories, entities }) =>
        this.whenByService.getListWhenBy(entities).pipe(
          map((entityInfos: EntityInfo[]) =>
            changeHistories.map(history =>
              this.updateHistoryWithEntity(history, entityInfos)
            )
          )
        )
      ),
      shareReplay(1)
    ).subscribe(histories => {
      this.histories = histories;
    });
  }

  private mapCarrierHistory(history: any): HistoryEntry {
    return {
      text: !history.currentCarrierId && history.newCarrierId ? 'Carrier {subject} assigned' :
            history.currentCarrierId && !history.newCarrierId ? 'Carrier {subject} unassigned' : '',
      by: {
        id: history.whenBy.byId,
        collections: history.whenBy.collection,
        name: history.whenBy.byId
      },
      subject: {
        id: history.currentCarrierId && !history.newCarrierId ? history.currentCarrierId : history.newCarrierId,
        collections: 'carriers',
        name: history.currentCarrierId && !history.newCarrierId ? history.currentCarrierId : history.newCarrierId
      },
      time: new Date(history.whenBy.when).toLocaleString('en-US', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    };
  }

  private mapDriverHistory(history: any): HistoryEntry {
    return {
      text: !history.currentDriverId && history.newDriverId ? 'Driver {subject} assigned' :
            history.currentDriverId && history.newDriverId ? 'Driver changed: {subject}' :
            history.currentDriverId && !history.newDriverId ? 'Driver {subject} unassigned' : '',
      by: {
        id: history?.whenBy?.byId,
        collections: history?.whenBy?.collection,
        name: history?.whenBy?.byId
      },
      subject: {
        id: history?.newDriverId,
        collections: 'drivers',
        name: history?.newDriverId
      },
      time: new Date(history?.whenBy?.when).toLocaleString('en-US', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    };
  }

  private collectEntityIds(histories: HistoryEntry[]) {
    let entities: { entityId: string, entity: string }[] = [];
    histories.map(history => {
      const subjectCollection = history?.subject?.collections;
      if (!entities.find(e => e.entityId === history?.subject?.id && e.entity === subjectCollection)) {
        entities.push({ entityId: history?.subject?.id, entity: subjectCollection });
      }
      const byCollection = history?.by?.collections;
      if (!entities.find(e => e.entityId === history?.by?.id && e.entity === byCollection)) {
        entities.push({ entityId: history?.by?.id, entity: byCollection });
      }
    });

    return entities;
  }

  private updateHistoryWithEntity(
    history: HistoryEntry,
    entityInfos: EntityInfo[]
  ): HistoryEntry {
    const historyEntity = entityInfos.find(e => e.entityId === history?.subject?.id);
    const byEntity = entityInfos.find(e => e.entityId === history?.by?.id);

    return {
      ...history,
      text: history.text.replace('{subject}', historyEntity?.name ?? `${historyEntity?.entity}_${historyEntity?.entityId}`),
      by: {
        ...history.by,
        name: byEntity?.name ?? `${byEntity?.entity}_${byEntity?.entityId}`
      },
      subject: {
        ...history.subject,
        name: historyEntity?.name ?? `${historyEntity?.entity}_${historyEntity?.entityId}`
      }
    };
  }

  private getAssignedCarrierHistory(loadInfo: Job): HistoryEntry | null {
    if (!loadInfo?.assignedCarrier?.carrierId) return null;
    
    return {
      text: 'Carrier {subject} assigned',
      by: {
        id: loadInfo.assignedCarrier.update?.byId,
        collections: 'users',
        name: loadInfo.assignedCarrier.update?.byId,
      },
      subject: {
        id: loadInfo.assignedCarrier.carrierId,
        collections: 'carriers',  
        name: loadInfo.carrier?.basicInfo?.name
      },
      time: new Date(loadInfo.assignedCarrier.update?.when || loadInfo.assignedCarrier.firstAssigned).toLocaleString('en-US', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    };
  }
}
