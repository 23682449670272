import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";

const CustomInputs = [
  CommonModule,
  NzToolTipModule,
  NzBadgeModule
]

@Component({
  selector: 'job-live-icon',
  standalone: true,
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  imports: CustomInputs,
})
export class JobLiveIconComponent {
  @Input() isOnline = false;

}
