import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import RouteEntity from "../entity/RouteEntity";
import { ShipmentItem, Task } from "@wearewarp/types/data-model";
import RouteTaskEntity from "../entity/RouteTaskEntity";
import ShipmentEntity from "../entity/ShipmentEntity";
import { environment } from "@env/environment";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { Log } from "@services/log";

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  private routeData: BehaviorSubject<any> = new BehaviorSubject(null)
  public routeData$ = this.routeData.asObservable();
  private routeId: string;
  private route: RouteEntity = null;
  private taskEntities: Map<string, RouteTaskEntity> = new Map();
  private shipmentEntities: Map<string, ShipmentEntity> = new Map();
  private shipmentItemsMap: Map<string, ShipmentItem> = new Map();

  constructor(private api: ApiService) {
  }

  public async setRouteId(routeId) {
    if (routeId) {
      this.routeId = routeId;
      await this.refresh();
    }
  }

  public async refresh() {
    let promises = [
      this.fetchRoute(),
      this.fetchTasks(),
      this.fetchShipments(),
      this.fetchShipmentItems()
    ]

    await Promise.all(promises).catch(e => {
      console.error(e);
    })
  }

  public getRoute() {
    return this.route;
  }

  private async fetchRoute() {
    if (!this.routeId) return;
    try {
      let url =  `${environment.backendUrl}/${Const.APIV2(Const.APIURI_JOBS)}/${this.routeId}`;
      const resp = await lastValueFrom(this.api.GET(url));
      this.route = await new RouteEntity().setRouteService(this).init(resp.data);
      this.routeData.next(resp.data);
      Log.d("Fetched routeData: ", this.getRoute()?.getData());
    }
    catch (e) {
      Log.d("Fetch route data error: ", e.message);
    }
  }

  private async fetchTasks(): Promise<void> {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.routeId}/tasks`);
      const resp = await lastValueFrom(this.api.GET(url));
      const data = resp?.data?.list_data;
      for (let item of data) {
        const taskEntity = new RouteTaskEntity().setRouteService(this).init(item);
        this.taskEntities.set(taskEntity.getId(), taskEntity);
      }
    }
    catch (e) {
      Log.d("Fetch tasks error: ", e.message);
    }
  }

  async fetchShipments(): Promise<void> {
    try {
      let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_JOBS)}/${this.routeId}/shipments`;
      const resp = await lastValueFrom(this.api.GET(url));
      const data = resp?.data?.list_data;
      for (let item of data) {
        const shipmentEntity = new ShipmentEntity().setRouteService(this).init(item);
        this.shipmentEntities.set(shipmentEntity.getId(), shipmentEntity);
      }
    }
    catch (e) {
      Log.d("Fetch shipments error: ", e.message);
    }
  }

  private async fetchShipmentItems() {
    try {
      let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_JOBS)}/${this.routeId}/shipment_items`;
      const resp = await lastValueFrom(this.api.GET(url));
      const data = resp?.data?.list_data;
      for (let item of data) {
        this.shipmentItemsMap.set(item.id, item);
      }
    }
    catch (e) {
      Log.d("Fetch shipment items error: ", e.message);
    }
  }

  public getRouteTaskById(taskId) {
    return this.taskEntities.get(taskId);
  }

  public getShipmentById(shipmentId: string) {
    return this.shipmentEntities.get(shipmentId);
  }

  public getShipmentItemById(itemId: string) {
    return this.shipmentItemsMap.get(itemId);
  }

  public getLiveTrackingStatus(): boolean {
    const routeData = this.getRoute().getData();
    let status = routeData?.assignedDriver?.isActive == true ? true : false;
    return status;
  }

}