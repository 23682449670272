import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import DeliveryMapV2 from '@app/components/delivery-map-v2/map';
import { JobDetail } from '@app/interfaces/map-v2/job-detail';
import { MapService } from '@app/components/delivery-map-v2/map.service';
import { Location } from '@app/interfaces/map-v2/location';
import { getLocId, hexColor2array } from '@app/utils/map-v2.util';
import { fromEvent, Subscription } from 'rxjs';
import { AddressComponent } from './components/address';
import { StatusComponent } from './components/status';
import { JobTaskCodeComponent } from './components/task-code';
import { WarpCode } from './components/warp-code';
import { DurationComponent } from './components/duration';
import { DatePipe, DecimalPipe } from '@angular/common';
import { DeliveryUpdateService } from '../../services/delivery-update.service';
import { LiveLocationFeedService } from '../../services/live-location-feed.service';
import { DeliveryUpdate } from '@app/interfaces/map-v2/delivery-update';
import { NzIconModule } from 'ng-zorro-antd/icon';
@Component({
    selector: 'job-detail-map',
    imports: [NzIconModule, AddressComponent, StatusComponent, JobTaskCodeComponent, WarpCode, DurationComponent, DecimalPipe, DatePipe],
    templateUrl: './job-detail-map-component.component.html',
    styleUrl: './job-detail-map-component.component.scss',
    standalone: true
})
export class JobDetailMapComponent implements OnDestroy {
    @ViewChild('mapboxContainer', { static: true }) mapContainer: ElementRef | null = null;
    map: DeliveryMapV2 | null = null
    _job: JobDetail
    theme: string = 'normal'
    locationSubscription: Subscription
    hoverObjectSubscription: Subscription
    // liveGpsSubscription: string | null = null

    constructor(
        private mapService: MapService,
        private deliveryUpdateService: DeliveryUpdateService,
        private liveTrafficService: LiveLocationFeedService
    ) {
        this.onPointerLocationChange = this.onPointerLocationChange.bind(this)
        this.onHoverObjectChange = this.onHoverObjectChange.bind(this)
        // this.onNewDriverLocation = this.onNewDriverLocation.bind(this)

        this.locationSubscription = fromEvent(window, 'mousemove').subscribe({
            next: this.onPointerLocationChange
        })
        this.hoverObjectSubscription = mapService.onHover(this.onHoverObjectChange)
    }

    ngOnDestroy(): void {
        this.locationSubscription?.unsubscribe()
        this.hoverObjectSubscription?.unsubscribe()
        this.map?.destroy()
        this.map = null
        // this.unsubscribeTraffic()
    }

    @Input() set detail(v: JobDetail) {
        this._job = v
        this.initMap()
    }

    @Input() set historicalLocations(v: DeliveryUpdate[]) {
        if (this.map) {
            this.map.historicalGpsLocation = v
            this.refresh()
        }
    }

    @Input() set updatedLocations(v: DeliveryUpdate[]) {
        if (this.map) {
            this.map.updatedGpsLocation = v
            this.refresh()
        }
    }

    @Input() set liveLocations(v: DeliveryUpdate[]) {
        if (this.map) {
            this.map.liveGpsLocation = v
            this.refresh()
        }
    }

    refresh() {
        this.map.refresh()
    }

    @Output() onMapChange: EventEmitter<DeliveryMapV2> = new EventEmitter()
    initMap() {
        if (this.mapContainer != null) {
            // this.map?.destroy()
            if (!this.map)
                this.map = new DeliveryMapV2('', this.mapContainer, this.mapService)
            // this.map.onClickShipment = this.onClickShipment
    
            // this.clickLocationSubscription =  this.mapService.onLocationClick(this.onClickLocation)
            const locations = this.extractLocations(this._job)
            const latlngs = locations.map(it => it.latlng)

            this.map.locations = locations
            this.map.jobs = [this._job].filter(it => it)
            this.map.refresh()    
            this.map?.resize()
            this.map.fitBounds(latlngs)
            this.onMapChange.emit(this.map)
        }
    }

    extractLocations(detail: JobDetail | null) {
        const pickupLocations: Location[] = detail?.stops?.filter(it => it.tasks?.filter(t => t.type == 'PICKUP')?.length)?.map(stop => Object.assign({
            type: 'PICKUP',
            id: getLocId(stop.latlng),
            name: stop.name,
            address: stop.address,
            latlng: stop.latlng,
            stop: stop,
            status: stop.status,
        }))
        const dropoffLocations: Location[] = detail?.stops?.filter(it => it.tasks?.filter(t => t.type == 'DROPOFF')?.length)?.map(stop => Object.assign({
            id: getLocId(stop.latlng),
            name: stop.name,
            address: stop.address,
            latlng: stop.latlng,
            stop: stop,
            type: 'DROPOFF',
            status: stop.status
        }))

        const locs = (pickupLocations ?? []).concat(dropoffLocations ?? [])

        for (let loc of locs) {
            switch (loc.status) {
                case 'succeeded':
                    loc.color = hexColor2array('72bc96')
                    break;
                case 'arrived':
                    loc.color = hexColor2array('fbc860'); // fbc860
                    break;
                case 'enroute':
                    loc.color = hexColor2array('77bbfa')
                    break;
                case 'failed':
                case 'pickupFailed':
                    loc.color = hexColor2array('e57e82')
                    break;
                case 'canceled':
                    loc.color = hexColor2array('888')
                    break;
                default:
                    break;
            }
        }
        
        return locs
    }

    /**
     * Handle popup
     */
    hoveredObject: any  = null
    hoveredLocation: any = null
    hoveredLeg: any = null
    hoveredGps: any = null
    mousePointerX: any = null
    mousePointerY: any = null
    onPointerLocationChange(event: MouseEvent) {        
        this.mousePointerX = Math.min(event.clientX, event.view.innerWidth - 200)
        this.mousePointerY = event.clientY + 18
    }
    onHoverObjectChange(object: any) {
        if (object?.type == 'DROPOFF' || object?.type == 'PICKUP') {
            this.hoveredLocation = object
        } else {
            this.hoveredLocation = null
        }
        if (object?.type == 'LEG') {
            this.hoveredLeg = object
        } else {
            this.hoveredLeg = null
        }
        if (object?.type == 'GPS') {
            this.hoveredGps = object
        } else {
            this.hoveredGps = null
        }
    }
}
