<div class="suggestions-list">
  <div class="suggestions-item">
    <ng-container *ngIf="!isDriverActive">
      <div class="item-label">Contact the driver to:</div>
      <div class="item-content">
          <li>Remind them to turn on live tracking</li>
          <li>Ask for ETA</li>
          <li>Check how many miles they are from {{stopType}}</li>
      </div>
      <div class="item-note">
        <div>Record the details below.</div>
      </div>
    </ng-container>
    
    <ng-container *ngIf="isDriverActive">
      <div class="item-note">Check the live tracking details and note the route status below. If the Driver is about to be late, please contact them for an update.</div>
    </ng-container>
  </div>
</div>