<form nz-form [formGroup]="formInput">
  <div class="create-task-modal">
    <div class="header">
      <div class="title-box">
        <div class="semibold">{{ displayInfo?.jobTitle }}</div>
        <div class="job-status-box"><span class="job-status" [ngClass]="displayInfo.statusClass">{{ displayInfo?.statusTxt }}</span></div>  
      </div>
    </div>
    <div class="body top20">
      <div class="semibold">Create & Assign task</div>

      <div class="form-detail top15">
        <!-- Assignee -->
        <div class="form-item" *ngIf="'inChargeId' as key">
          <div class="label">{{ getLabel(key) }}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <nz-select 
            [formControlName]="key"
            [nzShowSearch]="true"
            [nzAllowClear]="true"
            [nzPlaceHolder]="getPlaceHolder(key)"
            style="width: 100%;"
          >
            <nz-option *ngFor="let item of listOperators" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
          </nz-select>
        </div>

        <!-- Title -->
        <div class="form-item" *ngIf="'name' as key">
          <div class="label">{{ getLabel(key) }}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <input nz-input [formControlName]="key" [placeholder]="getPlaceHolder(key)" />
        </div>

        <!-- Description -->
        <div class="form-item" *ngIf="'description' as key">
          <div class="label">{{ getLabel(key) }}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <textarea nz-input [formControlName]="key" [nzAutosize]="{ minRows: 2, maxRows: 5 }"></textarea>
        </div>
      </div>
    </div>
    <div class="footer top20">
      <button nz-button nzType="primary" 
        [nzLoading]="isSubmitting"
        [disabled]="isSubmitting || !needUpdate"
        (click)="onBtnCreate()"
      >Create & Assign</button>
    </div>
  </div>
</form>