<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>
<div *ngIf="isLoading && inConfirm" class="text-center bold">Email sending in progress. Please wait a moment...</div>

<div class="confirm-content" *ngIf="!isLoading && inConfirm">
    <div class="email-bol-popup">
      <div class="title">Email BOL</div>
      <div class="description">Recipients will receive an email with a link to download BOL.</div>
    </div>
    <div class="confirm-content">
  
      <div nz-row class="bottom15">
        <div nz-col nzSpan="24"><b>Sender:</b></div>
        <!-- <div nz-col nzSpan="24">{{authUserFullName}} / {{authUserEmail}}</div> -->
        <div nz-col nzSpan="24">{{'dispatch@wearewarp.com'}}</div>
      </div>
  
      <div nz-row *ngFor="let item of confirmData?.customers" class="bottom15">
        <div nz-col nzSpan="24"><b>{{item.name}}</b></div>
        <div nz-col nzSpan="24">Email: {{item.email}}</div>
      </div>
  
      <div nz-row>
        <div nz-col nzSpan="24"><b>Remark:</b></div>
        <div nz-col nzSpan="24">{{confirmData?.remark}}</div>
      </div>
    </div>
</div>
  
<form *ngIf="!isLoading && !inConfirm" class="form-detail" [formGroup]="formInput" nz-form nzLayout="vertical">
    <div class="email-bol-popup">
        <div class="title">Email BOL Customer</div>
        <div class="description">Recipients will receive an email with a link to download BOL.</div>
    </div>
    <ng-container formArrayName="customers">
        <form nz-form *ngFor="let item of getArrayControls('customers'); let i = index" [formGroupName]="i" [ngClass]="{ 'pt20': i }">
            <div nz-row class="bottom10">
                <div nz-col nzSpan="24">
                <label nz-checkbox formControlName="isSend">
                    <span>{{getFormItemValue(getFormArrayKey(i, 'name'))}}</span>
                </label>
                </div>
            </div>
            <nz-form-item>
                <nz-form-control nzErrorTip="The input is not valid E-mail!">
                    <nz-input-group [nzAddOnAfter]="editCustomerEmailBtnTemp">
                        <input nz-input formControlName="email" nzPlaceHolder="Please enter customer's email"/>
                    </nz-input-group>
                    <ng-template #editCustomerEmailBtnTemp>
                        <a (click)="onEditEmail(i, 'email')">
                        Edit
                        </a>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </form>
    </ng-container>
    <nz-form-item style="padding-top: 20px;">
        <nz-form-label nzFor="remark">Remark</nz-form-label>
        <nz-form-control>
        <textarea nz-input formControlName="remark" id="remark" placeholder="Write a message..."></textarea>
        </nz-form-control>
    </nz-form-item>
</form>

<div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
    [canClickOK]="needUpdate && !onProgress && canClickOK" [canClickCancel]="!onProgress"
    [labelOK]="inConfirm? 'Confirm': 'Email BOL'" (onOK)="onBtnSave()" (onCancel)="onBtnCancel()"></div>
