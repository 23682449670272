import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { DeliveryInfo, Job, Order, Shipment } from "@wearewarp/types/data-model";
import { WarpId } from "@wearewarp/universal-libs";
import { TaskType } from "@wearewarp/types";


export default class BizUtil {

  static getShipmentCode(shipment: Shipment | { code?: string, warpId?: number, isUsingCode?: boolean }) {
    return shipment.isUsingCode ? shipment?.code : WarpId.showShipment(shipment?.warpId!);
  }

  static getOrderCode(order: Order | { code?: string, warpId?: number, isUsingCode?: boolean }) {
    return order.isUsingCode ? order?.code : WarpId.showOrder(order?.warpId!);
  }

  static getMiles(distance: number) {
    return `${(distance / 1609.34).toFixed(1)}`;
  }

  static createHyperLinkForJob(job: Job) {
    let firstShipment = job.shipments?.[0];
    let hyperLinkUrl = `${environment.webAdminUrl}/dashboard/dispatch/${job.id}`;
    const clientName = job.clients?.[0]?.name || 'Customer N/A';
    let hyperLinkText = `${clientName} / ${firstShipment.code}`;
    if (job.shipments && job.shipments.length > 1) {
      hyperLinkText += ` (+${job.shipments.length - 1})`;
    }
    hyperLinkText += ` / Route ${job.code}`;
    return { hyperLinkText, hyperLinkUrl };
  }

  static getDeliveryInfo(type: string, order: {deliveryInfos: Array<{id?: any, type?: string}>}, deliveryId = undefined): DeliveryInfo {
    let info;
    if (deliveryId) {
      info = (order?.deliveryInfos ?? []).filter(it => it.id == deliveryId && it.type == type)[0];
    } else {
      info = (order?.deliveryInfos ?? []).filter(it => it.type == type)[0];
    }
    return info;
  }

  static getPickInfo(order: {deliveryInfos: Array<{id?: any, type?: TaskType}>}, deliveryId = undefined): DeliveryInfo {
    return BizUtil.getDeliveryInfo(Const.RouteTaskType.PICKUP, order, deliveryId);
  }

  static getDropInfo(order: {deliveryInfos: Array<{id?: any, type?: TaskType}>}, deliveryId = undefined): DeliveryInfo {
    return BizUtil.getDeliveryInfo(Const.RouteTaskType.DROPOFF, order, deliveryId);
  }

  static getStatusJob(status: string): string {
    switch (status) {
      case Const.JobStatus.created: return 'Created';
      case Const.JobStatus.inProgress: return 'In Progress';
      case Const.JobStatus.canceled: return 'Canceled';
      case Const.JobStatus.completed: return 'Completed';
      default: return 'Created';
    }
  }

  static getJobStatusClass(status: string) {
    if (!status) 
      status = 'created';
    return Const.JobStatus[status];
  }
}