@if (content) {
    <span class="wrapper" [class]="class">
        @if (link) {
        <span class="link" (click)="openLink()">
            <span class="material-symbols-outlined md-14 gray">
                arrow_outward
            </span>
        </span>
        } @else if (onOpen.observed) {
            <span class="link" (click)="onLinkBtn($event)">
                <span class="material-symbols-outlined md-14 gray">
                    arrow_outward
                </span>
            </span>    
        } @else if (menu) {
            <span class="link" [matMenuTriggerFor]="popupmenu">
                <i nz-icon nzType="more"></i>
            </span>
            <mat-menu #popupmenu>
                <ng-container [ngTemplateOutlet]="menu"></ng-container>
            </mat-menu>
        }
        <span popup-wrapper>
            <code [class]="theme" class="clickable" (click)="onClick()">
                @if (prefix) {
                    <span class="prefix">{{ prefix }}</span><span class="delim">-</span>
                }
                <span class="main">{{ main }}</span>
                @if (suffix) {
                    <span class="delim">-</span><span class="suffix">{{ suffix }}</span>
                }
            </code>    
            <ng-template popup-content>
                <ng-container [ngTemplateOutlet]="content"></ng-container>
            </ng-template>
        </span>
    </span>
} @else {
    <span class="wrapper" [class]="class">
        @if (link) {
            <span class="link" (click)="openLink()">
                <span class="material-symbols-outlined md-14 gray">
                    arrow_outward
                </span>
            </span>
        }    
    <code [class]="theme" class="clickable" (click)="onClick()">
        @if (prefix) {
            <span class="prefix">{{ prefix }}</span><span class="delim">-</span>
        }
        <span class="main">{{ main }}</span>
        @if (suffix) {
            <span class="delim">-</span><span class="suffix">{{ suffix }}</span>
        }            
    </code>
</span>

}
