<div class="bookmark-container">
  <div class="bookmark-header">
    <nz-tabset [(nzSelectedIndex)]="selectedTabIndex" (nzSelectedIndexChange)="onTabIndexChange()">
      <nz-tab [nzTitle]="getTabTitle('urgent')"></nz-tab>
      <nz-tab [nzTitle]="getTabTitle('working')"></nz-tab>
      <nz-tab [nzTitle]="getTabTitle('manual')"></nz-tab>
    </nz-tabset>
  </div>

  <div class="list-body">
    <div class="list-item" *ngFor="let item of listData"> 
      <div one-route-tasks [data]="item" [currentTaskId]="currentTaskId" (doTask)="doTask($event)" (unassignMyself)="unassignMyself($event)"></div>
    </div>
  </div>
</div>