export function addCssClass(classForAdding: string, currentClass?: string): string {
  let str = currentClass ?? '';
  if (str.length > 0) {
    str += ' ';
  }
  str += classForAdding;
  return str;
}

export function getErrorString(err: any): string {
  if (!err) {
    return '';
  }
  if (typeof err === 'string') {
    return err;
  }
  if (err.constructor.name === 'Response') {
    err = err.json();
  }
  if (err.message) {
    return err.message;
  } else {
    try {
      return JSON.stringify(err);
    } catch (e) {
      return err;
    }
  }
}