import { BaseComponent } from "@abstract/BaseComponent";
import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { DateUtil } from "@services/date-util";
import dayjs from "dayjs";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { UIHelper } from '@services/UIHelper';

const CustomInputs = [
  NgIf,
  NzPopoverModule,
  NzIconModule,
  NzToolTipModule
]

@Component({
  selector: '[delivery-eta]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class DeliveryEta extends BaseComponent {
  interval: number = 30000
    lastLoaded: number = 0
    _id: string = null
    get id() {
        return this._id
    }
    @Input() set id(v) {
        this._id = v

        this.lastLoaded = 0
        this.interval = 30000
        this.loadEta()
        if (!this.timer)
            this.timer = setInterval(this.loadEta, 15000)
    }
    @Input() compact = false;
    @Input() overflow = false;
    @Input() tz = null;
    @Output() getETASuccess: EventEmitter<any> = new EventEmitter<any>();

    public _eta = null;
    get eta() {
        return this._eta
    }
    set eta(v) {
        if (
            v?.eta?.window?.from === this._eta?.eta?.window?.from &&
            v?.eta?.window?.to === this._eta?.eta?.window?.to
        ) {
            this.interval = Math.min(180000, this.interval + 30000)
        } else {
            this.interval = 30000
        }
        this._eta = v
        this.getETASuccess.emit(v?.eta)
        this.processData()
    }
    public reported = null
    public reportedTs = null
    public mileOut = null
    public timeToDestination = null
    public window = null
    public break = null
    public loading = false
    public expanded = false
    timer = null
    displayTz = null
    showEta = null

    constructor() {
      super()
      this.loadEta = this.loadEta.bind(this)
      this.reset()
  }

  ngOnDestroy(): void {
      this.timer && clearInterval(this.timer)
      this.timer = null
  }

  ngOnChanges(): void {
      this.displayTz = this.tz ? DateUtil.timezoneStandardToUsShort(this.tz) : null
      this.displayTz = this.displayTz || this.tz
  }

  onToggleExpanded() {
      this.expanded = !this.expanded
  }

  reset() {
      this.expanded = false
      this._eta = null
      this.reported = null
      this.mileOut = null
      this.timeToDestination = null
      this.break = null
      this.window = null
      this.reportedTs = null
      this.showEta = null
  }

  seconds2hours(seconds) {
      const s = seconds % 60
      const minutes = (seconds - s) / 60
      const m = minutes % 60
      const h = (minutes - m) / 60
      if (h > 0) return `${h}h ` + (m > 0 ? `${m}m` : '')
      return `${m} minutes`
  }

  processData() {
      if (!this._eta) return
      const { ts } = this._eta
      const { window, distance, time, idleTime } = this._eta.eta || {}
      const { from, to } = window || {}

      this.reported = dayjs(ts).fromNow();
      this.reportedTs = dayjs(ts).format(Const.FORMAT_GUI_DATETIME)
      if (distance) {
          this.mileOut = `${(distance / 1609.34).toFixed(1)} mi`
      }
      if (time) {
          this.timeToDestination = this.seconds2hours(time)
          const eta = ts + 1000 * (time || 0) + 1000 * (idleTime || 0)
          this.showEta = DateUtil.displayLocalTimeV2(eta, {
              format: 'MMM D, h:mm a',
              timezone: this.tz
          })
      }
      if (idleTime) {
          this.break = this.seconds2hours(idleTime)
      }
      if (from && to) {
          this.window = DateUtil.displayTimeWindow(window, {
              timezone: this.tz,
              format: 'MMM D, h:mm a',
              formatDateOnly: 'MMM D'
          })
      }
  }

  loadEta() {
    if (!environment.eventUrl) return
    if (this.lastLoaded > Date.now() - this.interval) return
    this.lastLoaded = Date.now()
    this.loading = true
    let url = `${environment.eventUrl}/delivery/${this.id}/ETA?limit=1`;
    this.api.GET(url).subscribe({
      next: (resp) => {
        this.loading = false
        if (resp && resp.length > 0) {
            this.eta = resp[0]
        }
        else {
          this.getETASuccess.emit(null);
        }
      },
      error: (err) => {
        this.loading = false
      }
    })
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      UIHelper.showSuccess('Copied to clipboard');
    }).catch(err => {
      UIHelper.showErr('Failed to copy');
      console.error('Could not copy text: ', err);
    });
  }
}