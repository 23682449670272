import { CompositeLayer } from '@deck.gl/core';
import { PathLayer } from '@deck.gl/layers';
import { getLngLat } from '@app/utils/map-v2.util';
import { JobSearchResultItem } from '@app/interfaces/map-v2/job-search-result';

export default class JobPathLayer extends CompositeLayer {
    override id: string
    static override layerName = "routing-route-path-layer"
    jobs: JobSearchResultItem[]
    routePath: any
    currentTime: number = 0
    options: any = {}
    _tripLayer: any = null

    renderLayers() {
        return this._tripLayer
    }

    constructor(id: string, jobs: JobSearchResultItem[], options: any = {}) {
        super({data: jobs})
        this.id = `job-path-${id}`

        this.jobs = jobs
        this.options = options

        this.prepareRoutePathData = this.prepareRoutePathData.bind(this)
        this.routePath = jobs.map(this.prepareRoutePathData).filter(it => it)
        this._tripLayer = this.createRoutePathLayer()
    }

    prepareRoutePathData(job: JobSearchResultItem) {
        if (!job.tasks?.length) return null
        // no path at all
        const latlngs = job.tasks.map(it => it.location)
        const coordinates = latlngs.filter(it => it).map(it => getLngLat(it!!))
        return {
            type: 'ROUTE',
            coordinates,
            job: job,
            color: this.options?.color ?? job.metadata['color'] ?? [38, 70, 83],
        }
    }

    createRoutePathLayer() {
        return new PathLayer({
            id: `job-path-${this.id}`,
            data: this.routePath,
            getPath: (d: any) => d.coordinates,
            getColor: (d: any) => d.color,
            widthMinPixels: this.options.minWidth || 3,
            jointRounded: true,
            capRounded: true,
            pickable: this.options?.pickable ?? true,
            onHover: this.options?.onHover
        });
    }
}