<div>
  <div nz-row [nzGutter]="{ sm: 16 }" class="document-row">
    <div nz-col nzSm="9">
      <span><img src="/assets/img/description.svg" /></span>
      <span class="document-label">BOL For Carrier</span>
    </div>
    <div *ngIf="!isLoading" nz-col nzSm="15" class="group-document-action">
      <div class="document-action flex clickable" (click)="onClick('view BOL', $event)">
        <div><img src="/assets/img/visibility.svg" /></div>
        <div class="label">View</div>
      </div>
      <div class="document-action flex clickable" (click)="onClick('download BOL', $event)">
        <div><img src="/assets/img/download.svg" /></div>
        <div class="label">Download</div>
      </div>
      <div class="document-action flex clickable" (click)="onClick('email BOL', $event)">
        <div><img src="/assets/img/mail.svg" /></div>
        <div class="label">Email</div>
      </div>
      <div class="document-action flex clickable" (click)="onClick('re-generate BOL', $event)"
        nz-tooltip="Use this button to refresh the latest data when the file information is incorrect.">
        <div><span nz-icon nzType="sync" nzTheme="outline" style='color:#1E40AF'></span>
        </div>
        <div class="label">Re-generate</div>
      </div>
    </div>
    <div>
      <ng-container *ngIf="bolCreated" [ngTemplateOutlet]="tplCreated" [ngTemplateOutletContext]="{data: bolCreated}"></ng-container>
      <ng-container *ngIf="bolLastSent" [ngTemplateOutlet]="tplLastSent" [ngTemplateOutletContext]="{data: bolLastSent}"></ng-container>
    </div>
  </div>
  <div class="document-row">
    <div nz-row [nzGutter]="{ sm: 16 }">
      <div nz-col nzSm="15">
        <span><img src="/assets/img/description.svg" /></span>
        <span class="document-label">BOL For Customer</span>
      </div>
      <div nz-col nzSm="9" class="group-document-action">
        <div class="document-action flex clickable" (click)="onClick('email BOL Client', $event)">
          <div><img src="/assets/img/mail.svg" /></div>
          <div class="label">Email</div>
        </div>
      </div>
    </div>
    <div style="padding: 10px 0px 10px 30px ;"></div>
  </div>
  <div *ngIf="isAssignedCarrier" nz-row [nzGutter]="{ sm: 16 }" class="document-row">
    <div nz-col nzSm="9">
      <span><img src="/assets/img/description.svg" /></span>
      <span class="document-label">LoadTender</span>
    </div>
    <div *ngIf="!isLoading" nz-col nzSm="15">
      <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
      <nz-spin [nzSpinning]="!loadTenderInfo" [nzIndicator]="indicatorTemplate">
        <div class="group-document-action">
          <div class="document-action flex clickable" (click)="onClick('view Load Tender', $event)">
            <div><img src="/assets/img/visibility.svg" /></div>
            <div class="label">View</div>
          </div>
          <div class="document-action flex clickable" (click)="onClick('download Load Tender', $event)">
            <div><img src="/assets/img/download.svg" /></div>
            <div class="label">Download</div>
          </div>
          <div class="document-action flex clickable" (click)="onClick('email load tender', $event)">
            <div><img src="/assets/img/mail.svg" /></div>
            <div class="label">Email</div>
          </div>
          <div class="document-action flex clickable" (click)="onClick('re-generate Load Tender', $event)"
            nz-tooltip="Use this button to refresh the latest data when the file information is incorrect.">
            <div><span nz-icon nzType="sync" nzTheme="outline" style='color:#1E40AF'></span>
            </div>
            <div class="label">Re-generate</div>
          </div>
        </div>
      </nz-spin>
    </div>
    <div>
      <ng-container *ngIf="loadtenderCreated" [ngTemplateOutlet]="tplCreated" [ngTemplateOutletContext]="{data: loadtenderCreated}"></ng-container>
      <ng-container *ngIf="loadTenderLastSent" [ngTemplateOutlet]="tplLastSent" [ngTemplateOutletContext]="{data: loadTenderLastSent}"></ng-container>
    </div>
  </div>
  <div class="document-row">
    <div nz-row [nzGutter]="{ sm: 16 }">
      <div nz-col nzSm="15">
        <span><img src="/assets/img/description.svg" /></span>
        <span class="document-label">Customer Document</span>
      </div>
      <div nz-col nzSm="9" class="group-document-action">
        <!-- <div class="document-action flex clickable" (click)="onClick('view Document Customer', $event)">
          <div><img src="/assets/img/visibility.svg" /></div>
          <div class="label">View</div>
        </div> -->
        <div class="document-action flex clickable" (click)="onClick('upload Document Customer', $event)">
          <div><img src="/assets/img/upload_file.svg" /></div>
          <div class="label">Upload</div>
        </div>
      </div>
    </div>
    <div style="padding: 10px 0px 10px 30px ;">
        <app-document-customer #customerDocument [jobId]="jobId"></app-document-customer>
      </div>
  </div>
  <!-- <div nz-row [nzGutter]="{ sm: 16 }" class="document-row" *ngIf="saksReportEnabled">
    <div nz-col nzSm="9">
      <span><img src="/assets/img/dispatch-icons/description.svg" /></span>
      <span class="document-label">Saks Manifest</span>
    </div>
    <div *ngIf="!isLoading" nz-col nzSm="15" class="group-document-action">
      <div class="document-action flex clickable" (click)="downloadSaksManifest($event)">
        <div><img src="/assets/img/dispatch-icons/download.svg" /></div>
        <div class="label">Download</div>
      </div>
    </div>
    <ng-container *ngIf="bolLastSent" [ngTemplateOutlet]="tplLastSent" [ngTemplateOutletContext]="{data: bolLastSent}"></ng-container>
  </div>

  <div nz-row [nzGutter]="{ sm: 16 }" class="document-row" *ngIf="ACIBOLEnabled">
    <div nz-col nzSm="9">
      <span><img src="/assets/img/dispatch-icons/description.svg" /></span>
      <span class="document-label">ACI BOL</span>
    </div>
    <div *ngIf="!isLoading" nz-col nzSm="15" class="group-document-action">
      <div class="document-action flex clickable" (click)="downloadACIBOL($event)">
        <div><img src="/assets/img/dispatch-icons/download.svg" /></div>
        <div class="label">Download</div>
      </div>
    </div>
    <ng-container *ngIf="bolLastSent" [ngTemplateOutlet]="tplLastSent" [ngTemplateOutletContext]="{data: bolLastSent}"></ng-container>
  </div> -->
</div>

<ng-template #tplLastSent let-data="data">
  <div class="last-sent">
    Last sent: {{data.time}}<span *ngIf="data.sender?.name">, by {{data.sender.name}}</span>
  </div>
</ng-template>

<ng-template #tplCreated let-data="data">
  <div class="last-sent">
    Generated: {{data}}
  </div>
</ng-template>