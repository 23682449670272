import { Component } from "@angular/core";
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { FormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { CommonModule } from "@angular/common";
import { DateUtil } from "@services/date-util";
import { Utils } from "@services/utils";
import { AttachedFileUtil } from "@services/attached-file-util";
import { DialogService } from "@app/dialogs/dialog.service";
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { ResponseWorkQueueRouteNoteUI } from "./interfaces";
import { RouteNoteService } from "../note.service";
import { UIHelper } from '@services/UIHelper';
import { BaseComponent } from '@abstract/BaseComponent';
import { Const } from '@const/Const';

const CustomInputs = [
  CommonModule,
  NzIconModule,
  NzPopoverModule,
  FormsModule,
  NzInputModule,
  NzButtonModule,
  NzGridModule,
  NzEmptyModule,
  NzSpaceModule,
  NzToolTipModule,
  NzTimelineModule,
  NzAvatarModule
]

@Component({
  selector: '[note-list]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class NoteListComponent extends BaseComponent {
  private timerReloadData;
  public uploadImages: any[] = [];
  public noteItems: ResponseWorkQueueRouteNoteUI[] = [];
  public pinnedItems: ResponseWorkQueueRouteNoteUI[] = [];
  public editingId: number = -1;
  public newNote: string = '';
  isLoading = false;

  constructor( private routeNoteService: RouteNoteService ) {
    super();
  }

  ngOnInit(): void {
    this.routeNoteService.getNotes().subscribe(notes => {
      this.loadData(notes);
    });
  }

  ngOnChanges(): void {
    if (this.timerReloadData) {
      clearInterval(this.timerReloadData);
    }
    this.refreshData();
    this.timerReloadData = setInterval(() => {
      this.refreshData();
    }, 10000);
  }

  ngOnDestroy(): void {
    if (this.timerReloadData) {
      clearInterval(this.timerReloadData);
      this.timerReloadData = null;
    }
  }

  async refreshData() {
    this.routeNoteService.refresh();
  }

  get isEmptyNotes() {
    return this.noteItems.length === 0;
  }

  async loadData(listData: ResponseWorkQueueRouteNoteUI[]) {
    const {items, pinnedItems} = this.buildDisplayNotes(listData);
    this.noteItems = items;
    this.pinnedItems = pinnedItems;
  }

  buildDisplayNotes(data: ResponseWorkQueueRouteNoteUI[]) {
    let items: ResponseWorkQueueRouteNoteUI[] = [];
    let pinnedItems: ResponseWorkQueueRouteNoteUI[] = [];
    for (let note of data) {
      if (Utils.isArrayNotEmpty(note?.attachedFiles)) {
        for (let item of note.attachedFiles) {
          item.url = AttachedFileUtil.attachedFileUrl(item);
        }
      }
      let date = note.insert?.when ?? '';
      if (note.pin?.when && note.pin?.by) {
        pinnedItems.push(note);
        note.time = DateUtil.dateToString(date, "MM/DD/YY hh:mm A");
        continue;
        //nếu note được pined thì đẩy lên đầu và không hiển thị lại ở phía dưới.
      }
      note.time = DateUtil.dateToString(date, "MM/DD/YY hh:mm A");
      items.push(note);
    }
    return {
      items,
      pinnedItems
    };
  }

  viewImageItem(imgUrl) {
    if (!imgUrl) return;
    DialogService.previewImg(imgUrl);
  }

  $formatDate(date: Date) {
    return DateUtil.dateToString(date, "hh:mm A MMM D");
  }

  getAuthorName(note: ResponseWorkQueueRouteNoteUI, defaultValue = ''): string {
    const name = note?.insert?.by?.name;
    if (!name) return defaultValue;
    let suffix = '';
    switch (note.insert.by.entity) {
      case 'carriers':
        suffix = ' (Carrier)';
        break;
      case 'drivers':
        suffix = ' (Driver)';
        break;
      case 'clients':
        suffix = ' (Customer)';
        break;
    }
    return name + suffix;
  }

  getAvatarCharacter(note: ResponseWorkQueueRouteNoteUI): string {
    return this.getAuthorName(note)?.slice(0,1) || 'N';
  }

  isExfreight(note: ResponseWorkQueueRouteNoteUI) {
    return note.insert?.by?.entity == 'carriers' && note.insert?.by?.id == Const.CarrierId.exfreight;
  }

  shouldShowUpdate(note: ResponseWorkQueueRouteNoteUI) {
    if (!note?.update?.when) return false;
    // Nếu note đã bị thằng khác edit thì show lên thằng nào edit.
    // Hiện nay đã ko có edit note của người khác nữa, tuy nhiên vẫn cần hiển thị cho data cũ.
    return note.insert.by.entity != note.update.by.entity || note.insert.by.id != note.update.by.id;
  }

  onBtnPin(item: ResponseWorkQueueRouteNoteUI) {
    this.isLoading = true;
    this.routeNoteService.pinNote(item.id, true).subscribe(
      () => {
        this.isLoading = false;
        UIHelper.showSuccess("Your Note has been pinned successfully.");
      },
      (err) => {
        this.isLoading = false;
        UIHelper.showErr(err?.message || "Failed to pin note");
      }
    );
  }

  onBtnUnPin(item: ResponseWorkQueueRouteNoteUI) {
    this.isLoading = true;
    this.routeNoteService.pinNote(item.id, false).subscribe(
      () => {
        this.isLoading = false;
        UIHelper.showSuccess("Your Note has been un-pinned successfully.");
      },
      (err) => {
        this.isLoading = false;
        UIHelper.showErr(err?.message || "Failed to unpin note");
      }
    );
  }

  isPinned(item: ResponseWorkQueueRouteNoteUI): boolean {
    return !!item.pin?.when && !!item.pin?.by;
  }

  canEditNote(item: ResponseWorkQueueRouteNoteUI): boolean {
    return this.authUser.id === item.insert.by.id
  }

  onEdit = (item) => {
    this.newNote = item.content || '';
    this.editingId = item.id;
  };

  onCancelEdit = () => {
    this.editingId = -1;
    this.newNote = "";
  };

  onSubmitEdit = () => {
    if (!this.newNote.trim()) return;
    this.isLoading = true;
    this.routeNoteService.updateNote(this.editingId, this.newNote).subscribe({
      next: (resp) => {
        this.isLoading = false;
        UIHelper.showSuccess("Note has been updated successfully.");
        this.onCancelEdit();
        this.refreshData();
      },
      error: (err) => {
        this.isLoading = false;
        UIHelper.showErr(err?.message || "Failed to update note");
      }
    });
  };
}