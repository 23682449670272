import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { environment } from '@env/environment';
import { BaseFormDialog } from '@app/dialogs/base-form-dialog';

@Component({
  selector: 'app-create-new-driver',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzCheckboxModule
  ],
  templateUrl: './create-new-driver.component.html',
  styleUrls: ['./create-new-driver.component.scss']
})
export class CreateNewDriverComponent extends BaseFormDialog implements OnInit {
  driverForm: FormGroup;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    @Inject(NZ_MODAL_DATA) public data: { carrierId: string }
  ) {
    super(data);
    this.driverForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]], 
      phone: ['', [Validators.required]],
      optInSMS: [true]
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async onSubmit() {
    if (this.driverForm.valid) {
      this.isLoading = true;
      try {
        const formValue = this.driverForm.value;
        const payload = {
          firstName: formValue.firstName,
          lastName: formValue.lastName,
          phone: formValue.phone,
          optInSMS: formValue.optInSMS,
          carrierId: this.data.carrierId
        };

        const url = `${environment.backendWorkQueueUrl}/v1/carriers/${this.data.carrierId}/drivers`;
        // Implement API call here
      } catch (error) {
        console.error('Error creating driver:', error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  closeModal() {
    this.closeDialog();
  }

}
