import { Component, Inject} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BaseFormDialog } from "@app/dialogs/base-form-dialog";
import { Const } from "@const/Const";
import { UIHelper } from "@services/UIHelper";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from 'ng-zorro-antd/input';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

const CustomInputs = [
  FormsModule, 
  ReactiveFormsModule,
  NzFormModule,
  NzButtonModule,
  NzInputModule
]

@Component({
  selector: '[resend-confirm-booking-dialog]',
  standalone: true,
  imports: CustomInputs,
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class ResendConfirmBookingDialog extends BaseFormDialog {
  jobId: string;
  contactName: string;
  emailContact: string;
  emailSubject: string;
  confirmLink: string;

  onSuccess: () => void;

  public isDisabledSenderEmail = true;
  public customSenderEmail = '';

  constructor(@Inject(NZ_MODAL_DATA) data: any) {
    super(data);
    this.jobId = data.jobId;
    this.contactName = data.contactName;
    this.emailContact = data.emailContact;
    this.emailSubject = data.emailSubject;
    this.confirmLink = data.confirmLink;
  }
  ngOnInit() {
    super.ngOnInit();
    this.customSenderEmail = this.emailContact;
  }

  get emailContent() {
    return `
      Hello ${this.contactName}!<br>
      You have a WARP route starting soon that requires your attention.<br>
      To confirm, please click the link below:<br>
      <a href="${this.confirmLink}" target="_blank">${this.confirmLink}</a><br>
      Please Note:<br>
      - Failure to confirm may result in loss of the load.<br>
      - Tracking is required for this load via the WARP Driver App<br>
      If you have any questions or concerns please contact our WARP support team at dispatch@wearewarp.com, call (213) 267-1373 or reply this email.<br>
      Sincerely,<br>
      WARP Dispatcher team    
    `
  }

  public isSendMailLoading = false;
  onBtnSendEmail() {
    if (!this.jobId) {
      UIHelper.showErr("jobId is missing");
      return;
    }
    this.isSendMailLoading = true;
    let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.jobId}/send-email-checklist-task`);
    this.api.POST(url, {}).subscribe({
      next: (resp) => {
        this.isSendMailLoading = false;
        UIHelper.showSuccess('Email has been sent successfully!');
        this.updateSuccess(resp);
        this.closeDialog();
      },
      error: (e) => {
        this.isSendMailLoading = false;
        UIHelper.showErr(e);
      }
    });
  }

  onEditSenderEmail() {
    this.isDisabledSenderEmail = !this.isDisabledSenderEmail;
  }
}