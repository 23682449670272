import { Component, Input } from '@angular/core';
import { Task } from '@wearewarp/types/data-model';
import { StatusComponent } from "../status/index";
import { JobTaskCodeComponent } from '../task-code';
import { WarpCode } from "../warp-code/index";
import { DatePipe } from '@angular/common';
import { StringUtils } from '../status/utils';

@Component({
    selector: 'task-status-update',
    imports: [StatusComponent, JobTaskCodeComponent, WarpCode, DatePipe],
    templateUrl: './task-status.component.html',
    styleUrl: './task-status.component.scss',
    standalone: true
})
export class TaskStatusComponent {
    _task: any
    status: string = ''
    type: string = ''
    timezone?: string
    updatedWhen?: string | null = null

    @Input() set task(v: Task) {
        this._task = v

        this.status = v.status ?? ''
        this.type = StringUtils.normalize(v.type?.toString()?.toLowerCase() ?? '')
        this.updatedWhen = v.statusChangeLog?.[v.status]?.changeWhen ?? v.statusChangeLog?.[v.status]?.when
        this.timezone = v.info?.addr?.metadata?.timeZoneStandard
    }
}
