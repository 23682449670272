import { Component, inject, Input } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { TaskType } from "@wearewarp/types";
import { BaseFormDialog } from '@app/dialogs/base-form-dialog';
import { DateUtil } from "@services/date-util";
import { MasterData } from "@services/master.data";
import { FormDataActualTime } from "@wearewarp/types-server-admin/form-data/dispatch";
import { TimeWindow } from "@wearewarp/types/data-model/types/TimeWindow";
import { Observable } from "rxjs";
import { DelayCode } from "@wearewarp/types/data-model";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzInputModule } from "ng-zorro-antd/input";
import { UIHelper } from '@services/UIHelper';
import { NzFormModule } from "ng-zorro-antd/form";
import { NzSelectModule } from "ng-zorro-antd/select";
import { FormFooter } from '@app/form-footer/form-footer';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: '[update-arrived-time]',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NzDatePickerModule,
    NzInputModule,
    NzFormModule,
    NzSelectModule,
    FormFooter
  ],
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class UpdateArrivedTime extends BaseFormDialog {

  nzModalData = inject(NZ_MODAL_DATA);

  type: TaskType = this.nzModalData.type;
  timezone: string = this.nzModalData.timezone;
  appointment: TimeWindow = this.nzModalData.appointment;
  onSave: (data: FormDataActualTime) => Observable<any> = this.nzModalData.onSave;
  onRefreshDetailJob: () => void = this.nzModalData.onRefreshDetailJob;
  isConfirm: boolean = this.nzModalData.isConfirm;
  public shortTimezone;
  
  public static get declaration(): FormGroupDeclaration {return {
    arrived: {label: 'Arrived Time', type: 'dateTime', skipDiffSecond: true, required: true},
    departed: {label: 'Departed Time', type: 'dateTime', skipDiffSecond: true, required: true},
    delayCodeId: {label: 'Failure Code for delay'},
    note: {label: 'Note for delay'},
    confirmed: {label: ''}
  }}
  protected formGroupDeclaration: FormGroupDeclaration = UpdateArrivedTime.declaration;

  listDelayCodes: DelayCode[] = [];
  isShowDelayCode = false;

  // protected beforeBindModel(model: any) {
  //   if(model?.arrived === 'N/A') {
  //     model.arrived = null;
  //     this.formGroupDeclaration.arrived.readOnly = true;
  //   }
  //   if(model?.departed === 'N/A') {
  //     model.departed = null;
  //     this.formGroupDeclaration.departed.readOnly = true;
  //   }
  //   this.listDelayCodes = MasterData.getDelayCodesByType(this.type);
  //   console.log(`xxx listDelayCodes: `, this.listDelayCodes);
  //   this.onCheckShowDelayCode(model);
  
  //   return model;
  // }

  private updateFormValues(model): void {
    if(model?.arrived === 'N/A') {
      model.arrived = null;
      this.formGroupDeclaration.arrived.readOnly = true;
    }
    if(model?.departed === 'N/A') {
      model.departed = null;
      this.formGroupDeclaration.departed.readOnly = true;
    }
    this.listDelayCodes = MasterData.getDelayCodesByType(this.type);
    this.onCheckShowDelayCode(model);
    this.bindDataModel(model);
    this.setEnableFormGroup(true);
    
    if (this.formInput) {
      if (model?.arrived === null) {
        this.formInput.get('arrived')?.disable();
      }
      if (model?.departed === null) {
        this.formInput.get('departed')?.disable();
      }
    }
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  ngOnInit(): void {
    if (!this.timezone) {
      throw Error('timezone is required');
    }
    this.shortTimezone = DateUtil.timezoneStandardToUsShort(this.timezone);
    super.ngOnInit();
    this.updateFormValues(this.nzModalData.model);
    this.oncheckRequiredDelayCode();
  }

  onBtnSave() {
    if (!this.needUpdate) {
      return;
    }

    let data: any = this.getFormData_JSON(true);
    if (!this.isShowDelayCode) {
      data.delayCodeId = null;
      data.note = null;
    }
    this.onProgress = true;
    this.onSave(data).subscribe({
      next: (resp) => {
        UIHelper.showSuccess('Update scheduled time successfully');
        this.onProgress = false;
        this.onRefreshDetailJob();
        this.closeDialog();
      },
      error: (err) => {
        UIHelper.showErr(err);
        this.onProgress = false;
        this.closeDialog();
      }
    });
  }

  onBtnCancel() {
    this.closeDialog();
  }

  onCheckShowDelayCode(params) {
    const fromTime = this.appointment?.from ? new Date(DateUtil.displayLocalTime(this.appointment.from, this.timezone, "MM/DD/YY h:mm A")).getTime() : null;
    const toTime = this.appointment?.to ? new Date(DateUtil.displayLocalTime(this.appointment.to, this.timezone, "MM/DD/YY h:mm A",)).getTime() : null;
    // không có thời gian arrived thì lấy thời gian departed
    let arrivedTime = null;
    if (params?.arrived) {
      const date = new Date(params?.arrived);
      date.setMilliseconds(0);
      date.setSeconds(0);
      date.getTime();
      arrivedTime = date.getTime();
    } else if (params?.departed) {
      const date = new Date(params?.departed);
      date.setMilliseconds(0);
      date.setSeconds(0);
      date.getTime();
      arrivedTime = date.getTime();
    }
    // Kiểm tra điều kiện delay
    const hasAppointmentTime = fromTime || toTime;
    const isLate = hasAppointmentTime && arrivedTime && 
    ((fromTime && arrivedTime > fromTime) || (toTime && arrivedTime > toTime));
    // Cập nhật trạng thái
    const shouldShowDelayCode = isLate;
    if (shouldShowDelayCode !== this.isShowDelayCode) {
      this.isShowDelayCode = shouldShowDelayCode;
      this.oncheckRequiredDelayCode();
    }
  }

  oncheckRequiredDelayCode() {
    let fc = <FormControl> this.formInput?.get('delayCodeId');
    if (fc) {
      if (this.isShowDelayCode) {
        fc.addValidators(Validators.required);
      } else {
        fc.removeValidators(Validators.required);
      }
      fc.updateValueAndValidity();
    }
  }

  onChange(value, key) {
    setTimeout(() => {
      switch(key) {
        case 'arrived':
        case 'departed':
          let data: any = this.getFormData_JSON(true);
          this.onCheckShowDelayCode(data);
        return 
      }
    }, 50);
  }
  
}
