import { BaseComponent } from '@abstract/BaseComponent';
import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { TrackTraceService } from '@app/track-trace/services/track-trace.service';
import { Const } from '@const/Const';
import { DateUtil } from '@services/date-util';
import { Job, WhenBy } from '@wearewarp/types/data-model';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { DocumentCustomerComponent } from './document-customer';
import { DialogService } from '@app/dialogs/dialog.service';
import { EmailLoadTenderComponent } from './email-load-tender';
import { EmailBolComponent } from './email-bol';
import { DownloadingComponent } from './downloading';
import { Utils } from '@services/utils';
import { ModalHelper } from '@wearewarp/ng-antd-2';
import { AttachedFileUtil } from '@services/attached-file-util';
import { EmailBolClientComponent } from './email-bol-client';
import { DrawerService } from '@app/drawers/drawer.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { UploadDocumentCustomer } from './document-customer/upload-document';
@Component({
  selector: 'app-document-tab',
  standalone: true,
  imports: [CommonModule, NzIconModule, NzToolTipModule, NzGridModule, NzSpinModule,DocumentCustomerComponent],
  providers: [ModalHelper, NzDrawerService],
  templateUrl: './document.html',
  styleUrl: './document.scss'
})
export class DocumentTabComponent extends BaseComponent {
  public jobId: string | null = null;
  public loadInfo: Job | null = null;
  public bolFileId: string | null = null;
  public bolInfo
  public loadTenderFileId: string | null = null;
  public loadTenderInfo
  public isAssignedCarrier = false;
  public jobCode: string | null = null;

  isLoading = false;

  @ViewChild('customerDocument') customerDocument: DocumentCustomerComponent;

  constructor(private trackTraceService: TrackTraceService, private modalHelper: ModalHelper) {
    super();
    this.trackTraceService.getLoadInfo().subscribe(loadInfo => {
      this.loadInfo = loadInfo;
      this.isAssignedCarrier = this.getIsAssignedCarrier();

      this.getJobId();
      this.getBolFileId();
      this.getLoadTenderFileId();
      this.getDocumentData();
      this.getJobCode();
    });
  }

  getIsAssignedCarrier() {
    return this.loadInfo?.assignedCarrier ? true : false
  }

  getJobId() {
    if (this.loadInfo?.id) {
      this.jobId = this.loadInfo.id;
    }
  }

  getBolFileId() {
    if (this.loadInfo?.bolFileId) {
      this.bolFileId = this.loadInfo.bolFileId;
    }
  }

  getLoadTenderFileId() {
    if (this.loadInfo?.assignedCarrier?.loadTenderFileId) {
      this.loadTenderFileId = this.loadInfo.assignedCarrier.loadTenderFileId;
    }
  }

  getJobCode() {
    if (this.loadInfo?.code) {
      this.jobCode = this.loadInfo.code;
      console.log("jobCode", this.jobCode);
    }
  }

  getDocumentData() { 
    let urls = [];
    if (this.bolFileId) urls.push(`${Const.APIV2(`jobs/${this.jobId}/document`)}?type=bol`);
    if (this.loadTenderFileId) urls.push(`${Const.APIV2(`jobs/${this.jobId}/document`)}?type=loadTender`);
    if (!urls.length) {
      this.loadTenderInfo = {};
      this.bolInfo = {};
      return
    };
    this.isLoading = true;
    this.api.concurrentGET(urls).subscribe(
      (resp) => {
        if (resp.length > 1) {
          this.bolInfo = resp[0].data;
          this.loadTenderInfo = resp[1].data;
        } else {
          if (this.bolFileId) {
            this.bolInfo = resp[0].data;
          } else if (this.loadTenderFileId) {
            this.loadTenderInfo = resp[0].data
          }
        }
        this.bolLastSent = this.extractLastSent(this.bolInfo);
        this.loadTenderLastSent = this.extractLastSent(this.loadTenderInfo);
        this.bolCreated = this.extractCreatedInfo(this.bolInfo?.insert);
        this.loadtenderCreated = this.extractCreatedInfo(this.loadTenderInfo?.insert);
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  bolCreated = '';
  loadtenderCreated = '';
  bolLastSent;
  loadTenderLastSent;

  private extractLastSent(data) {
    let lastSent = data?.lastSent;
    if (!lastSent) {
      return null;
    }
    return {
      time: DateUtil.dateToString(lastSent.insert.when, "MMM D, YYYY hh:mm:ss A"),
      sender: {
        email: lastSent.insert?.byUser?.email,
        name: Utils.getFullName(lastSent.insert?.byUser),
      },
      receiver: {
        email: lastSent.email
      }
    }
  }

  private extractCreatedInfo(insert: WhenBy): string {
    if (!insert?.when) return '';
    return DateUtil.format(insert.when, 'MMM D, YYYY hh:mm:ss A');
  }

  onClick(action, event) {
    let forceUpdate = false;
    if (event.ctrlKey || event.metaKey) {
      forceUpdate = true;
    };

    switch (action) {
      case "view BOL":
        if (this.bolInfo) {
          DialogService.previewImg([this.bolInfo], 0);
        } 
        else {
          this.downloadBOL({ type: 'bol', forceUpdate })
        }
        break;
      case "view Load Tender":
        if (this.loadTenderInfo) {
          DialogService.previewImg([this.loadTenderInfo], 0);
        }
        break;
      case "download BOL":
        if (this.bolInfo) {
          this.downloadAttachedFileWithLoading({ ...this.bolInfo, type: 'bol', forceUpdate })
        } else {
          this.downloadBOL({ type: 'bol', forceUpdate })
        }
        break;
      case "download Load Tender":
        if (this.loadTenderInfo) {
          this.downloadAttachedFileWithLoading({ ...this.loadTenderInfo, type: 'loadTender', forceUpdate })
        }
        break;
      case "email BOL":
        DialogService.openFormDialog(EmailBolComponent, {
          nzData: {
            loadInfo: this.loadInfo,
            jobId: this.jobId,
            bolFileId: this.bolFileId,
            carrier: this.loadInfo?.carrier,
            // clients: this.loadInfo?.clients,
            stops: this.loadInfo?.stops,
            closeOnSuccess: true,
            updateSuccess: (resp) => {
            },
          },
          nzClassName: "email-bol-form"
        })
        break;
      case "email load tender":
          DialogService.openFormDialog(EmailLoadTenderComponent, {
            nzData: {
              jobId: this.jobId,
              carrier: this.loadInfo?.carrier,
              loadTenderFileId: this.loadTenderFileId
            },
            nzClassName: "email-load-tender-form"
          })
        break;
      case "re-generate BOL":
        if (this.bolInfo) {
          this.modalHelper.confirmYesNo(
            'Do you really want to re-generate BOL?', 
            () => this.downloadAttachedFileWithLoading({ ...this.bolInfo, type: 'bol', forceUpdate: true })
          );
        } else {
          this.modalHelper.confirmYesNo(
            'Do you really want to re-generate BOL?', 
            () => this.downloadBOL({ type: 'bol', forceUpdate: true })
          );
        }
        break;
      case "re-generate Load Tender":
          this.modalHelper.confirmYesNo(
            'Do you really want to re-generate Load Tender?', 
            () => this.downloadAttachedFileWithLoading({ ...this.loadTenderInfo, type: 'load_tender', forceUpdate: true })
          );
        break;
      case "email BOL Client":
        DialogService.openFormDialog(EmailBolClientComponent, {
          nzData: {
            jobId: this.jobId,
            clients: this.loadInfo?.clients,
            closeOnSuccess: true,
            updateSuccess: (resp) => {
            },
        },
        nzClassName: "email-load-tender-form",
      });
      break;
      case "upload Document Customer":
        this.openDrawerUploadDocumentCustomer();
        break; 
      case "note":
        break;
      default:
    }
  }

  private async downloadAttachedFileWithLoading(event) {
    console.log("downloadAttachedFileWithLoading", event);
    if (!this.jobId) {
      console.log('jobId is required.');
      return;
    }
    
    let dialogRef = null;
    
    try {
      dialogRef = DialogService.openFormDialog(DownloadingComponent, {
        nzClosable: false,
        nzMaskClosable: false,
        nzKeyboard: false
      });

      if (event.forceUpdate && event.type) {
        let url = `${Const.APIURI_JOBS}/${this.jobId}/${event.type}`;
        let response = await this.api.POST(url).toPromise();
        if (!response) {
          dialogRef?.destroy();
          return;
        }
        let data = response.data;
        event = { ...event, ...data };
      }

      if (event.type === "bol") {
        event.name = `BOL_route_${this.jobCode}_${Date.now()}.pdf`;
      } else if (event.type === "load_tender") {
        event.name = `LoadTender_${this.jobCode}_${Date.now()}.pdf`;
        this.loadTenderFileId = event.id;
        this.loadTenderInfo = event;
      }

      try {
        const result = await AttachedFileUtil.downloadAttachedFile(event);
        setTimeout(() => dialogRef?.destroy(), 1500);
        return result;
      } catch (downloadError) {
        dialogRef?.destroy();
        throw downloadError;
      }

    } catch (error) {
      console.error('Download error:', error);
      dialogRef?.destroy();
      throw error;
    }
  }

  private async downloadBOL(event) {
    if (!this.jobId) {
      console.log('jobId is required.');
      return;
    }

    if (event.forceUpdate && event.type) {
      return await this.downloadAttachedFileWithLoading(event);
    }

    let dialogRef = null;

    try {
      dialogRef = DialogService.openFormDialog(DownloadingComponent, {
        nzClosable: false,
        nzMaskClosable: false,
        nzKeyboard: false
      });
      
      let url = `${Const.APIURI_JOBS}/${this.jobId}/download-bol`;
      
      try {
        await new Promise((resolve, reject) => {
          this.api.download(url).subscribe(
            (resp) => {
              try {
                let blob = new Blob([resp], { type: "application/pdf" });
                let url = URL.createObjectURL(blob);
                let fileName = `BOL_route_${this.jobId}_${Date.now()}.pdf`;
                Utils.downloadFile(url, fileName);
                setTimeout(() => dialogRef?.destroy(), 500);
                resolve(true);
              } catch (error) {
                dialogRef?.destroy();
                reject(error);
              }
            },
            (err) => {
              console.log(err);
              dialogRef?.destroy();
              reject(err);
            }
          );
        });
      } catch (downloadError) {
        dialogRef?.destroy();
        throw downloadError;
      }
      
    } catch (error) {
      console.error('Download error:', error);
      dialogRef?.destroy();
    }
  }

  private openDrawerUploadDocumentCustomer() {
    if (!this.jobId) return;
    DrawerService.openFormDrawer1(UploadDocumentCustomer, {
      nzContentParams: {
        clients: this.loadInfo?.clients,
        jobOrders: this.loadInfo?.shipments,
        createSuccess: data => {
          this.customerDocument?.fetchData();
        },
        closeOnSuccess: true
      },
      nzWidth: 400,
      nzWrapClassName: 'wrap-drawer',
    });
  }
  
}

