import { Component, OnInit } from "@angular/core";
import { LoadSuggestionsComponent } from "./load-suggestions/load-suggestions.component";
import { TaskHandlerService } from "@app/track-trace/services/task-handler.service";
import { TrackTraceService } from "@app/track-trace/services/track-trace.service";
import { WorkTaskUtil } from "@app/utils/worktask";
import { WorkRequestedTaskComponent } from "./requested-task";
import { NgIf } from "@angular/common";

const CustomInputs = [
  NgIf,
  LoadSuggestionsComponent,
  WorkRequestedTaskComponent,
]

@Component({
  selector: 'app-load-track-trace-task',
  imports: CustomInputs,
  standalone: true,
  templateUrl: './index.html',
  providers: [TaskHandlerService],
  styleUrls: []
})
export class TrackTraceTaskComponent implements OnInit {  
  constructor(
    private trackTraceService: TrackTraceService,
  ) {}

  isRequestedTask: boolean = false;
  isTrackTraceTask: boolean = false;

  ngOnInit(): void {
    this.trackTraceService.getWorkTask().subscribe(task => {
      this.isRequestedTask = WorkTaskUtil.isRequestedTask(task);
      this.isTrackTraceTask = WorkTaskUtil.isTrackTraceTask(task);
    })
  }
}