<div class="unassign-carrier-container">
  <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

  <form nz-form [formGroup]="formInput" nzLayout="vertical" *ngIf="!isLoading">
    <div class="title bottom20">
      Select reason to unassign
    </div>
    <div>
      <nz-form-item>
        <nz-form-control>
          <nz-radio-group formControlName="code" (ngModelChange)="onChangeReason($event)">
            <label nz-radio *ngFor="let item of reasons" [nzValue]="item.value" class="flex pb-10">
              {{item.label}}<br />
              <span style="font-style: italic;" *ngIf="isShowChild(item) && item?.description">{{ item?.description }}</span>
              <div class="sub-options" style="padding-top: 12px;" *ngIf="isShowChild(item) && item?.options?.length">
                <nz-radio-group formControlName="subCode">
                  <label nz-radio *ngFor="let option of item.options" [nzValue]="option.value" class="flex pb-10">
                    {{option.label}}<br />
                  </label>
                </nz-radio-group>
              </div>
            </label>
            <nz-divider></nz-divider>
            <label nz-checkbox formControlName="notifySlack">
              Notify to Slack "need-recovery" channel
            </label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
</div>

<div class="separator h bottom20"></div>

<!-- <div class="form-footer">
  <button nz-button nzType="primary" nzDanger (click)="onBtnConfirm()" [nzLoading]="isUnassigning" [disabled]="!needUpdate">Unassign</button>
  <button nz-button nzType="default" (click)="onBtnCancel()">Cancel</button>
</div> -->

<div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress"
  [labelOK]="'Unassign'" (onOK)="onBtnConfirm()" (onCancel)="onBtnCancel()"></div>
