import { Injectable } from "@angular/core";
import { SocketEvent } from "@app/enum";
import { Log } from "@services/log";
import { SocketService } from "@services/socket.service";
import { EventSocket_DriverFollow, EventSocket_DriverOnlineStatus, EventSocket_DriverRequestOnlineStatus } from "@wearewarp/types/event";
import { Subject, Subscription } from "rxjs";


@Injectable({providedIn: 'root'})
export class DispatchLiveTracking {
  private driverOnlineSubjects: {[driverId: string]: Subject<boolean>} = {};

  private startSubscription: Subscription;

  constructor(private socketService: SocketService) {
  }

  private handleDriverOnline(data: EventSocket_DriverOnlineStatus) {
    Log.d(`handleDriverOnline `, data);
    const isOnline: boolean = data.status == 'online';
    const driverId = data.driverId;
    if (!this.driverOnlineSubjects[driverId]) {
      this.driverOnlineSubjects[driverId] = new Subject();
    }
    this.driverOnlineSubjects[driverId].next(isOnline);
  }

  private startIfNeeded() {
    if (this.startSubscription) return;
    const subs = [
      this.socketService.subscribeConnectionStatus(isConnected => this.handleConnectionChange(isConnected)),
      this.socketService.subscribeEvent<EventSocket_DriverOnlineStatus>(SocketEvent.driverOnlineStatus, data => this.handleDriverOnline(data)),
    ]
    this.startSubscription = new Subscription();
    subs.map(sub => this.startSubscription.add(sub));
  }

  handleConnectionChange(isConnected: boolean) {
    if (isConnected) {
      // Các trường hợp follow job/driver thực chất là join room.
      // Nếu bị mất kết nối, hoặc là server restart thì cần phải join lại room.
      for (let driverId of this.followDriverIds) {
        this.socketService.emit<EventSocket_DriverFollow>(SocketEvent.driverFollow, {data: {driverId}});
      }
    }
  }

  stop() {
    this.startSubscription.unsubscribe();
    this.startSubscription = undefined;
  }

  resetListDriverObjects() {
    this.followDriverIds = [];
    this.driverOnlineSubjects = {};
  }

  // Hiện với workqueue chỉ check 1 driver tại 1 thời điểm => reset list khi check 1 driver mới
  private followDriverIds: string[] = [];   // để re-follow trong trường hợp reconnect
  trackDriverOnline(driverId: string, next: (isOnline: boolean) => void) {
    this.startIfNeeded();
    if (!this.driverOnlineSubjects[driverId]) {
      this.resetListDriverObjects();
      this.followDriverIds.push(driverId);
      this.driverOnlineSubjects[driverId] = new Subject();
    }
    const sub = this.driverOnlineSubjects[driverId].subscribe(next);
    this.socketService.emit<EventSocket_DriverFollow>(SocketEvent.driverFollow, {data: {driverId}});
    this.socketService.emit<EventSocket_DriverRequestOnlineStatus>(SocketEvent.driverRequestOnlineStatus, {data: {driverId}, callback: resp => this.handleDriverOnline(resp)});
    return sub;
  }

}