// Deck.gl arc layer with custom shader
import { ArcLayer } from '@deck.gl/layers'

export default class ArcBrushingLayer extends ArcLayer<any, {coef: number, baseOpacity?: number}> {
  static override layerName = "ArcBrushingLayer"
  override getShaders() {
    // here comes our custom shader
    // we will use step function to create opacity gradient with colorA and color B
    // for more information see https://thebookofshaders.com/05/
    return Object.assign({}, super.getShaders(), {
      inject: {
        'vs:#decl': `
           uniform float coef;
           uniform float baseOpacity;
          `,
        'vs:#main-end': `
          vec4 pct = vec4(segmentRatio);
          vec4 colorA = mix(instanceSourceColors, instanceTargetColors, pct);
          float segments = 4.0;
          float scaled = pct.a * segments * 0.5;
          float index = floor(scaled);
          float pcta = index + coef;
          float span = 0.25;
          float gap = pcta - scaled;
          if (gap > 1.0 - span) {
            gap = gap - 1.0;
          }
          if (gap < span - 1.0) {
            gap = gap + 1.0;
          }
          gap = abs(gap);
          float gap1 = gap / span;
          if (gap < span) {
            colorA.a = baseOpacity + (1.0 - baseOpacity) * gap1 * gap1;
          }
          vColor = colorA;
          `,
        'fs:#main-start': `
          if (vColor.a == 0.0) discard;
                      `
      }

/**
           if (coef > 0.0) {
            if (pct.a > coef)
              colorA.a = baseOpacity;
          } else {
            if (pct.a < pcta)
              colorA.a = baseOpacity;
          }

 */
    });
  }

  override draw(opts) {
    const { coef, baseOpacity } = this.props;
    // add uniforms
    const uniforms = Object.assign({}, opts.uniforms, { coef, baseOpacity: baseOpacity ?? 0.3});
    super.draw(Object.assign({}, opts, { uniforms }));
  }
}