<div class="load-actions" *ngIf="loadInfo">
  <div class="load-actions-container">
    <div class="group-btn">
      <!-- <button class="action-btn primary">
        <i class="fas fa-phone"></i>
        Call Driver
      </button>
      <button class="action-btn">
        <i class="fas fa-message"></i>
        Message
      </button>
      <button class="action-btn">
        <i class="fas fa-clock-rotate-left"></i>
        History
      </button> -->
      <button class="action-btn" (click)="openNotifySlack()">
        Notify Slack
      </button>
      <button *ngIf="!isUrgent()" class="action-btn" (click)="onBtnUpdateMarkUrgent('markUrgent')">
        <img src="assets/svg/urgent_before_icon.svg" class="urgent-icon" />
        <span>Mark Urgent</span>
      </button>
      <button *ngIf="isUrgent()" class="action-btn" (click)="onBtnUpdateMarkUrgent('unmarkUrgent')">
        <img src="assets/svg/urgent_icon.svg" class="urgent-icon" />
        <span style="color: #FF4D4F;">Un-mark Urgent</span>
      </button>

    </div>

    <div class="group-btn">
      <button class="action-btn" (click)="onBtnRemindTask()">
        Remind task
      </button>
      <button class="action-btn primary" (click)="onBtnNextTask()" nzLoading="isProcessing" [disabled]="isProcessing">
        Next task
      </button>
    </div>
  </div>
</div> 