<div class="body">
  <div class="title">Note</div>
  <textarea nz-input class="note-textarea top10"
  [(ngModel)]="note"
  [nzAutosize]="{ minRows: 1, maxRows: 3}"
  ></textarea>
</div>
<div class="footer top20">
  <button nz-button nzType="primary" (click)="submit()" 
    [disabled]="isSubmitting" 
    [nzLoading]="isSubmitting"
  >Confirm</button>

  <button nz-button nzType="default" (click)="closeModal()"
    [disabled]="isSubmitting"
  >Cancel</button>
</div>