import { Component, Input, OnInit } from '@angular/core';
import { TrackTraceService } from '../../services/track-trace.service';
import { CommonModule } from '@angular/common';
import { DateUtil } from '@services/date-util';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { WorkTaskHistory } from '@wearewarp/types/data-model';
import { Const } from '@const/Const';
import { Const as WarpConst } from '@wearewarp/universal-libs';

@Component({
  selector: 'track-trace-route-history',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  standalone: true,
  imports: [CommonModule, NzEmptyModule, NzTimelineModule] 
})
export class TrackTraceRouteHistoryComponent implements OnInit {
  public tasksLog: any[];
  isLoading: boolean = false;

  constructor(private trackTraceService: TrackTraceService) { }

  ngOnInit() {
    this.trackTraceService.getLogHistory_ForRoute().subscribe(items => {
      this.tasksLog = items;
      this.buildDisplayInfo();
    });
  }

  isHasRouteHistory() {
    return this.tasksLog && this.tasksLog.length > 0;
  }

  buildDisplayInfo() {
    this.tasksLog = this.tasksLog.map(item => {
      return {
        ...item,
        desc: this.getLogDesc(item),
        createdWhen: DateUtil.displayLocalTime(DateUtil.timestampToDate(item.ts), DateUtil.getLocalTimeZone(), 'MM/DD/YYYY hh:mm a'),
        createdBy: this.getFullName(item)
      }
    })
  }

  private getFullName(item: WorkTaskHistory) {
    let metadata = item?.subject?.metadata || {};
    if (metadata.fullName) return metadata.fullName;
    if (metadata.name) return metadata.name;
    return `${metadata.firstName || ''} ${metadata.lastName || ''}`.trim();
  }

  private getLogDesc(log: WorkTaskHistory): string { 
    let splitArr = (log?.action || '').split('.');
    let action = splitArr[splitArr.length - 1];
    let desc = '';
    if (action === Const.LogAction.updateStopStatus) {
      desc = this.getLogDesc_UpdateStopStatus(log);
    }
    else if (action === Const.LogAction.sendConfirmBookingEmail) {
      desc = this.getLogDesc_SendConfirmBookingEmail(log);
    }
    return desc;
  }

  getLogDesc_UpdateStopStatus(log: WorkTaskHistory) {
    let stopId = log?.states?.stopId;
    let newStatus = log?.states?.status;
    let statusText = Const.getStopStatusText(newStatus);
    let stopIndex = this.trackTraceService.findStopIndex(stopId) + 1;
    return `${statusText} stop ${stopIndex}`;
  }

  getLogDesc_SendConfirmBookingEmail(log: WorkTaskHistory) {
    return `Email "Confirm Booking" Resent`;
  }

  capitalizeCase(str: string): string {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  }
}