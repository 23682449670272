import { Component, Inject, Input } from "@angular/core";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { BaseComponent } from '@abstract/BaseComponent';
import { AttachedFileUtil } from '@services/attached-file-util';
import { CommonModule } from '@angular/common';
import { AttachedFile } from '@wearewarp/types/data-model';
import { PdfViewerModule} from '@app/pdf-viewer/pdf-viewer.module';
import { ModalDataImg } from './dialog.service';

@Component({
  selector: '[dlg-preview-img]',
  standalone: true,
  imports: [CommonModule, PdfViewerModule],
  templateUrl: './dlg-preview-img.html',
  styleUrls: ['./dlg-preview-img.scss' ,'./dialog.scss']
})
export class DlgPreviewImgComponent extends BaseComponent {
  @Input() imgUrl: Array<AttachedFile> = [];
  @Input() currentIndex = 0;
  
  constructor(private modal: NzModalRef, @Inject(NZ_MODAL_DATA) data: ModalDataImg) {
    super();
    this.imgUrl = data.imgUrl;
    this.currentIndex = data.currentIndex;
  }

  get podItem() {return this.imgUrl[this.currentIndex]}
  get isPDF() {return AttachedFileUtil.isPdf(this.podItem)}
  get itemUrl() {return AttachedFileUtil.attachedFileUrl(this.podItem)}
  get canBack() {return this.currentIndex > 0}
  get canNext() {return this.currentIndex < this.imgUrl.length - 1}

  public closeDialog() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.currentIndex < 0 || this.currentIndex >= this.imgUrl.length) {
      this.currentIndex = 0;
    }
  }

  ngAfterViewInit(): void {
  }

  onBtnBack() { 
    if (this.canBack) {
      this.currentIndex--;
    }
  }

  onBtnNext() {
    if (this.canNext) {
      this.currentIndex++;
    }
  }

}