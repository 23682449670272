import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { RouteNotesComponent } from "../load-notes/load-notes.component";
import { TrackTraceRouteHistoryComponent } from "../load-history";

const CustomInputs = [
  CommonModule,
  NzTabsModule,
  RouteNotesComponent,
  TrackTraceRouteHistoryComponent
]

@Component({
  selector: 'route-sidebar-menu',
  standalone: true,
  imports: CustomInputs,
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class RouteSidebarComponent {
  constructor() {}
}