import { Component, Inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NZ_MODAL_DATA } from "ng-zorro-antd/modal";
import { BaseFormDialog } from "@app/dialogs/base-form-dialog";
import { WorkTask } from "@wearewarp/types/data-model";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzSelectModule } from "ng-zorro-antd/select";
import { environment } from "@env/environment";
import { Const } from "@const/Const";
import { UIHelper } from "@services/UIHelper";

interface InputRemindTaskDialog {
  task: WorkTask;
}

const CustomInputs = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NzFormModule,
  NzButtonModule,
  NzIconModule,
  NzSelectModule
];

@Component({
  selector: 'remind-task-dialog',
  standalone: true,
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  imports: CustomInputs,
})


export class RemindTaskDialog extends BaseFormDialog {
  private _task: WorkTask;
  
  constructor(@Inject(NZ_MODAL_DATA) data: InputRemindTaskDialog) {
    super(data);
    this._task = data.task;
  }

  // value: minutes
  public remindOptions = [
    { label: '10 mins', value: 10 },
    { label: '20 mins', value: 20 },
    { label: '30 mins', value: 30 },
    { label: '1 hour', value: 60 },
    { label: '2 hours', value: 120 },
    { label: '4 hours', value: 240 },
    { label: '8 hours', value: 480 },
  ]
  public remindTime = 60;     // default: 1 hour

  get task() { return this._task; }

  get remindDesc() {
    return `By default, tasks for this route are re-generated every hour. You can adjust the timing here if you'd like to follow up earlier or later.`
  }

  onBtnSave() {
    this.pauseTask();
  }

  isSubmitting = false;
  pauseTask() {
    this.isSubmitting = true;
    const params = {
      taskId: this.task.id,
      delayTime: this.remindTime,
      autoResume: true,
    }
    const url = `${environment.backendWorkQueueUrl}/${Const.API_PAUSE_TASK(this.task.id)}`;
    this.api.POST(url, params).subscribe({
      next: (resp) => {
        this.isSubmitting = false;
        UIHelper.showSuccess('Updated successfully');
        this.onUpdateSuccess(resp);
      },
      error: (err) => {
        UIHelper.showErr(err);
        this.isSubmitting = false;
      }
    })
  }
}