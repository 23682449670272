<div *ngIf="isLoading" class="nodata" style="flex-direction: column;">
  <div style="text-align: center;">Fetching device information<br/>(it may take some time)</div>
  <i nz-icon nzType="loading" nzTheme="outline" style="margin-top: 20px;"></i>
</div>

<div *ngIf="errMsg" class="nodata" style="text-align: center;">{{errMsg}}</div>

<ng-container *ngIf="!isLoading && !errMsg">
  <div class="header" *ngIf="displayInfos.length > 1">Driver is using {{displayInfos.length}} different devices</div>
  <div class="device" *ngFor="let item of displayInfos">
    <div class="title">
      <img class="device-icon {{item.platform}}"/>
      <div class="device-desc">Device {{item.deviceInfo}}</div>
    </div>
    <div class="content">
      <div class="content-line">
        <div class="label">Screen size</div>
        <div>{{item.screenSize}}</div>
      </div>
  
      <div class="content-line">
        <div class="label">Battery</div>
        <div>{{item.batteryInfo}}</div>
      </div>
    
      <div class="content-line">
        <div class="label">GPS</div>
        <div>{{item.location}} <span *ngIf="item.locationTime" class="location-time">(at {{item.locationTime}})</span></div>
      </div>
    
      <div class="content-line">
        <div class="label">Accuracy</div>
        <div>{{item.accuracy}} <span *ngIf="item.accuracyWarning" class="warning">({{item.accuracyWarning}})</span></div>
      </div>

      <div class="content-line">
        <div class="label">Speed</div>
        <div>{{item.speed}}</div>
      </div>
    </div>
    
    <div class="title">
      <img class="app-icon" src="assets/img/ic_driver_app.png"/>
      <div class="app-desc">App version {{item.appVersion}}</div>
    </div>
    <div class="content">
  
      <div class="content-line">
        <div class="label">Install when</div>
        <div>{{item.firstInstall}}</div>
      </div>
    
      <div class="content-line">
        <div class="label">Location permission</div>
        <div>{{item.locationPermission}}</div>
      </div>
    
      <div class="content-line">
        <div class="label">Background location</div>
        <div class="flex" style="align-items: center;">
          {{item.backgroundLocation}}
          <!-- <div style="width: 24px; height: 24px;"
            class="clickable flex center-children left5"
            (click)="startBackgroundLocation()">
            <i nz-icon nzType="aim" nzTheme="outline"></i>
          </div> -->
        </div>
      </div>
    
      <div class="content-line">
        <div class="label">App state</div>
        <div>{{item.appState}}</div>
      </div>
    
      <!-- <div class="content-line">
        <div class="label">Active route ID</div>
        <div style="font-weight: 500;">{{item.activeRoute}}</div>
      </div> -->
    </div>
    
    <div class="title">
      <i nz-icon nzType="node-index" nzTheme="outline" style="margin-right: 34px;"></i>
      <div class="app-desc">Opening routes ({{item.assignedRoutes.length}})</div>
    </div>
    <div class="content">
      <div class="content-line route" *ngFor="let item of item.assignedRoutes" [ngClass]="{'active': item.isActive == true}">
        <div class="label">{{item.code}}</div>
        <div>{{item.status}}</div>
      </div>
    </div>
  </div>

  <div class="top20 f18m">Send command</div>
  <div class="top10 clickable" (click)="sendCommand_reloadOpenningRoutes()">Reload openning routes</div>

</ng-container>

