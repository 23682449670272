<ng-content></ng-content>
@if (popup && content) {
    <div class="global-popup" [class]="position"
        [style.top]="top"
        [style.bottom]="bottom"
        [style.left.px]="left"
    >
        <ng-container [ngTemplateOutlet]="content.templateRef"></ng-container>
        @if (frozen) {
            <div class="close-button" (click)="onUnFreeze($event)">
                <span class="material-symbols-outlined md-18">close</span>
            </div>
        }
    </div>
}
