import { Injectable } from "@angular/core"
import { PickingInfo } from "@deck.gl/core";
import { Job } from "@wearewarp/types/data-model";
import { BehaviorSubject } from "rxjs"
import { LatLngLike } from "@app/interfaces/map-v2/latlng";

@Injectable({
    providedIn: 'root'
})
export class MapService {
    private onHoverBehavior = new BehaviorSubject(null);
    private onClickBehavior = new BehaviorSubject(null);
    public onZoomInBehavior = new BehaviorSubject<LatLngLike[] | null>(null);

    // To register
    onHover(handler: (object: Job | null) => void) {
        return this.onHoverBehavior.subscribe(handler)
    }

    onClick(handler: (object: any | null) => void) {
        return this.onClickBehavior.subscribe(handler)
    }

    hover(id: string, info: PickingInfo) {
        /**
         * object, color, coordinates, pixel, devicePixel, layer, pixelRatio, sourceLayer, viewPort
         */
        this.onHoverBehavior.next(info.object)
    }
    click(id: string, info: PickingInfo | undefined | null) {
        if (!info?.object) {
            this.onClickBehavior.next({
                type: 'MAP',
                coordinates: info?.coordinate
            })
            return
        }
        this.onClickBehavior.next(info.object)
    }

    zoom(latlngs: LatLngLike[]) {
        this.onZoomInBehavior.next(latlngs)
    }
}