<div class="driver-info">
  <div class="main-driver">
    <div class="header">
      <h3>Driver</h3>
      <button nz-button nzType="link" class="edit-btn" *ngIf="isAssignedDriver()" (click)="editDriver()">Edit driver</button>
    </div>

    <div class="driver-details" *ngIf="isAssignedDriver()">
      <div class="driver-name" *ngIf="isDriverInfo()">
        <img src="assets/svg/account_circle.svg" alt="driver" class="driver-icon">
        <span class="name">{{ getDriverName() }}</span>
        <nz-tag [nzColor]="isDriverOnline ? 'success' : 'default'" class="app-status" (click)="viewDriverAppStatus()">
          {{ isDriverOnline ? 'Online' : 'Offline' }}
        </nz-tag>
      </div>

      <div class="contact-info" *ngIf="isDriverInfo()">
        <div class="contact-item">
          <!-- <a href="tel:{{ getDriverPhone() }}" class="value">{{ getDriverPhone() }}</a> -->
          <span nz-icon nzType="phone" nzTheme="outline"></span>
          <span class="value">{{ getDriverPhone() }}</span>
          <span class="copy-icon" (click)="copyToClipboard(getDriverPhone())" nz-tooltip nzTooltipTitle="Copy phone number">
            <i nz-icon nzType="copy"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="driver-details" *ngIf="!isAssignedDriver()">
      <div class="driver-name">
        <span class="name">No driver assigned</span>
        <button nz-button nzType="link" class="edit-btn" (click)="editDriver()">Assign driver</button>
      </div>
    </div>
</div>
  <ng-containter *ngIf="isShowSecondaryDriver">
    <div class="header top15">
      <h3>Secondary Driver</h3>
    </div>
    <div class="driver-details" *ngIf="isShowSecondDriverInfo()">    
      <div class="driver-name">
        <span class="name">{{ getSecondDriverName() }}</span>
      </div>
      <div class="contact-info">
        <div class="contact-item">
          <!-- <a href="tel:{{ getSecondDriverPhone() }}" class="value">{{ getSecondDriverPhone() }}</a> -->
          <span class="value">{{ getSecondDriverPhone() }}</span>
          <span class="copy-icon" (click)="copyToClipboard(getDriverPhone())" nz-tooltip nzTooltipTitle="Copy phone number">
            <i nz-icon nzType="copy"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="driver-details" *ngIf="!isShowSecondDriverInfo()">
      <div class="driver-name">
        <span class="name">No driver assigned</span>
        <button nz-button nzType="link" class="edit-btn" (click)="editDriver()">Assign driver</button>
      </div>
    </div>
  </ng-containter>
</div>