<div class="nodata">
  <i nz-icon nzType="loading" nzTheme="outline" nzSpin></i>
</div>
<div class="text-center bold">
  <ng-container *ngIf="!error">
    Preparing to download documents.<br/>Please wait a moment...
  </ng-container>
  <ng-container *ngIf="error">
    Download failed.<br/>{{errorMessage}}
  </ng-container>
</div>