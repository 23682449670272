import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TaskHandlerService } from "@app/track-trace/services/task-handler.service";
import { Const } from "@const/Const";
import { UIHelper } from "@services/UIHelper";
import { WorkTask } from "@wearewarp/types/data-model";
import { WorkTaskPriorityMask } from "@wearewarp/types/data-model/types/WorkTask";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { NZ_MODAL_DATA, NzModalModule, NzModalRef } from "ng-zorro-antd/modal";


const CustomInputs = [
  CommonModule,
  NzModalModule,
  NzButtonModule,
  NzIconModule,
  NzInputModule,
  FormsModule
]

@Component({
  selector: 'mark-urgent-dialog',
  standalone: true,
  imports: CustomInputs,
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  providers: [TaskHandlerService]
})
export class UpdateTaskPriorityMaskDialog {
  note: string = '';
  isSubmitting = false;
  task: WorkTask;
  priorityMask: WorkTaskPriorityMask;
  onSuccess: () => void;
  
  constructor(
    private modalRef: NzModalRef,
    private taskHandlerService: TaskHandlerService,
    @Inject(NZ_MODAL_DATA) public data: any
  ) {
    this.task = data.task;
    this.priorityMask = data.priorityMask;
    this.onSuccess = data.onSuccess || (() => {});
  }

  closeModal() {
    this.modalRef.close();
  }

  submit() {
    this.isSubmitting = true;
    this.taskHandlerService.updateTaskPriority(this.task, {
      priorityMask: this.priorityMask || Const.PriorityMask.medium,
      note: this.note
    }).subscribe({
      next: () => {
        this.isSubmitting = false;
        this.onSuccess();
        this.closeModal();
      },
      error: (err) => {
        this.isSubmitting = false;
        UIHelper.showErr(err);
      }
    });
  }
}
