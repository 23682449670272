<ng-container *ngIf="isLoading">
  <span nz-icon nzType="loading" nzTheme="outline"></span>
</ng-container>
<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="viewTemplate">
    <ng-container *ngTemplateOutlet="viewTemplate; context: { hasData, displayInfo }"></ng-container>
  </ng-container>
  
  <ng-container *ngIf="!viewTemplate">
    <span [style.font-weight]="styleFontWeight" [style.color]="styleTextColor" >
      <span>{{displayInfo.createdWhen}}</span>
      by
      <a [routerLink]="[displayInfo.hyperLinkCreatedBy]" 
        [style.color]="styleTextColor" 
        [style.font-weight]="styleFontWeight" 
        target="_blank">
        {{displayInfo.createdBy}}
      </a>
    </span>
    
  </ng-container>
</ng-container>
