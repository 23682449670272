import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'enroute-tracking-suggestion',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './index.html',
  styleUrl: './index.scss'
})
export class EnrouteTrackingSuggestion {
  @Input() isDriverActive: boolean = false;
  @Input() stopType: string = '';
}
