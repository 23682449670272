import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BaseFormItem } from '../base-components/form-item';
import { DialogService } from '@app/dialogs/dialog.service';
import { ImageUtil } from '@app/utils/image-util';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const CustomInputs = [
  CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    NzIconModule,
    NzRadioModule,
    NzButtonModule,
    NzAutocompleteModule,
]

@Component({
  selector: "input-upload-images",
  standalone: true,
  imports: [CustomInputs],
  templateUrl: "./index.html",
  styleUrls: ["./style.scss"],
})
export class FormInputUploadImages extends BaseFormItem implements OnInit {
  @Input() visible: boolean = true;
  @Output() fileSelectedChange = new EventEmitter<any[]>();

  isLoading: boolean = true;
  isUploading: boolean = false;
  isError: boolean = false;
  constructor() {
    super();
  }
  selectedFiles = [];
  previews = [];

  ngOnInit(): void {}

  public resetFormInput() {
    this.selectedFiles = [];
    this.previews = [];
  }

  onFileSelected(inputElement: HTMLInputElement) {
    let file = inputElement.files[0];
    inputElement.value = "";
    ImageUtil.resizeImageIfNeeded(file).then((blob) => {
      const previewUrl = URL.createObjectURL(blob);
      
      const obj = {
        file: blob,
        fileName: file.name,
        preview: previewUrl
      };
      
      this.selectedFiles.push(obj);
      this.previews.push(previewUrl);
      this.fileSelectedChange.emit(this.selectedFiles.map(f => f.file));
    });
  }

  onBtnDelImage(index) {
    if (this.selectedFiles[index]?.preview) {
      URL.revokeObjectURL(this.selectedFiles[index].preview);
    }
    
    this.selectedFiles.splice(index, 1);
    this.previews.splice(index, 1);
    this.fileSelectedChange.emit(this.selectedFiles.map(f => f.file));
  }

  viewImageItem(imgUrl) {
    if (!imgUrl) return;
    DialogService.previewImg([imgUrl], 0);
  }

  updateFiles(files: File[]): void {
    this.selectedFiles.forEach(file => {
      if (file.preview) {
        URL.revokeObjectURL(file.preview);
      }
    });
    
    this.selectedFiles = files.map(file => {
      const previewUrl = URL.createObjectURL(file);
      return {
        file,
        fileName: file.name,
        preview: previewUrl
      };
    });
    
    this.previews = this.selectedFiles.map(f => f.preview);
    this.fileSelectedChange.emit(this.selectedFiles.map(f => f.file));
  }

  ngOnDestroy() {
    this.selectedFiles.forEach(file => {
      if (file.preview) {
        URL.revokeObjectURL(file.preview);
      }
    });
  }
}
