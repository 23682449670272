import { Component } from '@angular/core';
import { CarrierInfoComponent } from './carrier-info/carrier-info.component';
import { DriverInfoComponent } from './driver-info/driver-info.component';
import { TrackingInfoComponent } from './tracking-info/tracking-info.component';
import { AssignHistoryComponent } from './assign-history/assign-history.component';
import { TrackTraceService } from '../../../services/track-trace.service';
import { NgIf } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Job } from '@wearewarp/types/data-model';
import { CarrierBidService } from '../../../services/carrier-bid.service';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { UIHelper } from '@services/UIHelper';
import { ConfirmBookingInfoComponent } from './confirm-booking-info';
@Component({
  selector: 'app-carrier-driver-tab',
  standalone: true,
  imports: [
    CarrierInfoComponent,
    ConfirmBookingInfoComponent,
    DriverInfoComponent,
    TrackingInfoComponent,
    AssignHistoryComponent,
    NgIf,
    NzButtonModule,
    NzDividerModule
  ],
  templateUrl: './carrier-driver-tab.component.html',
  styleUrl: './carrier-driver-tab.component.scss'
})
export class CarrierDriverTabComponent {
  isAssignedCarrier = false;
  loadInfo: Job;
  isLoading = false;

  constructor(private trackTraceService: TrackTraceService, private router: Router, private carrierBidService: CarrierBidService) {
    this.trackTraceService.getLoadInfo().subscribe(loadInfo => {
      this.loadInfo = loadInfo;
      this.isAssignedCarrier = !!loadInfo?.assignedCarrier?.carrierId;
    });
  }

  goToCarrierBid() {
    const jobId = this.loadInfo.id;
    this.isLoading = true;
    this.carrierBidService.getCarrierBid(jobId).subscribe(bid => {
      if (!bid) {
        UIHelper.showErr("No bid found, can not find bid for this job");
      }
      const bidUrl = `${environment.webAdminUrl}/dashboard/carrier-sales/${bid.id}`;
      window.open(bidUrl, '_blank');
      this.isLoading = false;
    }, () => {
      UIHelper.showErr("Error, can not find bid for this job");
      this.isLoading = false;
    });
  }
}
