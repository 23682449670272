import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ApiService } from "@services/api.service";
import { map, Observable, Subject } from "rxjs";
import { JobSearchResultItem } from "@app/interfaces/map-v2/job-search-result";
import { environment } from "@env/environment";
import { JobInfo } from "@app/interfaces/map-v2/job-info";
import { TaskStatus, TaskType } from "@wearewarp/types"
import { JobDetail } from "@app/interfaces/map-v2/job-detail";
import { LatLng, LatLngShort, Shipment, Task } from "@wearewarp/types/data-model";
import { DeliveryStop } from "@app/interfaces/map-v2/stop";
import { TimeWindow } from "@app/interfaces/map-v2/time-window";
import { getMetadata, isSameLocation, setMetadata } from "@app/utils/map-v2.util";

@Injectable({
  providedIn: 'root'
})
export class Map_JobService {
  baseUrl: string | null = null
  onViewJob = new Subject<string | null>()
  onFocusJob = new Subject<JobSearchResultItem[]>()

  constructor(
    private apiService: ApiService,
    private title: Title
  ) {
    this.baseUrl = environment.dispatchApi + '/job'
    this.processJobDetail = this.processJobDetail.bind(this)
  }

  loadJob(id: string): Observable<JobDetail> {
    const url = `${this.baseUrl}/${id}`
    if (environment.mock) {
      console.log('mock request')
      const p: JobDetail = require("../../../../../assets/mock/job-detail.json");
      return new Observable((observer) => observer.next(this.processJobDetail(p)))

    }
    return this.apiService.GET(url).pipe(map(this.processJobDetail))
  }

  loadJobInfo(id: string): Observable<JobInfo> {
    const url = `${this.baseUrl}/${id}/info`
    if (environment.mock) {
      console.log('mock request')
      const p: JobInfo = require("../../../../../assets/mock/job-info.json");
      return new Observable((observer) => observer.next(p))
    }
    return this.apiService.GET(url)
}

  viewJob(id: string | null) {
    // create stops
    this.onViewJob.next(id)
    if (id) {
      this.focusJob(null)
    }

    if (!id) {
      this.title.setTitle('Dispatch')
    }
  }

  extractShipmentRefNums(shipment: Shipment) {
    const refs: string[] = []
    for (let info of shipment?.deliveryInfos ?? []) {
      for (let r of info.refNums ?? []) {
        if (r && !refs.includes(r)) {
          refs.push(r)
        }
      }
    }
    return refs
  }

  processJobDetail(detail: JobDetail) {
    if (!detail) return null;
    const shipmentMap: any = {}
    const clientMap: any = {}
    const infoMap: any = {}
    const taskMap: any = {}
    const taskIdMap: any = {}
    for (let client of detail.clients ?? []) {
        clientMap[client.id] = client
    }

    for (let shipment of detail?.shipments ?? []) {
        const refNums = this?.extractShipmentRefNums(shipment)
        setMetadata(shipment, 'refNums', refNums)
        shipmentMap[shipment.id ?? ''] = shipment
        for (let info of shipment.deliveryInfos ?? []) {
            infoMap[info.id ?? ''] = info
        }
    }

    for (let task of detail.tasks ?? []) {
        taskMap[task.id!!] = task
        taskIdMap[`${task.shipmentId}-${task.type}`] = task.id
        setMetadata(task, 'loaded', true)
    }

    for (let task of detail.tasks ?? []) {
        let refId: string | null = null
        if (task.type == 'PICKUP') {
            refId = taskIdMap[`${task.shipmentId}-DROPOFF`]
        } else if (task.type == 'DROPOFF') {
            refId = taskIdMap[`${task.shipmentId}-PICKUP`]
        }
        if (refId) {
            const relative = taskMap[refId]
            if (relative) {
                setMetadata(task, 'corresponding', relative)
            }
        }
        const shipment: Shipment | null = shipmentMap[task.shipmentId ?? '']
        if (shipment)
            setMetadata(task, 'shipment', shipment)
        const client = clientMap[shipment?.clientId ?? '']
        if (client)
            setMetadata(task, 'client', client)
        const info = infoMap[task.deliveryId ?? '']
        if (info) {
            task.info = info
        }
    }

    if (detail.jobInfo?.path?.length && detail.tasks?.length)
    if (detail.jobInfo?.path?.length == (detail?.tasks?.length ?? 0) - 1) {
        for (let i = 0; i < detail.jobInfo.path.length; i++) {
            setMetadata(detail.tasks[i], 'path', detail.jobInfo.path[i])
        }
    }

    const stops = this.calculateStops(detail)
    detail.stops = stops
    // if (detail.job) {
    //     this.saveJobToCache(detail.job.id, detail.job)
    // }
    return detail
  }

  calculateStops(detail: JobDetail) {
    const stops: DeliveryStop[] = []
    let lastLocation: LatLng | null | undefined = null
    let lastStop: DeliveryStop | null = null

    let i = -1
    let index = 0
    let lastTask: Task | null = null
    for (let task of detail.tasks ?? []) {
      i += 1
      task.trafficCost = {}
      if (i > 0) {
        if (detail.jobInfo?.distances?.length && detail.jobInfo?.distances.length >= i) {
          task.trafficCost.distance = detail.jobInfo?.distances[i - 1]
        }
        if (detail.jobInfo?.drivingTimes?.length && detail.jobInfo?.drivingTimes.length >= i) {
          task.trafficCost.time = detail.jobInfo?.drivingTimes[i - 1] * 60
        }
      }
        
      if (lastStop != null && lastLocation != null && task.location != null && isSameLocation(lastLocation, task.location)) {
        const stopTasks = lastStop.tasks ?? []
        stopTasks.push(task)
        setMetadata(task, 'stopIndex', lastStop.index)
        lastStop.tasks = stopTasks
        if (task.info?.locationName) {
          lastStop.name = task.info.locationName
        }
        if (task.info?.warehouseId) {
          lastStop.warehouseId = task.info.warehouseId
        }
        if (task.info?.addr) {
          lastStop.address = task.info.addr
        }
      } else {
        index += 1
        setMetadata(task, 'stopIndex', index)
        lastStop = {
          index: index,
          name: task.info?.locationName,
          latlng: task.location ?? [],
          warehouseId: task.info?.warehouseId,
          tasks: [task],
          address: task.info?.addr,
          trafficCost: task.trafficCost,
          instructions: [],
        }
        if (lastTask) {
          const path = getMetadata(lastTask, 'path')
          if (path) {
            lastStop.path = path
          }
        }
        stops.push(lastStop)
      }
      if (task.info?.instructions) {
        if (lastStop.instructions?.includes(task.info?.instructions) !== true)
            lastStop.instructions?.push(task.info?.instructions)
      }
      lastLocation = task.location
      lastTask = task
    }
    for (let stop of stops) {
      stop.status = this.calculateStopStatus(stop)
      stop.completed = this.isStopCompleted(stop)
      stop.type = this.calculateStopTypes(stop)
      
      stop.arrivalTime = this.calculateStopArrivalTime(stop)
      stop.departureTime = this.calculateStopCompletedTime(stop)
      stop.scheduledTime = this.calculateStopScheduledTime(stop)

      stop.dwellTime = this.calculateStopDwellTime(stop)
    }
    return stops
  }

  calculateStopDwellTime(stop: DeliveryStop) {
    if (stop.arrivalTime) {
      stop.arrivalTs = new Date(stop.arrivalTime).getTime()
    }
    if (stop.departureTime) {
      stop.departureTs = new Date(stop.departureTime).getTime()
    }

    if (stop.arrivalTs && stop.departureTs) {
      return (stop.departureTs - stop.arrivalTs) / 1000
    }

    return 60 * 15 // 15 minutes
  }

  calculateStopTypes(stop: DeliveryStop): string {
    const allTaskTypes = stop.tasks?.map(it => it.type)
    const distinct = new Set(allTaskTypes)
    const taskTypes = [...distinct]
    taskTypes.sort()
    return taskTypes.join("-")
  }
  calculateStopStatus(stop: DeliveryStop) {
    const taskStatuses = (stop.tasks ?? []).map(it => (!it.status || it.status == 'created') ? 'pending' : it.status)
    const statuses: Set<string> = new Set(taskStatuses.filter(it => it != 'pickupFailed' && it != 'canceled'))
    if (statuses.size == 0) return taskStatuses[0] ?? 'pending'
    if (statuses.size == 1) return [...statuses][0]
    const statusList = [...statuses]
    statusList.sort()
    return statusList.join("-")
  }
  isStopCompleted(stop: DeliveryStop) {
    const terminal: Set<TaskStatus> = new Set(['succeeded', 'failed', 'pickupFailed'])
    const taskStatuses: TaskStatus[] = (stop.tasks ?? []).map(it => it.status ?? 'created')
    return taskStatuses.filter(it => !terminal.has(it)).length < 1
  }
  calculateStopArrivalTime(stop: DeliveryStop) {
    const taskArrival = (stop.tasks ?? []).map(it => it.statusChangeLog?.['arrived']).filter(it => it)
        .map(it => it?.changeWhen ?? it?.when).filter(it => it)
    taskArrival.sort()
    return taskArrival?.length ? taskArrival[0] : null
  }
  calculateStopCompletedTime(stop: DeliveryStop) {
    const taskArrival = (stop.tasks ?? []).map(it => it.statusChangeLog?.['succeeded'] ?? it.statusChangeLog?.['failed']).filter(it => it)
        .map(it => it?.changeWhen ?? it?.when).filter(it => it)
    taskArrival.sort()
    return taskArrival?.length ? taskArrival[taskArrival.length - 1] : null
  }

  calculateStopScheduledTime(stop: DeliveryStop): TimeWindow | null {
    const scheduledTimes = (stop.tasks ?? []).filter(it => it.status != 'canceled' && it.status != 'pickupFailed')
        .map(it => it.info?.windows?.[0]).filter(it => it)
    if (!scheduledTimes.length) return null
    return {
      from: scheduledTimes[0]?.from,
      to: scheduledTimes[0]?.to,
      timezone: stop.address?.metadata?.timeZoneStandard
    }
  }

  _focusedJobs: JobSearchResultItem[] = []
  focusJob(job: JobSearchResultItem | null) {
    if (!job) {
      this._focusedJobs = []
      this.onFocusJob.next([])
      return
    }
    if (this._focusedJobs?.filter(it => it.job?.id == job?.job?.id).length) return
    // create stops
    if (!getMetadata(job, 'infoLoaded')) {
      this.loadJobInfo(job.job.id).subscribe({
        next: (info) => {
          job.jobInfo = info
          this.processJobInfo(job)
          this.processJobDetail(job)
          job.stops = this.calculateStops(job)
          setMetadata(job, 'infoLoaded', true)
          this.onFocusJob.next(this._focusedJobs)
        }
      })
    }
  }

  processJobInfo(item: JobSearchResultItem) {
    // if (item.job) {
    //   this.saveJobToCache(item.job.id, item.job)
    // }
    if (item.jobInfo?.taskStatuses) {
      const initials = this.decodeInitialsString(item.jobInfo?.taskStatuses)
      item.taskStatuses = initials.map(this.decodeTaskStatus)
    }
    if (item.jobInfo?.taskTypes) {
      const initials = this.decodeInitialsString(item.jobInfo?.taskTypes)
      item.taskTypes = initials.map(this.decodeTaskType).filter(it => it).map((it: TaskType | null) => it!!)
    }
  }

  decodeTaskStatus(initial: string): TaskStatus {
    switch (initial) {
      case 'S':
        return 'succeeded';
      case 'F':
        return 'failed';
      case 'A':
        return 'arrived';
      case 'E':
        return 'enroute';
      case 'P':
        return 'created';
      case 'C':
        return 'canceled';
      case 'U':
        return 'pickupFailed';
      default:
        return 'created'
    }
  }

  decodeTaskType(initial: string): TaskType | null {
      switch (initial) {
          case 'P':
              return 'PICKUP';
          case 'D':
              return 'DROPOFF';
          case 'R':
              return 'RETURN';
          case 'T':
              return 'TRANSIT';
          default:
              return null
      }
  }

  decodeInitialsString(raw: string) {
    const initials: string[] = []
    const zero = '0'.charCodeAt(0)
    const nine = '9'.charCodeAt(0)
    let digitString: string = ''
    for (let i = 0; i < raw.length; i++) {
      const ch = raw.charAt(i)
      const c = raw.charCodeAt(i)
      if (c >= zero && c <= nine) {
        digitString += ch
      } else {
        if (digitString.length) {
          const repeat = parseInt(digitString) - 1
          if (repeat > 0 && initials.length) {
            const lastChar = initials[initials.length - 1]
            for (let j = 0; j < repeat; j++) {
              initials.push(lastChar)
            }
          }
          digitString = ''
        }
        initials.push(ch)
      }
    }
    if (digitString.length) {
      const repeat = parseInt(digitString) - 1
      if (repeat > 0 && initials.length) {
        const lastChar = initials[initials.length - 1]
        for (let j = 0; j < repeat; j++) {
          initials.push(lastChar)
        }
      }
      digitString = ''
    }
    return initials
  }
}