<div class="sidebar-menu">
  <div class="menu-list">
    <!-- Bookmark -->
    <div class="menu-item"
      nz-popover 
      [nzPopoverContent]="bookmarkTemplate"
      [nzPopoverVisible]="bookmarkVisible"
      [nzPopoverTrigger]="'click'"
      [nzPopoverPlacement]="'rightTop'"
      (click)="changeBookmarkVisible()"
    >
      <nz-badge [nzCount]="displayInfo.bookmarkBadge" nzShowZero [nzOffset]="[6, 0]" >
        <i class="menu-item-icon" nz-icon nzType="schedule" nzTheme="outline"
          nz-tooltip nzTooltipTitle="Bookmark">
        </i>
      </nz-badge>
    </div>


    <!-- Filter route-->
    <div class="menu-item" 
      nz-popover 
      [nzPopoverContent]="routeFilterTemplate"
      [nzPopoverVisible]="routeFilterVisible"
      [nzPopoverTrigger]="null"
      [nzPopoverPlacement]="'rightTop'"
      (click)="changeRouteFilterVisible()"
    >
      <i class="menu-item-icon" nz-icon nzType="search" nzTheme="outline"
        nz-tooltip nzTooltipTitle="Search route">
      </i>
    </div>

    <!-- Templates -->
    <ng-template #routeFilterTemplate>
      <div>
        <div class="flex-row-reverse">
          <div><i class="icon" nz-icon nzType="close" nzTheme="outline" (click)="changeRouteFilterVisible()"></i></div>
        </div>
        <route-filter-modal></route-filter-modal>
      </div>
    </ng-template>
  </div>

  <!-- User Avatar -->
  <div class="user-info">
    <div nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" nzPlacement="bottomRight" style="cursor: pointer;">
        <div class="center-children">
          <!-- <i class="fa fa-fw fa-angle-up"></i> -->
          <div class="ava-box" [ngClass]="{'online': isOnline}" 
              nz-tooltip [nzTooltipTitle]="userName">
            {{shortUsername}}
          </div>
          <!-- <div class="log-out-box" nz-icon nzType="logout" nzTheme="outline" (click)="confirmLogout()"></div> -->
          <!-- <div class="user-name" [ngClass]="{'online': isOnline}">{{shortUsername}}</div> -->
        </div>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <ng-container *ngFor="let item of navMenuItems">
          <li nz-menu-item (click)="onMenuItemClick(item)">
            <i *ngIf="item.nzIcon" nz-icon [nzType]="item.nzIcon" nzTheme="outline" style="margin-right: 10px;"></i>
            <span *ngIf="!item.link">{{item.label}}</span>
          </li>
        </ng-container>
      </ul>
    </nz-dropdown-menu>
  </div>

  <!-- Bookmark -->
  <ng-template #bookmarkTemplate>
    <div>
      <track-trace-bookmark-modal [currentTaskId]="currentTaskId"></track-trace-bookmark-modal>
    </div>
  </ng-template>
</div>