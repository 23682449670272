import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadStopsComponent } from './components/load-stops/load-stops.component';
import { LoadTitleComponent } from './components/load-title/load-title.component';
import { LoadActionsComponent } from './components/load-actions/load-actions.component';
// import { LoadMapComponent } from './components/load-map/load-map.component';
import { LoadTimeSummaryComponent } from './components/load-time-summary';
import { LoadCarrierDriverComponent } from './components/load-carrier-driver/load-carrier-driver.component';
import { TrackTraceService } from './services/track-trace.service';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { TrackTraceTaskComponent } from './components/work-queue-task';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { DriverChatBoxComponent } from './components/driver-chat-box/index';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RouteSidebarComponent } from './components/load-sidebar-menu';
import { TrackTraceSidebarMenu } from './components/sidebar-menu';
import { JobMapV2Component } from './components/load-map-v2';
import { DateUtil } from '@services/date-util';
import { timeToSeconds } from '@app/utils/datetime';
@Component({
  selector: 'app-track-trace',
  templateUrl: './track-trace.component.html',
  styleUrls: ['./track-trace.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LoadStopsComponent,
    LoadTitleComponent,
    LoadActionsComponent,
    // LoadMapComponent,
    LoadTimeSummaryComponent,
    LoadCarrierDriverComponent,
    TrackTraceTaskComponent,
    DriverChatBoxComponent,
    RouteSidebarComponent,
    TrackTraceSidebarMenu,
    NzSpinModule,
    NzEmptyModule,
    NzButtonModule,
    JobMapV2Component
  ]
})
export class TrackTraceComponent implements OnInit, AfterViewInit {
  private isResizing = false;
  public isFetchingLoadInfo = true;
  public isFetchingTaskInfo = false;
  public isEmptyTask = true;
  public isBreaking = false;
  public jobId: string = '';

  nextTaskTime: string = '15:00'; // chưa có API set time lấy task mặc định là 15 phút
  private countdownInterval: any;
  private totalSeconds: number = 0;

  private boundMouseMove: (e: MouseEvent) => void;
  private boundMouseUp: () => void;

  @ViewChild('mainContent') mainContent: ElementRef;
  @ViewChild('resizeHandle') resizeHandle: ElementRef;

  constructor(private trackTraceService: TrackTraceService) {
    this.boundMouseMove = this.handleMouseMove.bind(this);
    this.boundMouseUp = this.handleMouseUp.bind(this);

    // Khôi phục thời gian từ localStorage khi reload
    const savedTime = localStorage.getItem('nextTaskTime');
    const savedSeconds = localStorage.getItem('totalTaskSeconds');
    
    if (savedTime) {
      this.nextTaskTime = savedTime;
    }
    
    if (savedSeconds) {
      this.totalSeconds = parseInt(savedSeconds, 10);
    } else {
      this.totalSeconds = timeToSeconds(this.nextTaskTime);
    }
  }

  ngOnInit() {
    this.trackTraceService.fetchNewTask();
    this.trackTraceService.getWorkTask().subscribe(info => {
      this.isEmptyTask = info ? false : true;
      this.jobId = info?.object?.entity == 'job' ? info?.object?.entityId : '';
      
      if (this.isEmptyTask && !this.isBreaking) {
        this.startCountdown(this.totalSeconds);
      } else {
        this.stopCountdown();
      }
    })
    this.trackTraceService.fetchingTaskInfo.subscribe(fetching => {
      this.isFetchingTaskInfo = fetching;
    })
    this.trackTraceService.fetchingLoadInfo.subscribe(fetching => {
      this.isFetchingLoadInfo = fetching;
    });
    this.trackTraceService.stopWorking$.subscribe(isBreaking => {
      this.isBreaking = isBreaking;
    });                            
  }

  ngAfterViewInit() {          
    setTimeout(() => {
      this.initResizeHandlers();
    }, 1000);
  }

  ngOnDestroy() {
    this.stopCountdown();
  }

  get isLoading() {
    return this.isFetchingTaskInfo || this.isFetchingLoadInfo ? true : false;
  }

  private initResizeHandlers() {
    // Ngoc 28/03: disable resize
    // if (!this.resizeHandle || !this.mainContent) return;
    // this.resizeHandle.nativeElement.addEventListener('mousedown', this.handleMouseDown.bind(this));
  }

  private handleMouseMove = (e: MouseEvent) => {
    if (!this.isResizing) return;
  
    const gridElement = this.mainContent.nativeElement.closest('.content-grid');
    if (!gridElement) return;
  
    const containerWidth = gridElement.offsetWidth;
    const mousePosition = e.clientX;
    const percentage = (mousePosition / containerWidth) * 100;
    const constrainedPercentage = Math.min(Math.max(percentage, 10), 85);
    
    gridElement.style.gridTemplateColumns = `${constrainedPercentage}% 8px 1fr`;
    
    e.preventDefault();
  }

  private handleMouseDown = () => {
    this.isResizing = true;
    document.addEventListener('mousemove', this.boundMouseMove);
    document.addEventListener('mouseup', this.boundMouseUp);
  }

  private handleMouseUp = () => {
    this.isResizing = false;
    document.removeEventListener('mousemove', this.boundMouseMove);
    document.removeEventListener('mouseup', this.boundMouseUp);
  }

  private startCountdown(seconds: number) {
    this.stopCountdown();
    
    this.totalSeconds = Math.max(0, seconds);
    this.updateCountdownDisplay();

     // Lưu giá trị vào localStorage
      localStorage.setItem('totalTaskSeconds', this.totalSeconds.toString());
      localStorage.setItem('nextTaskTime', this.nextTaskTime);
    
    if (this.totalSeconds > 0) {
      this.countdownInterval = setInterval(() => {
        this.totalSeconds--;
        
        localStorage.setItem('totalTaskSeconds', this.totalSeconds.toString());
        
        if (this.totalSeconds <= 0) {
          this.stopCountdown();
          this.totalSeconds = 0;
          this.onContinueWork();
        }
        
        this.updateCountdownDisplay();
      }, 1000);
    }
  }
  
  private stopCountdown() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
    }
  }
  
  private updateCountdownDisplay() {
    const minutes = Math.floor(this.totalSeconds / 60);
    const seconds = this.totalSeconds % 60;
    
    this.nextTaskTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    localStorage.setItem('nextTaskTime', this.nextTaskTime);
  }

  onContinueWork() {
    localStorage.removeItem('totalTaskSeconds');
    localStorage.removeItem('nextTaskTime');
    this.trackTraceService.continueWorking();
  }
} 