<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div class="notify-slack-modal" *ngIf="!isLoading">
  <div class="modal-header">
    <h2>Notify Slack</h2>
    <button nz-button nzType="text" (click)="closeModal()">
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
  </div>

  <form nz-form [formGroup]="formInput" (ngSubmit)="onSubmit()">
    <div class="modal-content">
      <div *ngIf="'requireAction' as key" class="form-section">
        <label class="bottom10">Message</label>
        <p class="section-label">Select message type</p>
        <nz-radio-group [formControlName]="key" (ngModelChange)="onChangeRequiredAction($event)">
          <div *ngFor="let item of listActions">
            <div class="radio-option">
              <label nz-radio [nzValue]="item.value">
                {{ item.label }}
                <div class="option-desc">{{ item.desc }}</div>
              </label>
            </div>
          </div>
        </nz-radio-group>
      </div>

      <div *ngIf="'plainText' as key" class="form-section">
        <label class="content-label">Content</label>
          <textarea 
            rows="4" 
            [formControlName]="key"
            nz-input 
            [placeholder]="getContentPlaceholder()">
          </textarea>
      </div>

      <div *ngIf="'channelIds' as key" class="form-section">
        <label>Select Slack channel</label>
        <nz-select [formControlName]="key" nzMode="multiple" class="channel-select" (ngModelChange)="onChannelSelectChange($event)">
          <nz-option *ngFor="let item of slackChannels" 
            [nzValue]="item.channelId" 
            [nzLabel]="item.channel">
          </nz-option>
        </nz-select>
        <div *ngIf="showChannelError" class="error-message">
          * Please select at least 01 Slack channel
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button nz-button type="button" (click)="closeModal()" class="cancel">Cancel</button>
      <button nz-button type="submit"
        nzType="primary" 
        [nzLoading]="isSubmitting"
        [disabled]="isSendButtonDisabled"
        class="send">
        Send message
      </button>
    </div>
  </form>
</div>
