import { Const } from "@const/Const";
import { DateUtil } from "@services/date-util";
import { WorkTask } from "@wearewarp/types/data-model";

export class WorkTaskUtil {

  public static isTrackTraceTask(task: WorkTask): boolean {
    const taskType: any = task.type || '';
    return [
      Const.WorkRouteTask.routeConfirmation,
      Const.WorkRouteTask.etaConfirmation,
      Const.WorkRouteTask.arrivalConfirmation,
      Const.WorkRouteTask.departureConfirmation,
      Const.WorkRouteTask.enrouteTracking
    ].includes(taskType);
  }

  public static isRequestedTask(task: WorkTask): boolean {
    const taskType: any = task.type || '';
    return taskType === Const.WorkManualTask.manual;
  }

  public static getLastTrackTxt(task: WorkTask): string {
    const lastTrack = task.lastTrack;
    if (!lastTrack) return '';
    const trackTime = lastTrack.when;
    const diff = new Date().getTime() - new Date(trackTime).getTime();
    const mins = Math.round((diff ?? 0) / (1000 * 60));
    return `Last tracked ${DateUtil.getParseDurationTimeAgo(mins)}`;
  }
}