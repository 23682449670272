import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { TrackTraceService } from '@app/track-trace/services/track-trace.service';
import { AssignedCarrier, Job, WhenBy, WorkTask, WorkTaskHistory } from '@wearewarp/types/data-model';
import { Const } from '@const/Const';
import { environment } from '@env/environment';
import { BaseComponent } from '@abstract/BaseComponent';
import { UIHelper } from '@services/UIHelper';
import { Utils } from '@services/utils';
import { DateUtil } from '@services/date-util';
import { DialogService } from '@app/dialogs/dialog.service';
import { ResendConfirmBookingDialog } from '@app/work-dispatcher/task-queue/confirm-route/components/resend-confirm-booking-dialog';
import { RequestCreateWorkTaskHistory } from './interface';
import { WarpWhenBy } from './create-by-user';

type ByCollectionEnum = 'users' | 'drivers' | 'clients' | 'carriers';
interface IByData {
  collection: ByCollectionEnum,
  id?: string,
  warpId?: number,
}

const CustomInputs = [
  CommonModule,
  NzDividerModule,
  NzButtonModule,
  NzIconModule,
  NzModalModule,
  NzInputModule,
  NzSelectModule,
  NzTagModule,
  DatePipe,
  WarpWhenBy
]
@Component({
  selector: 'app-confirm-booking-info',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrl: './index.scss'
})

export class ConfirmBookingInfoComponent extends BaseComponent implements OnInit {
  jobId: string;
  loadInfo: Job;
  assignedCarrier: AssignedCarrier;
  tasks: (WorkTask & {logHistory: WorkTaskHistory[]})[] = [];
  logSendConfirmBookingEmail = [];
  automationTrackingUrl: string;
  displayInfo: any = {};
  
  isLoading = false;
  isAllItemsConfirmed: boolean = false;
  
  emailWonBidSentBy: any = null;
  
  constructor( private trackTraceService: TrackTraceService) {
    super();
  }

  ngOnInit(): void {
    this.trackTraceService.getLoadInfo().subscribe(loadInfo => {
      this.loadInfo = loadInfo;
      this.jobId = this.loadInfo?.id;
      this.assignedCarrier = this.loadInfo?.assignedCarrier;
      this.automationTrackingUrl = (<any>this.loadInfo)?.automationTrackingUrl;
      this.emailWonBidSentBy = this.assignedCarrier?.sentEmailWonBidBy;
      this.fetchRouteWorkTasks();
    });
    this.fetchRouteWorkTasks();
  }

  get isHasTask() {
    return this.tasks.length > 0;
  }

  get isAssignedCarrier() {
    return !!this.assignedCarrier?.carrierId;
  }

  isTaskConfirmBooking(task) {
    return task.type === 'routeConfirmation';
  }

  fetchRouteWorkTasks() {
    if (!this.jobId) return;
    this.isLoading = true;
    const url = `${environment.backendWorkQueueUrl}/v1/w/d/tasks/job/${this.jobId}`;
    this.api.GET(url).subscribe(
      (resp) => {
        this.isLoading = false;
        this.tasks = resp.data?.list_data || [];
        this.buildData();
      },
      (err) => {
        UIHelper.showErr(err);
        this.isLoading = false;
      }
    )
  }

  buildData() {
    let allHistories = this.tasks.map(task => task.logHistory).flat();
    this.logSendConfirmBookingEmail = allHistories.filter(h => h.action?.includes('sendConfirmBookingEmail'));
    this.displayInfo = {
      itemsConfirmed: this.getDisplayItemsConfirmed()
    }
  }

  getDisplayItemsConfirmed() {
    if (!this.isAssignedCarrier) return 'Need carrier';
    let cnt = 0;
    let requireCarrierAcceptLoadtender = this.loadInfo?.settings?.isRequireCarrierAcceptLoadTender
    let numNeedConfirm = requireCarrierAcceptLoadtender ? 3 : 2;
    if (this.assignedCarrier?.loadTenderStatus === 'accepted' && requireCarrierAcceptLoadtender) cnt++;
    if (this.assignedCarrier?.isReady) cnt++;
    if (this.assignedCarrier?.confirmPickupTime) cnt++;
    if (cnt === numNeedConfirm) {
      this.isAllItemsConfirmed = true;
    }
    return `${cnt}/${numNeedConfirm} confirmed`;
  }

  get confirmItemStatus() {
    if (!this.isAssignedCarrier) return 'need-carrier';
    if (this.isAllItemsConfirmed) return 'all-confirmed';
    return '';
  }

  onBtnOpenSendEmailDialog(task) {
    const job = this.loadInfo;
    const stops = job?.stops;
    const pickCity = stops?.[0]?.info?.addr?.city;
    const dropCity = stops?.[stops.length - 1]?.info.addr?.city;
    if (this.isTaskConfirmBooking(task)) {
      let carrierContact: any = this.loadInfo?.carrier?.basicInfo?.owner || this.loadInfo?.carrier?.basicInfo?.contacts?.[0];
      let contactName = carrierContact?.firstName || carrierContact?.fullName;
      let emailContact = carrierContact?.email;
      let emailSubject = `[Action required] ${job?.code} Booking Confirmation - ${pickCity} - ${dropCity}`;
      let confirmLink = this.automationTrackingUrl;
      DialogService.openFormDialog(ResendConfirmBookingDialog, {
        nzData: {
          jobId: this.jobId,
          contactName,
          emailContact,
          emailSubject,
          confirmLink,
          onSuccess: () => {
            this.resendConfirmBookingEmailSuccess(task);
          }
        },
        nzTitle: 'Resend Booking Confirmation email',
        nzClosable: true,
        nzWidth: '600px'
      })
    }
  }

  resendConfirmBookingEmailSuccess(task) {
    const workTaskId = task.id;
    const params: RequestCreateWorkTaskHistory = {
      taskId: workTaskId,
      action: 'sendConfirmBookingEmail',
      object: { entity: 'jobs', entityId: this.jobId },
      source: { entity: 'warp-web' },
      evidences: { jobId: this.jobId, status: this.loadInfo?.status }
    };
    let url = `${environment.backendWorkQueueUrl}/v1/history`;
    this.isLoading = true;
    this.api.POST(url, params).subscribe(
      (resp) => {
        this.isLoading = false;
        this.fetchRouteWorkTasks();
      },
      (err) => {
        UIHelper.showErr(err);
        this.isLoading = false;
      }
    )
  }

  async onBtnOpenChecklistDialog() {
    let requireCarrierAcceptLoadtender = this.loadInfo?.settings?.isRequireCarrierAcceptLoadTender;
    const isConfirmedLoadtender = this.assignedCarrier?.loadTenderStatus === 'accepted' ? true : false;
    const isConfirmedReady = this.assignedCarrier?.isReady ? true : false;

    let acceptedLoadTenderBy: any = this.loadInfo?.assignedCarrier?.acceptedLoadTenderBy || null;
    if (acceptedLoadTenderBy) {
      const result = await this.getWhenByData(acceptedLoadTenderBy);
      acceptedLoadTenderBy = { ...acceptedLoadTenderBy, ...result };
    }
    let confirmedReadyBy: any = this.loadInfo?.assignedCarrier?.confirmedReadyBy || null;
    if (confirmedReadyBy) {
      const result = await this.getWhenByData(confirmedReadyBy);
      confirmedReadyBy = { ...confirmedReadyBy, ...result };
    }

    let confirmedPickupTimeBy: any = this.loadInfo?.assignedCarrier?.confirmPickupTime || null;
    if (confirmedPickupTimeBy) {
      const result = await this.getWhenByData(confirmedPickupTimeBy);
      confirmedPickupTimeBy = { ...confirmedPickupTimeBy, ...result };
    }
    
    let textAcceptedLoadTenderBy = isConfirmedLoadtender ? `- by ${ acceptedLoadTenderBy?.basicInfo?.name || acceptedLoadTenderBy?.name || this.getFullName(acceptedLoadTenderBy)} at ${this.$formatDate(acceptedLoadTenderBy?.when) } (EST)` : '';
    let textConfirmedReadyBy = isConfirmedReady ? `- by ${ confirmedReadyBy?.basicInfo?.name || confirmedReadyBy?.name || this.getFullName(confirmedReadyBy)} at ${this.$formatDate(confirmedReadyBy?.when) } (EST)` : '';
    let textConfirmedPickupTimeBy = confirmedPickupTimeBy ? `- by ${ confirmedPickupTimeBy?.basicInfo?.name || confirmedPickupTimeBy?.name || this.getFullName(confirmedPickupTimeBy)} at ${this.$formatDate(confirmedPickupTimeBy?.when) } (EST)` : '';

    let content = '<ul>';
    if (requireCarrierAcceptLoadtender) {
      content = content + `<li>Carrier ${!isConfirmedLoadtender ? 'hasn\'t ' : ''}accepted Load Tender ${textAcceptedLoadTenderBy}</li>`;
    }
    content = content + `<li>Carrier ${!isConfirmedReady ? 'hasn\'t':''} confirmed Equipment ${textConfirmedReadyBy}</li>`;

    content = content + `<li>Carrier ${!confirmedPickupTimeBy ? 'hasn\'t':''} confirmed Pickup Time ${textConfirmedPickupTimeBy}</li>`;
    content = content + '</ul>';
    UIHelper.confirm(content, () => {}, { title: 'Confirm Booking status', okText: 'OK', cancelText: null  })
  }

  public getFullName(user) {
    if (!user) return '';
    if (user.fullName) return user.fullName;
    let text = user.firstName || '';
    if (user.lastName) {
      if (text) {
        text += ' ';
      }
      text += user.lastName;
    }
    return text;
  }

  private async getWhenByData(params: WhenBy) {
    if (params.byCarrierId) {
      let byData: IByData = { collection: 'carriers' };
      if (Utils.isNumber(params.byCarrierId)) byData.warpId = params.byCarrierId;
      if (params.byId) byData.id = params.byId;
      const res = await this.getWhenbyDataFromAPI(byData);
      return res;
    }
    if (params.byDriverId) {
      let byData: IByData = { collection: 'drivers' };
      if (Utils.isNumber(params.byDriverId)) byData.warpId = params.byDriverId;
      if (params.byId) byData.id = params.byId;
      const res = await this.getWhenbyDataFromAPI(byData);
      return res;
    }
    if (params.by) {
      let byData: IByData = { collection: 'users' };
      if (Utils.isNumber(params.by)) byData.warpId = params.by;
      if (params.byId) byData.id = params.byId;
      const res = await this.getWhenbyDataFromAPI(byData);
      return res;
    }
  }

  async getWhenbyDataFromAPI(params: IByData) {
    let url = `${Const.APIURI_USERS}/get-when-by-info`;
    const response = await this.api.POST(url, params).toPromise();
    return response?.data;
  }

  $formatDate = (date: Date) => {
    return DateUtil.displayLocalTime(date, 'America/New_York', 'MM/DD/YYYY hh:mm A');
  }
}
