<ng-container *ngIf="isLoading">
  <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
</ng-container>

<ng-container *ngIf="!isLoading">
  <ng-template [nzModalTitle]>
    <div class="flex-space-between">
      <span>{{ headerText }}</span>
      <div class="flex">
        <button *ngIf="isJobStarted && tabIndex == 0" nz-button class="right10" (click)="onBtnChangeDriverWhileJobStarted()">Change Driver</button>
        <button *ngIf="!isCreateNew && tabIndex == 0" nz-button nzDanger (click)="onBtnUnassignDriver()" [nzLoading]="isUnassigningDriver">Unassign Driver</button>
        <button *ngIf="isJobStarted && tabIndex == 1" nz-button class="right10" (click)="onBtnChangeSecondaryDriver()">Change Driver</button>
        <button *ngIf="!isCreateNew && tabIndex == 1" nz-button nzDanger (click)="onBtnUnassignSecondaryDriver()" [nzLoading]="isUnassigningSecondaryDriver">Unassign Driver</button>
        <span class="left10"><span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span></span>
      </div>
    </div>
  </ng-template>

  <!-- Content -->
  <form nz-form [formGroup]="formInput">
    <nz-tabset (nzSelectedIndexChange)="onTabChange($event)" [nzSelectedIndex]="tabIndex">
      <nz-tab nzTitle="{{ isShowSecondaryDriverTab ? 'Primary Driver' : 'Driver' }}" [nzDisabled]="shouldDisableDriverTab">
        <ng-container *ngIf="!shouldDisableDriverTab">
          <!-- Assign Driver -->
          <form nz-form *ngIf="'assignedDriver' as childGroup" [formGroupName]="childGroup">
            <ng-container *ngFor="let key of ['driverId']">
              <nz-form-item>
                <nz-form-control>
                  <nz-select [formControlName]="key" [nzDisabled]="isJobStarted" [nzOptionHeightPx]="55" style="width: 100%;"
                    [nzShowSearch]="true" nzAllowClear nzPlaceHolder="Please select driver" nzDropdownClassName="choose-driver">
                    <nz-option nzCustomContent *ngFor="let driver of allDrivers" [nzLabel]="driver.label" [nzValue]="driver.id">
                      <span>{{driver.name}}</span>
                      <span *ngIf="driver.phone" class="sub-info">{{driver.phone}}</span>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </ng-container>
          </form>
          <div class="separator h top10 bottom10"></div>
          <ng-container *ngFor="let key of ['trailerNumber']">
            <span class="form-label-v2 bottom10">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></span>
            <nz-form-item>
              <nz-form-control>
                <input nz-input [formControlName]="key"
                  [type]="'string'"
                  [placeholder]="getPlaceHolder(key)"
                  (input)="onInputChanged($event, key)"
                  (keypress)="onInputKeyPress($event, key)" />
              </nz-form-control>
            </nz-form-item>
          </ng-container>
          <ng-container *ngIf="driverToken">
            <!-- <send-driver-sms [token]="driverToken" [jobCode]="jobCode" [jobId]="jobId" [driver]="driverObj" [pickDt]="pickDt"></send-driver-sms> -->
          </ng-container>
        </ng-container>
      </nz-tab>

      <!-- tab secondary driver -->
      <ng-container *ngIf="isShowSecondaryDriverTab">
        <nz-tab nzTitle="Secondary Driver" [nzDisabled]="shouldDisableDriverTab">
          <ng-container *ngIf="!shouldDisableDriverTab">
            <ng-container *ngFor="let childGroup of ['assignedSecondaryDrivers']" [formArrayName]="childGroup">
              <form nz-form *ngFor="let item of getArrayControls(childGroup); let i = index" [formGroupName]="i">
                <ng-container *ngFor="let key of ['driverId']">
                  <nz-form-item>
                    <nz-form-control>
                      <nz-select [formControlName]="key" [nzDisabled]="isJobStarted" [nzOptionHeightPx]="55" style="width: 100%;"
                        [nzShowSearch]="true" nzAllowClear nzPlaceHolder="Please select driver" nzDropdownClassName="choose-driver">
                        <nz-option nzCustomContent *ngFor="let driver of allDrivers" [nzLabel]="driver.label" [nzValue]="driver.id">
                          <span>{{driver.name}}</span>
                          <span *ngIf="driver.phone" class="sub-info">{{driver.phone}}</span>
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
              </form>
            </ng-container>
            
          </ng-container>
        </nz-tab>
      </ng-container>
    </nz-tabset>
  </form>

  <!-- Footer -->
  <ng-template [nzModalFooter]>
    <ng-container>
      <button nz-button nzType="default" nzTheme="outline" (click)="closeDialog()" style="min-width: 100px; margin-right: 10px;">Cancel</button>
      <button nz-button nzType="primary" (click)="onBtnSubmit()" style="min-width: 100px;" [disabled]="!needUpdate" [nzLoading]="isSubmitting">{{ btnSubmitLabel}}</button>
    </ng-container>
  </ng-template>
</ng-container>
