<div class="work-requested-task">
  <div class="container">
    <div class="header">
      <div class="semibold">Requested Task</div>
      <div class="group-btn">
        <a *ngIf="!isTaskCompleted" (click)="onBtnMarkAsResolved()">Mark as resolved</a>
        <nz-tag *ngIf="isTaskCompleted" style="color: #2fa131;">Resolved</nz-tag>
      </div>
    </div>
    <div class="content top15">
      <div class="task-info">
        <div class="semibold">{{ displayInfo?.name }}</div>
        <div class="task-desc top15">{{ displayInfo?.description }}</div>
      </div>
      <div class="more-info top10">
        <div class="created-by">Task created by {{ displayInfo?.createdBy }}</div>
      </div>
    </div>
  </div>
</div>