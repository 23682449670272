<div class="suggestions-list">
  <div class="suggestions-item">
    <ng-container *ngIf="!isDriverActive">
      <div class="item-label">Contact the driver to:</div>
      <div class="item-content">
          <li>Remind them to turn on live tracking</li>
          <li>Ask if the driver has already arrived, then tell them to mark the stop as "Arrived" in the Driver App. If they can’t, update the stop status yourself</li>
      </div>
      <div class="item-note">
        <div>Record the details below.</div>
      </div>
    </ng-container>
    
    <ng-container *ngIf="isDriverActive">
      <div class="item-note">Check the live tracking details and note the route status below. If the driver hasn’t arrived at the stop, please contact them for an update.</div>
    </ng-container>
  </div>
</div>