import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { environment } from "@env/environment";
import { ApiService } from "@services/api.service";
import { UIHelper } from "@services/UIHelper";
import { RequestListJob_ForTrackTraceBookmark, ResponseListJobItem_ForTrackTrace, ResponseTrackTraceStatistic } from "@wearewarp/types-server-workqueue/operator";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { OneRouteTasksComponent } from "../components/one-route-tasks";
import { WorkTaskMeta } from "@wearewarp/types-server-workqueue/common";
import { TrackTraceService } from "@app/track-trace/services/track-trace.service";
import { StatisticsService } from "@app/track-trace/services/statistics.service";
import { WorkTaskUtil } from "@app/utils/worktask";


const CustomInputs = [
  CommonModule,
  NzTabsModule,

  OneRouteTasksComponent
]

@Component({
  selector: 'track-trace-bookmark-modal',
  standalone: true,
  imports: CustomInputs,
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class TrackTraceBookmarkModal {
  @Input() currentTaskId: string = '';

  selectedTabIndex: number = 0;
  isLoading: boolean = false;
  listData: ResponseListJobItem_ForTrackTrace[] = [];
  statistic = {
    totalUrgent: 0,
    totalWorking: 0,
    totalManual: 0
  }

  constructor(
    private api: ApiService,
    private trackTraceService: TrackTraceService,
    private statisticsService: StatisticsService
  ) {}

  
  ngOnInit() {
    this.statisticsService.getBookmarkStatistic().subscribe(statistic => {
      this.statistic.totalUrgent = statistic?.totalUrgent ?? 0;
      this.statistic.totalWorking = statistic?.totalWorking ?? 0;
      this.statistic.totalManual = statistic?.totalManual ?? 0;
    });
    this.loadData();
  }

  loadData() {
    this.fetchListJob();
  }

  getSelectedTabTag() {
    switch (this.selectedTabIndex) {
      case 0: return 'urgent';
      case 1: return 'working';
      case 2: return 'manual';
      default: return 'urgent';
    }
  }

  fetchListJob() {
    const tag = this.getSelectedTabTag();
    const url = `${environment.backendWorkQueueUrl}/v1/jobs/list-for-tracktrace-bookmark`;
    const params: RequestListJob_ForTrackTraceBookmark = { tag };
    this.isLoading = true;
    this.api.POST(url, params).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.listData = res.data?.list_data ?? [];
      },
      error: (err) => {
        UIHelper.showErr(err);
        this.isLoading = false;
      }
    })
  }

  onTabIndexChange() {
    this.fetchListJob();
  }

  getTabTitle(tag: 'urgent' | 'working' | 'manual') {
    switch (tag) {
      case 'urgent':
        return `Urgent (${this.statistic.totalUrgent})`;
      case 'working':
        return `Working (${this.statistic.totalWorking})`;
      case 'manual':
        return `Manual (${this.statistic.totalManual})`;
      default:
        return 'N/A';
    }
  }

  doTask(task: any) {
    if (WorkTaskUtil.isTrackTraceTask(task) && task?.object?.entityId) {
      this.trackTraceService.getTaskFocusOnRoute(task.object.entityId);
    }
    else {
      this.trackTraceService.setSpecificTask(task.id);
    }
  }

  unassignMyself(task: any) {
    UIHelper.confirm('Are you sure you want to release this task?', () => {
      this.trackTraceService.unassignMyTask(task.id).subscribe({
        next: () => {
          UIHelper.showSuccess('Unassign task successfully');
        this.statisticsService.fetchBookmarkStatistic();
          this.loadData();
        },
        error: (err) => {
          console.error('Failed to unassign task:', err);
        }
      })
    },
    {
      okText: 'Release task',
      cancelText: 'No, not now',
    }
  );
  }
}
