import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ResponseWorkQueueRouteNoteUI } from "./note-list/interfaces";
import { ApiService } from "@services/api.service";
import { environment } from "@env/environment";
import { tap } from "rxjs/operators";
import { Const } from '@const/Const';


@Injectable({
  providedIn: 'root'
})
export class RouteNoteService {
  private notes = new BehaviorSubject<ResponseWorkQueueRouteNoteUI[]>([]);
  private fetchingNotes = new BehaviorSubject<boolean>(false);
  private jobId: string;

  constructor(private api: ApiService) {}

  setJobId(jobId: string) {
    this.jobId = jobId;
    this.fetchNotes();
  }

  isFetchingNotes(): Observable<boolean> {
    return this.fetchingNotes.asObservable();
  }

  getNotes(): Observable<ResponseWorkQueueRouteNoteUI[]> {
    return this.notes.asObservable();
  }

  refresh() {
    this.fetchNotes();
  }

  fetchNotes() {
    if (!this.jobId) return;
    this.fetchingNotes.next(true);
    this.api.GET(`${environment.backendWorkQueueUrl}/v1/conversations/for-route/${this.jobId}`).subscribe({
      next: (resp) => {
        this.fetchingNotes.next(false);
        this.notes.next(resp.data.list_data as unknown as ResponseWorkQueueRouteNoteUI[]);
      },
      error: (err) => {
        this.fetchingNotes.next(false);
        console.error(err);
      }
    });
  }
  
  pinNote(noteId: string, isPinned: boolean) {
    const jobId = this.jobId;
    
    return this.api.PUT(
      `${Const.APIURI_CONVERSATIONS}/${noteId}/pin?subjectId=${jobId}&subjectType=job&type=note`,
      { pin: isPinned }
    ).pipe(
      tap(() => {
        this.refresh();
      })
    );
  }
  
  updateNote(noteId: number, content: string) {
    const jobId = this.jobId;
    return this.api.PUT(
      `${Const.APIURI_CONVERSATIONS}/${noteId}/?subjectId=${jobId}&subjectType=job&type=note`,
      { content }
    ).pipe(
      tap(() => {
        this.refresh();
      })
    );
  }
}
