import { Component, Input } from '@angular/core';
import { DeliveryStop } from '@app/interfaces/map-v2/stop';
import { getMetadata, setMetadata } from '@app/utils/map-v2.util';
import { TaskService } from '../../services/task.service';
import { Task } from '@wearewarp/types/data-model';
import { AddressComponent } from '../job-detail-map-component/components/address';
import { DecimalPipe } from '@angular/common';
import { DurationComponent } from '../job-detail-map-component/components/duration';
import { TaskStatusComponent } from '../job-detail-map-component/components/task-status-update/task-status.component';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
    selector: 'delivery-progress-stop-popup',
    templateUrl: './index.html',
    styleUrl: './index.scss',
    imports: [
        NzIconModule,
        AddressComponent,
        DecimalPipe,
        DurationComponent,
        TaskStatusComponent
    ],
    standalone: true
})
export class DeliveryProgressStopComponent {
    _stop?: DeliveryStop
    loading: boolean = false
    @Input() set stop(v: DeliveryStop) {
        this._stop = v

        // load info
        this.loadTask()
    }

    constructor(
        private taskService: TaskService
    ) {}

    loadTask() {
        this.loading = true
        const taskIds = this._stop.tasks.filter(it => !getMetadata(it, 'loaded')).map(it => it.id)
        if (taskIds.length < 1) {
            this.loading = false
            return
        }
        this.taskService.loadTask(taskIds).subscribe({
            next: (r) => {
                const taskMap: any = {}
                for (let t of r) {
                    taskMap[t.id] = t
                }
                for (let task of this._stop.tasks ?? []) {
                    // copy info
                    const loaded: Task = taskMap[task.id]
                    if (loaded) {
                        task.info = loaded.info
                        task.status = loaded.status
                        task.statusChangeLog = loaded.statusChangeLog
                        task.metadata = loaded.metadata
                        setMetadata(task, 'loaded', true)

                        if (loaded.address) {
                            if (!this._stop.address) {
                                this._stop.address = loaded.address
                            }
                        } else if (loaded.info?.addr) {
                            if (!this._stop.address) {
                                this._stop.address = loaded.info.addr
                            }
                        }
                        if (loaded.info?.warehouseId) {
                            if (!this._stop.warehouseId) {
                                this._stop.warehouseId = loaded.info.warehouseId
                            }
                        }
                        if (loaded.info?.locationName) {
                            if (!this._stop.name) {
                                this._stop.name = loaded.info.locationName
                            }
                        }
                    }
                }
                this.loading = false
            }
        })
    }
}