import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import BizUtil from "@app/utils/biz.util";
import { WorkTaskUtil } from "@app/utils/worktask";
import { Const } from "@const/Const";
import { WorkTaskMeta } from "@wearewarp/types-server-workqueue/common";
import { ResponseListJobItem_ForTrackTrace } from "@wearewarp/types-server-workqueue/operator";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzTagModule } from 'ng-zorro-antd/tag';

interface IDisplayJobItem extends ResponseListJobItem_ForTrackTrace {
  id: string,
  code: string,
  title: string,
  statusTxt: string,
  statusClass: string,
  tasks: (WorkTaskMeta & { 
    taskName: string, 
    lastTrackTxt: string
  })[],
}

const CustomInputs = [
  CommonModule,
  NzButtonModule,
  NzTagModule
]

@Component({
  selector: '[one-route-tasks]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  standalone: true,
  imports: CustomInputs
})
export class OneRouteTasksComponent {
  private _data: ResponseListJobItem_ForTrackTrace;
  @Input() currentTaskId: string;
  @Input() set data(val: ResponseListJobItem_ForTrackTrace) {
    this._data = val;
    this.buildDisplayData();
  }
  get data() {
    return this._data;
  }
  @Output() doTask: EventEmitter<WorkTaskMeta> = new EventEmitter();
  @Output() unassignMyself: EventEmitter<WorkTaskMeta> = new EventEmitter();
  public displayData: IDisplayJobItem = null;

  constructor() {}

  buildDisplayData() {
    if (!this.data) {
      this.displayData = null;
      return;
    }

    this.displayData = {
      ...this.data,
      id: this.data.id,
      code: this.data.code,
      title: this.getTitle(),
      statusTxt: BizUtil.getStatusJob(this.data.status),
      statusClass: BizUtil.getJobStatusClass(this.data.status),
      tasks: (this.data.workTasks || []).map(it => this.buildTaskItem(it))
    }
  }

  buildTaskItem(task: WorkTaskMeta) {
    const taskName = WorkTaskUtil.isTrackTraceTask(task as any) ? `Track & Trace` : task.name ?? task.type;
    const lastTrackTxt = WorkTaskUtil.getLastTrackTxt(task as any);
    return {
      ...task,
      taskName,
      lastTrackTxt
    }
  }

  getTitle() {
    const clientInfos = this.data.clientInfos || [];
    const clientTxt = clientInfos.map((it) => it.name).join(', ');
    return `${clientTxt} - ${this.data.code}`;
  }

  isUrgent(task: WorkTaskMeta) {
    return task.priorityMask === Const.PriorityMask.urgent;
  }

  doTaskNow(task: WorkTaskMeta) {
    this.doTask.emit(task);
  }

  isCurrentTask() {
    return this.displayData.id === this.currentTaskId; 
  }

  unassignTask(task: WorkTaskMeta) {
    this.unassignMyself.emit(task);
  }
}