import { DatePipe, DecimalPipe } from "@angular/common";
import { afterNextRender, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DeliveryUpdate } from "@app/interfaces/map-v2/delivery-update";
import { DeliveryStop } from "@app/interfaces/map-v2/stop";
import * as d3 from 'd3';

@Component({
  selector: 'driver-location-chart',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  standalone: true,
  imports: [DatePipe, DecimalPipe],
})
export class DriverLocationChartComponent implements OnInit {
  _locations: DeliveryUpdate[] = []
  _timelines: DeliveryStop[] = []
  dots: any
  tooltip: any

  @Output() onHover: EventEmitter<{location?: DeliveryUpdate, timeline?: DeliveryStop} | null> = new EventEmitter()
  @Output() onClick: EventEmitter<DeliveryUpdate> = new EventEmitter()


  constructor() {
    this.initBarChart = this.initBarChart.bind(this)
    afterNextRender(() => {
        this.refreshChart()
    })
  }

  ngOnInit(): void {
  }

  _width: number = 780
  _height: number = 100
  @Input() id: string = 'chart'

  @Input() set height(v: number) {
      this._height = v
      this.refreshChart()
  }

  @Input() set width(v: number) {
    this._width = v
    this.refreshChart()
  }

  @Input() set locations(v: DeliveryUpdate[]) {
    this._locations = v
    this.refreshChart()
  }

  @Input() set timelines(v: DeliveryStop[]) {
    this._timelines = v?.filter(it => it.departureTs && it.arrivalTs) ?? []
    if (!v?.length) return
    this.refreshChart()
  }

  private createTimeline(v: DeliveryStop[], fromTs: number, toTs: number) {
    const dims = this.getDims()
    const svg = this.initBarChart()

    var x = d3.scaleTime()
        .domain([fromTs, toTs])
        .range([ 0, dims.width ]);
    var y = d3.scaleLinear()
        .domain([-10, dims.height > 80 ? 100 : 80])
        .range([ dims.height, -10]);
    
    const dots = svg.select('.timeline').node() ? svg.select('.timeline') : svg.append('g').attr('class', 'timeline')

    const color = (d) => {
      if (!d) return '#440154ff'
      if (d.status == 'canceled') return '#CD4246'
      if (d.status == 'failed') return '#CD4246'
      if (d.status == 'pickupFailed') return '#CD4246'
      return '#21a06dff'
    }

    dots.selectAll('rect')
        .data(v)
        .join('rect')
        .attr("x", function (d) { return x(d.arrivalTs); } )
        .attr("y", y(-6))
        .attr('height', 6)
        .attr('rx', 3)
        .attr('width', function (d) { return Math.max(5, x(d.departureTs) - x(d.arrivalTs)); })
        .style("stroke", function (d) { return color(d) } )
        .style('fill', function (d) { return color(d) })
        .style('fill-opacity', 0.7)
        .style("stroke-width", 1 )
  }

  private getDims() {
    const margin = {top: 5, right: 10, bottom: 16, left: 30}
    const width = this._width - margin.left - margin.right;
    const height = this._height - margin.top - margin.bottom;
    return {
      width, height, margin
    }
  }
  private getXaxis(svg) {
    const dims = this.getDims()
    const xaxis = svg.select('.xaxis').node() ? svg.select('.xaxis') : svg.append("g")
        .attr('class', 'xaxis')
    xaxis.attr("transform", "translate(0," + dims.height + ")")
    return xaxis
  }

  private refreshChart() {
    if (!this._locations) return
    this.createBarChart(this._locations)
  }

  private createBarChart(v: DeliveryUpdate[]) {
    const dims = this.getDims()
    const margin = {top: 5, right: 10, bottom: 16, left: 30}
    const svg = this.initBarChart()

    const xaxis = this.getXaxis(svg)

    var x = d3.scaleTime()
        .domain([v?.[0]?.ts ?? 0, v?.[v.length - 1]?.ts ?? 0])
        .range([ 0, dims.width ]);
    const tickCount = Math.round(dims.width / 80)
    xaxis.call(d3.axisBottom(x).tickFormat(d3.timeFormat("%H:%M")).ticks(tickCount));

    var y = d3.scaleLinear()
        .domain([-10, dims.height > 60 ? 100 : 80])
        .range([ dims.height, 0]);

    const color = (d) => {
        if (!d) return '#440154ff'
        if (d > 60) return '#21a06dff'
        if (d > 40) return '#2150adff'
        if (d > 20) return '#fde725ff'
        if (d > 10) return '#fd2745ff'
        return '#440154ff'
    }

    // tooltip
    const chart = d3.select(`.${this.id}`)
    const tooltipLineX = svg.select('.tooltip-line').node() ? svg.select('.tooltip-line') : svg.append('line')
        .attr('class', 'tooltip-line')
        .attr('stroke', 'red')
        .attr('display', 'none')
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', '3,2')
    tooltipLineX.attr('y1', 0).attr('y2', dims.height + 10)

    const onClick = this.onClick

    if (this._timelines)
      this.createTimeline(this._timelines, v?.[0]?.ts ?? 0, v?.[v.length - 1]?.ts ?? 0)

    var newX = x
    const updateChart = (event) => {
      // recover the new scale
      newX = event.transform.rescaleX(x);
  
      // update axes with these new boundaries
      xaxis.call(d3.axisBottom(newX).tickFormat(d3.timeFormat("%H:%M")).ticks(tickCount))
      svg
        .selectAll("circle")
        .attr('cx', function(d) {return newX(d.ts)})
      svg
        .selectAll("rect")
        .attr('x', function(d) {return newX(d.arrivalTs)})
        .attr('width', function (d) { return Math.max(5, newX(d.departureTs) - newX(d.arrivalTs)); })
    }

    const locate = (event) => {
      const bisectTs = d3.bisector(d => d.ts).center
      const x0 = newX.invert(event.offsetX - margin.left)
      const x1 = newX.invert(event.offsetX - margin.left + 5)
      const i = bisectTs(v, x0, 1)
      const d = v[i]
      if (!d) {
          return null
      }
      const d0 = newX(d.ts)
      if (Math.abs((event.offsetX - margin.left) - d0) > 2) {
          return null
      }
      return d
    }

    var click = function(event) {
      const d = locate(event)
      if (d)
      onClick.emit(d)
    }

    chart.on('mousemove', (event) => {
      const x0 = newX.invert(event.offsetX - margin.left)
      const x1 = newX.invert(event.offsetX - margin.left + 3)
      const d = locate(event)

      tooltipLineX.style('display', 'block')
          .attr('x1', event.offsetX - margin.left).attr('x2', event.offsetX - margin.left)

      const timeline = this._timelines.filter(it => it.arrivalTs <= x0 && x0 <= Math.max(x1 - x0 + it.arrivalTs, it.departureTs))?.[0]

      if (!d && !timeline) {
        this.onHover.emit(null)
        return
      }
      if (!d) {
        this.onHover.emit({timeline})
        return
      }
      const d0 = newX(d.ts)
      if (Math.abs((event.offsetX - margin.left) - d0) > 2) {
        this.onHover.emit(null)
        return
      }

      this.onHover.emit({timeline, location: d})
    })
    chart.on('mouseleave', (event) => {
      tooltipLineX.style('display', 'none')
      this.onHover.emit(null)
    })
    chart.on("click", click)

    var zoom = d3.zoom()
        .scaleExtent([1, 20])  // This control how much you can unzoom (x0.5) and zoom (x20)
        .extent([[0, dims.height], [dims.width, dims.height]])
        .on("zoom", updateChart);
    chart.call(zoom)

    const dots = svg.select('.dot').node() ? svg.select('.dot') : svg.append('g').attr('class', 'dot')
    dots.selectAll('circle')
        .data(v)
        .join('circle')
        .attr("cx", function (d) { return x(d.ts); } )
        .attr("cy", function (d) { return y(d.location?.speed ?? 0); } )
        .attr("r", dims.width > 600 ? 3 : 2)
        .style("fill", function (d) { return color(d.location.speed) } )
  }

  private initBarChart() {
    // calculate width
    const dims = this.getDims()
    const chart = d3.select(`.${this.id}`)
        .style("pointer-events", "all")
    const svg = chart.select('svg').node() ? chart.select('svg').select('g') : chart.append("svg")
        .append("g")
        .attr("transform", "translate(" + dims.margin.left + "," + dims.margin.top + ")");

    chart.select('svg')
        .attr("width", dims.width + dims.margin.left + dims.margin.right)
        .attr("height", dims.height + dims.margin.top + dims.margin.bottom)

    // Add Y axis
    var y = d3.scaleLinear()
        .domain([-10, dims.height > 60 ? 100 : 80])
        .range([ dims.height, 0]);
    const yaxis = chart.select('.yaxis').node() ? chart.select('.yaxis') : svg.append("g")
        .attr('class', 'yaxis')
    yaxis.call(d3.axisLeft(y).ticks(dims.height > 80 ? 5 : 4));

    // Tooltip
    return svg
}
}
