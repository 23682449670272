import { AddressUS, LatLng, LatLngShort } from "@wearewarp/types/data-model";
import { LatLngLike } from "@app/interfaces/map-v2/latlng";


export function getMetadata(s: {metadata?: any}, k: string) {
  if (!s?.metadata) return undefined
  return s.metadata[k]
}

export function setMetadata(s: {metadata?: any}, key: string, value: any) {
  if (!s.metadata) {
    s.metadata = {}
  }
  s.metadata[key] = value
}

export function isSameLocation(loc1: LatLngLike, loc2: LatLngLike): boolean {
  const d = distance(loc1, loc2)
  return d < 100
}

export function distance(loc1: LatLngLike, loc2: LatLngLike): number {
  // should be Haversine
  const R = 6378000
  const lat = (getLatitude(loc1) ?? 0) - (getLatitude(loc2) ?? 0)
  const lng = (getLongitude(loc1) ?? 0) - (getLongitude(loc2) ?? 0)
  const d2 = lat * lat + lng * lng
  const d = Math.sqrt(d2) * R
  return d
}

export function getLatitude(latlng: LatLngLike): number | null {
  if ((<LatLng>latlng).latitude) {
      return (<LatLng>latlng).latitude
  }
  if ((<LatLngShort>latlng).lat) {
      return (<LatLngShort>latlng).lat
  }
  return (<number[]>latlng)[0]
}

export function getLongitude(latlng: LatLngLike): number {
  if ((<LatLng>latlng).longitude) {
      return (<LatLng>latlng).longitude
  }
  if ((<LatLngShort>latlng).lng) {
      return (<LatLngShort>latlng).lng
  }
  return (<number[]>latlng)[1]
}

export function getLngLat(latlng: LatLngLike): number[] {
  return [
    getLongitude(latlng),
    getLatitude(latlng)
  ]
}

export function latLngId(latLng: LatLng) {
  if (!latLng?.latitude || !latLng?.longitude) return 'LOC_0::0'
  return `LOC_${Math.round(latLng?.latitude * 10000)}:${Math.round(latLng?.longitude * 10000)}`
}

export function latLngId2(latlng: LatLngShort) {
  const { lat, lng } = latlng || {}
  if (!lat || !lng) return 'LOC_0::0'
  return `LOC_${Math.round(lat * 10000)}:${Math.round(lng * 10000)}`
}

export function getLocId(latlng: LatLngLike) {
  if ((<LatLng>latlng).latitude) {
      return latLngId(<LatLng>latlng)
  }
  if ((<LatLngShort>latlng).lat) {
      return latLngId2(<LatLngShort>latlng)
  }
  return `LOC_${Math.round(latlng[0] * 10000)}:${Math.round(latlng[1] * 10000)}`
}

export function getAddrLatLng(addr: AddressUS): LatLngLike | null {
  return {
      latitude: addr.metadata?.latitude,
      longitude: addr.metadata?.longitude
  }
}

function hex2decimal(s: string): number {
  let v = 0
  const A = 'A'.charCodeAt(0)
  const zero = '0'.charCodeAt(0)
  for (let i = 0; i < s.length; i++) {
      const c = s.charCodeAt(i)
      if (c >= A) {
          v = v * 16 + (10 + c - A)
      } else {
          v = v * 16 + (c - zero)
      }
  }
  return v
}

export function hexColor2array(h: string) {
  const s = h.startsWith("#") ? h.substring(1).toUpperCase() : h.toUpperCase()
  let r,g,b: string
  if (s.length == 6) {
      r = s.substring(0, 2)
      g = s.substring(2, 4)
      b = s.substring(4)
  } else  if (s.length  == 3) {
      r = s.substring(0, 1)
      g = s.substring(1, 2)
      b = s.substring(2)
      r = r + r
      g = g + g
      b = b + b
  }
  return [hex2decimal(r), hex2decimal(g), hex2decimal(b)]
}

export function getDisplayTime(
  epoch: number | string,
  dateOption: Intl.DateTimeFormatOptions = {dateStyle: 'short'},
  timeOption: Intl.DateTimeFormatOptions = {timeStyle: 'short'},
  locale: string | null = 'en-us',
  timeOnly: boolean = false
) {
  const date = typeof epoch == 'number' ? new Date(epoch * 1000) : new Date(epoch)
  if (timeOnly)
      return date.toLocaleTimeString(locale ?? 'en-us', timeOption ?? {timeStyle: 'short'})
  return date.toLocaleDateString(locale ?? 'en-us', dateOption ?? {dateStyle: 'short'})
      + ' ' + date.toLocaleTimeString(locale ?? 'en-us', timeOption ?? {timeStyle: 'short'})
}

export function copyTextToClipboard(text: string): Promise<any> {
  if (!navigator.clipboard) {
      return fallbackCopyTextToClipboard(text);
  }
  return navigator.clipboard.writeText(text).then(() => {
      return null
  }, function (err) {
      return err
  });
}

export function fallbackCopyTextToClipboard(text: string): Promise<any> {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
      var successful = document.execCommand('copy');
      document.body.removeChild(textArea);
      return Promise.resolve(successful ? null : "document.execCommand('copy') failed.");
  } catch (err) {
      document.body.removeChild(textArea);
      return Promise.resolve(err)
  }
}