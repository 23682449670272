<div class="stop-tasks">
  <nz-table #basicTable [nzData]="stopTasks" nzBordered [nzShowPagination]="false" [nzLoading]="shipments?.length === 0">
    <thead>
      <tr>
        <th>Shipment</th>
        <th>Reference Numbers</th>
        <th>Status</th>
        <!-- <th>POD</th> -->
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let task of stopTasks">
        <tr class="clickable-row">
          <td>
            <a ngClass="shipment-code" [href]="getShipmentDetailUrl(task)" target="_blank" (click)="$event.stopPropagation()">
              {{ getShipmentCode(task) }}
            </a>
          </td>
          <td>
            <nz-tag *ngFor="let refNum of getRefNums(task)" nzSize="small">{{ refNum }}</nz-tag>
          </td>
          <td>
            <nz-tag [nzColor]="getStatusColor(task.status)" (click)="onStatusClick(task); $event.stopPropagation()">{{ getStatusText(task.status) }}</nz-tag>
          </td>
          <!-- <td>
            <nz-badge [nzCount]="totalPods" [nzOverflowCount]="9" (click)="viewPOD(task); $event.stopPropagation()">
              <button nz-button nzSize="small">View POD {{ getTotalPods() }}</button>
            </nz-badge>
          </td> -->
        </tr>
        <tr [nzExpand]=true>
            <app-shipment-items [shipmentId]="task.shipmentId" (reportMissing)="onReportMissing($event)"></app-shipment-items>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</div>