@if (code) {
<warp-code [theme]="_task?.status ?? ''" style="font-size: 13px; line-height: 13px;; margin-right: 3px; margin-left: 2px; vertical-align: text-top;" [code]="code">
    <ng-template popup-content>
        <table>
            <tr>
                <td>{{ _task?.type }}</td>
                <td>
                    <warp-status [status]="_task?.status ?? ''"></warp-status>
                    @if (stopInd) {
                        at stop #{{ stopInd }}
                    }
                </td>
            </tr>
            <tr>
                <td>{{ correspondingTask?.type }}</td>
                <td>
                    <warp-status [status]="correspondingTask?.status ?? ''"></warp-status>
                    @if (correspondingStop) {
                        at stop #{{ correspondingStop }}
                    }
                </td>
            </tr>
            @if (shipment?.status) {
                <tr>
                    <td>Shipment</td>
                    <td>
                        <warp-status [status]="shipment?.status!!"></warp-status>
                    </td>
                </tr>
            } 
            <tr>
                <td style="padding-right: 8px;">Old Warp ID</td>
                <td>{{ shipment?.warpId }}</td>
            </tr>
            <tr>
                <td style="padding-right: 8px;">Customer</td>
                <td>{{ client?.name }}</td>
            </tr>
            @if (refNums?.length) {
            <tr>
                <td style="padding-right: 8px;">Reference</td>
                <td>
                    <div style="max-width: 400px;">
                        @for (ref of refNums; track $index) {
                            <code class="ref">{{ ref }}</code>
                        }
                    </div>
                </td>
            </tr>
            }
            @if (_task?.type == 'PICKUP' && dropoff?.addr) {
                <tr>
                    <td>Deliver To</td>
                    <td>
                        <div>
                            <i nz-icon nzType="home" class="right5"></i>
                            @if (correspondingStop) { <span class="right-3">[Stop {{ correspondingStop }}]</span> }
                            {{ dropoff?.locationName }}
                        </div>
                        <div>
                            <i class="fa fa-map right5" style="color: #888888;"></i>
                            <us-address mode="full" [address]="dropoff?.addr!!"></us-address>
                        </div>
                    </td>
                </tr>
            }
            @if (_task?.type == 'DROPOFF' && pickup?.addr) {
                <tr>
                    <td>Pickup From</td>
                    <td>
                        <div>
                            <i nz-icon nzType="home" class="right5"></i>
                            @if (correspondingStop) { <span class="right-3">[Stop {{ correspondingStop }}]</span> }
                            {{ pickup?.locationName }}
                        </div>
                        <div><us-address mode="full" [address]="pickup?.addr!!"></us-address></div>
                    </td>
                </tr>
            }
        </table>
    </ng-template>
</warp-code>
}
