import { Utils } from '../services/utils';
import { Component } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { Const } from "@const/Const";
import { Log } from "@services/log";
import { BaseDrawer } from "./base-drawer";
import { Subscription } from 'rxjs';
import { NotificationDispatchService } from "./notification-dispatch.service";
import { DateUtil } from '@services/date-util';
import { ScrollTrackerDirective } from './scroller.directive';

@Component({
  selector: '[notification-dispatch]',
  templateUrl: './notification-dispatch.html',
  styleUrls: ['notification-dispatch.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NzIconModule,
    NzButtonModule,
    ScrollTrackerDirective
  ]
})
export class NotificationDispatch extends BaseDrawer {
  isLoading = false;
  isLoadingNext = false;
  protected subApi: Subscription;
  protected notifyDispathService: NotificationDispatchService;
  public listNotifications = [];
  public loadedAllData = false;
  public groupNotifications = [];

  constructor() {
    super();
    this.notifyDispathService = this.injector.get(NotificationDispatchService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isLoading = true;
    this.getNotifications();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subApi?.unsubscribe();
  }

  public getNotifications() {
    if(this.loadedAllData) return;

    this.isLoadingNext = true;
    this.subApi?.unsubscribe();
    this.subApi = this.api.GET(`${Const.APIURI_JOB_NOTIFICATIONS}/?skip=${this.listNotifications.length}&limit=30`).subscribe(
      resp => {
        Log.d('get list data done: ', resp);
        this.getDataDone(resp);
        this.isLoading = false
        this.isLoadingNext = false;
      }, err => {
        Log.e(err);
        this.isLoading = false
        this.isLoadingNext = false;
      }
    );
  }
  protected getDataDone(resp) {
    this.listNotifications = [...this.listNotifications, ...resp.data.list_data];
    if(this.listNotifications.length == resp.data.total || resp.data.list_data.length == 0) this.loadedAllData = true;
    for (let item of this.listNotifications) {
      this.processItem(item);
    }
    let todayArr = [];
    let thisWeekArr = [];
    let olderArr = [];
    let beginToday = DateUtil.toBeginOfDay(new Date()).toISOString();
    let beginThisWeek = DateUtil.toBeginOfDay(DateUtil.toBeginOfThisWeek()).toISOString();
    let unReadNotificationNumber = 0;
    for (let item of this.listNotifications) {
      if (!item.read) unReadNotificationNumber++;
      if (item.insert.when >= beginToday) {
        todayArr.push(item);
      } else if (item.insert.when >= beginThisWeek) {
        thisWeekArr.push(item);
      } else {
        olderArr.push(item);
      }
    }
    this.groupNotifications = [];
    if (Utils.isArrayNotEmpty(todayArr)) {
      this.groupNotifications.push({ groupName: 'TODAY', listData: todayArr})
    }
    if (Utils.isArrayNotEmpty(thisWeekArr)) {
      this.groupNotifications.push({ groupName: 'THIS WEEK', listData: thisWeekArr})
    }
    if (Utils.isArrayNotEmpty(olderArr)) {
      this.groupNotifications.push({ groupName: 'OLDER', listData: olderArr})
    }
    this.notifyDispathService.setNotificationsNumber(unReadNotificationNumber);
  }

  protected processItem(item) {
    //nếu các item có contentNotification thì là item cũ, không cần xử lý nữa.
    if(item.contentNotification) return;

    let routeLabel = this.getHyperLinkTextForJob(item.job, item.clients);
    item.hyperLinkUrl = `${Const.routeAdminDispatchList}/${item.job._id}`;
    if (item.type == Const.JobNotificationType.needCarrier) {
      item.contentNotification = `${routeLabel} needs Carrier.`;
    } else if (item.type == Const.JobNotificationType.assignPIC) {
      item.contentNotification = `${routeLabel} has been assigned to you.`;
    } else if (item.type == Const.JobNotificationType.delayed) {
      let toTime = item.info?.timeWindow?.to;
      let taskType = item.info?.type;
      if (toTime) {
        let timeDelayed =  this.getDelayedTime(new Date().getTime() - new Date(toTime).getTime());
        item.contentNotification = `${routeLabel} is delayed ${taskType} by ${timeDelayed}.`;
      } else {
        item.contentNotification = `${routeLabel} is delayed ${taskType}.`;
      }
    } else if (item.type == Const.JobNotificationType.needPOD) {
      let orderId = item.info?.order_id || '';
      item.contentNotification = `Shipment ${orderId} (${routeLabel}) is missing POD.`;
    } else if (item.type == Const.JobNotificationType.podAvailableReview) {
      let orderId = item.info?.order_id || '';
      item.contentNotification = `Shipment ${orderId} POD available for review.`;
    }
    let createTime = item.insert?.when;
    if (createTime) {
      let timeAgo =  new Date().getTime() - new Date(createTime).getTime();
      item.timeAgo = this.getNotificationTimeAgo(timeAgo);
    }
  }

  private getHyperLinkTextForJob(job: any, clients: any) {
    let firstOrder = job.orders[0];
    let clientName = clients[0].name;
    let hyperLinkText = `${clientName} / ${firstOrder.id}`;
    if (job.orders.length > 1) {
      hyperLinkText += ` (+${job.orders.length-1})`;
    }
    hyperLinkText += ` / Route ${job.label}`;
    return hyperLinkText;
  }

  public gotoRouteDetail(item) {
    if (!item.read) {
      this.subApi?.unsubscribe();
      this.subApi = this.api.POST(`${Const.APIURI_JOB_NOTIFICATIONS}/${item._id}/read`).subscribe(
        resp => {
          let unReadNotificationNumber = resp.data.unReadNotificationNumber;
          this.notifyDispathService.setNotificationsNumber(unReadNotificationNumber);
        }, err => {
          Log.e(err);
        }
      );
    }
    this.closeDrawer();
  }

  public markAllAsRead() {
    this.subApi?.unsubscribe();
    this.subApi = this.api.POST(`${Const.APIURI_JOB_NOTIFICATIONS}/mark_all_as_read`).subscribe(
      resp => {
        let unReadNotificationNumber = resp.data.unReadNotificationNumber;
        this.notifyDispathService.setNotificationsNumber(unReadNotificationNumber);
        this.getNotifications();
      }, err => {
        Log.e(err);
      }
    );
  }

  private getNotificationTimeAgo(lateTimeMillis) {
    let timeValue = Math.floor(lateTimeMillis/60000)
    if (timeValue <= 0) {
      return 'now';
    }
    let txt = '';
    let minutes = timeValue;
    let hours = Math.floor(minutes / 60);
    if (hours > 24) {
      let days = Math.floor(hours / 24);
      txt = `${days} day${days > 1 ? 's' : ''}`;
    } else if (hours > 0) {
      txt = `${hours} hour${hours > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
      txt = `${minutes} min${minutes > 1 ? 's' : ''}`;
    }
    return `${txt} ago`;
  }

  private getDelayedTime(lateTimeMillis) {
    let timeValue = Math.floor(lateTimeMillis/60000)
    if (timeValue <= 0) {
      return '';
    }
    let txt = '';
    let minutes = timeValue;
    let hours = Math.floor(minutes / 60);
    if (hours > 0) {
      txt = `${hours} hour${hours > 1 ? 's' : ''}`;
      minutes = minutes - hours * 60;
    }
    if (minutes > 0) {
      if (txt.length > 0) {
        txt += ' ';
      }
      txt += `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    return txt;
  }

  public get isEmptyNotifications() {
    return this.listNotifications.length == 0;
  }
}
