import { Component, Input } from '@angular/core';

@Component({
    selector: 'duration',
    template: '{{ display }}',
    imports: [],
    standalone: true
})
export class DurationComponent {
    _seconds: number = 0
    days: number = 0
    hours: number = 0
    minutes: number = 0
    display: string | null = null
    @Input() set seconds(v: number) {
        this._seconds = v
        const seconds = v % 60
        const totalMinutes = (v - seconds) / 60
        this.minutes = totalMinutes % 60
        const totalHours = (totalMinutes - this.minutes) / 60
        this.hours = totalHours % 24
        this.days = (totalHours - this.hours) / 24
        const components: string[] = []
        if (this.days) {
            components.push(`${this.days}d`)
        }
        if (this.hours | this.days) {
            components.push(`${this.hours}h`)
        }
        if (this.minutes) {
            components.push(`${this.minutes}m`)
        }
        this.display = components.join(' ')
    }
}