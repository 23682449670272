import { CommonModule, NgTemplateOutlet } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BaseFormDialog } from "@app/dialogs/base-form-dialog";
import { ClientService } from "@app/track-trace/services/client.service";
import { environment } from "@env/environment";
import { UIHelper } from "@services/UIHelper";
import { ClientMeta } from "@wearewarp/types/data-model";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzSelectModule } from "ng-zorro-antd/select";
import BizUtil from '@app/utils/biz.util';
import { Const } from "@const/Const";
import { TrackTraceService } from "@app/track-trace/services/track-trace.service";
import { DialogService } from "@app/dialogs/dialog.service";
import { CreateTrackTraceTaskModal, ICreateTrackTraceTaskModalData } from "../create-task";
import { ResponseListJobItem_ForTrackTrace } from "@wearewarp/types-server-workqueue/operator";
import { WorkTaskUtil } from "@app/utils/worktask";
import { map, of } from "rxjs";
import { WhenByService } from '@app/track-trace/services/when-by.service';

const CustomInputs = [
  CommonModule,
  NgTemplateOutlet,
  FormsModule,
  ReactiveFormsModule,
  NzModalModule,
  NzFormModule,
  NzInputModule,
  NzSelectModule,
  NzDatePickerModule,
  NzButtonModule,
  NzIconModule,
]

interface IDisplayJobItem extends ResponseListJobItem_ForTrackTrace {
  id: string,
  code: string,
  title: string,
  statusTxt: string,
  statusClass: string,
  desc: string
}

@Component({
  selector: 'route-filter-modal',
  standalone: true,
  imports: CustomInputs,
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class RouteFilterModal extends BaseFormDialog {
  constructor(
    private trackTraceService: TrackTraceService,
    private clientService: ClientService,
    private whenByService: WhenByService
  ) {
    super({});
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    searchTxt: { label: 'Search', placeHolder: 'Search by Route/ Shipment/ Ref', type: 'string' },
    clientIds: { label: 'Customers', placeHolder: 'Select customers', type: 'string' }
  }

  public isSearching: boolean = false;
  public clients: ClientMeta[] = [];
  public listData: ResponseListJobItem_ForTrackTrace[] = [];
  public displayData: IDisplayJobItem[] = [];

  ngOnInit(): void {
    this.clientService.getAllClients().subscribe((clients) => {
      this.clients = clients;
    });
    super.ngOnInit();
  }

  doSearch() {
    const data = this.getFormData_JSON(true);
    let url = `${environment.backendWorkQueueUrl}/v1/jobs/list-for-tracktrace`;
    let params: any = {
      search: data.searchTxt ?? '',
      filter: {}
    };
    if (data.clientIds && data.clientIds.length > 0) { 
      params.filter.clientIds = data.clientIds;
    }
    this.isSearching = true;
    this.api.POST(url, params).subscribe({
      next: (res: any) => {
        this.listData = res.data?.list_data;
        this.buildDisplayData();
        this.model = data;
        this.isSearching = false;
      },
      error: (err: any) => {
        this.isSearching = false;
        UIHelper.showErr(err);
      }
    })
  }

  buildDisplayData() {
    this.displayData = this.listData.map((item) => {
      const displayItem = {
        ...item,
        id: item.id,
        code: item.code,
        title: this.getTitle(item),
        statusTxt: BizUtil.getStatusJob(item.status),
        statusClass: this.getStatusClass(item.status),
        desc: this.getDesc(item),
        lastTrackTxt: ''
      };
      
      this.buildLastTrackTxt(displayItem).subscribe(text => {
        displayItem.lastTrackTxt = text;
      });
      
      return displayItem;
    });
  }

  buildLastTrackTxt(item: IDisplayJobItem) {
    const task = this.getTaskFocusOnRoute(item) as any;
    if (!task) { return of(''); }
    const lastTrackTxt = WorkTaskUtil.getLastTrackTxt(task as any);
    if (!lastTrackTxt) { return of(''); }
    const userIds: {entityId: string, entity: string}[] = [{entityId: task?.lastTrack?.byId, entity: task?.lastTrack?.collection}];

    return this.whenByService.getListWhenBy(userIds).pipe(
      map(users => {
        let userName = '';
        if (users && users.length > 0) {  
          userName = users[0].name;
        }
        return `${lastTrackTxt} by ${userName}`;
      })
    );
  }

  getTitle(item: ResponseListJobItem_ForTrackTrace) {
    const clientInfos = item.clientInfos || [];
    const clientTxt = clientInfos.map((it) => it.name).join(', ');
    return `${clientTxt} - ${item.code}`;
  }

  getDesc(item: ResponseListJobItem_ForTrackTrace) {
    return '';
  }

  getStatusClass(status: string) {
    if (!status) 
      status = 'created';
    return Const.JobStatus[status];
  }

  shouldShowSeparator(index: number) {
    return index > 0;
  }

  isInputChanged() {
    return this.isFormDataChanged();
  }

  goToRoute(item: IDisplayJobItem) {
    const jobId = item.id;
    const task = this.getTaskFocusOnRoute(item);
    if (!task) {
      UIHelper.showErr(`No task found for this route ${jobId}`);
      return;
    }
    if (WorkTaskUtil.isTrackTraceTask(task as any)) {
      this.trackTraceService.getTaskFocusOnRoute(jobId);
    }
    else {
      this.trackTraceService.setSpecificTask(task.id);
    }
  }

  private getTaskFocusOnRoute(item: IDisplayJobItem) {
    const tasks = item.workTasks;
    const task = tasks.find(it => it.type === Const.WorkRouteTask.enrouteTracking)
    return task ?? tasks[0] ?? null;
  }

  openCreateTaskModal(item: IDisplayJobItem) {
    const data: ICreateTrackTraceTaskModalData = {
      jobId: item.id,
      code: item.code,
      status: item.status,
      jobTitle: item.title
    }
    DialogService.openFormDialog(CreateTrackTraceTaskModal, {
      nzData: data,
      nzMaskClosable: true,
      nzFooter: null,
      nzTitle: null,
    })
  }
}
  