import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { TrackTraceService } from '../../services/track-trace.service';
import { Const } from '@const/Const';
import { Client, Job, Shipment } from '@wearewarp/types/data-model';
import { CommonModule } from '@angular/common';
import { getVehicleText } from '@app/utils/getVehicleText';
import { JobLiveIconComponent } from '../dispatch-live-tracking/job-live-icon';
import { DateUtil } from '@services/date-util';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { WarpId } from '@wearewarp/universal-libs/src/WarpId';
import { Utils } from '@services/utils';
import { UIHelper } from '@services/UIHelper';
import { BaseComponent } from '@abstract/BaseComponent';
import { RouterModule } from '@angular/router';
import { environment } from '@env/environment';

export interface HyperLinkObject {
  hyperLinkText: string,
  hyperLinkUrl: string,
}

@Component({
  selector: 'app-load-title',
  templateUrl: './load-title.component.html',
  styleUrls: ['./load-title.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, NzTagModule, NzIconModule, NzToolTipModule, JobLiveIconComponent]
})
export class LoadTitleComponent extends BaseComponent implements OnInit, AfterViewInit {
  loadInfo: Job;
  shipments: Shipment[] = [];
  displayInfo: any = {};
  jobHyperLink: HyperLinkObject;
  isSticky: boolean = false;

  @ViewChild('jobLink') jobLink: ElementRef<HTMLAnchorElement>;
  @ViewChild('loadTitleContainer') loadTitleContainer: ElementRef;

  constructor(private trackTraceService: TrackTraceService) {
    super();
  }

  setEmptyData() {
    this.loadInfo = null;
    this.displayInfo = {};
  }

  ngOnInit() {
    this.trackTraceService.getLoadInfo().subscribe(info => {
      this.onLoadInfoChange(info);
    });
  }

  ngAfterViewInit() {
    this.setupStickyObserver();
  }

  private setupStickyObserver() {
    const options = {
      threshold: [1.0],
      rootMargin: '-1px 0px 0px 0px'  // Ngưỡng 1px để phát hiện khi element bắt đầu sticky
    };

    const observer = new IntersectionObserver(([entry]) => {
      this.isSticky = !entry.isIntersecting;
    }, options);

    if (this.loadTitleContainer) {
      observer.observe(this.loadTitleContainer.nativeElement);
    }
  }

  onLoadInfoChange(job: Job | null) {
    this.setEmptyData();
    this.loadInfo = job;
    this.buildDisplayInfo();
  }

  buildDisplayInfo() {
    this.jobHyperLink = this.getHyperLink(this.router.url);
    this.displayInfo = {
      clientName: this.getClientName(this.loadInfo?.clients),
      loadCode: this.loadInfo?.code || '',
      routeStatus: this.getStatusText(this.loadInfo?.status),
      pickupLocation: this.getFirstPickupLocation(),
      deliveryLocation: this.getLastDeliveryLocation(),
      totalStops: this.getTotalStops(),
      totalMiles: this.getTotalMiles(),
      trafficTime: this.getTrafficTime(),
      vehicleType: this.getVehicleType(),

      isDriverOnline: this.checkDriverOnline(),

      onHold: this.getOnHold(),
      lineHaul: this.getLineHaul(),
    };
  }

  checkDriverOnline() {
    let isDriverOnline = false;
    if (this.loadInfo?.lastUpdateLocation) {
      const lastUpdateLocation = (Date.now() - DateUtil.isoDate(this.loadInfo.lastUpdateLocation.when).getTime()) / 1000;
      isDriverOnline = lastUpdateLocation <= 60;   // update không quá 1 phút coi như online
    }
    return isDriverOnline;
  }

  getStatusClass(status: string) {
    if (!status)
      status = 'created';
    return Const.JobStatus[status];
  }

  getStatusText(status: string) {
    return Const.getJobStatusText(status);
  }

  getClientName(clients: Client[]) {
    if (!clients || clients.length === 0) return '';
    return clients.map(client => client.name).join(', ');
  }

  getFirstPickupLocation() {
    const firstPickup = this.loadInfo?.metadata?.firstPickInfo?.addr
    return firstPickup?.city + ', ' + firstPickup?.state;
  }

  getLastDeliveryLocation() {
    const lastDropInfo = this.loadInfo?.metadata?.lastDropInfo?.addr
    return lastDropInfo?.city + ', ' + lastDropInfo?.state;
  }

  getTotalStops() {
    return this.loadInfo?.stops?.length || 0;
  }

  getVehicleType() {
    // TODO: get vehicle text include service options
    if (!this.loadInfo?.requiredVehicle) return 'N/A';
    return getVehicleText([this.loadInfo?.requiredVehicle]);
  }

  getTrafficTime() {
    return Math.round((this.loadInfo?.totalTime / 3600) * 10) / 10 + ' hrs' || '';
  }

  getTotalMiles() {
    return Math.round(this.loadInfo?.totalDistance / 1609.34) + ' mi' || '';
  }

  getOnHold() {
    const shipmentIds = this.loadInfo?.shipments?.map(it => it.id) || [];

    this.trackTraceService.getShipments(shipmentIds).subscribe(shipments => {
      const onHold = shipments.filter(it => it.tags && it.tags.indexOf('HOLD') >= 0);
      this.displayInfo.onHold = onHold.map(it => this.getCodeText(it));
    });
  }

  getCodeText(shipment: Shipment) {
    return WarpId.showShipmentCode(shipment);
  }

  getLineHaul(): boolean {
    return this.loadInfo?.type === Const.JobType.linehaul;
  }

  onBtnCopyRouteLink() {
    Utils.selectAll(this.jobLink.nativeElement);
    document.execCommand('copy');
    UIHelper.notification.create('success', '', 'The link has been copied.');
  }

  getHyperLink(url: string = null): HyperLinkObject {
    if (!this.loadInfo) return;

    const shipmentIds = this.loadInfo.shipments.map(item => item.id);

    this.trackTraceService.getShipments(shipmentIds).subscribe(shipments => {
      let firstShipment = shipments[0];
      let hyperLinkUrl;
      // let hyperLinkUrl = url?.split('?')?.[0];
      const clients = this.loadInfo.clients;
      const clientName = clients?.[0]?.name || 'Customer N/A';
      let hyperLinkText = `${clientName} / ${firstShipment.code}`;
      if (shipments.length > 1) {
          hyperLinkText += ` (+${shipments.length - 1})`;
      }
      hyperLinkText += ` / Route ${this.loadInfo.code}`;
      if (!hyperLinkUrl) {
        hyperLinkUrl = `${environment.webAdminUrl}${Const.routeAdminDispatchList}/${this.loadInfo.id}`;
      }
      this.jobHyperLink = { hyperLinkText, hyperLinkUrl };
    });
  }
}