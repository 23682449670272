<div>
  <div class="title">
    <div class="text-title">{{ displayData?.title }}</div>
    <div class="job-status-box"><span class="job-status" [ngClass]="displayData?.statusClass">{{ displayData?.statusTxt }}</span></div>
  </div>
  
  <div class="list-data top20">
    <div class="list-item" *ngFor="let item of displayData?.tasks || []; let i = index">
      <div class="item-info">
        <div class="right10" *ngIf="isUrgent(item)">
          <img src="assets/svg/urgent_icon.svg" class="urgent-icon" />
        </div>
        <div class="item-container">
          <div class="item-content">
            <div class="item-title">{{ item?.taskName }}</div>
            <div *ngIf="item.lastTrackTxt" class="text-secondary top10">{{ item.lastTrackTxt }}</div>
            <div class="item-note top10">{{ item?.note || '' }}</div>
          </div>
          <div class="item-actions">
            <span *ngIf="!isCurrentTask()" class="unassign-text" (click)="unassignTask(item)">
              Release task
            </span>         
            <nz-tag nzColor="#108ee9" *ngIf="isCurrentTask()" class="working-text">Working</nz-tag>
            <button nz-button nzType="default" nzTheme="outline" (click)="doTaskNow(item)">
              Do now
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>