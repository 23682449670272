import { NgTemplateOutlet } from "@angular/common";
import { Component, ContentChild, Directive, HostListener, Input, OnDestroy, TemplateRef } from "@angular/core";
import { fromEvent, Subscription } from "rxjs";

@Directive({
  selector: '[popup-content]',
  standalone: true
})
export class PopupContentDirective {
  constructor(public templateRef: TemplateRef<unknown>) {
  }
}

@Component({
    selector: '[popup-wrapper]',
    templateUrl: 'index.html',
    styleUrl: 'index.scss',
    imports: [
        NgTemplateOutlet
    ],
    standalone: true
})
export class PopupComponent implements OnDestroy {
  popup: boolean = false
  _pointerLoc?: number[]
  locSubscription: Subscription
  frozen: boolean = false
  position: string = 'below'
  left: number = 0
  top: string = 'auto'
  bottom: string = 'auto'
  @Input() minHeight: number = 120
  @Input() pinnable?: string
  @ContentChild(PopupContentDirective) content: PopupContentDirective | null = null;

  @Input() set pointerLoc(v: number[]) {
      this._pointerLoc = v
      if (v[1] + this.minHeight > v[3]) {
          this.position = 'above'
      } else {
          this.position = 'below'
      }
  }

  constructor(
  ) {
      this.onLocationChanged = this.onLocationChanged.bind(this)
  }
  ngOnDestroy(): void {
      this.locSubscription?.unsubscribe()
  }

  onLocationChanged(loc: number[]) {
      if (!this.frozen) {
          this.pointerLoc = loc
          this.left = loc[0]
          if (loc[1] + this.minHeight < loc[3]) {
              this.top = loc[1] + 'px'
              this.bottom = 'auto'
          } else {
              this.top = 'auto'
              this.bottom = (loc[3] - loc[1]) + 'px'
          }
      }
  }

  onFreeze() {
      this.frozen = true
  }
  onUnFreeze($event: MouseEvent) {
      $event.stopPropagation()
      this.frozen = false
      this.popup = false
  }

  @HostListener('mouseenter', ['$event'])
  handleMouseenter(event: MouseEvent) {
      this.onLocationChanged([event.clientX, event.clientY, event.view.innerWidth, event.view.innerHeight])
      this.popup = true
      this.locSubscription?.unsubscribe()
      this.locSubscription = fromEvent(window, 'mousemove').subscribe({
          next: (event: MouseEvent) => {
              this.onLocationChanged([event.clientX, event.clientY, event.view.innerWidth, event.view.innerHeight])
          }
      })

  }

  @HostListener('mouseleave', ['$event'])
  handleMouseleave(event: MouseEvent) {
      if (!this.frozen) {
          this.popup = false
          this.locSubscription?.unsubscribe()
          this.locSubscription = null
      }
  }

  @HostListener('click', ['$event'])
  handleMouseClick(event: MouseEvent) {
      if (this.pinnable != undefined) {
          event.stopPropagation()
          this.onFreeze()
          return false
      }
      return true
  }
}