import { Directive, Output, EventEmitter, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[scrollTracker]',
  standalone: true
})
export class ScrollTrackerDirective {
  _isScrollTop = false;
  @Output() scrollingFinished = new EventEmitter<void>();
  @Input() get isScrollTop() { return this._isScrollTop };
  set isScrollTop(value) {
    this._isScrollTop = value;
    if (value) {
      setTimeout(() => {
        this.el.nativeElement.scrollTop = 0;
      }, 100);
    }
  }

  emitted = false;
  onScrollHandler;
  el: ElementRef
  constructor(el: ElementRef) {
    this.el = el;
  }

  ngOnInit() {
    //binding lại để có thể unmount
    this.onScrollHandler = this.onScroll.bind(this)
    window.addEventListener('scroll', this.onScrollHandler, true); //third parameter
  }

  ngOnDestroy() {
      window.removeEventListener('scroll', this.onScrollHandler, true);
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    let dom: any = this.el?.nativeElement;
    if(!dom) return;
    if (dom.closest(".ant-drawer-body")) {
      dom = dom.closest(".ant-drawer-body");
    }
    if ((dom.offsetHeight + dom.scrollTop) >= (dom.scrollHeight - 300) && !this.emitted) {
      this.emitted = true;
      this.scrollingFinished.emit();
    } else  {
      this.emitted = false;
    }
  }
}
