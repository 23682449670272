<div class="warp-tracking-modal">
  <div class="modal-header">
    <h2>WARP live tracking URL</h2>
  </div>
  
  <div class="modal-content">
    <div class="url-container">
      <input nz-input [value]="trackingUrl" readonly />
      <button nz-button nzType="default" (click)="copyUrl()">
        <i nz-icon nzType="copy"></i>
      </button>
    </div>
    
    <div class="message-container">
      <textarea nz-input
        [(ngModel)]="smsContent"
        [ngModelOptions]="{standalone: true}"
        [nzAutosize]="{ minRows: 3, maxRows: 5 }">
      </textarea>
    </div>
  </div>
  
  <div class="modal-footer">
    <button nz-button nzType="default" (click)="onCancel()">Cancel</button>
    <button 
      nz-button 
      [disabled]="isSendingSMS"
      [nzLoading]="isSendingSMS"
      nzType="primary" 
      (click)="onSendSMS()">
      Send SMS
    </button>
  </div>
</div> 