import { Injectable } from "@angular/core";
import { ApiService } from "@services/api.service";
import { environment } from "@env/environment";
import { map, Observable } from "rxjs";
import { Task } from "@wearewarp/types/data-model";
import { DeliveryStop } from "@app/interfaces/map-v2/stop";
import { getMetadata, setMetadata } from "@app/utils/map-v2.util";

@Injectable({
    providedIn: 'root'
})
export class TaskService {
    baseUrl: string | null = null

    constructor(
        private api: ApiService
    ) {
        this.baseUrl = environment.dispatchApi + '/task'
    }

    loadTask(ids: string[]): Observable<Task[]> {
        const url = `${this.baseUrl}/load`
        // if (environment.mock) {
        //     console.log('mock request')
        //     const p: JobDetail = require("../../assets/mock/job-detail.json");
        //     return new Observable((observer) => observer.next(this.processJobDetail(p)))

        // }
        return this.api.POST(url, {ids})
    }
}