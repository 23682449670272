import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { Component, Inject } from "@angular/core";
import { BaseFormDialog } from "@app/dialogs/base-form-dialog";
import { NZ_MODAL_DATA } from "ng-zorro-antd/modal";
import { JobStatus } from "@wearewarp/types";
import BizUtil from "@app/utils/biz.util";
import { Const } from "@const/Const";
import { RequestCreateWorkTask } from "@wearewarp/types-server-workqueue/common";
import { UIHelper } from "@services/UIHelper";
import { environment } from "@env/environment";
import { UserService } from "@app/track-trace/services/user.service";
import { StatisticsService } from "@app/track-trace/services/statistics.service";

export interface ICreateTrackTraceTaskModalData {
  jobId: string,
  code?: string,
  status: JobStatus,
  jobTitle: string,       // clients - jobCode
}

const CustomInputs = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NzSelectModule,
  NzInputModule,
  NzButtonModule,
  NzFormModule,
]

@Component({
  selector: 'create-track-trace-task-modal',
  standalone: true,
  imports: CustomInputs,
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class CreateTrackTraceTaskModal extends BaseFormDialog {
  displayInfo: any = {};
  isSubmitting: boolean = false;
  listOperators: any[] | null = null;

  constructor(
    @Inject(NZ_MODAL_DATA) private data: ICreateTrackTraceTaskModalData,
    private userService: UserService,
    private statisticsService: StatisticsService,
  ) {
    super(data);
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    inChargeId: { label: 'Assignee', placeHolder: '', type: 'string' },
    name: { label: 'Title', placeHolder: '', type: 'string', required: true },
    description: { label: 'Description', placeHolder: '', type: 'string' },
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.userService.getListOperators().subscribe(operators => {
      this.listOperators = operators.map(user => {
        return {
          label: user.fullName || `${user.firstName} ${user.lastName}`,
          value: user.id
        }
      })
    });
    this.displayInfo = {
      jobTitle: this.data.jobTitle,
      status: this.data.status,
      statusTxt: BizUtil.getStatusJob(this.data.status),
      statusClass: this.getStatusClass(this.data.status),
    }
  }

  getStatusClass(status: string) {
    if (!status) 
      status = 'created';
    return Const.JobStatus[status];
  }

  onBtnCreate() {
    if (!this.data.jobId) {
      UIHelper.showErr('jobId is missing');
      return;
    }
    const json = this.getFormData_JSON(true);
    const params: RequestCreateWorkTask = {
      type: Const.WorkManualTask.manual,
      name: json.name,
      description: json.description,
      object: {
        entity: 'job',
        entityId: this.data.jobId,
      },
      inChargeId: json.inChargeId,
    }
    const url = `${environment.backendWorkQueueUrl}/${Const.API_CREATE_TASK}`;
    this.isSubmitting = true;
    this.api.POST(url, params).subscribe({
      next: (res) => {
        this.isSubmitting = false;
        UIHelper.showSuccess('Create task successfully');
        this.statisticsService.fetchBookmarkStatistic();
        this.closeDialog();
      },
      error: (err) => {
        this.isSubmitting = false;
        UIHelper.showErr(err);
      }
    })
  }
}

