import { Component, OnInit } from '@angular/core';
import { BaseFormDialog } from '@app/dialogs/base-form-dialog';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-downloading',
  standalone: true,
  imports: [NzIconModule, CommonModule],
  templateUrl: './index.html',
  styleUrl: './index.scss'
})
export class DownloadingComponent extends BaseFormDialog implements OnInit {
  error = false;
  errorMessage = '';

  constructor() {
    super(false);
  }

  setError(message: string) {
    this.error = true;
    this.errorMessage = message;
  }

  ngOnInit(): void {}
}