<div class="next-task-container">
  <div class="next-task-header">
    <div class="flex1"></div>
    <div><span nz-icon nzType="close" nzTheme="outline" (click)="closeDialog()" class="clickable"></span></div>
  </div>

  <div class="next-task-content">
    <div class="bold-text bottom10">Nice, you've completed the task</div>
    <!-- <div>Would you like to take a break or get next task?</div> -->
  </div>

  <div class="next-task-footer top20">
    <button nz-button (click)="onBtnGetNextTask()" nzType="primary"  style="border-radius: 4px;">
      Get next task
    </button>
    <!-- <button nz-button (click)="onBtnStopWorking()" style="border-radius: 4px;">
      Take a break
    </button> -->
  </div>
  
</div>