import { Component, inject, Input, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StopTasksComponent } from '../stop-tasks/stop-tasks.component';
import { getArrivedTime, getDepartedTime, getFirstTask, getTimezone, getAppointmentTime, getScheduledTime } from '@app/utils/stopData';
import { TrackTraceService } from '../../services/track-trace.service';
// import { Task, DelayCode, Stop, Job } from '@wearewarp/types/data-model';
import { DelayCode } from '@wearewarp/types/data-model/types/DelayCode';
import { Job } from '@wearewarp/types/data-model/types/Job';
import { Task } from '@wearewarp/types/data-model/types/Task';
import { Stop } from '@wearewarp/types/data-model/types/Stop';
import { displayTime, displayTimeWindow } from '@app/utils/datetime';
import { Const } from '@const/Const';
import { Const as WarpConst } from '@wearewarp/universal-libs'
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FormDataCreateTonuRoute, FormDataUpdateTaskStatus, FormDataUpdateTimeWindows, InputDataUpdateTaskStatus, UpdateTaskStatus } from '@wearewarp/ng-antd-2';
import { ModalHelper } from '@wearewarp/ng-antd-2';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { MasterData } from '@services/master.data';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { UIHelper } from '@services/UIHelper';
import { LoadTimeSummaryComponent } from '../load-time-summary';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { DrawerService } from '@app/drawers/drawer.service';
import { RoutePOD } from '@app/components/pod';
import { lastValueFrom, Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';

interface ExtendedTask extends Task {
  key: string;
  isItemsExpanded?: boolean;
}
import { NzTagModule } from 'ng-zorro-antd/tag';
import { UpdateTimeWindows } from '@wearewarp/ng-antd-2';
import { FormDataActualTime, JobUpdateFormId } from "@wearewarp/types-server-admin/form-data/dispatch";
import { BaseComponent } from '@abstract/BaseComponent';
import { DateUtil } from '@services/date-util';
import { DialogService } from '@app/dialogs/dialog.service';
import { UpdateArrivedTime } from '@app/components/update-actual-time';
import { DisplayAppointment } from '@app/components/display-appointment';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { Shipment } from '@wearewarp/types/data-model/types/Shipment';

@Component({
  selector: 'app-stop-details',
  templateUrl: './stop-details.component.html',
  styleUrls: ['./stop-details.component.scss'],
  standalone: true,
  providers: [ModalHelper],
  imports: [CommonModule, StopTasksComponent, DisplayAppointment, NzIconModule, NzModalModule, NzPopoverModule, LoadTimeSummaryComponent, NzBadgeModule, NzTagModule, NzToolTipModule],
})
export class StopDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  private modalHelper = inject(ModalHelper);
  private podSubscription: Subscription;

  @Input() selectedStop: any;
  @Input() isScheduled: boolean = false;
  isEnableUpdate: boolean = true;

  @Input() selectedStopIndex: number;
  tasks: Task[] = [];
  shipments: Shipment[] = [];
  stopTasks: ExtendedTask[] = [];
  loadInfo: Job;
  showTasks = false;
  isAppointment = false;
  isLinehaulNoShipment = false;
  actualArrived: string;
  actualDeparted: string;


  isUpdatingTaskStatus = false;
  @Input() isInitialSelectedStop: boolean = false;

  constructor(private trackTraceService: TrackTraceService, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.trackTraceService.getTasks(this.selectedStop.taskIds).subscribe(tasks => {
      this.tasks = tasks;
      this.stopTasks = tasks.map(task => (
        {
        ...task,
        key: task.id,
        }
      ));
    });
    this.trackTraceService.getLoadInfo().subscribe(loadInfo => {
      this.loadInfo = loadInfo;
      if (this.isGhostLoad) {
        this.isEnableUpdate = false;
      }
      if (this.isLinehaul) {
        const shipmentIds = this.loadInfo.shipments.map(s => s.id);
        this.isLinehaulNoShipment = shipmentIds.length === 0;
        this.isEnableUpdate = shipmentIds.length > 0;
      }
    });
    this.trackTraceService.isUpdatingTaskStatus.subscribe(isUpdating => {
      this.isUpdatingTaskStatus = isUpdating;
    });

    this.podSubscription = this.trackTraceService.getPodUpdates().subscribe(
      update => {
        if (update && this.selectedStop.taskIds.some(id => update.taskIds.includes(id))) {
          this.tasks = update.pods;
          this.stopTasks = update.pods.map(task => ({
            ...task,
            key: task.id,
          }));
          this.cdr.detectChanges();
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.podSubscription) {
      this.podSubscription.unsubscribe();
    }
  }

  // getFirstTask(): ExtendedTask {
  //   return this.stopTasks.find(task => task.status !== Const.RouteTaskStatus.created || task.type !== Const.RouteTaskStatus.pickupFailed);
  // }

  get isGhostLoad(): boolean {
    return this.loadInfo?.type === WarpConst.JobType.ghost;
  }

  get isLinehaul(): boolean {
    return this.loadInfo?.type === WarpConst.JobType.linehaul;
  }

  get appointment() {
    const appointment = this.selectedStop.info.appointmentInfo;
    if (appointment?.from) return appointment

    const timeWindow = this.selectedStop.info?.windows?.[0]
    if (timeWindow?.from) return timeWindow;
  }

  get timezone() {
    return getTimezone(this.selectedStop);
  }

  getStatusClass(status: string): string {
    return `status-${status.toLowerCase()}`;
  }

  getStatusText(status: string): string {
    return status ? Const.getStopStatusText(status) : '';
  }

  getShipmentIds(): string[] {
    return this.selectedStop.shipments.map(s => s.id);
  }

  getSchedule(): string {
    const scheduledTime = getScheduledTime(this.selectedStop);
    const timezone = getTimezone(this.selectedStop);
    const displayTime = displayTimeWindow(scheduledTime, timezone);
    if (displayTime) {
      return displayTime;
    }
    return 'N/A';
  }

  getAppointment(): any {
    const timezone = getTimezone(this.selectedStop);

    const appointment = this.selectedStop.info.appointmentInfo;
    if (appointment?.from) {
      this.isAppointment = true;
      return {
        ...appointment,
        displayTime: displayTimeWindow(appointment, timezone)
      };
    }

    const timeWindow = this.selectedStop.info?.windows?.[0];
    if (timeWindow?.from) {
      this.isAppointment = true;
      return {
        ...timeWindow,
        displayTime: displayTimeWindow(timeWindow, timezone)
      };
    }

    return {
      displayTime: 'N/A'
    };
  }

  getArrivalTime(): string {
    const task = getFirstTask(this.selectedStop, this.tasks);
    const time = getArrivedTime(task);
    const timezone = getTimezone(task);
    return DateUtil.displayLocalTime(time, timezone, 'MM/DD/YY h:mm A') || 'N/A';
  }

  getDepartureTime(): string {
    const task = getFirstTask(this.selectedStop, this.tasks);
    const time = getDepartedTime(task);
    const timezone = getTimezone(task);
    return DateUtil.displayLocalTime(time, timezone, 'MM/DD/YY h:mm A') || 'N/A';
  }

  ghostLoadAllowUpdateField(type) {
    return this.isGhostLoad && ['change-address', 'update-schedule', 'update-appointment'].includes(type);
  }

  linehaulAllowUpdateField(type) {
    return this.isLinehaul && ['change-address', 'update-schedule', 'update-appointment'].includes(type);
  }

  public onBtnUpdateClick(type, isConfirm = false) {
    if (!(this.isEnableUpdate || this.ghostLoadAllowUpdateField(type) || this.linehaulAllowUpdateField(type))) {
      return;
    }
    switch (type) {
      case 'change-actual-time':
        this.onChangeActualTime(isConfirm);
        break;
      case 'update-schedule':
        this.onUpdateSchedule();
        break;
    }
  }

  onUpdateSchedule() {
    const data = {
      timezone: this.getTimezone(),
      reasonCodes: this.getDelayCodeOptions().map(code => ({ id: code._id, name: code.name })),
      model: {
        windows: this.getTimeWindows(),
        reasonCodeId: this.getReasonCodeId(),
      },
      onSave: (data: FormDataUpdateTimeWindows) => {
        this.submitUpdateTimeWindows('location-windows', data);
      },
    }
    UpdateTimeWindows.openModal(this.modalHelper, {
      nzTitle: `${this.selectedStop.type} Time Windows`,
      nzWidth: '700px',
      nzData: data
    });
  }

  getTimezone(): string {
    const stop = this.selectedStop;
    return stop.info?.addr?.metadata?.timeZoneStandard || '';
  }

  getTimeWindows(): any[] {
    return this.selectedStop.info?.windows || {};
  }

  getReasonCodeId(): string {
    const stop = this.selectedStop;
    return stop.info['reasonCodeId'] || '';
  }

  private convertData(formId: JobUpdateFormId, data: any) {
    const tasks = this.tasks.map(it => ({ ...it })) || [];
    const taskIds = tasks.map(it => it.id);
    const shipmentIds = this.selectedStop.shipments.map(sh => sh.id) || [];
    const deliveryIds = this.selectedStop.info.id
    const locationType = this.selectedStop.type;

    let params = { ...data, taskIds, shipmentIds, deliveryIds, locationType }
    if (formId === 'actual-time') {
      let taskData = [];
      for (let task of tasks) {
        const { statusChangeLog, status } = task;
        const timezone = task.info.addr?.metadata?.timeZoneStandard;

        if (!statusChangeLog[status]) continue;
        if (params.delayCodeId) {
          if (!statusChangeLog[status].info) statusChangeLog[status].info = {};
          statusChangeLog[status].info.delayCodeId = params.delayCodeId;
          statusChangeLog[status].info.delayNote = params.note;
        } else if (statusChangeLog[status]?.info?.delayCodeId) {
          statusChangeLog[status].info.delayCodeId = null;
          statusChangeLog[status].info.delayNote = null;
        }

        if (statusChangeLog[status]) {
          for (let type of ['arrived', 'departed']) {
            if ([undefined, null, 'N/A'].includes(data[type])) continue;
            let changeWhen = DateUtil.convertLocalTime(data[type], timezone).toISOString();
            if (type === 'arrived') {
              statusChangeLog['arrived'] = {
                ...statusChangeLog[status],
                changeWhen
              }
            }
            if (type === 'departed') {
              statusChangeLog[status] = {
                ...statusChangeLog[status],
                changeWhen
              }
            }
          }
        }

        let item: any = {
          taskId: task.id,
          statusChangeLog
        }

        if(data.confirmed) item = { ...item, confirmed: true }

        taskData.push(item);
      }

      params = { taskData, taskIds, shipmentIds, deliveryIds, locationType, stopId: this.selectedStop.id }
    }
    return params;
  }

  submitUpdateTimeWindows(formId: JobUpdateFormId, data: FormDataUpdateTimeWindows) {
    const params = this.convertData(formId, data)
    return this.trackTraceService.updateTimeWindows(formId, params).subscribe({
      next: (response) => {
        UIHelper.showSuccess('Update Time Windows Successfully');
        this.trackTraceService.refreshLoadInfo(); // Refresh data sau khi update
      },
      error: (error) => {
        UIHelper.showErr(error);
      }
    });
  }

  submitUpdateActualTime(formId, data: FormDataActualTime) {
    const params = this.convertData(formId, data)
  return this.trackTraceService.updateTimeWindows(formId, params);
  }

  onChangeActualTime(isConfirm) {
    if (!this.getArrivalTime() && !this.getDepartureTime()) return;
    const delayInfo = this.getDelay();
    let data: FormDataActualTime = {
      arrived: this.getArrivalTime(),
      departed: this.getDepartureTime(),
      delayCodeId : delayInfo?.delayCodeId,
      note : delayInfo?.note
    }
    DialogService.openFormDialog(UpdateArrivedTime, {
      nzWidth: '600px',
      nzData: {
        type: this.selectedStop.type,
        timezone: this.getTimezone(),
        appointment: this.getAppointment(),
        model: data,
        isConfirm: isConfirm,
        onSave: (data) => {
          return this.submitUpdateActualTime('actual-time', data);
        },
        onRefreshDetailJob: () => {
          this.trackTraceService.refreshLoadInfo();
        },
        nzClassName: 'modal-no-padding modal-update-time',
      }
    })
  }

  getFirstTask(): Task | undefined {
    return this.tasks?.find(task => ![Const.RouteTaskStatus.canceled, Const.RouteTaskStatus.pickupFailed].includes(<any>task.status));
  }

  getStatus(): string {
    return this.getFirstTask()?.status || Const.RouteTaskStatus.created;
  }

  isFirstStop(): boolean {
    const stops = this.loadInfo?.stops;
    return stops.findIndex(s => s.id === this.selectedStop.id) === 0;
  }

  getDelayCodeOptions(): DelayCode[] {
    return [
      WarpConst.TaskType.PICKUP,
      WarpConst.TaskType.DROPOFF
    ].includes(<any>this.selectedStop.type)
      ? MasterData.getDelayCodesByType(<any>this.selectedStop.type) : MasterData.getDelayCodes()
  }

  openUpdateTaskStatusModal() {
    const taskIds = this.tasks.map(it => it.id);
    const data: InputDataUpdateTaskStatus = {
      tasks: this.tasks,
      isFirstStop: this.isFirstStop(),
      delayCodeOptions: this.getDelayCodeOptions(),
      isAssignedCarrier: this.loadInfo?.assignedCarrier?.carrierId ? true : false,
      shipments: this.selectedStop.shipments || [],
      onCreateTonuRoute: (data: FormDataCreateTonuRoute) => {
        this.submitCreateTonuRoute(data);
      },
      onSave: (data: FormDataUpdateTaskStatus) => {
        this.submitUpdateTaskStatus(taskIds, data);
      },
    }

    UpdateTaskStatus.openModal(this.modalHelper, {
      nzTitle: 'Update Task Status',
      nzWidth: '600px',
      nzData: data
    });
  }

  submitUpdateTaskStatus(taskIds: string[], data: FormDataUpdateTaskStatus) {
    this.trackTraceService.updateTaskStatus(taskIds, data);
  }

  submitCreateTonuRoute(data: FormDataCreateTonuRoute) {
    this.trackTraceService.createTonuRoute(data).subscribe(res => {
      UIHelper.showSuccess('Create Tonu Route', 'Tonu route created successfully');
    });
  }

  toggleTasks() {
    this.showTasks = !this.showTasks;
  }

  getDelay() {
    const task = getFirstTask(this.selectedStop, this.tasks);
    const log = task?.statusChangeLog?.[Const.RouteTaskStatus.arrived];
    if (log?.info?.delayCodeId) {
      return {
        delayCodeId: log.info.delayCodeId,
        note: log.info.delayNote
      }
    } else {
      return task?.delay;
    }
  }

  private getDisplayDelayInfo(delayInfo) {
    if (!delayInfo) return null;
    let data: any = {};
    const listDelayCodes = MasterData.getDelayCodes();
    if (delayInfo?.delayCodeId) {
      data.failureCode = listDelayCodes.find((option) => option._id == delayInfo.delayCodeId)?.name;
      data.note = delayInfo.note;
    }
    return data;
  }

  get delayInfo() {
    try {
      const delay = this.getDelay();
      if (!delay) return null;
      const displayInfo = this.getDisplayDelayInfo(delay);
      return displayInfo || null;
    } catch (error) {
      console.error('Error getting delay info:', error);
      return null;
    }
  }

  getAppointmentType(): string | null {
    const appointmentInfo = this.selectedStop?.info?.appointmentInfo;
    if (!appointmentInfo?.type) return null;

    switch (appointmentInfo.type) {
      case 'fcfs': return 'FCFS';
      case 'callAhead': return 'Call Ahead';
      case 'strictAppointment': return 'Strict Appointment';
      default: return null;
    }
  }

  getLocationType(): string {
    return this.selectedStop.type == Const.TaskType.PICKUP ? 'Pickup' : 'Delivery';
  }
  // POD
  get totalPods() {
    return this.getFirstTask()?.podUploadFiles?.length ?? 0;
  }

  getPodUploadFiles() {
    return this.getFirstTask()?.podUploadFiles || [];
  }

  getPodNotConfirmed() {
    let pods =  this.getPodUploadFiles().map(podId => this.trackTraceService.getPODById(podId)).filter(it => it);
    return pods.filter(pod => !pod?.podConfirmed).length;
  }

  get podNotConfirmed() {
    return this.getPodNotConfirmed() ?? 0;
  }

  onBtnAddPod() {
    let taskIds = (this.selectedStop.taskIds || []).map(item => item);
    let shipmentIds = this.loadInfo.shipments.map(item => item.id);

    this.trackTraceService.getShipments(shipmentIds).subscribe(shipments => {
      DrawerService.openDrawer1(RoutePOD, {
        nzContentParams: {
          shipment: shipments,
          taskIds: taskIds,
          stop: this.selectedStop,
          jobId: this.loadInfo?.id,
          selectedStopIndex: this.selectedStopIndex,
          refreshData: async () => {
            return await lastValueFrom(this.trackTraceService.refreshPodData(taskIds));
          }
        },
        nzWidth: 400,
        nzWrapClassName: 'wrap-drawer route-pod-drawer',
      });
    });
  }

  onBtnViewPod() {
    let taskIds = (this.selectedStop.taskIds || []).map(item => item);
    let shipmentIds = this.loadInfo.shipments.map(item => item.id);

    this.trackTraceService.getShipments(shipmentIds).subscribe(shipments => {
      DrawerService.openDrawer1(RoutePOD, {
        nzContentParams: {
          shipment: shipments,
          taskIds: taskIds,
          stop: this.selectedStop,
          jobId: this.loadInfo?.id,
          selectedStopIndex: this.selectedStopIndex,
          refreshData: async () => {
            return await lastValueFrom(this.trackTraceService.refreshPodData(taskIds));
          }
        },
        nzWidth: 400,
        nzWrapClassName: 'wrap-drawer route-pod-drawer',
      });
    });
  }
} 