<div class="track-trace-container">
  <div class="sidebar-container">
    <track-trace-sidebar-menu></track-trace-sidebar-menu>
  </div>
  <div class="track-trace-task">
    <div class="loading-container" *ngIf="isLoading">
      <div class="loading-content">
        <nz-spin></nz-spin>
      </div>
    </div>
    <!-- No task -->
    <div class="empty-task-container" *ngIf="!isLoading && isEmptyTask && !isBreaking">
      <div class="empty-task-content">
        <nz-empty nzNotFoundContent=""></nz-empty>
        <span class="empty-task-text">
          Next task coming up in <b>{{nextTaskTime}}</b>
          <br><br>
          No task right now. A new one may arrive soon, so please stay ready.
        </span>
      </div>
    </div>
    <div class="empty-task-container" *ngIf="!isLoading && isBreaking">    
      <div class="empty-task-content"> 
        <img src="assets/svg/take-a-break.svg" alt="Take a break" />
        <h1>Your shift has ended</h1>
        <h3>Great job today! Have a wonderful rest of your day!</h3>
        <button nz-button nzType="primary" (click)="onContinueWork()">Start new shift</button>
      </div> 
    </div>
    <!-- Working Task -->
    <div class="content-grid" *ngIf="!isLoading && !isEmptyTask && !isBreaking">
      <div class="main-content" #mainContent>
        <div class="scroll-container">
          <app-load-title></app-load-title>
          <div class="main-content-body">
            <app-load-stops></app-load-stops>
            <app-load-carrier-driver></app-load-carrier-driver>
          </div>
        </div>
      </div>
      <div class="resize-handle" #resizeHandle></div>
        <div class="side-content">
          <div class="scroll-container">
            <!-- <app-load-map></app-load-map> -->   <!-- Use map-v2 instead -->
            <job-map-v2 [id]="jobId"></job-map-v2>
            <!-- <app-load-time-summary></app-load-time-summary> -->
            <app-load-track-trace-task></app-load-track-trace-task>
            <route-sidebar-menu></route-sidebar-menu>
          </div>
      </div>
      <div class="note-box-container">
        <driver-chat-box></driver-chat-box>
        <div style="margin-top: 5px;"></div>
      </div>
      <div class="actions-content">
        <app-load-actions></app-load-actions>
      </div>
    </div>
  </div>
</div>