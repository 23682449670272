import { Input, Directive } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { BaseFormItem } from '@app/base-components/form-item';
import { FormUtil } from '@services/form-util';

@Directive({
  standalone: true
})
export abstract class BaseFormDrawer extends BaseFormItem {
  @Input() isDrawer: boolean = false;
  @Input() closeOnSuccess: boolean = false;                    
  @Input() getDrawerRef: () => NzDrawerRef = () => null;
  @Input() createSuccess: (resp) => void = () => {};      
  @Input() updateSuccess: (resp) => void = () => {};   
  
  constructor() {
    super();
  }
  
  ngOnInit(): void {
    super.ngOnInit();
    // for (let key of Object.keys(this.formGroupDeclaration)) {
    //   let d = this.formGroupDeclaration[key];
    //   if (d.type == 'uploadFile') {
    //     d.isChanged = this.isChangedFile.bind(this, key);
    //     if (d.required) {
    //       FormUtil.addValidator(d, this.requireAttachFile.bind(this, key));
    //     }
    //   }
    // }
    // this.createFormInput();
    // if (this.model) {
    //   this.bindDataModel(this.model);
    // }
  }
 
  ngAfterViewChecked(): void {
    const heightDrawerBody = document.querySelector('.ant-drawer-body');
    const heightStarInserted = document.querySelector('.ant-drawer-body .ng-star-inserted');
    const heightFormWrapperBody = document.querySelector('.ant-drawer-wrapper-body');
    if (heightFormWrapperBody && heightDrawerBody && heightStarInserted && 
      typeof heightDrawerBody.clientHeight != 'undefined' && 
      typeof heightStarInserted.clientHeight != 'undefined' && 
      heightDrawerBody.clientHeight > heightStarInserted.clientHeight + 50) {
        heightFormWrapperBody.classList.add('short');
    } else if (heightFormWrapperBody) {
      heightFormWrapperBody.classList.remove('short');
    }
  }
  
  protected get nzDrawer(): NzDrawerRef {
    return this.getDrawerRef();
  }
  
  public onClose() {
    this.nzDrawer?.close();
  }
  
  protected onCreateSuccess(resp) {
    super.onCreateSuccess(resp);
    this.createSuccess(resp);
    if (this.closeOnSuccess) {
      this.onClose();
    }
  }
  
  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.updateSuccess(resp);
    if (this.closeOnSuccess) {
      this.onClose();
    }
  }
  
  protected getApiUrl(): string {
    return '';
  }

}