import { Const } from "@const/Const";
import { Stop, Task, TimeWindow } from "@wearewarp/types/data-model";

/**
 * Lấy task đầu tiên của stop
 * @param stop 
 * @param tasks 
 * @returns 
 */
export function getFirstTask(stop: Stop, tasks: Task[]): Task | undefined {
  const firstTaskIds = stop.taskIds?.slice(0, 1);
  return tasks.find(it => firstTaskIds?.includes(it.id));
}

/**
 * Lấy thời gian arrived của task
 * @param task 
 * @returns 
 */
export function getArrivedTime(task: Task): string | undefined {
  if (!task) return;
  if (![Const.StopStatus.arrived, Const.StopStatus.succeeded, Const.StopStatus.failed].includes(<any>task.status)) return;
  const log = task.statusChangeLog?.[Const.StopStatus.arrived]
  let changeWhen = log?.changeWhen ?? log?.when
  //trường hợp không có thông tin arrived time (do đổi status trực tiếp sang completed),
  //sẽ lấy thời gian depart để hiển thị cho arrived. Coi như đến rồi đi luôn.
  if (!changeWhen) return getDepartedTime(task);
  return changeWhen
}

/**
 * Lấy thời gian departed của task
 * @param task 
 * @returns 
 */
export function getDepartedTime(task: Task): string | undefined {
  if (!task) return;
  if (![Const.StopStatus.succeeded, Const.StopStatus.failed].includes(<any>task.status)) return;
  //nếu status hiện tại là failed. thì lấy departed time là thời gian đổi sang failed
  let log;
  switch (task.status) {
    case Const.StopStatus.failed:
      log = task.statusChangeLog?.[Const.StopStatus.failed]
      break;
    case Const.StopStatus.succeeded:
      log = task.statusChangeLog?.[Const.StopStatus.succeeded]
      break;
    default:
      return;
  }
  return log?.changeWhen ?? log?.when
}

export function getTimezone(task: Task | Stop): string {
  if (!task) return '';
  if ((task as Task).address?.metadata.timeZoneStandard) {
    return (task as Task).address?.metadata.timeZoneStandard || '';
  }
  if ((task as Stop).info?.addr?.metadata?.timeZoneStandard) {
    return (task as Stop).info?.addr?.metadata?.timeZoneStandard || '';
  }
  return '';
}

export function getScheduledTime(stop: Stop): TimeWindow | undefined {
  if (!stop) return;
  const windows = stop.info?.windows?.[0];
  if (!windows) {
    return;
  }
  return windows
}

export function getAppointmentTime(stop: Stop): TimeWindow | undefined {
  if (!stop) return;
  const appointmentInfo = stop.info?.appointmentInfo;
  const requiresAppointment = stop.info?.requiresAppointment;
  if (!requiresAppointment || !appointmentInfo?.from || !appointmentInfo?.to) {
    return;
  }
  return {
    from: appointmentInfo.from,
    to: appointmentInfo.to
  }
}