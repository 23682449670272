import { CompositeLayer, PickingInfo } from '@deck.gl/core';
import { IconLayer, ScatterplotLayer } from '@deck.gl/layers'
import { Location } from '@app/interfaces/map-v2/location';
import { warehousePinMapping } from './icon';
import { getLngLat, getLocId } from '@app/utils/map-v2.util';

export default class LocationDotLayer extends CompositeLayer {
    override id: string
    static override layerName = "LocationDotLayer"
    locations: any[] = []
    data: any
    getInfo: any

    constructor(id: string, locations: Location[], options?: {}) {
        super({id, data: {locations}})
        this.id = id
        this.data = locations
        this.locations = this.processLocations(locations)
    }

    processLocations(locations: Location[]) {
        const iconName = (s: string) => {
            if (s == 'succeeded') return '1'
            if (s == 'enroute') return '3'
            if (s == 'failed') return '2'
            if (s == 'canceled'  || s == 'pickupFailed') return '5'
            if (s == 'arrived') return '4'
            return '0'
        }
        locations = locations.map(it => Object.assign({}, it, {
            coordinates: getLngLat(it.latlng),
            id: it.id ?? getLocId(it.latlng),
            type: it.type,
            color: it.color ?? [255, 255, 255],
            icon: iconName(it.status ?? '')
        }))
        return locations
    }

    createDotLayer() {
        const pickups = this.locations.filter(it => it.type == 'PICKUP')

        return new ScatterplotLayer({
            id: `locations-layer-${this.id}`,
            data: pickups,
            pickable: true,
            opacity: 1,
            stroked: true,
            filled: true,
            radiusScale: 5,
            radiusMinPixels: 5,
            radiusMaxPixels: 5,
            lineWidthMinPixels: 1,
            lineWidthMaxPixels: 1,
            getPosition: d => d.coordinates,
            getFillColor: d => d.color ?? [255,255,255],
            getLineColor: d => [44, 68, 230],
            onClick: this.onClick
        });
    }

    createIconLayer() {
        const dropoffs = this.locations.filter(it => it.type == 'DROPOFF')
        const mapping = warehousePinMapping()
        return new IconLayer({
            id: `icon-layer-${this.id}`,
            data: dropoffs,
            pickable: true,
            iconAtlas: '/assets/svg/map-v2/warehouse_pin.svg',
            // iconAtlas: 'https://warp-public-media.s3.us-west-2.amazonaws.com/images/svg/icons/warehouse_pin-240925-1.svg',
            iconMapping: mapping,
            getIcon: d => d.icon,
            getColor: d => d.color,
            getPosition: d => d.coordinates,
            sizeScale: 29,
            getSize: d => 0.5,
            parameters: {
                depthTest: false
            }
        });
    }

    renderLayers() {
        return [
            this.createDotLayer(),
            this.createIconLayer()
        ]
    }
}
