<div class="load-suggestions">
  <div class="suggestions-header" (click)="toggleExpand()">
    <div class="header-content">
      <img src="assets/svg/suggest_actions.svg" alt="suggest" class="suggest-icon"/>
      <span>Suggest actions</span>
    </div>
    <div class="task-info">
      <span class="task-name">{{ taskName }} {{displayStopIndex}}</span>
      <i nz-icon nzType="up" nzTheme="outline" *ngIf="isExpanded"></i>
      <i nz-icon nzType="down" nzTheme="outline" *ngIf="!isExpanded"></i>
    </div>
  </div>

  <div class="load-suggestions-container" *ngIf="isExpanded">
    <confirm-eta-suggestion
      *ngIf="isTaskConfirmETA"
      [isDriverActive]="isDriverActive">
    </confirm-eta-suggestion>

    <enroute-tracking-suggestion 
      *ngIf="isTaskConfirmEnroute"
      [isDriverActive]="isDriverActive"
      [stopType]="stopType">
    </enroute-tracking-suggestion>

    <confirm-arrival-suggestion
      *ngIf="isTaskConfirmArrival"       
      [isDriverActive]="isDriverActive"
      [stopType]="stopType">
    </confirm-arrival-suggestion>
    
    <confirm-departure-suggestion
      *ngIf="isTaskConfirmDeparture"
      [isDriverActive]="isDriverActive"
      [stopType]="stopType">
    </confirm-departure-suggestion>

  </div>
</div> 