import { CompositeLayer } from '@deck.gl/core';
import { PathLayer } from '@deck.gl/layers';
import polyline from '@mapbox/polyline';
import { JobDetail } from '@app/interfaces/map-v2/job-detail';
import { getLngLat } from '@app/utils/map-v2.util';

export default class JobTripLayer extends CompositeLayer {
    override id: string
    static override layerName = "routing-route-trip-layer"
    job: JobDetail
    routePath: any
    currentTime: number = 0
    selectRoute: (s: string) => void
    options: any = {}
    _tripLayer: any = null

    renderLayers() {
        return this._tripLayer
    }

    constructor(job: JobDetail, options: any = {}) {
        super({data: job})
        this.id = `job-trip-${job.job.id}`

        this.job = job
        this.options = options
        // this.selectRoute = options?.selectRoute

        this.routePath =  this.prepareRoutePathData(job)
        this._tripLayer = this.createRoutePathLayer()
    }

    prepareRoutePathData(job: JobDetail) {
        if (!job.stops?.length) return []
        // no path at all
        if (job.stops.filter(it => it.path).length < 1) {
            const latlngs = job.stops.map(it => it.latlng)
            const coordinates = latlngs.map(it => getLngLat(it))
            return [{
                type: 'ROUTE',
                coordinates,
                job: job,
                color: this.options.color ?? [38, 70, 83],
            }]
        }
        return job.stops.filter(it => it.path)
            .map(it => {
                const points = polyline.decode(it.path)
                const coordinates = points.map(it => [it[1], it[0]])
                return {
                    type: 'LEG',
                    coordinates,
                    job: job,
                    toStop: it,
                    color: this.options.color ?? [38, 70, 83],
                }
            })
    }

    createRoutePathLayer() {
        return new PathLayer({
            id: `job-trip-${this.id}`,
            data: this.routePath,
            getPath: (d: any) => d.coordinates,
            getColor: (d: any) => d.color,
            widthMinPixels: this.options.minWidth || 3,
            jointRounded: true,
            capRounded: true,
            pickable: true,
            onHover: this.options?.onHover
        });
    }
}