import { DateUtil } from "@wearewarp/nodejs-utils-datetime";
import { TimeWindow } from "@wearewarp/types/data-model";

export function displayTimeWindow(timeWindow: TimeWindow | undefined, timezone: string) {
  if (!timeWindow || !timezone) return 'N/A';
  return `${DateUtil.displayTimeWindow(timeWindow, {
    timezone: timezone,
    format: 'MM/DD/YY hh:mm A',
    formatDateOnly: 'MM/DD/YY'
  })} ${DateUtil.timezoneStandardToUsShort(timezone)}`;
}

export function displayTime(time: string | undefined, timezone: string) {
  if (!time) return;
  return `${DateUtil.displayClientTime(time, {
    timezone: timezone,
    format: 'MM/DD/YY hh:mm A'
  })} ${DateUtil.timezoneStandardToUsShort(timezone)}`;
}

export function timeToSeconds(timeString: string) {
  const [minutes, seconds] = timeString.trim().split(':').map(Number);
  return minutes * 60 + seconds;
}
