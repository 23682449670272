import { Component, Input } from "@angular/core";
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { FormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { BaseComponent } from "@abstract/BaseComponent";
import { Const } from "@const/Const";
import { UIHelper } from "@services/UIHelper";
import { DateUtil } from "@services/date-util";
import { NgFor, NgIf } from "@angular/common";
import { TimeWindow } from "@app/interfaces";
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TrackTraceService } from "../../services/track-trace.service";

const CustomInputs = [
  NgIf, NgFor,
  NzIconModule,
  NzPopoverModule,
  FormsModule,
  NzInputModule,
  NzButtonModule,
  NzGridModule,
  NzBadgeModule,
  NzEmptyModule,
  NzSpaceModule,
  NzToolTipModule
]

@Component({
  selector: 'driver-chat-box',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class DriverChatBoxComponent extends BaseComponent {
  public jobId: string;
  public driverInfo: any;
  private timerReloadData;
  private onProgress: boolean = false;
  public listMessages: any = [];
  public inputMsg: string = '';
  public currentTime: string;    // timeiso utc
  public boxChatVisiable: boolean = false;
  public countOldMsg: number = 0;   // tổng số message cũ
  public countNewMsg: number = 0;   // số message mới

  constructor(
    private trackTraceService: TrackTraceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.trackTraceService.getWorkTask().subscribe(info => {
      if (info?.object?.entityId) {
        this.jobId = info.object.entityId;
      }
    });

    this.trackTraceService.getLoadInfo().subscribe(info => {
      if (info?.driver) {
        this.driverInfo = info.driver;
        if (this.timerReloadData) {
          clearInterval(this.timerReloadData);
        }
        this.reloadData();
        this.timerReloadData = setInterval(() => {
          this.reloadData();
        }, 10000);
      }
    });
  }
  
  ngOnDestroy(): void {
    if (this.timerReloadData) {
      clearInterval(this.timerReloadData);
      this.timerReloadData = null;
    }
    super.ngOnDestroy();
  }

  async reloadData() {
    this.currentTime = DateUtil.convertLocalTime(new Date(), DateUtil.getLocalTimeZone()).toISOString();
    this.countOldMsg = this.listMessages.length || 0;
    this.fetchData();
  }

  get canSendMessage() {
    return !this.onProgress && this.inputMsg;
  }

  onBtnCloseBoxChat() {
    this.boxChatVisiable = false;
  }

  onSubmit() {
    let driverId = this.driverInfo?.id;
    if (!driverId) return UIHelper.showErr('Missing driverId');
    this.onProgress = true;
    let formData = new FormData();
    const jsonData = {
      content: this.inputMsg,
      sendTypes: ['sms'],
      driverId
    }
    formData.append("params", JSON.stringify(jsonData));
    this.api.postFormData(`${Const.APIURI_CONVERSATIONS}/?subjectId=${this.jobId}&subjectType=job&type=driver_message`, formData).subscribe({
      next: (resp) => {
        this.inputMsg = '';
        UIHelper.showSuccess("Your Message has been sent successfully.");
        this.onProgress = false;
        this.reloadData();
      },
      error: (err) => {
        UIHelper.showErr(err);
        this.onProgress = false;
      }
    })
  }

  // sắp xếp lại tin nhắn theo thời gian sớm nhất -> muộn nhất
  // sortData(data) {
  //   return data.sort((a, b) => {
  //     let timeA = new Date(a.insert?.when).getTime();
  //     let timeB = new Date(b.insert?.when).getTime();
  //     return timeB - timeA;
  //   })
  // }

  buildDisplayMessages(data) {
    let items = [];
    for (const item of data) {
      const collectionName = this.getCollectionName(item);
      const longAgo = this.getTimeLongAgo(item.insert?.when);

      items.push({
        content: item.content || '',
        longAgo: longAgo,
        collection: item.insert?.collection || '',
        collectionName: collectionName,
        avtCharacter: collectionName.charAt(0).toUpperCase()
      });
    }
    return items;
  }

  getCollectionName(item) {
    let data;
    let collection = item?.insert?.collection;
    if (collection === 'users') data = item?.user;
    if (collection === 'drivers') data = item?.driver;

    if (!data) return 'N/A';
    if (data?.fullName) return data.fullName;
    return `${data?.firstName || ''} ${data?.lastName || ''}`.trim();
  }

  getTimeLongAgo(time: string) {
    if (!time) return '';
    // time và current time đều được convert về iso utc
    let window: TimeWindow = {
      from: time,
      to: this.currentTime
    }
    let duration = DateUtil.getTimeDurationFromWindow(window);
    duration = Math.round(duration / (1000 * 60));   // convert to mins
    return DateUtil.getParseDurationTimeAgo(duration);
  }

  async fetchData() {
    if (!this.jobId) return UIHelper.showErr(`Invalid jobId ${this.jobId}`)
    this.onProgress = true;
    this.listMessages = [];
    this.api.GET(`${Const.APIURI_CONVERSATIONS}/?subjectId=${this.jobId}&subjectType=job&type=driver_message`).subscribe({
      next: (resp) => {
        const listData = resp?.data?.list_data || [];
        this.listMessages = this.buildDisplayMessages(listData);
        this.countNewMsg = this.listMessages.length - this.countOldMsg || 0;
        this.onProgress = false;
      },
      error: (err) => {
        UIHelper.showErr(err);
        this.onProgress = false;
      }
    });
  }

  onVisiableChange() {
    this.countNewMsg = 0;
  }
}