<div class="load-stops" *ngIf="loadInfo?.stops">
  <div class="stops-tabs">
    <div class="tabs-scroll-container">
      <nz-tabset [(nzSelectedIndex)]="selectedStopIndex" (nzSelectedIndexChange)="selectStop($event)" nzSize="small">
        <nz-tab *ngFor="let stop of loadInfo.stops; let i = index" [nzTitle]="titleTemplate">
          <ng-template #titleTemplate>
            <div class="tab-title">
              <div class="title-content" nz-tooltip [nzTooltipTitle]="getStatusText(stop.status)">
                <div class="main-title" [class]="'status-' + getStatusClass(stop.status)">Stop {{i + 1}} - {{stop.type}}</div>
                <div class="sub-title">{{ stop.info?.locationName || (stop.info?.addr?.city + ', ' + stop.info?.addr?.state) }}</div>
              </div>
            </div>
          </ng-template>
          <ng-template nz-tab>
            <app-stop-details *ngIf="selectedStopIndex === i" [selectedStop]="selectedStop" [selectedStopIndex]="selectedStopIndex" [isScheduled]="isSelectedStopScheduled" [isInitialSelectedStop]="isInitialSelectedStop()"></app-stop-details>
          </ng-template>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
</div>