import { AddressUS, Shipment } from '@wearewarp/types/data-model'
import ArcBrushingLayer from './brushing-arc-layer'
import { getAddrLatLng, getLngLat, getLocId } from '@app/utils/map-v2.util'
import { CompositeLayer, Layer, LayersList } from '@deck.gl/core'
import { ArcLayer, ScatterplotLayer } from '@deck.gl/layers'

export default class ShipmentArcLayer extends CompositeLayer<{coef: number}> {
    override renderLayers(): Layer | null | LayersList {
        return this.deckglLayer
    }

    override id: string
    static override layerName = "ShipmentArcLayer"
    shipments: Shipment[]
    data: any[]
    currentTime: number = 0
    // coef: number = 1
    selectShipment: (s: string) => void
    _deckglLayer: ArcBrushingLayer | null = null
    _dotLayer: ScatterplotLayer | null = null
    options: any = null
    timer: any = null
    get deckglLayer() {
        return [this._dotLayer, this._deckglLayer]
    }

    constructor(id: string, data: {shipments, currentTime?: number}, options?: {animation?: number, baseOpacity?: number, width?: number, sharedTilt?: boolean}) {
        super({data})
        this.id = `shipment-arc-${id}`
        this.shipments = data.shipments
        this.currentTime = data.currentTime || 0
        this.options = options
        this.data = this.prepareArcData(this.shipments)
        // console.log(this.data)
        this.createShipmentArcLayer = this.createShipmentArcLayer.bind(this)
        this._deckglLayer = this.createShipmentArcLayer()
        this._dotLayer = this.createDotLayer()
        this.tic = this.tic.bind(this)
        this.timer = setInterval(this.tic, 100)
    }

    static getSourceColor(item, mile) {
        return [24, 4, 195]
        if (item.selected) return [0, 255, 255]
        return mile === 'last' ? [14, 160, 98] : [44, 68, 230]
    }

    static getTargetColor(item, mile) {
        if (item.selected) return [0, 200, 200]
        const color = [250, 173, 20]
        return color;
        return mile === 'first' ? [14, 160, 98]: [200, 90, 46]
    }

    getAddrLatLng(a: AddressUS) {
        return [a.metadata?.latitude, a.metadata?.longitude]
    }
    getAddrLngLat(a: AddressUS) {
        return [a.metadata?.longitude, a.metadata?.latitude]
    }

    prepareArcData(shipments) {
        const byLane = {}
        
        for (let shipment of shipments) {
            const pickup = shipment.deliveryInfos.filter(x => x.type === 'PICKUP')[0]?.addr
            const dropoff = shipment.deliveryInfos.filter(x => x.type === 'DROPOFF')[0]?.addr
            if (!pickup || !dropoff) continue
            const fromLocId = getLocId(this.getAddrLatLng(pickup))
            const toLocId = getLocId(this.getAddrLatLng(dropoff))
            const lane = [fromLocId, toLocId].sort().join('<>')
            const existing = byLane[lane] || []
            existing.push(shipment.id)
            byLane[lane] = existing
        }

        const tilts = {}
        const heights = {}
        for (const k in byLane) {
            const l = byLane[k]
            let inc = 0
            if (!this.options?.sharedTilt) {
            if (l.length == 1) {
                inc = 0
            } else if (l.length == 2) {
                inc = 30
            } else if (l.length == 3 || l.length == 4) {
                inc = 15
            } else {
                inc = 90 / l.length
            }
            }
            for (let i = 0; i < l.length; i++) {
                tilts[l[i]] = inc * (i - (l.length - 1) / 2)
                heights[l[i]] = 0.5 + inc * (i - (l.length - 1) / 2) / 180
            }
        }

        return shipments.map(it => {
            const metadata: any = it.metadata
            const { mile } = metadata || {}
            const pickup = it.deliveryInfos.filter(x => x.type === 'PICKUP')[0]?.addr
            const dropoff = it.deliveryInfos.filter(x => x.type === 'DROPOFF')[0]?.addr
            if (!pickup || !dropoff) return null

            if (!pickup?.metadata?.longitude) return null
            if (!dropoff?.metadata?.longitude) return null
            return {
                warpId: it.warpId,
                code: it.code,
                id: it.id,
                type: 'SHIPMENT',
                from: [pickup.metadata.longitude, pickup.metadata.latitude],
                to: [dropoff.metadata.longitude, dropoff.metadata.latitude],
                sourceColor: ShipmentArcLayer.getSourceColor(it, mile),
                targetColor: ShipmentArcLayer.getTargetColor(it, mile),
                width: this.options.width ?? 3,
                tilt: tilts[it.id],
                height: heights[it.id],
                shipment: it,
                // width: it.selected ? 5 : 2
            }
        }).filter(it => it)
    }

    createDotLayer() {
        const locations = this.shipments.flatMap(it => it.deliveryInfos?.filter(d => d.type == 'DROPOFF' || d.type == 'PICKUP'))
            .map(it => Object.assign({
                coordinates: getLngLat(getAddrLatLng(it.addr)),
                color: it.type == 'DROPOFF' ? [250, 123, 20] : [24, 4, 195]
            }))
            .filter(it => it.coordinates?.length)
            .filter(it => it.coordinates[0])

        return new ScatterplotLayer({
            id: `locations-layer-${this.id}`,
            data: locations,
            pickable: true,
            opacity: 1,
            stroked: true,
            filled: false,
            radiusScale: 3,
            radiusMinPixels: 3,
            radiusMaxPixels: 3,
            lineWidthMinPixels: 1,
            lineWidthMaxPixels: 1,
            getPosition: d => d.coordinates,
            // getFillColor: d => d.color ?? [255,255,255],
            getLineColor: d => d.color ?? [44, 68, 230],
            onClick: this.onClick
        });
    }

    animate(animation, callback?: any) {
        this.options = Object.assign(this.options, {animation})
        this.options.callback = callback
    }

    tic() {
        if (this.options?.animation === 0) return false
        if (this.options?.animation === undefined) return false

        this.currentTime += 5
        if (this.options.animation > 0) {
            if (this.currentTime > this.options.animation * 100) {
                // stop
                this.options.animation = 0
                this._deckglLayer = this.createShipmentArcLayer()
                if (this.options.callback) {
                    this.options.callback()
                }
                return true
            }
        }

        const animation = Math.abs(this.options.animation)
        let t = this.currentTime % (animation * 100)
        const coef = t / (animation * 100)
        if (this.props.coef === coef) return false
        // console.log(coef)
        this._deckglLayer = this._deckglLayer.clone({coef})
        this.setState({coef})
        return true
    }

    createShipmentArcLayer() {
        if (this.options?.animation !== 0)
        return new ArcBrushingLayer({
            id: `${this.id}-arc`,
            data: this.data,
            pickable: true,
            getWidth: d => d.width,
            getSourcePosition: d => d.from,
            getTargetPosition: d => d.to,
            getHeight: d => d.height,
            getTilt: d => d.tilt,
            coef: this.props.coef,
            baseOpacity: this.options?.baseOpacity ?? 0.1,
            numSegments: 180,
            getSourceColor: d => d.sourceColor,
            getTargetColor: d => d.targetColor,
            greatCircle: true,
        });

        return new ArcLayer({
            id: `${this.id}-arc`,
            data: this.data,
            pickable: true,
            getWidth: d => d.width,
            getSourcePosition: d => d.from,
            getTargetPosition: d => d.to,
            getHeight: d => d.height,
            getTilt: d => d.tilt,
            coef: this.props.coef,
            baseOpacity: this.options?.baseOpacity ?? 0.1,
            numSegments: 180,
            getSourceColor: d => d.sourceColor,
            getTargetColor: d => d.targetColor,
            greatCircle: true,
        });
    }
}

ShipmentArcLayer.defaultProps = {
    coef: 1.0
}