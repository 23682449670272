import { WorkTask } from "@wearewarp/types/data-model";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzButtonModule } from "ng-zorro-antd/button";
import { BaseFormDialog } from "@app/dialogs/base-form-dialog";
import { Component, Inject } from "@angular/core";
import { NZ_MODAL_DATA } from "ng-zorro-antd/modal";
import { NzIconModule } from "ng-zorro-antd/icon";

interface InputCompleteTaskDialog {
  task: WorkTask;
  stopWorking?: () => void;
  getNextTask?: () => void;
  resetInputs?: () => void;
}

const CustomInputs = [
  CommonModule,
  NzButtonModule,
  NzIconModule
]

@Component({
  selector: 'get-next-task-dialog',
  standalone: true,
  imports: CustomInputs,
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class GetNextTaskDialog extends BaseFormDialog {
  private _task: WorkTask;
  private stopWorking: () => void = () => {};
  private getNextTask: () => void = () => {};
  private resetInputs: () => void = () => {};

  constructor(@Inject(NZ_MODAL_DATA) data: InputCompleteTaskDialog) {
    super(data);
    this._task = data.task;
    if (data.stopWorking) {
      this.stopWorking = data.stopWorking;
    }
    if (data.getNextTask) {
      this.getNextTask = data.getNextTask;
    }
    if (data.resetInputs) {
      this.resetInputs = data.resetInputs;
    }
  }

  get task() { return this._task; }

  onBtnGetNextTask() {
    this.resetInputs();
    this.getNextTask();
    this.closeDialog();
  }

  onBtnStopWorking() {
    this.stopWorking();
    this.closeDialog();
  }
}

