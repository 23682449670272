import { Component, OnInit, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { BaseFormDialog } from '@app/dialogs/base-form-dialog';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Job } from '@wearewarp/types/data-model';
import { UIHelper } from '@services/UIHelper';
import { environment } from '@env/environment';
import { Const } from '@const/Const';
import BizUtil from '@app/utils/biz.util';

@Component({
  selector: 'app-notify-slack',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzFormModule,
    NzRadioModule,
    NzInputModule,
    NzSelectModule,
    NzButtonModule,
    NzIconModule
  ],
  templateUrl: './notify-slack.component.html',
  styleUrls: ['./notify-slack.component.scss']
})
export class NotifySlackComponent extends BaseFormDialog implements OnInit {
  isSubmitting = false;
  loadInfo: Job;
  isAssignedCarrier = false;
  isLoading = false;
  showChannelError = true;
  isSendButtonDisabled = true;

  slackChannels: {channel: string, channelId: string}[] = [];

  readonly listActions = [
    { label: 'Need recovery', value: 'need-recovery', desc: 'Use this when the driver is late or unable to pick up, especially if the carrier is unassigned. Alerts your team to find a replacement.' },
    { label: 'Need attention', value: 'need-attention', desc: 'Use this when the driver is late to notify your team for support.' },
  ]

  protected formGroupDeclaration: FormGroupDeclaration = {
    requireAction: { label: 'Message', type: 'string', required: false },
    plainText: { label: 'Content', type: 'string', required: true },
    channelIds: { label: 'Channel', type: 'array', required: true }
  }

  constructor(
    private modalRef: NzModalRef,
    @Inject(NZ_MODAL_DATA) data: any
  ) {
    super(data);
    this.loadInfo = data.loadInfo;
    this.isAssignedCarrier = !!this.loadInfo?.assignedCarrier?.carrierId;
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    const jobId = this.loadInfo?.id;
    const url = `${environment.backendWorkQueueUrl}/${Const.API_GET_CHANNELS_FOR_TRACKTRACE_TASK(jobId)}`;
    this.api.GET(url).subscribe({
      next: (resp) => {
        this.slackChannels = (resp.data.channels || []).map(item => ({
          channel: item.channel,
          channelId: item.channelId
        }));
        this.onGetDataDone();
        this.isLoading = false;
      },
      error: (err) => {
        UIHelper.showErr(err);
        this.isLoading = false;
      }
    });
  }

  onGetDataDone() {
    const requireAction = this.listActions[0].value;
    const content = this.generateContent(requireAction);
    this.model = {
      requireAction,
      plainText: content.plainText,
      channelIds: []
    }
    this.createFormInput(this.model);
    this.setEnableFormGroup(true);
    this.isSendButtonDisabled = true;
  }

  generateContent(type: string) {
    if (!this.loadInfo) return { plainText: '' };
    let shouldShowState = false;
    let state = '';
    const pickupTime = this.loadInfo?.pickDt?.time;
    if (pickupTime) {
      shouldShowState = true;
      const diff = new Date(pickupTime).getTime() - new Date().getTime();
      const absDiff = Math.abs(diff);
      const days = Math.floor(absDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((absDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((absDiff % (1000 * 60 * 60)) / (1000 * 60));
      let time = '';
      if (days) time = `${days}d `;
      time += `${hours}h:${minutes}m`;
      state = `${time} ${diff > 0 ? 'left before' : 'after'} PU`;
    }
    const { hyperLinkText, hyperLinkUrl } = BizUtil.createHyperLinkForJob(this.loadInfo); 
    const defaultReason = this.isAssignedCarrier ? '' : '';
    if (type === 'need-recovery') {
      return {
        plainText: `[URGENT${shouldShowState ? ` - ${state}` : ''}] <${hyperLinkText}> needs recovery\nReason: ${defaultReason}`
      };
    } else {
      return {
        plainText: `${shouldShowState ? `[${state}] ` : ''}<${hyperLinkText}> needs attention\nReason: ${defaultReason}`
      };
    }
  }

  onChangeRequiredAction(value: string) {
    const content = this.generateContent(value);
    this.setItemValue('plainText', content.plainText);
  }

  getContentPlaceholder(): string {
    return 'Enter reason...';
  }

  onChannelSelectChange(value: string[]) {
    this.showChannelError = !value?.length;
    this.isSendButtonDisabled = !value?.length;
  }

  closeModal() {
    this.modalRef.close();
  }

  onSubmit() {
    let data = this.getFormData_JSON(true);
    const { hyperLinkText, hyperLinkUrl } = BizUtil.createHyperLinkForJob(this.loadInfo);
    const plainText = data?.plainText || '';
    const richText = plainText.replace(hyperLinkText, `${hyperLinkUrl}|${hyperLinkText}`);
    data.richText = richText;
    
    const url = `${environment.backendWorkQueueUrl}/${Const.API_NOTIFY_SLACK()}`;
    this.isSubmitting = true;
    this.api.POST(url, data).subscribe({
      next: (resp) => {
        this.isSubmitting = false;
        UIHelper.showSuccess('Notify Slack successfully');
        this.onUpdateSuccess(resp);
        this.closeModal();
      },
      error: (err) => {
        this.isSubmitting = false;
        UIHelper.showErr(err);
      }
    });
  }
}
