<button *ngIf="isVisibleOK" class="btn-save btn2" style="min-width: 120px;" nz-button nzType="primary" 
  [nzDanger]="nzOkDanger" [nzLoading]="onProgress"
  (click)="onBtnOK()" [disabled]="!canClickOK">
  <i *ngIf="!onProgress && nzIconOK" nz-icon [nzType]="nzIconOK" nzTheme="outline"></i>
  {{labelOK}}
</button>
<button *ngIf="isVisibleCancel" class="btn2" nz-button nzType="default" (click)="onBtnCalcel()" style="min-width: 120px;" [disabled]="!canClickCancel">
  <i nz-icon *ngIf="nzIconCancel" [nzType]="nzIconCancel" nzTheme="outline"></i>{{labelCancel}}
</button>
<ng-container>
  <div style="display: flex; flex-grow: 1;">
    <button *ngIf="isVisibleLeftButton" nzType="primary" class="btn2" nz-button (click)="onClickLeftButton()" style="min-width: 120px;">
      <i nz-icon *ngIf="nzIconCancel" [nzType]="nzIconCancel" nzTheme="outline"></i>{{labelLeftButton}}
    </button>
  </div>
</ng-container>

