import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { BaseFormDialog } from '@app/dialogs/base-form-dialog';
import { Utils } from '@services/utils';
import { Const } from '@const/Const';
import { Log } from '@services/log';
import { DateUtil } from '@services/date-util';
import { UIHelper } from '@services/UIHelper';

@Component({
  selector: 'app-warp-tracking-url',
  standalone: true,
  imports: [CommonModule, FormsModule, NzButtonModule, NzInputModule, NzIconModule],
  templateUrl: './warp-tracking-url.component.html',
  styleUrl: './warp-tracking-url.component.scss'
})
export class WarpTrackingUrlComponent extends BaseFormDialog implements OnInit {
  trackingUrl: string;
  driver: any;
  jobCode: string;
  jobId: string;
  pickDt: any;
  isSendingSMS = false;
  
  constructor(
    @Inject(NZ_MODAL_DATA) data: any
  ) {
    super(data);
    this.trackingUrl = data.trackingUrl;
    this.driver = data.driver;
    this.jobId = data.jobId;
    this.jobCode = data.jobCode;
    this.pickDt = data.pickDt;
  }

  ngOnInit(): void {
  }

  private _smsConttent;
  get smsContent() {
    if(this._smsConttent) return this._smsConttent;
    let pickTimeTxt = 'today';
    if (this.pickDt?.time && this.pickDt?.timezone) {
      pickTimeTxt = DateUtil.displayLocalTimeV2(this.pickDt.time, { timezone: this.pickDt.timezone, format: 'MMM D' });
      pickTimeTxt = `on ${pickTimeTxt}`;
    }
    //default content
    return `${Utils.getFullName(this.driver)}, this is Warp, you have a route ${pickTimeTxt}. Please click the link to go to driver app then START route [${this.jobCode}].`
  }

  set smsContent(value) {
    this._smsConttent = value;
  }

  
  copyUrl() {
    Utils.copyTextToClipboard(this.trackingUrl, e => {
      if (e) {
        UIHelper.showErr('Error copying URL, Please try again');
      } else {
        UIHelper.showSuccess('Successfully', 'Tracking URL has been copied to clipboard');
      }
    });
  }

  onSendSMS() {
    this.isSendingSMS = true;
    let text = this.smsContent;
    if (text) {
      text += '\n\n';
    }
    text += this.trackingUrl;
    let params = {
      driverId: this.driver._id,
      phone: this.driver.phone,
      text: text,
    };
    let url = `${Const.APIURI_JOBS}/${this.jobId}/driver_sms`;
    this.api.POST(url, params).subscribe({
      next: (resp) => {
        Log.d('send driver sms done ', resp);
        this.isSendingSMS = false;
        UIHelper.showSuccess(`An SMS has been sent to ${Utils.getFullName(this.driver)} .`);
        this.onUpdateSuccess(resp);
      },
      error: (err) => {
        UIHelper.showErr(err);
        this.isSendingSMS = false;
      }
    });
    this.closeDialog();
  }
  
  onCancel() {
    this.closeDialog();
  }
} 