<div class="load-title" [class.is-sticky]="isSticky" #loadTitleContainer *ngIf="loadInfo">
  <div class="load-title-container">
    <div *ngIf="displayInfo?.onHold?.length">
      <span class="warning">Attention:</span> Some shipments on this route are currently <code>On Hold</code>:
      <nz-tag *ngFor="let s of displayInfo.onHold">{{ s }}</nz-tag>
    </div>
    <div class="load-title-header">
      <div *ngIf="displayInfo?.lineHaul" class="linehaul-label">Linehaul</div>
      <div *ngIf="displayInfo?.onHold?.length" class="on-hold-label">Hold
        <!-- <div nz-tooltip [nzTooltipTitle]="holdTooltip">
          <span>Hold</span>
          <ng-template #holdTooltip>
            <div>
              <span class="warning">Attention:</span> Some shipments on this route are currently <code>On Hold</code>:
              <nz-tag *ngFor="let s of displayInfo.onHold">{{ s }}</nz-tag>
            </div>
          </ng-template>
        </div> -->
      </div>
      <div class="load-title-text">
        {{ displayInfo.clientName }} - {{ displayInfo.loadCode }}
      </div>
      <div class="load-title-status" [ngClass]="getStatusClass(loadInfo.status)">
        {{ displayInfo.routeStatus }}
      </div>
      <div class="route-link flex">
        <div>
          <a *ngIf="jobHyperLink" #jobLink href="{{jobHyperLink.hyperLinkUrl}}" target="_blank"
            style="height:0;font-size:0;border: 0;">{{jobHyperLink.hyperLinkText}}</a>
        </div>
        <div>
          <a class="route-link-icon" nz-icon nzType="link" (click)="onBtnCopyRouteLink()" nz-tooltip nzTooltipTitle="Copy link for internal WARP team"></a>
        </div>
      </div>
      <!-- <div *ngIf="displayInfo.onHold" class="hold-badge">HOLD</div> -->
      <div>
        <job-live-icon [isOnline]="displayInfo.isDriverOnline"></job-live-icon>
      </div>
    </div>
    <div class="load-title-info-container">    
      <div class="load-title-info">
        <span class="bold">{{ displayInfo.pickupLocation }} → {{ displayInfo.deliveryLocation }}</span>
      </div>
    <div class="load-title-sub-info">
      <span>
        {{ displayInfo.totalStops }} stops | {{ displayInfo.totalMiles }} | {{ displayInfo.trafficTime }} | {{ displayInfo.vehicleType }}
      </span>
      </div>
    </div>
  </div>
</div>