import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ModalHelper } from "@app/utils/modalHelper";
import { UIHelper } from "@services/UIHelper";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { RouteFilterModal } from "../track-trace-modals/route-filter";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzButtonModule } from "ng-zorro-antd/button";
import { TrackTraceBookmarkModal } from "../track-trace-modals/bookmark";
import { StatisticsService } from "@app/track-trace/services/statistics.service";
import { TrackTraceService } from "@app/track-trace/services/track-trace.service";
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { BaseComponent } from '@abstract/BaseComponent';
import { getInjector } from '@services/index';
import { NzModalService } from 'ng-zorro-antd/modal';

const CustomInputs = [
  CommonModule,
  NzIconModule,
  NzToolTipModule,
  NzBadgeModule,
  NzPopoverModule,
  NzButtonModule,
  NzDropDownModule,
  RouteFilterModal,
  TrackTraceBookmarkModal,
]

@Component({
  selector: 'track-trace-sidebar-menu',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  standalone: true,
  imports: CustomInputs,
  providers: [ModalHelper]
})

export class TrackTraceSidebarMenu extends BaseComponent implements OnInit {
  constructor(
    private statisticsService: StatisticsService,
    private trackTraceService: TrackTraceService
  ) {
    super();
  }
  public txtOK = "OK";
  public txtCancel = "Cancel";
  public isOnline = true;
  public navMenuItems;
  public currentTaskId: string = '';

  displayInfo: any = {
    bookmarkBadge: 0,
  };
  routeFilterVisible: boolean = false;
  bookmarkVisible: boolean = false;

  ngOnInit() {
    // task change => fetch bookmark statistic
    this.trackTraceService.getWorkTask().subscribe(task => {
      this.currentTaskId = task?.object?.entityId || '';
      this.statisticsService.fetchBookmarkStatistic();
    });
    this.statisticsService.getBookmarkStatistic().subscribe(statistic => {
      const totalUrgent = statistic?.totalUrgent ?? 0;
      const totalWorking = statistic?.totalWorking ?? 0;
      const totalManual = statistic?.totalManual ?? 0;
      this.displayInfo.bookmarkBadge = totalUrgent + totalWorking + totalManual;
    });
    this.initMenuItems();
  }

  get userName() {
    return this.authUser ? this.authUser.fullName || this.authUser.email : '';
  }

  get shortUsername() {
    const fullName = this.authUser?.fullName || '';
    const arrFullName = fullName.split(' ');
    if (arrFullName.length > 1) {
      return `${arrFullName[0].charAt(0)}${arrFullName[arrFullName.length - 1].charAt(0)}`;
    }
    return arrFullName[0].charAt(0);
  }

  onBtnManualCreateTask() {
    UIHelper.showInfo('Is comming soon');
  }

  changeRouteFilterVisible() {
    this.routeFilterVisible = !this.routeFilterVisible;
    this.bookmarkVisible = false;
  }

  changeBookmarkVisible() {
    this.bookmarkVisible = !this.bookmarkVisible;
    this.routeFilterVisible = false;
  }

  onMenuItemClick(item) {
    if (!item.key) {
      return;
    }
    switch (item.key) {
      case 'logout':
        this.confirmLogout();
        break;
      default:
        break;
    }
  }

  private initMenuItems() {
    this.navMenuItems = [
      {
        label: 'Log out',
        key: 'logout',
        nzIcon: 'logout'
      }
    ]
  }

  confirmLogout() {
    const modalService = getInjector().get(NzModalService);
    modalService.confirm({
      nzTitle: 'Sign out',
      nzContent: 'Are you sure you want to sign out.',
      nzWrapClassName: 'vertical-center-modal',
      nzOkText: this.txtOK,
      nzOkType: 'primary',
      nzOnOk: () => {
        this.api.logout();
        this.goLogin();
      },
      nzCancelText: this.txtCancel,
      nzOnCancel: () => {}
    })
  }
}