<div class="suggestions-list">
  <div class="suggestions-item">
    <ng-container *ngIf="!isDriverActive">
      <div class="item-label">Contact the driver to:</div>
      <div class="item-content">
          <li>Check if they’re still {{ stopAction }} or about to depart</li>
          <li>Tell them to turn on live tracking</li>
      </div>
      <div class="item-note">
        <div>If they have already departed, ask them to confirm departure in the Driver App. If they can’t, update the status yourself.</div>
        <div>Record the details below.</div>
      </div>
    </ng-container>
    
    <ng-container *ngIf="isDriverActive">
      <div class="item-note">
        <div>If the driver has been at the stop for a long time, call them to check their status.</div>
        <div>Record the details below.</div>
      </div>
    </ng-container>
  </div>
</div>