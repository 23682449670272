<div class="note-box-container">
  <div style="width: 100%;">
    <ng-container *ngIf="isEmptyNotes">
      <nz-empty nzNotFoundContent="Empty notes" style="color: gray;font-weight: 500;"></nz-empty>
    </ng-container>
    <div *ngIf="!isEmptyNotes" class="note-list">
      <div *ngIf="pinnedItems.length > 0" class="pinned-list">
        <div class="bottom5 flex">
          <div class="label">
            <span nz-icon nzType="pushpin" nzTheme="outline"></span>
            <span class="f13 left5">{{pinnedItems.length}} Pinned</span>
          </div>
        </div>
        <div class="group-content">
          <ng-container *ngFor="let item of pinnedItems" [ngTemplateOutlet]="noteItem"
            [ngTemplateOutletContext]="{item, editingId}"></ng-container>
        </div>
      </div>
      <div *ngIf="noteItems.length > 0">
        <div class="group-content">
          <ng-container *ngFor="let item of noteItems" [ngTemplateOutlet]="noteItem"
            [ngTemplateOutletContext]="{item, editingId}"></ng-container>
        </div>
      </div>
    </div>
</div>

<ng-template #noteItem let-item="item" let-editingId="editingId">
  <div class="note-list-item">
    <div class="poster">
      <ng-container *ngIf="isExfreight(item)">
        <nz-avatar nzIcon="user" nzSrc="assets/img/exfreight.png"></nz-avatar>
      </ng-container>
      <ng-container *ngIf="!isExfreight(item)">
        <nz-avatar [nzText]="getAuthorName(item)?.slice(0,1)" [ngStyle]="{ 'background-color': '#7265e6' }"></nz-avatar>
      </ng-container>
      <div class="info">
        <div class="name semibold">
          <span *ngIf="!item.insert?.by.id">{{getAuthorName(item, 'No name')}}</span>
          <span *ngIf="item.insert?.by.id" [ngClass]="{'deleted': item.insert?.by?.isDeleted}">{{getAuthorName(item, 'No name')}}</span>
          <!-- <span *ngIf="item.pin?.when" nz-icon nzType="pushpin" nzTheme="fill" class="left10" style="color: #FAAD14;"></span> -->
          <span class="time">{{item.time}}</span>
        </div>
        
        <div class="top5">
          <div style="line-height: 22px;word-wrap: break-word;white-space: pre-wrap;user-select: text;" *ngIf="item.content && editingId !== item.id" [innerHTML]="item.content"></div>
          <div class="time" style="margin-top: 6px;" *ngIf="shouldShowUpdate(item)">Updated: {{ $formatDate(item.update.when) }} by {{ getAuthorName(item, 'No name') }}</div>
          <div class="top10" *ngIf="item?.attachedFiles?.length > 0">
            <div *ngFor="let image of item.attachedFiles">
              <img (click)="viewImageItem(image.url)" [src]="image.url" alt="" style="width: 180px; cursor: pointer;" >
            </div>
          </div>

          <div class="edit-form" *ngIf="editingId === item.id">
            <textarea
              nz-input
              [(ngModel)]="newNote"
              [nzAutosize]="{ minRows: 2, maxRows: 4 }"
              placeholder="Edit your note here"
            ></textarea>
            <div class="edit-actions">
              <div class="input-actions-buttons">
                <div class="button cancel-input" [ngClass]="{'disabled': isLoading}" (click)="onCancelEdit()">Cancel</div>
                <div class="button save-input" [ngClass]="{'disabled': isLoading || !newNote.trim()}" (click)="onSubmitEdit()">
                  Save<span *ngIf="isLoading" nz-icon nzType="loading" nzSpin nzTheme="outline" class="left5"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <i nz-icon nzType="more" nzTheme="outline" class="clickable" nz-popover [nzPopoverContent]="moreActions"></i>
      <ng-template #moreActions>
        <div class="more-actions-note">
          <div *ngIf="!isPinned(item)" class="action" (click)="onBtnPin(item)">Pin this note</div>
          <div *ngIf="isPinned(item)" class="action" (click)="onBtnUnPin(item)">Unpin this note</div>
          <div class="action" (click)="onEdit(item)" *ngIf="canEditNote(item)">Edit message</div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>