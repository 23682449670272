import { Injectable } from "@angular/core";
import { TimeWindow } from "@app/interfaces";
import { DateUtil } from "@services/date-util";

export type CurrentStateType = 'ontime' | 'might_be_late' | 'lated';

export interface TaskCurrentState {
  state: CurrentStateType,
  duration: number
}

@Injectable()
export class TaskCurrentStateServiceV2 {
  
  public getCurrentStateForTaskConfirmETA(ops: {scheduleWindow, etaWindow, currentIsoTime}) {
    try {
      const {scheduleWindow, etaWindow, currentIsoTime} = ops;
      let duration: number = 0;
      let result: TaskCurrentState = {
        state: 'ontime',
        duration: 0,
      }
      let difFromNow = DateUtil.getTimeDurationFromWindow({
        from: currentIsoTime,
        to: scheduleWindow.to
      })
      // chưa bị trễ
      if (difFromNow > 0) {
        let windowOntime: TimeWindow = {
          from: etaWindow.to ?? etaWindow.from,
          to: scheduleWindow.to
        }
        duration = DateUtil.getTimeDurationFromWindow(windowOntime);
        if (duration >= 0) {
          let pastFromStart = DateUtil.getTimeDurationFromWindow({
            from: scheduleWindow.from,
            to: etaWindow.to ?? etaWindow.from
          });
          result.state = 'ontime';
          result.duration = pastFromStart;
        }
        else {
          result.state = 'might_be_late';
          result.duration = Math.abs(duration);
        }
      }
      else {
        result.state = 'lated';
        result.duration = Math.abs(difFromNow);
      }
      return result;  
    }
    catch (e) {
      return;
    }
  }
}