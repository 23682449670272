<div *ngIf="!isLoading && isHasTask">
  <div *ngFor="let task of tasks">
    <div class="flex-column" *ngIf="isTaskConfirmBooking(task)">
      <div class="task-header">
        <div class="task-title">Confirm Booking</div>
        <div class="items-confirmed" [ngClass]="confirmItemStatus">
          {{ displayInfo.itemsConfirmed }}
        </div>
        <div class="confirm-booking-actions">
          <div class="action-item" (click)="onBtnOpenChecklistDialog()">View checklist</div>
          <!-- <div class="action-item" *ngIf="isAssignedCarrier" (click)="onBtnOpenSendEmailDialog(task)">Resend email</div> -->
        </div>
      </div>
      <div class="task-content" *ngIf="isAssignedCarrier">
        <div *ngIf="emailWonBidSentBy" class="small-text italic-text flex">
          <span nz-icon nzType="mail" nzTheme="outline" class="right5"></span>
          <div class="right5">
            Email "You won the bid" Sent
          </div>
          <warp-when-by [model]="emailWonBidSentBy" [viewTemplate]="tplSendWonBidEmail"></warp-when-by>
          <ng-template #tplSendWonBidEmail let-displayInfo="displayInfo">
            <div>{{ displayInfo.createdWhen }} by WARP System</div>
          </ng-template>
        </div>
        <div *ngFor="let item of logSendConfirmBookingEmail; let i = index">
        <div class="small-text italic-text flex"><!-- wonbid email, default sent by system -->
          <span nz-icon nzType="mail" nzTheme="outline" class="right5"></span>
          <div class="right5">Email "Confirm Booking" {{ i == 0 ? 'Sent' : 'Resent'}}</div>
          <warp-when-by [model]="item?.insert" [viewTemplate]="tplSendConfirmBookingEmail"></warp-when-by>
          <ng-template #tplSendConfirmBookingEmail let-displayInfo="displayInfo">
            <div>{{ displayInfo.createdWhen }} by {{ displayInfo.createdBy }}</div>
          </ng-template>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>