import { Component, Inject, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { BaseFormDialog } from '@app/dialogs/base-form-dialog';
import { Const } from '@const/Const';
import { DateUtil } from '@services/date-util';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { UIHelper } from '@services/UIHelper';
import { Utils } from '@services/utils';
import { FormFooter } from '@app/form-footer/form-footer';
@Component({
  selector: 'app-email-load-tender',
  standalone: true,
  imports: [CommonModule, NzFormModule, NzInputModule, NzButtonModule, NzIconModule, NzSpinModule, ReactiveFormsModule, FormFooter],
  templateUrl: './index.html',
  styleUrl: './index.scss'
})
export class EmailLoadTenderComponent extends BaseFormDialog implements OnInit {
  protected formGroupDeclaration: FormGroupDeclaration = {
    contactName: { label: "Name" },
    contactEmail: { label: "Email", required: true },
    remark: { label: "Remark" },
  };

  @Input() jobId;
  @Input() carrier;
  @Input() loadTenderFileId;
  public isError = false;
  public isLoading = false;
  public confirmData: any = {};
  public inConfirm: boolean = false;
  public lastSent: string | null;
  public contacts: string[] = [];

  constructor(@Inject(NZ_MODAL_DATA) data: any) {
    super(data);
    this.jobId = data.jobId;
    this.carrier = data.carrier;
    this.loadTenderFileId = data.loadTenderFileId;
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.createFormInput();
    
    const basicInfo = this.carrier?.basicInfo || {};
    let contacts = basicInfo?.contacts || [];
    let contact = basicInfo.owner?.email ? basicInfo.owner : (contacts[0] || {});
    this.contacts = Utils.uniqElementsArray([contact?.email, ...contacts.map(it => it.email)].filter(it => it));

    this.setItemValue("contactName", basicInfo.name || "Carrier");
    this.setItemValue("contactEmail", contact.email);
    this.getItemByKey("contactEmail").disable();

    this.api
      .POST(`${Const.APIURI_JOBS}/get_latest_send_file_email`, {
        jobId: this.jobId,
        type: "loadTender",
      })
      .subscribe(
        (resp) => {
          let lastSentInfo = resp?.data?.insert;
          if (!lastSentInfo) return;
          this.lastSent = DateUtil.dateToString(lastSentInfo.when, "M/DD/YYYY");
        },
        (err) => {}
      );
  }

  onEditEmail() {
    if (this.getItemByKey("contactEmail").enabled)
      this.getItemByKey("contactEmail").disable();
    else this.getItemByKey("contactEmail").enable();
  }


  onBtnSave() {
    let json: any = this.getFormData_JSON(true);
    if (!this.inConfirm) {
      this.confirmData = json;
      this.inConfirm = true;
      return;
    }

    const data = {
      contacts: [
        {
          name: json.contactName,
          email: json.contactEmail,
        },
      ],
      remark: json.remark,
      jobId: this.jobId,
      // fileId: this.loadTenderFileId,
      type: "loadTender",
    };
    this.startProgress();
    this.isLoading = true;
    this.api.POST(`${Const.APIURI_JOBS}/send_file_email`, data).subscribe(
      (resp) => {
        this.stopProgress();
        this.isLoading = false;
        UIHelper.showSuccess("Load tender email has been sent successfully");
        this.closeDialog();
      },
      (err) => {
        this.stopProgress();
        UIHelper.showErr("Send load tender email failed");
        this.isLoading = false;
        this.closeDialog();
      } 
    );
  }
  onBtnCancel() {
    if (this.inConfirm) {
      this.inConfirm = false;
      return;
    }
    this.closeDialog();
  }

}
