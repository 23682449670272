<div class="history-container">
  <div *ngIf="!isHasRouteHistory()">
    <nz-empty nzNotFoundContent="No history" style="color: gray;font-weight: 500;"></nz-empty>
  </div>
  <div *ngIf="isHasRouteHistory()" class="task-history-list">
    <nz-timeline>
      <nz-timeline-item *ngFor="let item of tasksLog">
        <div style="white-space: pre-line; padding-top: 2px">
          {{ item.desc }}
        </div>
        <div class="desc-text">
          <span>{{ item.createdWhen }}</span>
          <span>, by {{ item.createdBy }}</span>
        </div>
      </nz-timeline-item>
    </nz-timeline>
  </div>
</div>
