import { CompositeLayer } from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers'
import { MAP_PIN_ICON_MAPPING } from './icon';
import { getLngLat } from '@app/utils/map-v2.util';
import { Warehouse } from '@wearewarp/types/data-model';

export default class MapPinLayer extends CompositeLayer {
    override id: string
    static override layerName = "MapPinLayer"
    locations: any[] = []
    data: any
    getInfo: any
    options?: any

    constructor(id: string, warehouses: Warehouse[], options?: {}) {
        super({id, data: {warehouses}})
        this.options = options
        this.id = id
        this.data = warehouses
        this.locations = this.processLocations(warehouses)
    }

    processLocations(locations: Warehouse[]) {
        const getWarehouseLatlng = (wh: Warehouse) => Object.assign({
            latitude: wh.pickAddr?.metadata?.latitude ?? 0.0,
            longitude: wh.pickAddr?.metadata?.longitude ?? 0.0,
        })
        locations = locations.map(it => Object.assign({}, it, {
            type: 'WAREHOUSE',
            warehouse: it,
            coordinates: getLngLat(getWarehouseLatlng(it)),
            id: it.id,
            icon: this.options?.icon ?? 'red'
        }))
        return locations
    }

    createIconLayer() {
        const mapping = MAP_PIN_ICON_MAPPING
        return new IconLayer({
            id: `icon-layer-${this.id}`,
            data: this.locations,
            pickable: true,
            iconAtlas: '/assets/svg/map_pin.svg',
            // iconAtlas: 'https://warp-public-media.s3.us-west-2.amazonaws.com/images/svg/icons/map_pin-241027-1.svg',
            iconMapping: mapping,
            getIcon: d => d.icon,
            getColor: d => d.color,
            getPosition: d => d.coordinates,
            sizeScale: 29,
            getSize: d => 1,
            parameters: {
                depthTest: false
            }
        });
    }

    renderLayers() {
        return [
            this.createIconLayer()
        ]
    }
}
