import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';

const CustomInputs = [
  CommonModule,
  NzButtonModule,
  NzIconModule
]

@Component({
  selector: '[form-footer]',
  templateUrl: './form-footer.html',
  styleUrls: ['./form-footer.scss'],
  host: {'class': 'form-footer'},
  standalone: true,
  imports: [CustomInputs]
})
export class FormFooter extends BaseComponent {
  @Input() onProgress = false;
  @Input() canClickOK = false;
  @Input() canClickCancel = false;
  @Input() nzOkDanger = false;
  @Input() nzIconOK = 'save';
  @Input() nzIconCancel = 'close';
  @Input() labelOK = 'Save';
  @Input() labelCancel = 'Cancel';
  @Input() isVisibleOK = true;
  @Input() isVisibleCancel = true;
  @Input() isVisibleLeftButton = false;
  @Input() labelLeftButton = 'Left Button';
  @Output() onOK: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickLeftBtn: EventEmitter<any> = new EventEmitter<any>();
  
  onBtnOK() {
    this.onOK.emit();
  }
  
  onBtnCalcel() {
    this.onCancel.emit();
  }
  onClickLeftButton() {
    this.onClickLeftBtn.emit();
  }
}