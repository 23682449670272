import { Injectable } from "@angular/core";
import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { ApiService } from "@services/api.service";
import { ResponseTrackTraceStatistic } from "@wearewarp/types-server-workqueue/operator";
import { BehaviorSubject } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  private bookmarkStatistic = new BehaviorSubject<ResponseTrackTraceStatistic | null>(null);

  constructor(private api: ApiService) {}

  getBookmarkStatistic() {
    return this.bookmarkStatistic.asObservable();
  }

  fetchBookmarkStatistic() {
    this.bookmarkStatistic.next(null);
    const url = `${environment.backendWorkQueueUrl}/${Const.APIURI_TRACK_TRACE_STATISTIC}`;
    this.api.GET(url).subscribe(resp => {
      this.bookmarkStatistic.next(resp.data as unknown as ResponseTrackTraceStatistic);
    });
  }
}
