import { Component, Input } from '@angular/core';
import { StringUtils } from './utils';

@Component({
    selector: 'warp-status',
    styleUrl: 'index.scss',
    template: '<code [class]="classes">{{ display }}</code>',
    imports: [],
    standalone: true
})
export class StatusComponent {
    _status: string = ''
    display: string = ''
    classes: string[] = []
    @Input() filled: string | undefined | null = undefined
    @Input() size: string = ''
    @Input() set status(v: string) {
        this._status = v ?? ''
        this.display = StringUtils.normalize(v == 'created' ? 'pending' : this._status).toUpperCase()
        const style = this._status.toLowerCase().replace('-', '').replace('_', '')
        const classes: string[] = []
        if (style) {
            classes.push(style)
        }
        if (this.size) {
            classes.push(this.size)
        }
        if (this.filled !== undefined) {
            classes.push('filled')
        }
        this.classes = classes
    }
}