import { IconLayer, ScatterplotLayer } from '@deck.gl/layers'
import { CompositeLayer } from '@deck.gl/core';
import { DRIVER_PIN_ICON_MAPPING } from './icon';
import { DeliveryUpdate } from '@app/interfaces/map-v2/delivery-update';

export default class LiveTrackingLayer extends CompositeLayer {
    static override layerName = "LiveTrackingLayer"
    data: any[]
    options: any = {}
    _baseLayer: any = null
    _layer: any = null

    constructor(id, locations: DeliveryUpdate[], options = {}) {
        super({id, data: {locations, options}})
        this.options = options
        this.data = locations?.map(this.processLocationData).filter(it => it)

        this._layer = this.createDriverLocationLayer()
        this._baseLayer = this.createBaseDotLocationLayer()
    }

    loadLocations(locations) {
        if (locations?.length !== this.data?.length)
            this.data = locations?.map(this.processLocationData).filter(it => it)
        else {
            for (let i = 0; i < locations.length; i++) {
                const updated = this.processLocationData(locations[i])
                Object.assign(this.data[i], updated)
            }
        }
    }

    processLocationData(data: DeliveryUpdate) {
        const { location, ts, source } = data
        if (!location) return null
        const { speed, heading, latitude, longitude } = location || {}
        const mph = source && source !== 'driver-app' ? speed || 0 : (speed || 0) * 3600 / 1609.34
        const icon =  mph >= 55 ? "0" : mph >= 30 ? "1" : mph >= 15 ? "2" : mph >= 5 ? "3" : mph >=2 ? "4" : "5"
        let bearing = 90 - (heading || 0)
        if (bearing < 0)
            bearing += 360

        return {
            ts,
            coordinates: [location.longitude, location.latitude],
            icon,
            angle: bearing,
            type: 'GPS',
            gps: data,
            live: ts > Date.now() - 60000
        }
    }

    createBaseDotLocationLayer() {
        return new ScatterplotLayer({
            id: `${this.id}-live-base-layer`,
            data: this.data,
            pickable: true,
            getPosition: d => d.coordinates, // [...(d.coordinates as number[]), 0.0001],
            stroked: true,
            filled: true,
            opacity: 0.8,
            getRadius: d => 16,
            radiusMinPixels: 16,
            radiusMaxPixels: 16,
            getFillColor: d => d.live ? [64, 113, 203] : [100, 100, 100],
            getLineColor: d => d.live ? [64, 113, 203] : [100, 100, 100],
            transitions: {
                getFillColor: 200,
                getPosition: {
                    duration: this.options?.noAnimation ? 0 : 200,
                },
            },
            updateTriggers: {
                getPosition: [this.data],
                getFillColor: [this.data]
            }
        });
    }

    createDriverLocationLayer() {
        return new IconLayer({
            id: `${this.id}-live-layer`,
            data: this.data,
            pickable: true,
            getPosition: d => d.coordinates,
            iconAtlas: 'assets/svg/map-v2/driver_pin.svg',
            // iconAtlas: 'https://warp-public-media.s3.us-west-2.amazonaws.com/images/svg/icons/driver_pin-240925-1.svg',
            iconMapping: DRIVER_PIN_ICON_MAPPING,
            getIcon: d => d.icon + (d.live ? '' : '1'),
            getAngle: d => d.angle,
            getSize: d => 24,
            billboard: false,
            transitions: {
                getPosition: {
                    duration: this.options?.noAnimation ? 0 : 200,
                }
            },
            updateTriggers: {
                getPosition: [this.data]
            },
            parameters: {
                depthTest: false
            }
        });
    }

    renderLayers() {
        return [
            this._layer
        ];
    }

}
