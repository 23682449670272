import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CommonModule } from '@angular/common';
import { Job, Stop } from '@wearewarp/types/data-model';
import { TrackTraceService } from 'src/app/track-trace/services/track-trace.service';
import { Utils } from '@services/utils';
import { DialogService } from '@app/dialogs/dialog.service';
import { AddCustomTrackingComponent } from './add-custom-tracking/add-custom-tracking.component';
import { BaseComponent } from '@abstract/BaseComponent';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { WarpTrackingUrlComponent } from './warp-tracking-url/warp-tracking-url.component';
import { DriverMeta } from '@wearewarp/types/data-model/types/Metadata';
import { UIHelper } from '@services/UIHelper';
import { isAdmin, isAdminReadOnlyRole } from '@services/auth.check-role';

@Component({
  selector: 'app-tracking-info',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    NzIconModule,
    NzToolTipModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './tracking-info.component.html',
  styleUrl: './tracking-info.component.scss'
})
export class TrackingInfoComponent extends BaseComponent {
  loadInfo: Job;
  token: string;
  driverUrl: string;
  driver: DriverMeta;
  jobCode: string;
  currentStop: Stop;
  
  constructor(
    private trackTraceService: TrackTraceService
  ) {
    super();
    this.trackTraceService.getLoadInfo().subscribe(loadInfo => {
      if (loadInfo) {
        this.loadInfo = loadInfo;
        this.token = this.loadInfo?.assignedDriver?.token;
        this.driverUrl = this.getTrackingLink();
        this.driver = this.loadInfo?.driver;
        this.jobCode = this.loadInfo?.code;
        this.currentStop = this.trackTraceService.getCurrentStop();
      }
    });
  }

  copyTrackingLink() {
    if (!this.token) {
      return UIHelper.showWarning('No tracking link available', 'Driver has not been assigned or tracking is not available.');
    }
    try {
      Utils.copyTextToClipboard(this.driverUrl, e => {
        if (e) {
          UIHelper.showErr('Error copying URL, please try again');
        } else {
        UIHelper.showSuccess('Successfully', 'Tracking URL has been copied to clipboard');
        }
      });
    } catch (err) {
      UIHelper.showErr('Error copying URL, please try again');
      console.error('Error copying tracking link:', err);
    }
  }

  getTrackingLink() {
    if (!this.token) {
      return '';
    }
    const nativeAppLink = 'https://native-app.wearewarp.com/driver';
    return `${nativeAppLink}/${this.token}`;
  }

  public getCustomTrackingLink() {
    if (!this.loadInfo?.customTracking?.length) return undefined;
    let links = this.loadInfo.customTracking;
    return links[links.length - 1];
  }

  openWarpTrackingModal() {
    if (!this.token) {
      return UIHelper.showWarning('No tracking link available', 'Driver has not been assigned or tracking is not available.');
    }

    DialogService.openFormDialog(WarpTrackingUrlComponent, {
      nzData: {
        trackingUrl: this.driverUrl,
        jobId: this.loadInfo?.id,
        driver: this.driver,
        jobCode: this.jobCode,
        pickDt: this.loadInfo?.pickDt
      },
      nzWidth: 600,
      nzClassName: 'modal-no-padding warp-tracking-url-modal',
    });
  }

  openExternalTrackingModal() {
    if (!this.canEditItem()) {
      return this.showErrRequirePiC();
    }
    
    DialogService.openFormDialog(AddCustomTrackingComponent, {
      nzData: {
        jobId: this.loadInfo?.id,
        links: this.loadInfo?.customTracking,
        updateSuccess: () => {
          this.trackTraceService.refreshLoadInfo();
        }
      },
      nzWidth: 600,
      nzClassName: 'modal-no-padding add-custom-tracking-form',
    });
  }

  goToExternalUrl() {
    const url = this.getCustomTrackingLink();
    if (url) {
      window.open(url, '_blank');
    }
  }

  editExternalUrl() {
    this.openExternalTrackingModal();
  }

  public canEditItem() {
    if (!isAdmin(this.authUser)) return false;
    if (isAdminReadOnlyRole(this.authUser)) return false;
    return true;
  }

  private showErrRequirePiC() {
    UIHelper.showErr('Only the person in charge can perform this action!  Please contact your administrator to perform this action.');
  }
}
