import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class LiveEtaService {
  private liveEtaInfo = new BehaviorSubject<any>(null);

  getLiveEtaInfo(): Observable<any> {
    return this.liveEtaInfo.asObservable();
  }

  setLiveEtaInfo(info: any) {
    this.liveEtaInfo.next(info);
  }
  
  
}
