import { Component, ViewChild } from '@angular/core';
import { TrackTraceService } from '@app/track-trace/services/track-trace.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { Job, WorkTask, WorkTaskHistory } from '@wearewarp/types/data-model';
import { Const } from '@const/Const';
import { LiveEtaService } from '@app/track-trace/services/live-eta.service';
import { DateUtil } from '@services/date-util';
import BizUtil from '@app/utils/biz.util';
import { UIHelper } from '@services/UIHelper';
import { NoteListComponent } from './note-list';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '@env/environment';
import { RequestWorkQueueCreateConversation } from '@wearewarp/types-server-workqueue/operator';
import { ApiService } from '@services/api.service';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { RouteNoteService } from './note.service';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { FormInputUploadImages } from '@app/input-upload-images';

const CustomInputs = [
  CommonModule, 
  FormsModule, 
  NzInputModule, 
  NzTagModule,
  NzTabsModule,
  NzTimelineModule,
  NzEmptyModule,
  NzIconModule,
  NzDividerModule,
  NoteListComponent,
  FormInputUploadImages,
]

@Component({
  selector: 'app-route-notes',
  templateUrl: './load-notes.component.html',
  styleUrls: ['./load-notes.component.scss'],
  standalone: true,
  imports: CustomInputs,
})
export class RouteNotesComponent {
  taskInfo: WorkTask;
  loadInfo: Job;
  taskType: string;
  stopIndexByTask: number;    // stop ứng với task hiện tại (có thể không phải stop focus của route)
  stopIndexCurrent: number;    // stop focus của route
  isDriverActive: boolean;
  noteContent: string = '';
  isSubmitting: boolean = false;
  liveEtaInfo: any;
  jobId: string;
  isLoading: boolean = true;
  uploadImages = [];
  private destroy$ = new Subject<void>();

  @ViewChild('uploadImagesForm') uploadImagesForm: FormInputUploadImages;
  
  constructor(
    private trackTraceService: TrackTraceService, 
    private liveEtaService: LiveEtaService,
    private routeNoteService: RouteNoteService,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.trackTraceService.getWorkTask().subscribe(task => {
      this.taskInfo = task;
      this.jobId = this.taskInfo?.object?.entityId;
      this.taskType = this.taskInfo?.type;
    });
    this.trackTraceService.getLoadInfo().subscribe(job => {
      this.onLoadInfoChange(job);
    });
    this.liveEtaService.getLiveEtaInfo().subscribe(info => {
      if (info) this.liveEtaInfo = info;
    });
    this.trackTraceService.getResetNotes().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.resetNote();
    });
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.uploadImages.forEach(file => {
      if ((file as any).preview) {
        URL.revokeObjectURL((file as any).preview);
      }
    });
  }

  onLoadInfoChange(job: Job) {
    this.loadInfo = job;
    this.routeNoteService.setJobId(this.jobId);
    this.isDriverActive = this.loadInfo?.assignedDriver?.isActive;
    const currentStop = this.trackTraceService.getCurrentStop();
    this.stopIndexCurrent = currentStop ? this.trackTraceService.findStopIndex(currentStop?.id) : null;
    this.stopIndexByTask = this.getStopIndexByWorkTask();
  }

  getStopIndexByWorkTask() {
    // stopSequence đã được +1 từ trước
    const stopSequence = this.taskInfo?.object?.metadata?.stopSequence;
    if (stopSequence) {
      return stopSequence - 1;
    }
    return null;
  }


  formatWorkLogItem(item: WorkTaskHistory) {
    return {
      desc: this.getDescFromLog(item),
      stopId: item?.object?.metadata?.stopSequence || 1,
      stopType: item?.object?.metadata?.stopType || 'PICKUP',
      createdWhen: DateUtil.displayLocalTime(DateUtil.timestampToDate(item.ts), DateUtil.getLocalTimeZone(), 'MM/DD/YYYY hh:mm a'),
      createdBy: this.getFullName(item)
    };
  }

  private getFullName(item: WorkTaskHistory) {
    let metadata = item?.subject?.metadata || {};
    if (metadata.fullName) return metadata.fullName;
    if (metadata.name) return metadata.name;
    return `${metadata.firstName || ''} ${metadata.lastName || ''}`.trim();
  }

  private getDescFromLog(log: WorkTaskHistory): string { 
    const states = log?.states || {};
    return states.note || '';
  }

  capitalizeCase(str: string): string {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  }

  get stopIndex() {
    return this.stopIndexByTask ?? this.stopIndexCurrent ?? 0;
  }
  get displayStopIndex() {
    return (this.stopIndex || 0) + 1;
  }

  get stopInfo() {
    return this.loadInfo?.stops?.[this.stopIndex];
  }

  get stopType() {
    return this.stopInfo?.type || 'PICKUP';
  }

  selectQuickNote(type: 'still-on-track' | 'not-responsive') {
    if (type === 'not-responsive') {
      this.noteContent = 'Both Driver & Carrier are not responsive';
      if (this.taskType !== Const.WorkRouteTask.etaConfirmation) {
        this.noteContent = `Stop ${this.displayStopIndex}\n${this.noteContent}`;
      }
    }
    else if (type === 'still-on-track') {
      this.noteContent = this.getDefaultNoteContent();
    }
  }

  getDefaultNoteContent() {
    switch (this.taskType) {
      case Const.WorkRouteTask.etaConfirmation: return this.getDefaultNote_ConfirmETA();
      case Const.WorkRouteTask.arrivalConfirmation: return this.getDefaultNote_ConfirmArrival();
      case Const.WorkRouteTask.departureConfirmation: return this.getDefaultNote_ConfirmDeparture();
      case Const.WorkRouteTask.enrouteTracking: return this.getDefaultNote_EnrouteTracking();
      default: return '';
    }
  }

  getDefaultNote_ConfirmETA() {
    let etaTxt = this.getLiveEtaTxt();
    const data = {
      'ETA': etaTxt,
      'Truck Empty?': '',
      'Miles out': this.getMilesOut()
    }
    return this.displayNote_FromObject(data);
  }
  
  getDefaultNote_EnrouteTracking() {
    let etaTxt = this.getLiveEtaTxt();
    const data = this.isDriverActive ? {
      ['Stop ' + this.displayStopIndex]: '',
      ['Heading to ' + this.stopType]: '',
      'Miles out': this.getMilesOut(),
      'ETA': etaTxt
    } : {
      ['Stop ' + this.displayStopIndex]: '',
      'ETA': '',
      'Miles out': ''
    }
    return this.displayNote_FromObject(data);
  }

  getDefaultNote_ConfirmArrival() {
    const data = {
      ['Stop ' + this.displayStopIndex]: '',
      ['Driver arrived ' + this.stopType]: ''
    }
    return this.displayNote_FromObject(data);
  }

  getDefaultNote_ConfirmDeparture() {
    let action = this.stopType === 'PICKUP' ? 'loading' : 'unloading';
    const data = {
      ['Stop ' + this.displayStopIndex]: '',
      ['Driver is ' + action + ' items']: '',
      'Estimated completion time': ''
    }
    return this.displayNote_FromObject(data);
  }

  onFileImageSelectedChange(files: File[]): void {
    this.uploadImages.forEach(file => {
      if ((file as any).preview) {
        URL.revokeObjectURL((file as any).preview);
      }
    });
    
    this.uploadImages = files.map(file => {
      const previewUrl = URL.createObjectURL(file);
      return Object.assign(file, { preview: previewUrl });
    });
  }

  private displayNote_FromObject(data: any) {
    let txt = '';
    for (let key in data) {
      if (data[key]) {
        txt += `${key}: ${data[key]}\n`;
      } else {
        txt += `${key}\n`;
      }
    }
    return txt;
  }

  getLiveEtaTxt() {
    let timezone = this.getTimeZone();
    let window = this.liveEtaInfo?.window;
    if (!window) return '';
    const timeTxt = DateUtil.displayTimeWindow(window, {
      timezone,
      format: 'MMM DD, YYYY h:mm A',
      formatDateOnly: 'MMM DD, YYYY'
    })
    const shortTz = this.getShortTz();
    return `${timeTxt} ${shortTz ? `(${shortTz})` : ''}`;
  }

  getMilesOut() {
    const distance = this.liveEtaInfo?.distance;
    if (!distance) return '';
    return `${BizUtil.getMiles(distance)} mi`;
  }

  getTimeZone() {
    return this.stopInfo?.info?.addr?.metadata?.timeZoneStandard || '';
  }

  getShortTz() {
    return DateUtil.timezoneStandardToUsShort(this.getTimeZone());
  }

  onSaveNote() {
    if (!this.jobId) {
      UIHelper.showErr('jobId is missing');
      return;
    }
    if (!this.noteContent && this.uploadImages.length == 0) return;
    this.isLoading = true;
    this.isSubmitting = true;
    // const url = `${environment.backendWorkQueueUrl}/v1/conversations`;
    let formData = new FormData();
    // const params: RequestWorkQueueCreateConversation = {
    //   type: 'note',
    //   subjectType: 'job',
    //   subjectId: this.jobId,
    //   content: this.noteContent,
    // };
    const jsonData = {
      'content': this.noteContent,
      'countImages': this.uploadImages.length
    }
    // formData.append("params", JSON.stringify(params));
    formData.append("params", JSON.stringify(jsonData));

    this.uploadImages.forEach((image, index) => {
      if (image instanceof File) {
        formData.append(`uploadNoteImages.${index}`, image, image.name);
      }
    });
    
    this.api
    .postFormData(`${Const.APIURI_CONVERSATIONS}/?subjectId=${this.jobId}&subjectType=job&type=note`, formData)
    .subscribe({
      next: (resp) => {
        this.isSubmitting = false;
        UIHelper.showSuccess("Note has been saved successfully.");
        this.resetNote();
        this.routeNoteService.refresh();
        this.isLoading = false;
      },
      error: (err) => {
        this.isSubmitting = false;
        UIHelper.showErr(err.message || 'Failed to save note');
        this.isLoading = false;
      }
    });
  }

  resetNote(): void {
    this.uploadImages.forEach(file => {
      if ((file as any).preview) {
        URL.revokeObjectURL((file as any).preview);
      }
    });
    
    this.noteContent = '';
    this.uploadImages = [];
    
    if (this.uploadImagesForm) {
      this.uploadImagesForm.resetFormInput();
    }
  }

  getImagePreviewUrl(file: File): string {
    return (file as any).preview || '';
  }
  
  removeImage(index: number): void {
    if (index >= 0 && index < this.uploadImages.length) {
      const file = this.uploadImages[index];
      if ((file as any).preview) {
        URL.revokeObjectURL((file as any).preview);
      }
      
      this.uploadImages.splice(index, 1);
      
      if (this.uploadImagesForm) {
        this.uploadImagesForm.updateFiles(this.uploadImages);
      }
    }
  }
} 