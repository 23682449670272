import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { TrackTraceService } from '../../services/track-trace.service';
import { ShipmentItem } from '@wearewarp/types/data-model';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { getInjector } from '@services/index';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Utils } from '@services/utils';

@Component({
  selector: 'app-shipment-items',
  templateUrl: './shipment-items.component.html',
  styleUrls: ['./shipment-items.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    NzButtonModule,
    NzIconModule,
    NzTagModule
  ]
})
export class ShipmentItemsComponent implements OnInit {
  @Input() shipmentId: string;
  @Output() reportMissing: EventEmitter<any> = new EventEmitter<any>();

  items: ShipmentItem[] = [];

  constructor(private trackTraceService: TrackTraceService) {}

  ngOnInit() {
    this.initItems();
  }

  initItems() {
    this.trackTraceService.getShipmentItems([this.shipmentId]).subscribe(items => {
      this.items = items;
    });
  }

  getItemName(item: ShipmentItem, index: number): string {
    return item.name || ('Item ' + (index+1));
  }

  getItemQty(item: ShipmentItem): string {
    const quantity = item?.qty || 'N/A';
    const unit = item?.qtyUnit || 'N/A';
    return `${quantity} ${unit}`;
  }

  getItemSize(item: ShipmentItem): string {
    const length = item.length ?? 0;
    const width = item.width ?? 0;
    const height = item.height ?? 0;
    const unit = item.sizeUnit ?? 'IN';
    return `${length}x${width}x${height} ${unit.toLocaleLowerCase()}`;
  }

  getItemTotalWeight(item: ShipmentItem): string {
    const quantity = item?.qty || 1;
    const weight = item?.weightPerUnit ?? 0;
    const unit = item?.weightUnit ?? '';
    return `${Utils.roundNumber(weight * quantity, 0)} ${unit}`
  }

  getItemWeightPerUnit(item: ShipmentItem): string {
    const weight = item?.weightPerUnit ?? 0;
    const unit = item?.weightUnit ?? '';
    return `Weight Per Unit:${weight} ${unit}`
  }

  onBtnReportMissing(item: ShipmentItem, index: number) {
    const itemName = this.getItemName(item, index);
    const itemQty = this.getItemQty(item);
    const itemTotalWeight = this.getItemTotalWeight(item);
    const itemSize = this.getItemSize(item);
    const itemBarcodesTxt = (item.barcodes || []).join(', ');

    const modalService = getInjector().get(NzModalService);
    modalService.create({
      nzTitle: 'Report Missing',
      nzContent: `
      ${itemName}, ${itemQty}, ${itemTotalWeight}, ${itemSize} <br/><br/>
      Barcode: ${itemBarcodesTxt} <br/><br/>
      Are you sure you want to mark this item is missing?
      `,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'OK',
      nzOnOk: () => this.reportMissing.emit(item.id),
      nzCancelText: 'Cancel'
    })
  }
} 