import { Injectable } from "@angular/core";
import { DeliveryUpdate } from "@app/interfaces/map-v2/delivery-update";
import { BaseWsUpdateService } from "./base-ws-update.service";

@Injectable({
    providedIn: 'root'
})
export class LiveLocationFeedService extends BaseWsUpdateService<DeliveryUpdate[]> {
    override parseWsData(raw: string): DeliveryUpdate[] {
        const update = JSON.parse(raw)
        return update ? [update] : []
    }
    override getObjectId(data: DeliveryUpdate[]): string | null {
        return data[0]?.object
    }

    constructor(
    ) {
        super('live-update')
    }
}