import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { BaseFormDialog } from "@app/dialogs/base-form-dialog";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NZ_MODAL_DATA, NzModalModule, NzModalService } from "ng-zorro-antd/modal";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzInputModule } from "ng-zorro-antd/input";
import { CreateNewDriverComponent } from "./create-new-driver/create-new-driver.component";
import { environment } from "@env/environment";
import { DialogService } from '@app/dialogs/dialog.service';

interface Driver {
  id: string;
  label: string;
  name: string;
  phone?: string;
}

interface InputChangeDriver {
  jobId: string;
  carrierId: string;
  driverId?: string;
  secondaryDriverId?: string;
}

@Component({
  selector: 'app-change-driver',
  standalone: true,
  templateUrl: './index.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzButtonModule,
    NzIconModule,
    NzModalModule,
    NzSelectModule,
    NzInputModule
  ]
})
export class ChangeDriverComponent extends BaseFormDialog implements OnInit {
  formInput: FormGroup;
  drivers: Driver[] = [];
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    @Inject(NZ_MODAL_DATA) public data: {
      jobId: string;
      carrierId: string;
      driverId?: string;
      allDrivers: Driver[];
    },
    private modalService: NzModalService
  ) {
    super(data);
    this.formInput = this.fb.group({
      driverId: ['', Validators.required],
      reason: ['', Validators.required]
    });
  }

  ngOnInit() {
    if (this.data.driverId) {
      this.formInput.patchValue({
        driverId: this.data.driverId
      });
    }
    this.drivers = this.data.allDrivers;
  }

  onCreateNewDriver() {
    DialogService.openFormDialog(CreateNewDriverComponent, {
      nzData: {
        carrierId: this.data.carrierId
      },
      nzTitle: 'Add New Driver',
      nzWidth: 700,
      nzClassName: 'change-driver-dialog',
      nzClosable: true,
    });
  }

  async onSubmit() {
    if (this.formInput.valid) {
      const formValue = this.formInput.value;
      try {
        // Implement API call to update driver
        this.closeDialog();
      } catch (error) {
        console.error('Error updating driver:', error);
      }
    }
  }
}