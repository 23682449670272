import { Component, Input } from '@angular/core';
import { AddressComponent } from '../address';
import { Client, DeliveryInfo, Shipment, Task } from '@wearewarp/types/data-model';
import { WarpCode } from '../warp-code';
import { StatusComponent } from '../status';
import { getMetadata } from '@app/utils/map-v2.util';
import { PopupContentDirective } from '../popup';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
    selector: 'job-task-code',
    templateUrl: './index.html',
    styleUrl: './index.scss',
    imports: [
        NzIconModule,
        AddressComponent,
        WarpCode,
        StatusComponent,
        PopupContentDirective,
    ],
    standalone: true
})
export class JobTaskCodeComponent {
    _task?: Task
    code?: string
    shipment?: Shipment
    client?: Client
    pickup?: DeliveryInfo
    dropoff?: DeliveryInfo
    refNums?: string[]
    correspondingTask?: Task
    correspondingStop?: any
    stopInd?: number
    link: string | null = null

    @Input() set task(v: Task) {
        this._task = v
        this.stopInd = getMetadata(v, 'stopIndex')
        this.shipment = getMetadata(v, 'shipment')
        this.code = this.shipment?.code
        this.client = getMetadata(v, 'client')
        this.pickup = this.shipment?.deliveryInfos?.filter(it => it.type == "PICKUP")[0]
        this.dropoff = this.shipment?.deliveryInfos?.filter(it => it.type == "DROPOFF")[0]
        this.correspondingTask = getMetadata(v, 'corresponding')
        this.correspondingStop = this.correspondingTask ? getMetadata(this.correspondingTask, 'stopIndex') : null
        this.refNums = this.shipment ? getMetadata(this.shipment, 'refNums') ?? [] : []
    }

    constructor(
    ) {}
}