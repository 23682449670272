import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { DateUtil } from '@services/date-util';
import { ResponseWhenBy, ResponseWhenBy_User } from "@wearewarp/types/rest-api/common";
import { CommonModule } from "@angular/common";

@Component({
  selector: 'when-by',
  templateUrl: './view.html',
  standalone: true,
  imports: [
    CommonModule,
  ],
  styleUrls: ['./style.scss']
})
export class WhenBy implements OnInit {
  @Input() viewTemplate: TemplateRef<any>;
  @Input() data: ResponseWhenBy;
  @Input() timezone: string;
  @Input() formatDateTime = 'YYYY-MM-DD HH:mm:ss';
  @Input() getHyperLink: (user: ResponseWhenBy_User) => string = () => ''
  @Input() classContainer = '';
  @Input() classTime = '';
  @Input() className = '';

  public time = '';
  public name = '';
  public hyperLink = '';
  public userType = '';
  public get isUser() { return this.data.by.entity == 'users' }
  public get hasUser() { return this.data.by.name.length > 0 }

  ngOnInit(): void {
    this.prepareData();
  }

  private prepareData() {
    this.time = this.showTime();
    this.name = this.data.by.name;
    this.hyperLink = this.getHyperLink(this.data.by);
    this.userType = this.showUserType();
  }

  private showTime() {
    if (!this.timezone) {
      return DateUtil.format(this.data.when, this.formatDateTime);
    }
    return DateUtil.displayLocalTime(this.data.when, this.timezone, this.formatDateTime);
  }

  private showUserType() {
    const entity = this.data?.by?.entity ?? '';
    switch (entity) {
      case 'users': return 'user';
      case 'drivers': return 'driver';
      case 'carriers': return 'carrier';
      case 'clients': return 'customer';
      default: return entity
    }
  }
}
