<div class="assign-history">
  <div class="header">
    <h3>Assign history</h3>
  </div>

  <nz-timeline>
    <nz-timeline-item *ngFor="let history of histories">
      <div class="timeline-content">
        <div class="event">{{ history.text }}</div>
        <div class="meta">
          <span class="by">By {{ history.by.name }}</span>
          <span class="time">{{ history.time }}</span>
        </div>
      </div>
    </nz-timeline-item>
  </nz-timeline>
</div>
