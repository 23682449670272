<ng-container *ngIf="isLoading">
  <div class="nodata">
    <i nz-icon nzType="loading" nzTheme="outline"></i>
  </div>
</ng-container>

<ng-container *ngIf="!isLoading">
  <!-- Header -->
  <ng-template [nzModalTitle]>
    <div class="flex-space-between">
      <div>Change Driver</div>
      <div class="flex">
        <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
      </div>
    </div>
  </ng-template>
  <!-- Content -->
  <form nz-form [formGroup]="formInput">
    <!-- Driver Selection -->
    <ng-container>
      <nz-form-item>
        <nz-form-label [nzRequired]="true">Driver</nz-form-label>
        <nz-form-control [nzErrorTip]="'Please select driver'">
          <nz-select 
            formControlName="driverId"
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Please select driver"
            [nzDropdownClassName]="'driver-select-dropdown'"
            [nzOptionHeightPx]="55"
            style="width: 100%;">
            <nz-option 
              *ngFor="let driver of drivers" 
              [nzValue]="driver.id"
              [nzLabel]="driver.label"
              nzCustomContent>
                <span class="driver-name">{{driver.name}}</span>
                <span class="driver-phone" *ngIf="driver.phone">{{driver.phone}}</span>
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <!-- Create New Driver -->
      <nz-form-item>
        <nz-form-control>
          <a (click)="onCreateNewDriver()">
            <i nz-icon nzType="plus" nzTheme="outline" style="margin-right: 5px;"></i>
            Create New Driver
          </a>
        </nz-form-control>
      </nz-form-item>
      <!-- Reason Input -->
      <nz-form-item>
        <nz-form-label [nzRequired]="true">Reason</nz-form-label>
        <nz-form-control [nzErrorTip]="'Please enter reason'">
          <textarea 
            nz-input
            formControlName="reason"
            placeholder="Enter reason for changing driver"
            [nzAutosize]="{ minRows: 3, maxRows: 5 }"
            style="width: 100%;">
          </textarea>
        </nz-form-control>
      </nz-form-item>
    </ng-container>
  </form>
  <!-- Footer -->
  <ng-template [nzModalFooter]>
    <div>
      <button nz-button (click)="closeDialog()" class="mr-2">Cancel</button>
      <button 
        nz-button 
        nzType="primary"
        [disabled]="!formInput.valid"
        (click)="onSubmit()">
        Update
      </button>
    </div>
  </ng-template>
</ng-container>