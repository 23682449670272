<div>
    <span class="right-5">Stop {{ _stop.index }}:</span>
    @if (_stop.name) {
        <i nz-icon nzType="home" class="left5 right5"></i> {{ _stop.name }}
    }
</div>
@if (_stop.address) {<div>   
    <i class="fa fa-map right5" style="color: #888888;"></i>
    <us-address mode="full" [address]="_stop.address"></us-address>
</div>
}
@if (_stop.trafficCost?.distance) {
    <div>
        From previous stop:
        <i nz-icon nzType="node-index"></i> {{ _stop.trafficCost.distance / 1609.34 | number : "1.0-0" }}mi
        <i nz-icon nzType="clock-circle" class="left5"></i> <duration [seconds]="_stop.trafficCost.time"></duration>
    </div>
}
@if (!loading) {
@for (task of _stop.tasks; track $index) {
    <div>
        <task-status-update [task]="task"></task-status-update>
    </div>
}
}