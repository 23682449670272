import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationDispatchService {
  private static _currentNotificationsNumber: number;
  public onNotificationsNumberChanged: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  public getNotificationsNumber() {
    if (!NotificationDispatchService._currentNotificationsNumber) {
      NotificationDispatchService._currentNotificationsNumber = 0;
    }
    return NotificationDispatchService._currentNotificationsNumber;
  }

  public setNotificationsNumber(value: number): boolean {
    if (NotificationDispatchService._currentNotificationsNumber && NotificationDispatchService._currentNotificationsNumber === value) {
      return false;
    }
    NotificationDispatchService._currentNotificationsNumber = value;
    this.onNotificationsNumberChanged.emit(value);
    return true;
  }

}
