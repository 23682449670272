import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackTraceService } from '../../services/track-trace.service';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ShipmentItemsComponent } from '../shipment-items/shipment-items.component';
import { DelayCode } from '@wearewarp/types/data-model/types/DelayCode';
import { Job } from '@wearewarp/types/data-model/types/Job';
import { Task } from '@wearewarp/types/data-model/types/Task';
import { Shipment } from '@wearewarp/types/data-model/types/Shipment';
import { Const } from '@const/Const';
import { FormDataUpdateTaskStatus, InputDataUpdateTaskStatus, ModalHelper, UpdateTaskStatus } from '@wearewarp/ng-antd-2';
import { MasterData } from '@services/master.data';
import { Const as WarpConst } from '@wearewarp/universal-libs';
import { TaskType } from '@wearewarp/types/common';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { UIHelper } from '@services/UIHelper';
import { ApiService } from '@services/api.service';

interface ExtendedTask extends Task {
  key: string;
  isItemsExpanded?: boolean;
}

@Component({
  selector: 'app-stop-tasks',
  templateUrl: './stop-tasks.component.html',
  styleUrls: ['./stop-tasks.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    NzButtonModule,
    NzIconModule,
    NzTagModule,
    NzBadgeModule,
    ShipmentItemsComponent
  ]
})
export class StopTasksComponent implements OnInit {
  private modalHelper = inject(ModalHelper);
  @Input() taskIds: string[];
  @Input() stopId: string;

  shipments: Shipment[] = [];
  public stopTasks: ExtendedTask[] = [];
  public expandSet = new Set<string>();
  public isExpanded = false;
  public totalPods = 0;

  private loadInfo: Job;

  constructor(private trackTraceService: TrackTraceService, 
    private router: Router,
    private api: ApiService ) { }

  onExpandChange(id: string, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  toggleTaskItems(task: ExtendedTask) {
    task.isItemsExpanded = !task.isItemsExpanded;
  }

  ngOnInit() {
    this.initTasks();
  }

  initTasks() {
    this.trackTraceService.getLoadInfo().subscribe(loadInfo => {
      this.loadInfo = loadInfo;
    });
    //TODO: get tasks from server
    this.trackTraceService.getTasks(this.taskIds).subscribe(tasks => {
      this.stopTasks = tasks.map(task => ({
        ...task,
        key: task.id,
      }));
      this.getTotalPods();
      const shipmentIds = tasks.map(task => task.shipmentId);
      this.trackTraceService.getShipments(shipmentIds).subscribe(shipments => {
        this.shipments = shipments;
      });
    });
  }

  getFirstTask(): ExtendedTask {
    return this.stopTasks.find(task => task.status !== Const.RouteTaskStatus.created || task.type !== Const.RouteTaskStatus.pickupFailed);
  }

  getTotalPods() {
    if (this.getFirstTask()?.podUploadFiles?.length > 0){
      this.totalPods = this.getFirstTask()?.podUploadFiles?.length;
      return `(${this.totalPods})`;
    } else {
      return '';
    }
  }

  getShipmentForTask(task: Task): Shipment {
    return this.shipments?.find(shipment => shipment.id === task.shipmentId);
  }

  getShipmentCode(task: Task): string {
    const shipment = this.getShipmentForTask(task);
    return shipment?.code || `S-${shipment?.warpId || 'N/A'}`;
  }

  getRefNums(task: Task): string[] {
    const shipment = this.getShipmentForTask(task);
    return shipment?.deliveryInfos?.find(info => info.type == "PICKUP")?.refNums || [];
  }

  getStatusColor(status: string): string {
    switch (status) {
      case Const.StopStatus.created:
        return 'default';
      case Const.StopStatus.enroute:
        return 'blue';
      case Const.StopStatus.arrived:
        return 'orange';
      case Const.StopStatus.succeeded:
        return 'green';
      case Const.StopStatus.failed:
        return 'red';
      case Const.StopStatus.canceled:
        return 'red';
      default:
        return 'default';
    }
  }

  getStatusText(status: string): string {
    return Const.getStopStatusText(status);
  }

  getShipmentDetailUrl(task: Task) {
    const shipment = this.getShipmentForTask(task);
    if(!shipment) {
      // this.notification.error('View Shipment', "Order not found");
      return '';
    }
    let orderId = shipment.orderId;
    if(!orderId) {
      orderId = shipment?.metadata?.parent?.orderId;
    }
    return `${environment.webAdminUrl}/dashboard/orders/${orderId}`;
  }

  viewPOD(task: Task) {
    console.log("View POD", task);
    UIHelper.showWarning('View POD', "POD view is not implemented yet");
  }

  onStatusClick(task: Task) {
    const data: InputDataUpdateTaskStatus = {
      tasks: [task],
      delayCodeOptions: this.getDelayCodeOptions(task.type),
      isAssignedCarrier: this.loadInfo?.assignedCarrier?.carrierId ? true : false,
      shipments: [this.getShipmentForTask(task)],

      onSave: (data: FormDataUpdateTaskStatus) => {
        this.submitUpdateTaskStatus([task.id], data);
      },
    }

    UpdateTaskStatus.openModal(this.modalHelper, {
      taskId: task.id,
      status: task.status,
      nzData: data
    });
  }

  getDelayCodeOptions(taskType: TaskType): DelayCode[] {
    return [
      WarpConst.TaskType.PICKUP,
      WarpConst.TaskType.DROPOFF
    ].includes(<any>taskType)
      ? MasterData.getDelayCodesByType(<any>taskType) : MasterData.getDelayCodes()
  }

  submitUpdateTaskStatus(taskIds: string[], data: FormDataUpdateTaskStatus) {
    console.log("submitUpdateTaskStatus",data);
    this.trackTraceService.updateTaskStatus(taskIds, data);
  }

  onReportMissing(itemId: string) {
    if (!itemId) {
      UIHelper.showErr(`itemId is missing`);
      return;
    }
    const params = {
      jobId: this.loadInfo.id,
      stopId: this.stopId,
      stopType: this.stopTasks?.[0]?.type     // có thể dùng luôn type của task đầu
    }
    const url = `${environment.backendWorkQueueUrl}/v1/shipments/shipment_items/${itemId}/missing`;
    // Report missing hiện chưa cần load lại data do không có thay đổi gì UI.
    this.api.PUT(url, params).subscribe({
      next: (res) => {
        UIHelper.showSuccess("Report missing item successfully");
        window.location.reload();
      },
      error: (err) => {
        UIHelper.showErr(err);
      }
    })
  }
} 