import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { User } from "@wearewarp/types/data-model";
import { BehaviorSubject, map, Observable } from "rxjs";
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private api: ApiService) { }
  public listOperators = new BehaviorSubject<User[] | null>(null);
  public fetchingOperators = new BehaviorSubject<boolean>(false);

  getUsers(userIds: string[]): Observable<User[]> {
    return this.api.POST(`${environment.backendWorkQueueUrl}/v1/users`, {
      userIds
    }).pipe(
      map(response => response?.data?.list_data as unknown as User[])
    );
  }

  getListOperators(): Observable<User[] | null> {
    if (this.fetchingOperators.value) {
      return this.listOperators.asObservable();
    }
    if (this.listOperators.value) {
      return this.listOperators.asObservable();
    }

    this.fetchingOperators.next(true);
    let url = `${environment.backendWorkQueueUrl}/${Const.APIURI_LIST_OPERATORS}`;
    const res = this.api.GET(url);
    res.subscribe(response => {
      this.listOperators.next(response.data?.list_data as unknown as User[]);
      this.fetchingOperators.next(false);
    });

    return this.listOperators.asObservable();
  }
}
