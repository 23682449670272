import { afterNextRender, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { DeliveryUpdate } from "@app/interfaces/map-v2/delivery-update";
import { JobDetail } from "@app/interfaces/map-v2/job-detail";
import { DeliveryUpdateService } from "./services/delivery-update.service";
import { Map_JobService } from "./services/job.service";
import { DriverLocationChartComponent } from "./components/driver-location-chart";
import { DeliveryStop } from "@app/interfaces/map-v2/stop";
import { CommonModule } from "@angular/common";
import { JobDetailMapComponent } from "./components/job-detail-map-component/job-detail-map-component.component";
import DeliveryMapV2 from "@app/components/delivery-map-v2/map";
import { debounceTime, fromEvent } from "rxjs";
import { LiveDeliveryUpdateService } from "./services/live-delivery-update.service";
import { LiveLocationFeedService } from "./services/live-location-feed.service";
import { DeliveryProgressStopComponent } from "./components/stop-popup";
import { DurationComponent } from "./components/job-detail-map-component/components/duration";
import { NzIconModule } from "ng-zorro-antd/icon";


const CustomInputs = [
  CommonModule,
  NzIconModule,
  JobDetailMapComponent,
  DriverLocationChartComponent,
  DeliveryProgressStopComponent,
  DurationComponent
]

@Component({
  selector: 'job-map-v2',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  standalone: true,
  imports: CustomInputs,
})
export class JobMapV2Component implements OnDestroy {
  _jobId: string | null = null
  _detail: JobDetail | null = null
  loading: boolean = false
  @ViewChild('driverLocationChart') chart: ElementRef<HTMLElement>;

  _chartWidth: number = 400
  _chartHeight: number = 60

  _map: DeliveryMapV2 | null = null
  liveDeliveryUpdateSubscription?: string
  deliveryUpdateSubscription?: string
  liveGpsSubscription?: string

  @Output() onLoaded: EventEmitter<any> = new EventEmitter()

  set detail(v: JobDetail) {
    this._detail = v
    this.onLoaded.emit(v.job)
  }

  constructor(
    private jobService: Map_JobService,
    private deliveryUpdate: LiveDeliveryUpdateService,
    private deliveryUpdateService: DeliveryUpdateService,
    private liveTrafficService: LiveLocationFeedService,
  ) {
    this.onDeliveryUpdate = this.onDeliveryUpdate.bind(this)
    this.onNewDriverLocation = this.onNewDriverLocation.bind(this)
    this.checkChartSize = this.checkChartSize.bind(this)
    afterNextRender(() => {
      this.afterRender()
    })
  }

  afterRender() {
    this.checkChartSize()
    fromEvent(window, 'resize').pipe(debounceTime(250)).subscribe({
      next: (l) => {
        this.checkChartSize()
      }
    })
  }

  private checkChartSize() {
    if (!this.chart) return
    const w = this.chart.nativeElement.clientWidth
    this._chartWidth = w
    this._chartHeight = w > 480 ? 100 : 60
  }

  @Input() set id(v: string) {
    if (this._jobId == v) return;
    this.jobService.viewJob(v)
    this._jobId = v

    // load job
    this.loadJob()

    if (this.liveDeliveryUpdateSubscription) {
      this.deliveryUpdate.unsubscribe(this.liveDeliveryUpdateSubscription)
    }
    this.liveDeliveryUpdateSubscription = this.deliveryUpdate.subscribe(`JOB_${v}`, this.onDeliveryUpdate)

    this.fetchDriverLocations();
    this.unsubscribeTraffic()
    if (v) {
      this.liveGpsSubscription = this.liveTrafficService.subscribe(`JOB_${v}`, this.onNewDriverLocation)
    }
  }

  ngOnDestroy(): void {
    if (this.liveDeliveryUpdateSubscription) {
      this.deliveryUpdate.unsubscribe(this.liveDeliveryUpdateSubscription)
    }
    this.unsubscribeTraffic()
  }

  historicalLocations: DeliveryUpdate[] = [];
  updatedLocations: DeliveryUpdate[] = [];
  liveLocations: DeliveryUpdate[] = [];
  allLocations: DeliveryUpdate[] = [];
  fetchDriverLocations() {
    if (!this._jobId) {
      this.historicalLocations = []
      this.updatedLocations = []
      this.liveLocations = []
      return
    }
    this.deliveryUpdateService.loadJobDriverLocations(this._jobId).subscribe({
      next: (v) => {
        v.sort((a,b) => a.ts - b.ts)
        this.historicalLocations = v
        this.allLocations = v
        this.updatedLocations = v.length ? [v[v.length - 1]] : []
        if (this.updatedLocations.length) {
            this.liveLocations = this.updatedLocations
        } else {
            this.liveLocations = []
        }
      },
      error: () => {
        this.historicalLocations = []
        this.updatedLocations = []
        this.allLocations = []
        this.liveLocations = []
      }
    })
  }

  onDeliveryUpdate(info: any) {
    const job = info?.job == this._detail.job.id ? this._detail : null
    if (!job || !info.status) return
    if (info.action == 'JOB.DELIVERY.UPDATE') {
      // find job
      job.job.status = info.status
      console.log('updated job status', job.job.code, job.job.status)
    }
    else if (info.action == 'TASK.DELIVERY.UPDATE') {
      // find task
      const task = job.tasks?.filter(it => it.id == info.task)[0]
      if (task) {
        task.status = info.status
        console.log('updated task status', job.job.code)
      }
      const jobTask = job.job.tasks?.filter(it => it.id == info.task)[0]
      if (jobTask) {
        jobTask.status = info.status
      }
      for (let stop of job.stops) {
        const task = stop.tasks?.filter(it => it.id == info.task)[0]
        if (task) {
          task.status = info.status
          stop.status = this.jobService.calculateStopStatus(stop)
          console.log('updated stop status', job.job.code, task.id, stop.status)
        }
      }
    }
    else if (info.action == 'JOB.DELIVERY.CARRIER_ASSIGNED') {
      // load job info
      this.jobService.loadJob(job.job.id).subscribe({
        next: (i) => {
          job.carrier = i.carrier
          job.job.status = i.job.status
        }
      })
    } else {
      console.log(info)
    }
    job.updatedTs = Date.now()
  }

  loadJob() {
    if (!this._jobId) return

    this.loading = true
    this.jobService.loadJob(this._jobId).subscribe({
      next: (r) => {
        this.loading = false
        this.detail = r
      }
    })
  }

  onNewDriverLocation(locations: DeliveryUpdate[]) {
    this.updatedLocations = (this.updatedLocations ?? []).concat(locations)
    this.allLocations = this.allLocations.concat(locations)
    this.liveLocations = locations
  }

  unsubscribeTraffic() {
    if (this.liveGpsSubscription) {
        this.liveTrafficService.unsubscribe(this.liveGpsSubscription)
    }
    this.liveGpsSubscription = null
  }

  _hoveredLocation: {location?: DeliveryUpdate, timeline?: DeliveryStop} | null;
  onHoverDriverLocation($event: {location?: DeliveryUpdate, timeline?: DeliveryStop} | null) {
    if ($event?.location) {
      this._map.highlightedLocations = [$event.location.location]
    } else {
        this._map.highlightedLocations = []
    }
    this._hoveredLocation = $event
    this._map.refresh()
  }

  onClickDriverLocation($event: DeliveryUpdate) {
    const { latitude, longitude } = $event.location
    this._map.fitBounds([$event.location])
    this._map.refresh()
  }

  onMapChange(v: DeliveryMapV2) {
      this._map = v
  }

}