<div class="load-eta">
  <div class="info-item">
    <label>Current time</label>
    <span>{{ currentDate | date:'MMM d, h:mm:ss a'}} ({{ getShortTz() }})</span>
  </div>
  <div class="info-item" *ngIf="shouldShowLiveETA()">
    <label>Live ETA</label>
    <div *ngIf="representativeTask" delivery-eta [overflow]="true" [compact]="true" [id]="'TASK_' + representativeTask?.id" [tz]="timezone" (getETASuccess)="onChangeLiveETA($event)"></div>
  </div>
  <div class="info-item">
    <label>Status</label>
    <span>{{ status || 'N/A' }}</span>
  </div>
</div> 