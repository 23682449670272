<div class="remind-task-container">
  <div class="remind-task-header bottom20">
    <div>Reminder</div>
    <div><span nz-icon nzType="close" nzTheme="outline" (click)="closeDialog()" class="clickable"></span></div>
  </div>
  <div class="remind-task-content">
    <div class="desc bottom10">{{ remindDesc }}</div>
    <div class="select-box">
      <nz-select [(ngModel)]="remindTime" [ngModelOptions]="{standalone: true}" [nzOptions]="remindOptions" style="width: 100%;"></nz-select>
    </div>
  </div>

  <div class="remind-task-footer top20">
    <button nz-button nzType="primary" (click)="onBtnSave()" style="border-radius: 4px;" [nzLoading]="isSubmitting">
      Confirm & Get next task
    </button>
    <button nz-button (click)="closeDialog()" style="border-radius: 4px;">
      Cancel
    </button>
  </div>
</div>