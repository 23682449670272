import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Const } from '@wearewarp/universal-libs';

@Component({
  selector: 'confirm-departure-suggestion',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './index.html',
  styleUrl: './index.scss'
})
export class ConfirmDepartureSuggestion {
  @Input() isDriverActive: boolean = false;
  @Input() stopType: string = '';

  get stopAction(): string {
    if (this.stopType === Const.TaskType.PICKUP) {
      return 'loading';
    } else if (this.stopType === Const.TaskType.DROPOFF) {
      return 'unloading';
    }
    return '';
  }
}
