import { Component, OnInit } from '@angular/core';
import { TrackTraceService } from '../../services/track-trace.service';
import { Job, Stop, Task, WorkTask } from '@wearewarp/types/data-model';
import { CommonModule } from '@angular/common';
import { LiveEtaService } from '@app/track-trace/services/live-eta.service';
import { DateUtil, TimeWindow } from '@services/date-util';
import { DeliveryEta } from '@app/components/delivery-eta';
import { TaskCurrentStateServiceV2 } from './current-state.service';
import { Const as WarpConst } from '@wearewarp/universal-libs';

@Component({
  selector: 'app-load-time-summary',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
  standalone: true,
  imports: [CommonModule, DeliveryEta],
  providers: [TaskCurrentStateServiceV2]
})
export class LoadTimeSummaryComponent implements OnInit {
  taskInfo: WorkTask;
  loadInfo: Job;
  currentDate: Date;
  status: string = 'N/A';
  timezone: string;
  liveETAWindow: TimeWindow;
  currentStop: Stop;
  representativeTask: Task;
  
  private timer: any;

  constructor(
    private trackTraceService: TrackTraceService, 
    private liveEtaService: LiveEtaService,
    private taskCurrentStateService: TaskCurrentStateServiceV2
  ) { }

  setEmptyData() {
    this.loadInfo = null;
    this.taskInfo = null;
    this.currentStop = null;
    this.timezone = null;
    this.liveETAWindow = null;
    this.representativeTask = null;
  }

  ngOnInit() {
    this.trackTraceService.getWorkTask().subscribe(taskInfo => {
      this.taskInfo = taskInfo;
    })
    this.trackTraceService.getLoadInfo().subscribe(info => {
      this.setEmptyData();
      this.loadInfo = info;
      this.currentStop = this.trackTraceService.getCurrentStop();
      this.timezone = this.getTimeZone(this.currentStop);
      this.getRepresentativeTask();
      this.getCurrentDate();
    });
    this.liveEtaService.getLiveEtaInfo().subscribe(eta => {
      this.liveETAWindow = eta?.window;
    });
    this.timer = setInterval(() => {
      this.getCurrentDate();
    }, 1000);
  }

  getRepresentativeTask() {
    let taskId = this.currentStop?.taskIds?.[0];
    if (taskId) {
      this.trackTraceService.getTasks([taskId]).subscribe(tasks => {
        this.representativeTask = tasks?.[0] || null;
      });
    }
    else { this.representativeTask = null; }
  }

  getTimeZone(stop: Stop) {
    return stop?.info?.addr?.metadata?.timeZoneStandard || '';
  }

  getShortTz() {
    return DateUtil.timezoneStandardToUsShort(this.timezone);
  }

  getCurrentDate() {
    this.currentDate = new Date(new Date().toLocaleString('en-US', { timeZone: this.timezone }));
    this.calculateStatus();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }

  calculateStatus(): string {
    if (!this.liveETAWindow || !this.representativeTask?.earliestTime || !this.representativeTask?.latestTime) {
      return 'N/A';
    }

    const currentState = this.taskCurrentStateService.getCurrentStateForTaskConfirmETA({
      scheduleWindow: {
        from: this.representativeTask.earliestTime,
        to: this.representativeTask.latestTime
      },
      etaWindow: this.liveETAWindow,
      currentIsoTime: this.currentDate.toISOString()
    });

    if (!currentState) {
      return 'N/A';
    }

    const hours = Math.floor(currentState.duration / (60 * 60 * 1000));
    const minutes = Math.floor((currentState.duration % (60 * 60 * 1000)) / (60 * 1000));
    switch (currentState.state) {
      case 'ontime':
        this.status = `On time (${hours}h ${minutes}m ahead)`;
        break;
      case 'might_be_late':
        this.status = `Might be late by ${hours}h ${minutes}m`;
        break;
      case 'lated':
        this.status = `Late by ${hours}h ${minutes}m`;
        break;
      default:
        this.status = 'N/A';
    }
  }

  // chỉ show live eta với stop created hoặc enroute
  shouldShowLiveETA() {
    const taskStatus = this.currentStop?.status;
    return taskStatus === WarpConst.TaskStatus.created ||
          taskStatus === WarpConst.TaskStatus.enroute;
  }

  onChangeLiveETA(eta) {
    this.liveEtaService.setLiveEtaInfo(eta);
  }

}
