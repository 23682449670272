import { IconLayer, ScatterplotLayer } from '@deck.gl/layers'
import { CompositeLayer } from '@deck.gl/core';
import { DeliveryUpdate } from '@app/interfaces/map-v2/delivery-update';

export default class HighlightedLiveTrackingLayer extends CompositeLayer {
    static override layerName = "HighlighteddLiveTrackingLayer"
    data: any[]
    options: any = {}
    _baseLayer: any = null
    _layer: any = null

    constructor(id, locations: DeliveryUpdate[], options = {}) {
        super({id: `${id}-highlighted-live-tracking`, data: {locations, options}})
        this.options = options
        this.data = locations?.map(this.processLocationData).filter(it => it)

        this._layer = this.createBaseDotLocationLayer()
    }

    loadLocations(locations) {
        if (locations?.length !== this.data?.length)
            this.data = locations?.map(this.processLocationData).filter(it => it)
        else {
            for (let i = 0; i < locations.length; i++) {
                const updated = this.processLocationData(locations[i])
                Object.assign(this.data[i], updated)
            }
        }
    }

    processLocationData(data: DeliveryUpdate) {
        const { location, ts, source } = data
        if (!location) return null

        return {
            ts,
            coordinates: [location.longitude, location.latitude],
        }
    }

    createBaseDotLocationLayer() {
        return new ScatterplotLayer({
            id: `${this.id}-live-base-layer`,
            data: this.data,
            pickable: false,
            getPosition: d => d.coordinates, // [...(d.coordinates as number[]), 0.0001],
            filled: true,
            getRadius: d => 16,
            radiusMinPixels: 16,
            radiusMaxPixels: 16,
            getFillColor: d => d.color ?? [42, 200, 43, 200],
        });
    }

    renderLayers() {
        return this._layer
    }

}
