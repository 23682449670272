import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { BaseFormDialog } from "@app/dialogs/base-form-dialog";
import BizUtil from '@app/utils/biz.util';
import { Const } from '@const/Const';
import { environment } from "@env/environment";
import { Log } from "@services/log";
import { UIHelper } from "@services/UIHelper";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from "ng-zorro-antd/form";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NZ_MODAL_DATA } from "ng-zorro-antd/modal";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { FormFooter } from "@app/form-footer/form-footer";
const CustomInputs = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NzFormModule,
  NzButtonModule,
  NzIconModule,
  NzRadioModule,
  NzCheckboxModule,
  NzDividerModule,
  FormFooter
]

@Component({
  selector: 'confirm-unassign-carrier',
  standalone: true,
  imports: CustomInputs,
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class ConfirmUnassignCarrierDialog extends BaseFormDialog {
  private _jobId: string;
  private _jobInfo: any;
  
  constructor(@Inject(NZ_MODAL_DATA) data: any) {
    super(data);
    this.jobId = data.jobId;
    this.jobInfo = data.jobInfo

  }
  set jobInfo(value: any) {
    this._jobInfo = value;
    this.getData();
  }
  
  get jobInfo() { return this._jobInfo; }

  set jobId(value: string) {
    this._jobId = value;
    this.getData();
  }
  get jobId() { return this._jobId; }

  protected formGroupDeclaration: FormGroupDeclaration = {
    code: { label: '', required: true },
    subCode: { label: '', required: false },
    notifySlack: { label: '', required: false, type: 'boolean', initialValue: true }
  }
  
  public isLoading: boolean = false;
  public isUnassigning: boolean = false;
  public reasons = [];

  private getData() {
    const jobId = this.jobId;
    if (!jobId) {
      return  Log.e('jobId is required');
    }
    this.isLoading = true;
    const urlGetJob = `${environment.backendWorkQueueUrl}/v1/jobs/${jobId}`;
    const urlGetReason = `${environment.backendWorkQueueUrl}/v1/metadata/ORG_0/DISPATCH_UNASSIGN_REASON/unassign_reason_settings`;
    this.api.concurrentGET([
      urlGetJob,
      urlGetReason
    ]).subscribe({
      next: (resp) => {
        this.isLoading = false;
        const [jobResp, reasonResp] = resp;
        const value = reasonResp?.data?.value || '[]';
        const settings = JSON.parse(value);
        this.reasons = settings;
      },
      error: (err) => {
        this.isLoading = false;
        UIHelper.showErr(err);
      }
    })
  }

  isShowChild(item): boolean {
    return this.getItemValue('code') === item.value;
  }

  onChangeReason(data) {
    const item = this.reasons.find(x => x.value === data);
    const options = item?.options || [];
    let isRequired = false;
    if(options.length) {
      this.formGroupDeclaration.subCode.required = true;
      isRequired = true;
    }
    else {
      this.formGroupDeclaration.subCode.required = false;
      this.setItemValue('subCode', '');
    }

    let fc = <FormControl>this.formInput.get('subCode');
    if (fc) {
      if (!isRequired) {
        fc.removeValidators(Validators.required);
      } else {
        fc.addValidators(Validators.required);
      }
      fc.updateValueAndValidity();
    }
  }

  private notifySlack() {
    const jsonData = this.getFormData_JSON(true);
    const item = this.reasons.find(x => x.value === jsonData.code);
    let reasonText = '';
    if (item?.options?.length) {
      const selectedOption = item.options.find(x => x.value === jsonData.subCode);
      reasonText = `${item.label} (${selectedOption?.label})`;
    } else {
      reasonText = 'WARP cancelled';
    }
    const { hyperLinkText, hyperLinkUrl } = BizUtil.createHyperLinkForJob(this.jobInfo);
    const plainText = `[URGENT] <${hyperLinkText}> needs recovery\nReason: ${reasonText}`;
    const richText = plainText.replace(hyperLinkText, `${hyperLinkUrl}|${hyperLinkText}`);
    const url = `${environment.backendWorkQueueUrl}/${Const.API_NOTIFY_SLACK()}`;
    const data = {
      requireAction: 'need-recovery',
      plainText,
      richText,
      channelIds: ['dev-test-notification-slack'] // select test channel
    }; 

    return this.api.POST(url, data);
  }

  onBtnConfirm() {
    const jobId = this.jobId;
    if (!jobId) {
      return  Log.e('jobId is required');
    }
    let jsonData = this.getFormData_JSON(true);
    const code = jsonData.subCode || jsonData.code;
    const item = this.reasons.find(x => x.value === jsonData.code);
    let reason = item?.label || '';
    if (item?.options?.length) {
      reason = item.options.find(x => x.value === jsonData.subCode)?.label || '';
    }
    
    this.isUnassigning = true;
    const url = `${environment.backendWorkQueueUrl}/v1/jobs/${jobId}/unassign_carrier`;
    this.api.PUT(url, { code, reason }).subscribe({
      next: (resp) => {
        if (jsonData.notifySlack) {
          this.notifySlack().subscribe({
            next: () => {
              UIHelper.showSuccess('Unassign carrier and notify Slack successfully');
              this.onUpdateSuccess(resp);
            },
            error: (err) => {
              UIHelper.showErr('Unassign successful but Slack notification failed');
              this.onUpdateSuccess(resp);
            }
          });
        } else {
          this.isUnassigning = false;
          UIHelper.showSuccess('Unassign carrier successfully');
          this.onUpdateSuccess(resp);
        }
      },
      error: (err) => {
        this.isUnassigning = false;
        UIHelper.showErr(err);
      }
    })
  }

  onBtnCancel() {
    this.closeDialog();
  }

}