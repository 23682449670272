<div class="tracking-info">
  <div class="tracking-section">
    <div class="section-header">
      <h3>App tracking URL</h3>
    </div>
    <div class="section-content">
      <div class="tracking-type">
        <img src="assets/svg/location_on.svg" alt="sms" class="sms-icon">
        <span>WARP live tracking URL</span>
        <a class="text-link" (click)="openWarpTrackingModal()">
          Send SMS
        </a>
      </div>
      <div class="tracking-message">Please ask the Driver to turn on location permission in the WARP Driver app</div>
      <!-- <div class="tracking-actions">
        <a class="text-link" (click)="copyTrackingLink()">
          Copy WARP tracking URL
        </a>
      </div> -->
      <div class="tracking-type">
        <img src="assets/svg/share_location.svg" alt="sms" class="sms-icon">
        <span>External URL</span>
        <a class="text-link" (click)="openExternalTrackingModal()">
          Add external URL
        </a>
      </div>
      <div class="tracking-message">If the carrier uses a third-party tracking system, ask the Carrier or Driver to provide their live tracking URL.</div>
      <!-- <div class="tracking-actions" *ngIf="!getCustomTrackingLink()">
        <a class="text-link" (click)="openExternalTrackingModal()">
          Add external URL
        </a>
      </div> -->
      <div class="tracking-actions" *ngIf="getCustomTrackingLink()">
        <a class="text-link" (click)="goToExternalUrl()" nz-tooltip [nzTooltipTitle]="getCustomTrackingLink()">
          Go to external URL
        </a>
        <a class="text-link" (click)="editExternalUrl()">
          Edit URL
        </a>
      </div>
    </div>
  </div>
</div>