import { CompositeLayer } from '@deck.gl/core';
import { ScatterplotLayer } from '@deck.gl/layers'
import { LatLngLike } from '@app/interfaces/map-v2/latlng';
import { getLngLat } from '@app/utils/map-v2.util';


export default class HighlightedLocationsLayer extends CompositeLayer {
    override id: string
    static override layerName = "Highlighted-Locations-Layer"
    locations: LatLngLike[] = []

    constructor(id: string, locations: LatLngLike[]) {
        super({id, data: locations})
        this.id = id
        this.locations = locations
    }

    createLocationPinLayer() {
        if (!this.locations?.length) return null
        const lnglats = this.locations.map(getLngLat)
        return new ScatterplotLayer({
            id: `highlighted-dot-layer-${this.id}`,
            data: lnglats,
            opacity: 0.6,
            pickable: false,
            stroked: true,
            filled: true,
            radiusScale: 6,
            radiusMinPixels: 10,
            radiusMaxPixels: 10,
            lineWidthMinPixels: 1,
            lineWidthMaxPixels: 1,
            getPosition: d => d,
            // getRadius: d => Math.sqrt(d.exits),
            getFillColor: d => [10, 250, 0, 100],
            getLineColor: d => [0, 100, 0]
        });
    }

    renderLayers() {
        return this.createLocationPinLayer()
    }
}