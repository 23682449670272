import { Component, Inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { BaseFormDialog } from "@app/dialogs/base-form-dialog";
import { NZ_MODAL_DATA, NzModalModule } from "ng-zorro-antd/modal";
import { Job } from "@wearewarp/types/data-model";
import { environment } from "@env/environment";
import { UIHelper } from "@services/UIHelper";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzInputModule } from "ng-zorro-antd/input";
import { lastValueFrom } from "rxjs";
import { InputHelper } from "@services/input-helper";
import { DialogService } from '@app/dialogs/dialog.service';
import { ChangeDriverComponent } from './change-driver';
import { FormDataAssignDriver } from "./interface";
import { Utils } from '@services/utils';

interface InputEditDriverDialog {
  jobId: string;
  carrierId?: string;
  action: 'assign-driver' | 'assign-secondary-driver';
}

const CustomInputs = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NzFormModule,
  NzButtonModule,
  NzIconModule,
  NzRadioModule,
  NzModalModule,
  NzTabsModule,
  NzSelectModule,
  NzInputModule
]

@Component({
  selector: 'edit-driver-dialog',
  standalone: true,
  imports: CustomInputs,
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class EditDriverDialog extends BaseFormDialog {
  private _jobId: string;

  constructor(@Inject(NZ_MODAL_DATA) data: InputEditDriverDialog) {
    super(data);
    this.jobId = data.jobId;
    this.action = data.action;
    this.carrierId = data.carrierId;
  }

  set jobId(value: string) {
    this._jobId = value;
    this.getData();
  }
  get jobId() { return this._jobId; }

  set action(value: 'assign-driver' | 'assign-secondary-driver') {
    if (value == 'assign-driver') {
      this.tabIndex = 0;
    } else if (value == 'assign-secondary-driver') {
      this.tabIndex = 1;
    }
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    assignedDriver: { label: 'Driver', type: "formGroup", notAcceptEmpty: true, childItem: {
      driverId: { label: "Driver", required: false, notAcceptEmpty: true },
    }},
    assignedSecondaryDrivers: { label: 'Secondary Driver', type: 'formArray', initialValue: [{}], childItem: {
      driverId: { label: "Secondary Driver", required: false},
    }},
    trailerNumber: { label: "Trailer Number", type: 'string', notAcceptEmpty: true },
  }

  private jobModel: Job
  public driverId;
  public carrierId;
  public driverToken;
  public tabIndex = 0; // 0: assign driver, 1: assign secondary driver
  public isLoading: boolean = false;
  public isJobStarted;
  public isShowSecondaryDriverTab: boolean = false;
  public shouldDisableDriverTab = false;
  public allDrivers: any[] = [];

  public isSubmitting: boolean = false;
  public isUnassigningDriver: boolean = false;
  public isUnassigningSecondaryDriver: boolean = false;

  get headerText() {
    return this.jobModel?.assignedDriver?.driverId ? 'Edit driver' : 'Assign driver';
  }

  get btnSubmitLabel() {
    return this.jobModel?.assignedDriver?.driverId ? 'Update' : 'Assign';
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  private async getData() {
    this.isLoading = true;
    const url = `${environment.backendWorkQueueUrl}/v1/jobs/${this.jobId}`;
    this.api.GET(url).subscribe({
      next: async (resp) => {
        this.jobModel = resp.data;
        let modelData: any = {};
        if (this.jobModel?.assignedDriver?.driverId) {
          modelData.assignedDriver = this.jobModel.assignedDriver;
          this.driverId = this.jobModel.assignedDriver.driverId;
          this.driverToken = this.jobModel.assignedDriver.token;
        }
        if (this.jobModel?.assignedSecondaryDrivers?.[0]?.driverId) {
          modelData.assignedSecondaryDrivers = this.jobModel.assignedSecondaryDrivers ?? [{ driverId: null }];
        } else {
          modelData.assignedSecondaryDrivers = [{ driverId: null }];
        }
        if (this.jobModel?.trailerNumber) {
          modelData.trailerNumber = this.jobModel.trailerNumber;
        }
        this.model = modelData;
        let vehicle = this.jobModel?.requiredVehicle;
        if ((vehicle?.options ?? []).includes('TEAM')) {
          this.isShowSecondaryDriverTab = true;
        }
        if (!this.jobModel?.assignedCarrier?.carrierId) {
          this.shouldDisableDriverTab = true;
        }
        this.isJobStarted = this.getIsJobStarted(this.jobModel);
        await this.getAllDrivers(this.jobModel?.assignedCarrier?.carrierId);
        this.getDataDone();
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        UIHelper.showErr(err);
      }
    })
  }

  private getDataDone() {
    if (this.isJobStarted) {
      this.formGroupDeclaration.trailerNumber.readOnly = true;
      this.formGroupDeclaration.trailerNumber.submitReadOnly = true;
    }
    this.createFormInput(this.model);
    this.setEnableFormGroup(true);
  }

  private async getAllDrivers(carrierId: string) {
    const url = `${environment.backendWorkQueueUrl}/v1/carriers/${carrierId}/drivers`;
    const resp = await lastValueFrom(this.api.GET(url));
    this.allDrivers = (resp.data?.list_data ?? []).map(driver => {
      return {
        id: driver.id,
        label: this.getDriverNameWithPhone(driver),
        name: Utils.getFullName(driver),
        phone: this.formatPhone(driver.phone)
      }
    });
  }

  public getDriverNameWithPhone(driver): string {
    if (driver?.phone) return `${Utils.getFullName(driver)}, ${this.formatPhone(driver?.phone)}`
    return Utils.getFullName(driver) ?? '';
  }
  public getDriverName(driver): string {
    return Utils.getFullName(driver);
  }

  formatPhone(phone: string) {
    return InputHelper.formatPhone(phone);
  }

  onTabChange(index){
    this.tabIndex = index;
  }
  
  onBtnChangeDriverWhileJobStarted() {
    DialogService.openFormDialog(ChangeDriverComponent, {
      nzData: {
        jobId: this.jobId,
        carrierId: this.carrierId,
        driverId: this.driverId,
        allDrivers: this.allDrivers,
      },
      nzTitle: 'Change Driver',
      nzWidth: 700,
      nzClassName: 'change-driver-dialog',
      nzClosable: true,
    });
  }

  onBtnUnassignDriver() {
    UIHelper.confirmYesNo('Do you really want to unassign driver?', () => {
      this.isUnassigningDriver = true;
      const jobId = this.jobId;
      const url = `${environment.backendWorkQueueUrl}/v1/jobs/${jobId}/unassign_driver`;
      this.api.PUT(url).subscribe({
        next: (resp) => {
          this.isUnassigningDriver = false;
          UIHelper.showSuccess('Driver unassigned successfully');
          this.onUpdateSuccess(resp);
        },
        error: (err) => {
          UIHelper.showErr(err);
          this.isUnassigningDriver = false;
        }
      });
    })
  }

  onBtnChangeSecondaryDriver() {

  }
  
  onBtnUnassignSecondaryDriver() {
    UIHelper.confirmYesNo('Do you really want to unassign secondary driver?', () => {
      this.isUnassigningSecondaryDriver = true;
      const jobId = this.jobId;
      const url = `${environment.backendWorkQueueUrl}/v1/jobs/${jobId}/unassign_secondary_driver`;
      this.api.PUT(url).subscribe({
        next: (resp) => {
          this.isUnassigningSecondaryDriver = false;
          UIHelper.showSuccess('Secondary driver unassigned successfully');
          this.onUpdateSuccess(resp);
        },
        error: (err) => {
          UIHelper.showErr(err);
          this.isUnassigningSecondaryDriver = false;
        }
      });
    })
  }

  onBtnCreateNewDriver() {
    
  }

  onBtnSubmit() {
    if (this.tabIndex == 0) {
      this.onBtnAssignDriver();
    } else if (this.tabIndex == 1) {
      this.onBtnAssignSecondaryDriver();
    }
  }

  onBtnAssignDriver() {
    let data: FormDataAssignDriver = this.getFormData_JSON(true);
    const params = data.assignedDriver;
    if (params.driverId == this.model.assignedSecondaryDrivers?.[0]?.driverId) {
      UIHelper.confirmYesNo('This driver is currently a secondary driver, do you want to change to primary driver?', () => {
        this.doAssignDriver(params);
      });
    } else {
      this.doAssignDriver(params);
    }
  }

  doAssignDriver(params) {
    this.isSubmitting = true;
    const url = `${environment.backendWorkQueueUrl}/v1/jobs/${this.jobId}/assign_driver`;
    this.api.PUT(url, params).subscribe({
      next: (resp) => {
        this.isSubmitting = false;
        UIHelper.showSuccess('Driver assigned successfully');
        this.onUpdateSuccess(resp);
      },
      error: (err) => {
        this.isSubmitting = false;
        UIHelper.showErr(err);
      }
    })
  }

  onBtnAssignSecondaryDriver() {
    let data: FormDataAssignDriver = this.getFormData_JSON(true);
    const driverIds = (data.assignedSecondaryDrivers ?? []).map(it => it.driverId);
    if (!driverIds.length) {
      UIHelper.showErr('Please select at least one secondary driver');
      return;
    }
    this.isSubmitting = true;
    const url = `${environment.backendWorkQueueUrl}/v1/jobs/${this.jobId}/assign_secondary_driver`;
    this.api.PUT(url, { driverIds }).subscribe({
      next: (resp) => {
        this.isSubmitting = false;
        UIHelper.showSuccess('Secondary driver assigned successfully');
        this.onUpdateSuccess(resp);
      },
      error: (err) => {
        this.isSubmitting = false;
        UIHelper.showErr(err);
      }
    })
  }

  private getIsJobStarted(job): boolean {
    const tasks = job?.tasks ?? [];
    const startedTasks = tasks.filter(task => task.status && task.status != WarpConst.TaskStatus.created);
    //nếu task chưa start thì coi như chưa bắt đầu.
    if (!startedTasks.length) {
      return false;
    }
    switch (job.status) {
      case WarpConst.JobStatus.inProgress:
      case WarpConst.JobStatus.completed:
        return true;
      default:
        return false;
    }
  }

}

