import { Injectable, TemplateRef, Type } from '@angular/core';
import { IBaseForm } from '@wearewarp/ng-form-2';
import { NzModalService, ModalOptions, ModalButtonOptions, NzModalRef, OnClickCallback } from 'ng-zorro-antd/modal';
import { isObservable, Observable } from 'rxjs';
import { addCssClass } from './strHelper';

/**
 * Edit mode patch thì khi submit chỉ lấy những trường có thay đổi, default thì sẽ luôn lấy hết các trường trong form.
 * Mặc định là default.
 * */
type FormEditMode = 'patch' | 'default';

/**
 * @deprecated use IBaseForm instead
 */
export type ModalFormInterface<D> = IBaseForm<D>;

/** Tham khảo https://ng.ant.design/version/13.4.x/components/modal/en#nzmodalservice */
export interface OpenModalOptions<T> extends ModalOptions<T> {
  skipDefaultNzClassName?: boolean,             /** Mặc định thì sẽ luôn gán class "custom-dialog" vào trường nzClassName */
  skipDefaultNzWarpClassName?: boolean,         /** Mặc định thì sẽ luôn gán class "custom-dialog-wrap" vào trường nzWrapClassName */
  canCloseWhileSubmiting?: boolean,             /** Sau khi đã ấn button submit và đang xoay xoay thì có cho đóng modal lại không (mặc định là không) */
  labelBtnOK?: string,
  labelBtnCancel?: string,
  editMode?: FormEditMode,
  /**
   * Nếu dùng với openForm() thì không cần các hàm callback onOK, onCancel.
   * Hàm openForm() sẽ tự build các button OK, Cancel và xử lý theo ModalFormInterface.
   */
  onOK?: (componentInstance: T) => void | Observable<any>,
  onCancel?: (componentInstance: T) => void,
  onSubmitError?: (err) => void,
  onSubmitSucceeded?: (resp) => void, 
  canSubmit?: (componentInstance: T) => boolean,  /** Chỉ áp dụng cho hàm open(), hàm openForm() thì sẽ gọi hàm của IBaseForm */
}

export interface ConfirmDeleteOptions {
  message: string,
  txtBtnOk?: string,
  txtBtnCancel?: string,
  center?: boolean,
  fnOk: () => void,
  fnCancel?: () => void
}

/**
 * Helper class để tạo modal dùng thư viện ng-zorro-antd.
 * Mục đích là để chuẩn hoá styles + 1 số behaviours chung.
 */
@Injectable()
export class ModalHelper {
  constructor(private modalService: NzModalService) {
  }

  private buildOptions<T>(options?: OpenModalOptions<T>): ModalOptions<T> {
    /** Tách các trường riêng của OpenModalOptions ra để tạo object ModalOptions chuẩn */
    const {skipDefaultNzClassName, skipDefaultNzWarpClassName, labelBtnOK, labelBtnCancel, canCloseWhileSubmiting, onOK, onCancel, ..._ops} = options ?? {};
    const defaultOptions: ModalOptions<T> = {nzClosable: true, nzMaskClosable: false, nzKeyboard: false};
    const ops: ModalOptions<T> = Object.assign(defaultOptions, _ops);
    if (!skipDefaultNzClassName) {
      ops.nzClassName = addCssClass('warp-ng-libs-modal', ops.nzClassName);
    }
    if (!skipDefaultNzWarpClassName) {
      ops.nzWrapClassName = addCssClass('warp-ng-libs-modal-wrap', ops.nzWrapClassName);
    }
    return ops;
  }

  /** Dùng để tạo modal bình thường (không phải là form nhập dữ liệu) */
  public open<T = any>(content: string | TemplateRef<any> | Type<T>, options?: OpenModalOptions<T>) {
    const {labelBtnOK, labelBtnCancel} = options ?? {};
    const ops = this.buildOptions(options);
    const closable = ops.nzClosable;
    const shouldPreventCloseWhileSubmiting = !options?.canCloseWhileSubmiting;
    let modalRef: NzModalRef;
    let isSubmitOnProgress = false;
    let defaultBtnOK: ModalButtonOptions = {
      label: labelBtnOK ?? 'OK',
      type: 'primary',
      loading: (componentInstance: T) => {
        return isSubmitOnProgress;
      },
      disabled: (componentInstance: T) => {
        if (typeof options?.canSubmit == 'function') {
          const canSubmit: boolean = options.canSubmit(componentInstance);
          return !canSubmit;
        }
        return false;
      },
      onClick: (componentInstance: T) => {
        isSubmitOnProgress = true;
        if (closable && shouldPreventCloseWhileSubmiting) {
          modalRef.updateConfig({nzClosable: false});
        }
        const fnOkRet = options?.onOK?.(componentInstance);
        if (isObservable(fnOkRet)) {
          fnOkRet.subscribe({
            next: resp => {
              isSubmitOnProgress = false;
              options?.onSubmitSucceeded?.(resp);
              modalRef?.destroy();
            },
            error: err => {
              isSubmitOnProgress = false;
              if (options?.onSubmitError) {
                options?.onSubmitError?.(err);
              } else {
                console.error(err);
              }
              modalRef.updateConfig({nzClosable: closable});
            }
          });
        } else {
          isSubmitOnProgress = false;
          modalRef?.destroy();
          return;
        }
      }
    };
    let defaultBtnCancel: ModalButtonOptions = {
      label: labelBtnCancel ?? 'Cancel',
      type: 'default',
      disabled: (componentInstance: T) => {
        return shouldPreventCloseWhileSubmiting && isSubmitOnProgress;
      },
      onClick: (componentInstance: T) => {
        options?.onCancel?.(componentInstance);
        modalRef?.destroy();
      }
    }
    ops.nzContent = content;
    if (ops.nzFooter === undefined) {
      ops.nzFooter = [defaultBtnCancel, defaultBtnOK];
    }
    modalRef = this.modalService.create(ops);
    return modalRef;
  }

  /**
   * Dùng để tạo form nhập dữ liệu, có 2 button submit + cancel.
   * Button submit sẽ không ấn được nếu form data không thay đổi gì, hoặc form validation có lỗi.
   * Khi ấn submit thì sẽ gọi hàm submit và hiển thị progress xoay xoay.
   * Submit thành công thì đóng modal.
   */
  public openForm<D, T extends IBaseForm<D>>(component: Type<T>, options?: OpenModalOptions<T>) {
    const {labelBtnOK, labelBtnCancel, editMode} = options ?? {};
    const ops = this.buildOptions(options);
    const closable = ops.nzClosable;
    const shouldPreventCloseWhileSubmiting = !options?.canCloseWhileSubmiting;
    let modalRef: NzModalRef;
    let isSubmitOnProgress = false;
    let defaultBtnOK: ModalButtonOptions = {
      label: labelBtnOK ?? 'OK',
      type: 'primary',
      loading: (componentInstance: T) => {
        return isSubmitOnProgress;
      },
      disabled: (componentInstance: T) => {
        if (typeof componentInstance?.canSubmit != 'function') {
          return false;
        }
        return !componentInstance.canSubmit();
      },
      onClick: (componentInstance: T) => {
        isSubmitOnProgress = true;
        if (closable && shouldPreventCloseWhileSubmiting) {
          modalRef.updateConfig({nzClosable: false});
        }
        const formData = editMode == 'patch' ? componentInstance.getFormData() : componentInstance.getFormData();
        const ret = componentInstance.submit(formData)
        if (isObservable(ret)) {
          ret.subscribe({
            next: resp => {
              isSubmitOnProgress = false;
              options?.onSubmitSucceeded?.(resp);
              modalRef?.destroy();
            },
            error: err => {
              isSubmitOnProgress = false;
              if (options?.onSubmitError) {
                options?.onSubmitError?.(err);
              } else {
                console.error(err);
              }
            }
          });
        } else {
          isSubmitOnProgress = false;
          modalRef?.destroy();
          return;
        }
      },
    };
    let defaultBtnCancel: ModalButtonOptions = {
      label: labelBtnCancel ?? 'Cancel',
      type: 'default',
      disabled: (componentInstance: T) => {
        return shouldPreventCloseWhileSubmiting && isSubmitOnProgress;
      },
      onClick: (componentInstance: T) => {
        modalRef?.destroy();
      },
    }
    ops.nzContent = component;
    if (ops.nzFooter === undefined) {
      ops.nzFooter = [defaultBtnCancel, defaultBtnOK];
    }
    modalRef = this.modalService.create(ops);
    return modalRef;
  }

  confirmDelete(ops: ConfirmDeleteOptions) {
    let fnCancel = ops.fnCancel;
    if (typeof fnCancel != 'function') {
      fnCancel = () => {
      }
    }
    this.modalService.confirm({
      nzTitle: ops.message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: ops.center ?? true,
      nzOkText: ops.txtBtnOk || 'Delete',
      nzOnOk: ops.fnOk,
      nzOkDanger: true,
      nzCancelText: ops.txtBtnCancel || 'Cancel',
      nzOnCancel: fnCancel
    });
  }

  confirmYesNo(message: string, onOk: OnClickCallback<void>) {
    this.modalService.confirm({
      nzTitle: message,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'Yes',
      nzOnOk: onOk,
      nzCancelText: 'No',
    });
  }
}
