<div class="stop-details-container">
<div class="stop-details" *ngIf="selectedStop">
  <div class="stop-header">
    <div class="stop-title">
      <h3>{{ selectedStop.info?.locationName }}, {{ selectedStop.info?.addr.street }}, 
        <span *ngIf="selectedStop.info?.addr.street2">
          {{ selectedStop.info?.addr.street2 }}
        </span>
        {{ selectedStop.info?.addr.city }}, {{ selectedStop.info?.addr.state }} {{ selectedStop.info?.addr.zipcode }} 
      </h3>
      <div *ngIf="!isUpdatingTaskStatus" class="stop-status" [class]="getStatusClass(selectedStop.status)" (click)="openUpdateTaskStatusModal()">
        <span>{{ getStatusText(selectedStop.status) }}</span>
        <i class="edit-icon" nz-icon nzType="edit" nzTheme="outline"></i>
      </div>
      <div *ngIf="isUpdatingTaskStatus"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    </div>
  </div>

  <div class="stop-info">
    <div class="schedule-container">
      <div class="schedule-info">
        <div class="info-row">
          <div class="info-group">
              <display-appointment [label]="isScheduled ? 'Appointment' : 'Scheduled'"
                [appointment]="appointment"
              [timezone]="timezone" [scheduled]="isScheduled"></display-appointment>
            <span *ngIf="!isScheduled" class="btn-update" (click)="onBtnUpdateClick('update-schedule')" 
            nz-tooltip nzTooltipTitle="Update Schedule">
              <i nz-icon nzType="edit" nzTheme="twotone" nzTwotoneColor="#1890ff"></i>
            </span>
          </div>
    </div>
    <div class="info-row">
      <div class="info-group">
        <div class="info-label">Arrived</div>
        <div class="info-value">{{ getArrivalTime() }} 
          <span *ngIf="isEnableUpdate" class="btn-update" (click)="onBtnUpdateClick('change-actual-time')" 
          nz-tooltip nzTooltipTitle="Update Actual Time">
            <i nz-icon nzType="edit" nzTheme="twotone" nzTwotoneColor="#1890ff"></i>
          </span>   
        </div>
      </div>
    </div>
    <div class="info-row">
      <div class="info-group">
        <div class="info-label">Departed</div>
        <div class="info-value">{{ getDepartureTime() }}       
          <span *ngIf="isEnableUpdate" class="btn-update" (click)="onBtnUpdateClick('change-actual-time')" 
          nz-tooltip nzTooltipTitle="Update Actual Time">
            <i nz-icon nzType="edit" nzTheme="twotone" nzTwotoneColor="#1890ff"></i>
          </span>   
        </div> 
      </div>
    </div>
      </div>
      <div class="time-summary-container" *ngIf="isInitialSelectedStop">
        <app-load-time-summary></app-load-time-summary>
      </div>
    </div>
    <div class="info-row" *ngIf="delayInfo">
      <div class="info-group">
        <div class="info-label">Delay reason</div>
        <div class="info-value" [ngClass]="{'cursor-pointer': delayInfo?.note}" 
            [nz-popover]="delayInfo?.note ? '' : null"
            [nzPopoverContent]="delayInfo?.note ? noteDelay : null" 
            [nzPopoverPlacement]="'top'" 
            [nzPopoverOverlayClassName]="'popover-delay-reason'">
          <span nz-icon nzType="warning" nzTheme="twotone" nzTwotoneColor="#faad14"></span>
          <span style="margin: 0 8px;">{{delayInfo?.failureCode || 'N/A'}}</span>
          <ng-container *ngIf="delayInfo?.note">
            <span nz-icon nzType="info-circle" nzTheme="outline"></span>
          </ng-container>
          <ng-template #noteDelay class="popover-delay-reason">
            {{delayInfo?.note}}
          </ng-template>
        </div>
      </div>
    </div>
    <div class="info-row pod">
      <ng-container *ngIf="totalPods == 0 && isEnableUpdate">
        <nz-badge>
          <button nz-button nzType="default" nzSize="small"
          (click)="onBtnAddPod()">
            Add POD
          </button>
        </nz-badge>
      </ng-container>
        <ng-container *ngIf="totalPods != 0 && isEnableUpdate">
          <nz-badge [nzCount]="podNotConfirmed" [nzOverflowCount]="9">
            <button nz-button nzType="default" nzSize="small" (click)="onBtnViewPod()">View POD
              ({{totalPods}})</button>
          </nz-badge>
        </ng-container>
        <ng-container *ngIf="totalPods != 0 && !isEnableUpdate">
          <nz-badge [nzCount]="podNotConfirmed" [nzOverflowCount]="9">
            <div style="padding: 4px 8px; border: 1px #d9d9d9 solid">POD ({{totalPods}})</div>
          </nz-badge>
        </ng-container>
    </div>
  </div>
  <div class="stop-tasks-container">
    <div class="shipment-toggle" (click)="toggleTasks()">
      <span>Shipment ({{ selectedStop.taskIds?.length || 0 }})</span>
      <i nz-icon [nzType]="showTasks ? 'up' : 'down'" nzTheme="outline"></i>
    </div>
    <app-stop-tasks *ngIf="showTasks" [taskIds]="selectedStop.taskIds" [stopId]="selectedStop.id"></app-stop-tasks>
  </div>

  <div class="location-instructions" *ngIf="selectedStop.info?.instructions">
    <h4>Location Instructions</h4>
    <div class="instructions-content">
      {{ selectedStop.info.instructions }}
    </div>
  </div>
</div>