<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>
<div *ngIf="isLoading && inConfirm" class="text-center bold">Email sending in progress. Please wait a moment...</div>

<div class="confirm-content" *ngIf="inConfirm && !isLoading">
  <div class="email-load-tender-popup">
    <div class="title">Email Load Tender</div>
    <div class="description">Recipients will receive an email with a link to download Load Tender.</div>
  </div>
  <div class="confirm-content">

    <div nz-row class="bottom15">
      <div nz-col nzSpan="24"><b>Sender:</b></div>
      <!-- <div nz-col nzSpan="24">{{authUserFullName}} / {{authUserEmail}}</div> -->
      <div nz-col nzSpan="24">dispatch&#64;wearewarp.com</div>
    </div>

    <div nz-row class="bottom15">
      <div nz-col nzSpan="24"><b>Carrier's Email Address:</b></div>
      <div nz-col nzSpan="24">{{confirmData?.contactEmail}}</div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24"><b>Remark:</b></div>
      <div nz-col nzSpan="24">{{confirmData?.remark}}</div>
    </div>
  </div>
</div>

<form *ngIf="!isLoading && !inConfirm" class="form-detail" [formGroup]="formInput" nz-form nzLayout="vertical">
  <div class="email-load-tender-popup">
    <div class="title">Email Load Tender</div>
    <div class="description">Recipients will receive an email with a link to download Load Tender.</div>
  </div>

  <nz-form-item>
    <nz-form-label nzRequired nzFor="email">Carrier's Email Address</nz-form-label>
    <nz-form-control nzErrorTip="The input is not valid E-mail!">
      <nz-input-group [nzAddOnAfter]="editBtnTemp">
        <input nz-input formControlName="contactEmail" id="email" />
      </nz-input-group>
      <ng-template #editBtnTemp>
        <a (click)="onEditEmail()">
          Edit
        </a>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzFor="remark">Remark</nz-form-label>
    <nz-form-control>
      <textarea nz-input formControlName="remark" id="remark" placeholder="Write a message..."></textarea>
    </nz-form-control>
  </nz-form-item>
  <div class="notice" *ngIf="lastSent">
    This Load Tender was sent on {{lastSent}}. To send a reminder, send Load Tender again.
  </div>
</form>

<div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress"
  [labelOK]="inConfirm? 'Confirm': 'Email Load Tender'" (onOK)="onBtnSave()" (onCancel)="onBtnCancel()"></div>