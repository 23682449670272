<div class="route-notes">
  <div class="input-note">
    <div class="input-area">
      <textarea
        nz-input
        [(ngModel)]="noteContent"
        [nzAutosize]="{ minRows: 3, maxRows: 5 }"
        placeholder="Note tasks here"
      >
      </textarea>
      
      <div class="input-images">
        <input-upload-images #uploadImagesForm 
          [visible]="!isLoading && !isSubmitting" 
          (fileSelectedChange)="onFileImageSelectedChange($event)">
        </input-upload-images>
      </div>

      <div class="quick-actions" [class.has-content]="noteContent.trim()">
        <div class="tags">
          <div class="tag still-on-track" (click)="selectQuickNote('still-on-track')">
            Still on track
          </div>
          <div class="tag not-responsive" (click)="selectQuickNote('not-responsive')">
            Not responsive
          </div>
        </div>
      </div>
    </div>

    <div class="image-previews" *ngIf="uploadImages.length > 0">
      <div *ngFor="let image of uploadImages; let i = index" class="preview-item">
        <img [src]="getImagePreviewUrl(image)" class="preview-image">
        <button class="remove-image" (click)="removeImage(i)" *ngIf="!isSubmitting">×</button>
      </div>
    </div>

    <div class="input-actions" *ngIf="noteContent || uploadImages.length > 0">
      <div class="input-actions-buttons">
        <div class="button cancel-input" [ngClass]="{'disabled': isSubmitting}" (click)="resetNote()">Cancel</div>
        <div class="button save-input" [ngClass]="{'disabled': isSubmitting || (!noteContent && uploadImages.length === 0)}" (click)="onSaveNote()">
          Save<span *ngIf="isSubmitting" nz-icon nzType="loading" nzSpin nzTheme="outline" class="left5"></span>
        </div>
      </div>
    </div>
  </div>
  <nz-divider></nz-divider>
  <!-- list content -->
  <div note-list></div>
</div>