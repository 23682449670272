import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Job } from '@wearewarp/types/data-model';
import { TrackTraceService } from '../../../../services/track-trace.service';
import { InputHelper } from '@services/input-helper';
import { DialogService } from '@app/dialogs/dialog.service';
import { ConfirmUnassignCarrierDialog } from '@app/components/confirm-unassign-carrier';
import { Const as WarpConst } from '@wearewarp/universal-libs';
import { RouterModule } from '@angular/router';
import { environment } from '@env/environment';
import { CarrierService } from '@app/track-trace/services/carrier.service';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { Utils } from '@services/utils';
import { UIHelper } from '@services/UIHelper';

@Component({
  selector: 'app-carrier-info',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzRateModule,
    NzAlertModule,
    NzIconModule,
    NzToolTipModule,
    ConfirmUnassignCarrierDialog,
    RouterModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './carrier-info.component.html',
  styleUrl: './carrier-info.component.scss'
})
export class CarrierInfoComponent {
  hasActiveLoads = true;
  rating = 4.5;
  isLoading = false;
  public loadInfo: Job;

  constructor(private trackTraceService: TrackTraceService, private carrierService: CarrierService) {
    this.trackTraceService.getLoadInfo().subscribe(loadInfo => {
      this.loadInfo = loadInfo;
    });
  }

  get showUnassignCarrier() {
    return this.loadInfo?.assignedCarrier?.carrierId 
          && this.loadInfo.status !== WarpConst.JobStatus.completed
          && this.loadInfo.status !== WarpConst.JobStatus.inProgress;
  }

  getCarrierName() {
    return this.loadInfo.carrier?.basicInfo?.name;
  }

  getCarrierContact() {
    const contact = this.loadInfo.carrier?.basicInfo?.contacts?.[0];
    const name = contact?.fullName ?? `${contact?.firstName} ${contact?.lastName}`;
    const phone = InputHelper.formatPhone(contact?.phone);
    const email = contact?.email;
    return {
      name,
      phone,
      email
    }
  }

  getCarrierRating() {
    // TODO: get rating from server
    return {
      score: 4.5,
      reviews: 34
    }
  }
  
  goToCarrierInfo()  {
    const carrierId = this.loadInfo?.carrier?.id;
    this.isLoading = true;
    if (!carrierId) {
      return;
    }
    const carrierUrl = `${environment.webAdminUrl}/dashboard/carriers/${carrierId}`;
    window.open(carrierUrl, '_blank');
    this.isLoading = false;
  }
  onBtnUnassignCarrier() {
    const jobId = this.loadInfo.id;
    const jobInfo = this.loadInfo;
    DialogService.openFormDialog(ConfirmUnassignCarrierDialog, {
      nzData: {
        jobId,
        jobInfo,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.onUnassignCarrierSuccess(resp);
        }
      },
      nzMaskClosable: false,
      nzClassName: 'confirm-unassign-carrier-dialog',
    })
  }

  onUnassignCarrierSuccess(resp) {
    this.trackTraceService.refreshLoadInfo();
  }

  copyToClipboard(text: string) {
    Utils.copyTextToClipboard(text, (err) => {
      if (err) {
        UIHelper.showErr(
          'Copy failed, please try again'
        );
      } else {
        UIHelper.showSuccess(
          'Successfully',
          'Copied to clipboard'
        );
      }
    });
  }
}
