<div class="carrier-info">
  <div class="header">
    <h3>Carrier</h3>
    <button nz-button *ngIf="showUnassignCarrier" nzType="link" class="unassign-btn" (click)="onBtnUnassignCarrier()">Unassign carrier</button>
  </div>

  <div class="carrier-details">
    <div class="carrier-name">
      <div class="left-group">
        <div (click)="goToCarrierInfo()" class="name">{{getCarrierName()}}</div>
        <!-- <div class="rating">
          <span class="score">{{getCarrierRating().score}}</span>
          <nz-rate [ngModel]="getCarrierRating().score" [nzAllowHalf]="true"></nz-rate>
          <span class="reviews">({{getCarrierRating().reviews}} reviews)</span>
        </div> -->
      </div>
      <!-- <div class="actions">
        <button nz-button nzType="link">
          <i nz-icon nzType="star"></i>
          Rate carrier
        </button>
      </div> -->
    </div>

    <div class="contact-info">
      <div class="contact-item">
        <img src="assets/svg/account_circle.svg" alt="carrier" class="carrier-icon">
        <span class="value">{{getCarrierContact().name}}</span>
      </div>
      <div class="contact-item">
        <!-- <a href="tel:{{getCarrierContact().phone}}" class="value">{{getCarrierContact().phone}}</a> -->
        <span nz-icon nzType="phone" nzTheme="outline"></span>
        <span class="value">{{getCarrierContact().phone}}</span>
        <span class="copy-icon" (click)="copyToClipboard(getCarrierContact().phone)" nz-tooltip nzTooltipTitle="Copy phone number">
          <i nz-icon nzType="copy"></i>
        </span>
      </div>
      <div class="contact-item">
        <!-- <a href="mailto:{{getCarrierContact().email}}" class="value">{{getCarrierContact().email}}</a> -->
        <span nz-icon nzType="mail" nzTheme="outline"></span>
        <span class="value">{{getCarrierContact().email}}</span>
        <span class="copy-icon" (click)="copyToClipboard(getCarrierContact().email)" nz-tooltip nzTooltipTitle="Copy email">
          <i nz-icon nzType="copy"></i>
        </span>
      </div>
    </div>

    <!-- <div class="active-loads" *ngIf="hasActiveLoads">
      <nz-alert nzType="warning" [nzMessage]="'Carrier is having 02 more loads'" [nzShowIcon]="true">
      </nz-alert>
    </div> -->
  </div>
</div>
