import { CompositeLayer, Layer, LayersList } from "@deck.gl/core";
import { Network } from "@app/interfaces/map-v2/net-work";
import { IconLayer, ScatterplotLayer, ArcLayer, LineLayer } from "@deck.gl/layers";
import { getLngLat, getLatitude, getLongitude } from "@app/utils/map-v2.util";
import { MAP_PIN_ICON_MAPPING, warehousePinMapping } from "./icon";

export default class NetworkLayer extends CompositeLayer {

    network: Network

    constructor(network: Network) {
        super({data: network})
        this.id = `Network-${network.id}`
        this.network = network
        this.createMap()
    }

    public createMap() {
        this._dotLayer = this.createDotLayer()
        this._iconLayer = this.createIconLayer()
        this._pinLayer = this.createMapPinLayer()
        this._arcLayer = this.createArcLayer()
        this._lineLayer = this.createLineLayer()
    }

    override renderLayers(): Layer | null | LayersList {
        return [this._lineLayer, this._arcLayer, this._dotLayer, this._iconLayer, this._pinLayer]
    }

    // layers
    _iconLayer: Layer | null
    _pinLayer: Layer | null
    _dotLayer: Layer | null
    _arcLayer: Layer | null
    _lineLayer: Layer | null

    private sortByLocation(nodes: any[]): any[] {
        return nodes.sort((a, b) => {
            // First sort by latitude (north to south)
            const latA = getLatitude(a.location)
            const latB = getLatitude(b.location)
            if (latA !== latB) {
                return latB - latA // Higher latitude (north) comes first
            }
            
            // If latitude is the same, sort by longitude (west to east)
            const lngA = getLongitude(a.location)
            const lngB = getLongitude(b.location)
            return lngA - lngB
        })
    }

    createDotLayer() {
        const dots = this.sortByLocation(
            this.network.nodes.filter(it => it.displayType == 'DOT')
        ).map(it => Object.assign({
            type: 'NETWORK-NODE',
            coordinates: getLngLat(it.location),
            color: it.styles?.['color'] ? this.convertHexColorToRGB(it.styles?.['color']) : [44, 68, 230],
            fillColor: it.styles?.['fill-color'] ? this.convertHexColorToRGB(it.styles?.['fill-color']) : [255,255,255],
            node: it
        }))

        return new ScatterplotLayer({
            id: `locations-layer-${this.id}`,
            data: dots,
            pickable: true,
            opacity: 1,
            stroked: true,
            filled: true,
            radiusScale: 5,
            radiusMinPixels: 5,
            radiusMaxPixels: 5,
            lineWidthMinPixels: 1,
            lineWidthMaxPixels: 1,
            getPosition: d => d.coordinates,
            getFillColor: d => d.fillColor ?? [255,255,255],
            getLineColor: d => d.color ?? [44, 68, 230],
            parameters: {
                depthTest: false
            }
        });
    }

    createIconLayer() {
        const pins = this.sortByLocation(
            this.network.nodes.filter(it => it.displayType == 'WAREHOUSE_PIN')
        ).map(it => Object.assign({
            type: 'NETWORK-NODE',
            coordinates: getLngLat(it.location),
            icon: it.styles?.['icon'] ?? '0',
            node: it
        }))

        const mapping = warehousePinMapping()
        return new IconLayer({
            id: `warehosue-icon-layer-${this.id}`,
            data: pins,
            pickable: true,
            iconAtlas: '../../../assets/icons/svg/map-v2/warehouse_pin.svg',
            // iconAtlas: 'https://warp-public-media.s3.us-west-2.amazonaws.com/images/svg/icons/warehouse_pin-250205-1.svg',
            iconMapping: mapping,
            getIcon: d => d.icon ?? '1',
            getPosition: d => d.coordinates,
            sizeScale: 29,
            getSize: d => 0.75,
            parameters: {
                depthTest: false
            }
        });
    }

    createMapPinLayer() {
        const pins = this.sortByLocation(
            this.network.nodes.filter(it => it.displayType == 'MAP_PIN')
        ).map(it => Object.assign({
            type: 'NETWORK-NODE',
            coordinates: getLngLat(it.location),
            icon: it.styles?.['icon'] ? it.styles?.['icon'] : 'red',
            node: it
        }))

        const mapping = MAP_PIN_ICON_MAPPING;
        return new IconLayer({
            id: `map-pin-layer-${this.id}`,
            data: pins,
            pickable: true,
            iconAtlas: '/assets/svg/map-v2/map_pin.svg',
            // iconAtlas: 'https://warp-public-media.s3.us-west-2.amazonaws.com/images/svg/icons/map_pin-250203-1.svg',
            iconMapping: mapping,
            getIcon: d => d.icon ?? '1',
            getPosition: d => d.coordinates,
            sizeScale: 29,
            getSize: d => 1,
            parameters: {
                depthTest: false
            }
        });
    }

    createArcLayer() {
        const arcs = this.network.arcs
            .filter(arc => arc.displayType === 'ARC')
            .map(arc => {
                return {
                    type: 'NETWORK-EDGE',
                    from: getLngLat(arc.fromLocation),
                    to: getLngLat(arc.toLocation),
                    sourceColor: [44, 68, 230],
                    targetColor: [250, 173, 20],
                    width: 3,
                    edge: arc
                }
            })
            .filter(arc => arc !== null)

        return new ArcLayer({
            id: `arc-layer-${this.id}`,
            data: arcs,
            pickable: true,
            getWidth: d => d.width,
            getSourcePosition: d => d.from,
            getTargetPosition: d => d.to,
            getSourceColor: d => d.sourceColor,
            getTargetColor: d => d.targetColor,
            greatCircle: true,
            numSegments: 180,
            // Optional: add these if you want height/tilt effects
            // getHeight: d => d.edge.styles?.height ?? 0,
            // getTilt: d => d.edge.styles?.tilt ?? 0,
        })
    }

    createLineLayer() {
        const arcs = this.network.arcs
        .filter(arc => arc.displayType === 'LINE')
        .map(arc => {
            return {
                type: 'NETWORK-EDGE',
                from: getLngLat(arc.fromLocation),
                to: getLngLat(arc.toLocation),
                color: arc.styles?.['color'] ? this.convertHexColorToRGB(arc.styles?.['color']) : [44, 68, 230],
                width: 3,
                edge: arc
            }
        })
        .filter(arc => arc !== null)

        if (arcs.length) {
            return new LineLayer({
                id: `line-layer-${this.id}`,
                data: arcs,
                pickable: true,
                getWidth: d => d.width,
                getSourcePosition: d => d.from,
                getTargetPosition: d => d.to,
                getColor: d => d.color,
            })
        }

        return null
    }

    convertHexColorToRGB(hexColor: string): number[] {
        if (hexColor.startsWith('#')) {
            hexColor = hexColor.slice(1);
        }
        const r = parseInt(hexColor.slice(0, 2), 16);
        const g = parseInt(hexColor.slice(2, 4), 16);
        const b = parseInt(hexColor.slice(4, 6), 16);
        return [r, g, b];
    }
}
